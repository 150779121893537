/*

justify layout

```html
<ul class="just_layout">
	<li class ="just_layout__item"></li>
	<li class ="just_layout__item"></li>
</ul>
```

*/

.just_layout {
	text-align:justify;
	text-justify:distribute-all-lines;
	zoom:1;
	display:block;
	line-height:0;
	&:after{
		line-height:0;
		visibility:hidden;
		content:"";
		display:inline-block;
		width:100%;
	}
	&__item{
		display:inline-block;
		text-align:left;
		vertical-align:top;
		line-height:normal;
	}
}

* html .just_layout .just_layout__item{
	display:inline;
	zoom:1;
}

*:first-child+html .just_layout .just_layout__item{
	display:inline;
	zoom:1;
}
