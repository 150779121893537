/*

キャプション

*/

.caption_lv1,
.caption_lv2 {
    font-feature-settings: "palt";
	letter-spacing: .15em;
	font-weight: 500;
}

.caption_lv1{
	font-size: fs(38);
	margin-bottom: 70px;
    border-bottom: 1px solid $color_line;
    padding-bottom: 10px;

	@include mq(sp) {
		font-size: fs(25);
		line-height: 1.5;
        margin-bottom: 30px;
	}

    span {
        font-size: fs(25,38);
        display: block;

        @include mq(sp) {
            font-size: fs(16,25);
        }
    }
}

.caption_lv2{
	font-size: fs(26);
    font-weight: 700;
    font-family: $family_en_heebo;
	margin-bottom: 50px;
    padding: 10px 30px 7px 30px;

	@include mq(sp) {
		font-size: fs(17);
		line-height: 1.5;
        padding: 10px 20px 7px 20px;
        margin-bottom: 20px;
	}

    &.rod {
        background-color: #0C9BBF;
        color: #fff;
    }

    &.lure {
        background-color: $color_main;
        color: #fff;
    }
}