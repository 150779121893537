/*

UIKIT OffCanvas

*/

.uk-offcanvas {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	touch-action: none;
	background: rgba(0, 0, 0, .1)
}
.uk-offcanvas.uk-active {
	display: block
}
body > *{
	@include transition( filter 500ms ease-in-out );
}
body.is_menu_active {
	height: 100vh;
	overflow:hidden;
	width: auto!important;

	margin-left: 0!important;

	@include mq(sp, true){
		margin-left: auto !important;
		overflow-y:auto;
	}
	.drawer_btn{
		i{
			background-color: #fff!important;
		}
	}

	.uk-offcanvas-bar-flip {
		@include mq(sp, true) {
			right: 50%;
		}

		@include mq(sp) {
			right: 100%;
		}
	}

	.site_header {
		.site_header__nav a{
			color: #fff!important;
		}
	}
}
.uk-offcanvas-page {

	.site_header{
		height: 100%;
		&__inner{
			position: relative;
			z-index: 1003;
		}
		.site_header__id a{
			@include mq(sp){
				color: #fff!important;
			}
		}

		.drawer_nav .uk-offcanvas-bar .lv1 > li > a{
			// color: #fff!important;
		}
		.drawer_nav .uk-offcanvas-bar .lv2{

		}
		@at-root body.top.uk-offcanvas-page .drawer_nav .uk-offcanvas-bar .lv2{
			@include mq(sp, true){
				margin-top: 25px;
				margin-bottom: 30px;
				li + li{
					margin-top: 20px;
				}
			}
		}
	}
	@include mq(sp, true){
		padding-right: 16px;
	}

}
.uk-offcanvas-bar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	z-index: 1001;
	width: 100%;
	@include mq(sp, true){
		width: 50%;
	}
	background: #333;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	-webkit-transition: -webkit-transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
	-ms-scroll-chaining: none
}
.uk-offcanvas.uk-active .uk-offcanvas-bar.uk-offcanvas-bar-show {
	-webkit-transform: translateX(0%);
	transform: translateX(0%)
}
.uk-offcanvas-bar-flip {
	left: auto;
	right: 0;
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}
.uk-offcanvas .uk-panel {
	margin: 20px 15px;
	color: #777
}
.uk-offcanvas .uk-panel a:not([class]), .uk-offcanvas .uk-panel-title {
	color: #ccc
}
.uk-offcanvas .uk-panel a:not([class]):hover {
	color: #fff
}


.drawer_btn{
	@include inline-block;
	cursor: pointer;
	position: absolute;
	z-index: 1003;
	top: 22px;
	right: 10px;
	@include mq(sp $width_liquid){
		right: $padding_liquid;
	}
	@include mq(sp, true){
		right: 45px;
		top: 37px;
		// PC下層は通常のナビ
		// @at-root body.page &{
		// 	display: none;
		// }
	}
	@at-root body.is_menu_active &{
	}
	@at-root body.is_menu_active .close{
		display: block;
		width: 40px;
		height: 30px;
		cursor: pointer;
		position: absolute;
		z-index: 2000;
		top: 0;
		right: 0;
	}
	a{
		display: block;
		width: 40px;
		height: 30px;
	}
	i{
		@include transition( transform 500ms ease-in-out, top 500ms ease-in-out, opacity 500ms ease-in-out, color 0ms ease-in-out );

		display: block;
		width: 100%;
		height: 2px;
		position: absolute;
		left: 0;
		background: #000;
		@for $i from 1 through 3{
			&:nth-child(#{$i}){
				top: 14px * ( $i - 1 );
			}
		}
		@at-root body.is_menu_active &:nth-child(1){
			top: 14px;
			@include rotate( 45deg );
		}
		@at-root body.is_menu_active &:nth-child(2){
			opacity: 0;
		}
		@at-root body.is_menu_active &:nth-child(3){
			top: 14px;
			@include rotate( -45deg );
		}
	}
	&.fix_header a i{
		background: black;
	}
}
.drawer_nav{
	.uk-offcanvas-bar{
		$base-color: rgba(#000, .8);
		@include box-shadow( -2px 0 5px rgba(black, .1) );
		background: $base-color;
		> nav {
			margin: 0 auto;
			@include mq(sp, true){
				width: 74%;
			}
			@include mq(sp) {
				width: 88%;
			}
		}
		@at-root{
			body.top &{
				@include mq(sp, true){
					nav{
						display: table;
						width: 100%;
						height: 100%;
					}
					.lv1{
						padding-top: 0;
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
		}
		.lv1{
			padding-top: 130px;
			@include mq(320px){
				padding-top: 85px;
			}
			@include mq(sp, true){
				padding-top: 140px;
				padding-bottom: 100px;
				&:before{
					display: block;
					font-family: $family_en_heebo;
					content: "MENU";
					color: #fff;
					text-align: center;
					font-size: fs(46);
					margin-bottom: 40px;
					font-weight: 600;
					letter-spacing: 2px;
				}
			}

			@include mq(sp) {
				padding-top: 90px;
				padding-bottom: 50px;
				&:before{
					display: block;
					font-family: $family_en_heebo;
					content: "MENU";
					color: #fff;
					text-align: center;
					font-size: fs(28);
					margin-bottom: 30px;
					font-weight: 600;
					letter-spacing: 2px;
				}
			}
			> li + li{
				margin-top: 5px;
				@include mq(sp, true){
					margin-bottom: 22px;
					&:last-child{
						margin-bottom: 0;
					}
				}

				@include mq(sp) {
					margin-top: 15px;
				}
			}
			> li{
				border-bottom: 1px solid #fff;
				&.attr_top {
					margin-bottom: 30px;
				}
				&.attr_top,
				&.attr_tackle,
				&.attr_charm,
				&.attr_faq {
					a {
						&::before {
							border-color: transparent transparent transparent #fff;
						}
					}
				}
				&.attr_top,
				&.attr_place {
					a {
						font-family: $family_en_heebo;
						font-weight: 600;
						@include mq(sp, true){
							font-size: fs(27);
						}

						@include mq(sp) {
							font-size: fs(24);
						}

						&.disable {
							color: #444 !important;
						}
					}
				}

				> a{
					display: inline-block;
					color: #fff;
					padding: 10px 0;
					text-decoration: none;
					letter-spacing: 2px;
					font-size: fs(20);
					@include mq(sp, true){
						font-size: fs(24);
						@at-root .uk-offcanvas-page .site_header .drawer_nav .uk-offcanvas-bar .lv1 > li > a{
							@include transition(color 300ms ease, padding-left 300ms ease);
						}
					}

					&:hover {
						@include mq(sp,true) {
							padding-left: 20px;
						}
					}
				}
			}
		}
		.lv2{
			display: none;
		}

		a {
			&.arrow {
				&::before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
					position: relative;
					top: 4px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 10px 0 10px 13px;
					border-color: transparent transparent transparent #aaa;
					float: left;
				}

				> span {
					display: block;
					overflow: hidden;
				}
			}
		}
	}
	&__banner{
		text-align: center;
		font-size: fs(14);
		margin-top: 20px;
		a{
			@include box-sizing(border-box);
			display: inline-block;
			width: 140px;
			&:hover{
				text-decoration: none;
			}
			&,
			i,
			i:before{
				color: #fff;
			}
			i{
				vertical-align: middle;
				font-size: fs(17,14);
				margin-right: 5px;
			}
		}
	}
	.uk-offcanvas-bar{
		.lv2{
			font-size: fs(13);
			margin-top: 10px;
			margin-bottom: 25px;
			> li {
				> a {
					color: #aaa!important;
					line-height: 1.5;
					display: block;
					letter-spacing: 1px;
					&:after{
						background-color: #aaa!important;
					}
				}
			}
		}
	}
	.uk-offcanvas-bar .lv1 > li.fn--toggle.selected > a:after{
		width: 100%;
	}
	@include mq(sp, true){
		// カレント表示
		@at-root{
			body.about & .uk-offcanvas-bar .lv1 > li.attr_about > a:after{
				width: 100%;
			}
			body.plan & .uk-offcanvas-bar .lv1 > li.attr_plan > a:after{
				width: 100%;
			}
			body.attractive & .uk-offcanvas-bar .lv1 > li.attr_attractive > a:after{
				width: 100%;
			}
			body.oneday & .uk-offcanvas-bar .lv1 > li.attr_oneday > a:after{
				width: 100%;
			}
			body.faq & .uk-offcanvas-bar .lv1 > li.attr_faq > a:after{
				width: 100%;
			}
		}
		.uk-offcanvas-bar{
			.lv1{
				> li{
					a{
						position: relative;
					}
				}
			}
			.lv2{
				margin-top: 10px;
				margin-bottom: 35px;
				> li{
					> a{
						&:hover{
							text-decoration: none;
						}
					}
				}
				li + li{
					@include mq(sp,true) {
						margin-top: 10px;
					}

					@include mq(sp) {
						margin-top: 15px;
					}
				}
			}
			a {
				transition: padding-left .2s;
				&:hover {
					padding-left: 20px;
				}
			}
		}
	}
}
