.module_attention_table{
	width: 100%;
	text-align: left;

	@include mq(sp, true) {
		margin-top: 30px;
		margin-bottom: 70px;
	}

	@include mq(sp) {
		margin-top: 20px;
		margin-bottom: 30px;
	}

	th,
	td{
		line-height: 1.75;
		padding: 5px 0px;
		font-weight: normal;
		vertical-align: middle;

		@include mq(sp) {
			display: block;
		}
	}
	th{
		font-weight: 600;

		@include mq(sp, true) {
			width: 15%;
		}
	}
	td{
		@include mq(sp, true) {
			width: 85%;
		}
	}
}