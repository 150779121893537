/*

汎用的にページを構成する概要、説明

*/

.module_instagram {
	&__list {
		dl {
			@include mq(sp, true) {
				@include display-flex;
				@include flex-wrap(wrap);
				@include justify-content(space-between);
				@include align-items(flex-start);
			}
		}
		dt {
			font-family: $family_en_heebo;
			font-weight: 600;
			border-bottom: 1px solid $color_line;
			line-height: 1.5;
			letter-spacing: .01em;

			@include mq(sp, true) {
				width: 18%;
				font-size: fs(20);
			}

			@include mq(sp) {
				font-size: fs(14);
				margin-bottom: 10px;
			}
		}

		dd {
			@include mq(sp, true) {
				width: 78%;
			}

			ul {
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				@include flex-wrap(wrap);

				li {
					margin-left: 2%;
					width: 32%;
					margin-bottom: 25px;

					&:nth-child(1n) {
						margin-left: 0;
					}
				}
			}
		}
	}

	&__item {
		& + .home_instagram__item {
			margin-top: 15px;

			@include mq(sp) {
				margin-top: 15px;
			}
		}
	}

	#sb_instagram {
		@include mq(sp) {
			padding-bottom: 20px !important;
		}
	}

	#sb_instagram #sbi_images {
		@include mq(sp) {
			padding: 0 !important;
		}
	}

	#sb_instagram.sbi_col_3.sbi_disable_mobile #sbi_images .sbi_item {
		@include mq(sp) {
			padding: 0 10px 10px 0 !important;
		}

		&:nth-of-type(3n) {
			@include mq(sp) {
				padding-right: 0 !important;
			}
		}
	}
	#sb_instagram #sbi_images .sbi_item {
		@include mq(sp) {
			padding: 0 0 10px 0 !important;
		}
	}
}

.page-template {
	.module_instagram{
		#sb_instagram.sbi_col_3.sbi_disable_mobile #sbi_images .sbi_item {
			@include mq(sp) {
				padding: 0 0 10px 0 !important;
				width: 100%;
				float: none;
			}
		}
	}
}