.align_l{
	text-align:left !important;
}

.align_r{
	text-align:right !important;
}

.align_c{
	text-align:center !important;
}


.valign_m{
	vertical-align:middle;
}

.valign_t{
	vertical-align:top;
}

.valign_b{
	vertical-align:bottom;
}

@each $key, $value in $breakpoints {
	@include mq($key) {
		.#{$key}_align_l{
			text-align:left !important;
		}

		.#{$key}_align_r{
			text-align:right !important;
		}

		.#{$key}_align_c{
			text-align:center !important;
		}


		.#{$key}_valign_m{
			vertical-align:middle;
		}

		.#{$key}_valign_t{
			vertical-align:top;
		}

		.#{$key}_valign_b{
			vertical-align:bottom;
		}
	}
}