.catch_btn{
	background-color: #00a0d4; //受付開始用
	// background-color: trasparent; //プレ用
	// color: #333 !important; //プレ用
	// cursor: default; //プレ用
	&,
	&:hover,
	i,
	i:before{
		color: #fff;
	}

	padding: 10px;
	position: relative;

	&:after{
		@include transition(height 500ms ease);
		@include opacity(.3);
		content: "";
		background-color: #fff;
		width: 100%;
		height: 0;
		position: absolute;
		z-index: 0;
		left: 0;
		bottom: 0;
	}
	&:hover{
		text-decoration: none;
		@include mq(sp, true){
			&:after{
				height: 100%; //受付開始用
			}
		}
	}
	&__inner{
		position: relative;
		z-index: 1;
	}

	&__main,
	&__sub{
		display: block;
		text-align: center;
	}
	&__main{
		font-family: $family_jp_yuh;
		font-feature-settings : "palt";
		letter-spacing: .06em;
		font-size: fs(20);
		i{
			font-size: fs(24, 20);
			margin-right: 8px;
			vertical-align: middle;
		}
	}
	&__sub{
		@include opacity(.4);
		font-family: $family_en_display;
		font-size: fs(13);
		font-weight: normal;
	}

}
