/*

ボタン

*/

.btn--default {
    border: $color_main 2px solid;
    display: inline-block;
    text-align: center;
    min-width:300px;
    box-sizing: border-box;
    position: relative;

    @include mq(sp, true) {
        font-size: fs(17);
        padding: 20px 0;
    }

    @include mq(sp) {
        font-size: fs(14);
        padding: 15px 0;
    }

    &::before {
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        background-color: $color_main;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: scaleX(1);
        transition: transform .3s;
        transform-origin: left center;
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color_main;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transform: scaleX(0);
        transition: transform .3s;
        transform-origin: right center;
    }

    span {
        display: block;
        position: relative;
        z-index: 2;
        transition: color .2s;
    }

    &:hover {
        &::before {
            transform: scaleX(0);
            transform-origin: right center;
        }
        &::after {
            transform: scaleX(1);
            transform-origin: left center;
        }

        span {
            color: #fff;
        }
    }
}