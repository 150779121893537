/*

汎用的にページを構成するページコンテナ

*/
.page_content{
	overflow: hidden;

	@include mq(sp, true){
		margin-bottom: 80px;
	}

	@include mq(sp) {
		margin-bottom: 30px;
	}

	> * {
		@include mq(sp) {
			margin-top: 50px;
		}

		&:first-of-type {
			@include mq(sp) {
				margin-top: 0;
			}
		}
	}
}

.page_section {
	@include mq(sp, true){
		padding: 90px 0;
	}

	&__column {
		@include mq(sp, true){
			@include display-flex;
			@include justify-content(space-between);
			@include align-items(flex-start);
		}
	}

	&__content {
		@include mq(sp, true){
			padding-left: 9%;
			width: 45%;
		}

		@include mq(sp) {
			width: 92%;
			margin: 0 auto;
		}
	}

	&__thumb {
		@include mq(sp, true){
			width: 38%;
		}

		@include mq(sp) {
			margin-top: 20px;
		}
	}

	&__title {
		font-size: fs(45);
		font-weight: 600;
		margin-bottom: 30px;
		letter-spacing: 2px;
	}

	&__lead {
		font-size: fs(20);
		font-weight: 400;
		line-height: 2.5;
	}
	&__image{
		width: 854px;
		height: 273px;
		margin: 30px auto 0;
		@include mq(sp){
			width: 92%;
			margin: 20px auto 0;
			
		}
	}
}