.module_area {
	@include mq(sp, true) {
		@include display-flex;
		@include flex-wrap(wrap);
		@include justify-content(space-between);
		@include align-items(center);
	}

	@include mq(sp) {
		width: 92%;
		margin: 0 auto;
		padding-top: 0;
	}

	&__item {
		padding-bottom: 20px;

		@include mq(sp, true) {
			width: 49.5%;
			border-bottom: 1px solid #D6D5D5;
			margin-bottom: 25px;
		}

		@include mq(sp) {
			margin-bottom: 0;
		}

		a {
			position: relative;
			display: block;
			padding: 7.5vw 0;
			background-position: center bottom;
			background-size: cover;
			font-family: $family_en_heebo;
			font-weight: 600;

			@include mq(sp) {
				padding: 50px 0;
			}

			&:hover {
				text-decoration: none;

				.module_area__btn {
					background-color: $color_main;
					color: #fff;

					span {
						&::before {
							border-color: #fff;
						}

						&::after {
							border-color: #fff;
						}
					}
				}
			}

			&.kobe {
				background-image: url(../img/top/image_location_01.jpg);
			}

			&.kix {
				background-image: url(../img/top/image_location_02.jpg);
			}

			&.zest {
				background-image: url(../img/top/image_location_03.jpg);
			}

			&.yamatoda {
				background-image: url(../img/top/image_location_04.jpg);
			}


		}
	}

	&__name {
		text-align: center;
		color: #fff;
		letter-spacing: 2px;

		@include mq(sp, true) {
			font-size: fs(57);
		}

		@include mq(sp) {
			font-size: fs(29);
		}
	}

	&__btn {
		position: absolute;
		right: 0;
		bottom: -20px;
		background-color: #fff;
		width: 60%;
		color: $color_text;
		transition: background-color .3s;

		@include mq(sp, true) {
			font-size: fs(25);
		}

		@media screen and (max-width: 1300px) {
			font-size: fs(22);
		}

		@include mq(sp) {
			font-size: fs(12);
		}

		span {
			display: block;
			position: relative;
			padding: 20px 25px;

			@include mq(sp, true) {
				padding: 20px 25px;
			}

			@include mq(sp) {
				padding: 10px 15px;
			}

			&::before {
				content: "";
				display: block;
				border: #ACACAC 1px solid;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				transition: border-color .3s;

				@include mq(sp, true) {
					width: 34px;
					height: 34px;
					right: 25px;
				}

				@include mq(sp) {
					width: 17px;
					height: 17px;
					right: 15px;
				}
			}

			&::after {
				content: "";
				display: block;
				border-right: 1px solid $color_line;
				border-bottom: 1px solid $color_line;
				transform: rotate(-45deg) translateY(-50%);
				position: absolute;
				top: 50%;
				transition: border-color .3s;

				@include mq(sp, true) {
					right: 37px;
					width: 8px;
					height: 8px;
				}

				@include mq(sp) {
					right: 20px;
					width: 5px;
					height: 5px;
				}
			}
		}
	}
}