/*

汎用的にページを構成するメインイメージ

*/
.page_main_image{
	position: relative;
	z-index: 1;
	overflow: hidden;

	@include mq(sp) {
		margin-bottom: 30px;
	}

	&:before{
		content: "";
		width: 100%;
		height: 240px;
		@include mq(sp, true){
			height: 470px;
		}
		position: absolute;
		z-index: 52;
		left: 0;
		bottom: 0;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.60) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.60) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.60) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 );
	}

	img {
		max-width: 100%;
		display: inline-block;
	}
	// 高さ固定
	&.attr_stational{
		&:before{
			height: 140px;
			@include mq(sp, true){
				height: 270px;
			}
		}
	}

	.slick {
		opacity: 0;
		transition: opacity .2s;

		&.slick-initialized {
			opacity: 1;
		}
	}

	@include mq(sp, true){
		height: 100%;
		position: relative;
		// &:after{
		// 	background: url(../img/top/main_direction.png) no-repeat center bottom;
		// 	content: "";
		// 	width: 20px;
		// 	height: 88px;
		// 	position: absolute;
		// 	z-index: 53;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 0;
		// 	margin: auto;
		// }
		.bx-wrapper{
			background-color: #000;
		}
		#pageMainSlider{
			@include transition(opacity 1000ms ease);
			@include opacity(0);
			&.animated{
				@include opacity(1);
			}
		}
		.bx-wrapper,
		.bx-viewport,
		#pageMainSlider,
		#pageMainSlider > li{
			width: 100%!important;
			height: 100%!important;
		}
		#pageMainSlider{
			position: relative;
		}
		&__bg{
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			max-width: none;
			max-height: 100%;
			position: absolute;
			left: -100%;
			right: -100%;
			top: -100%;
			bottom: -100%;
			margin: auto;
			&.ratio--wide{
				max-width: 100%;
				max-height: none;
			}
			// background
			&.attr_url{
				min-width: 0;
				min-height: 0;
				max-width: none;
				max-height: none;
				width: 100%;
				height: 100%;
				background: no-repeat center center;
				background-size: cover;
				left: 0;
				top: 0;
				right: auto;
				bottom: auto;
			}
		}
	}
	@include mq(sp){
		&__bg{
			// background
			&.attr_url{
				height: 420px;
			}
		}
	}
	// 高さ固定
	&.attr_stational{
		&,
		.page_main_image__bg{
			@include mq(sp, true){
				height: 500px;
			}
			@include mq(sp){
				height: 270px;
			}
		}
		.page_main_image__copy{
			@include mq(sp, true){
				bottom: 78px;
			}
			@include mq(sp){
				bottom: 30px;
			}
		}
		&:after{
			display: none;
		}
	}

	&__copy{
		color: #fff;
		font-weight: bold;
		width: 100%;
		text-align: center;
		position: absolute;
		z-index: 53;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);

		.elem_inner{
			@include mq(sp){
				padding-left: 15px;
				padding-right: 15px;
			}
			.elem_catch{
				position: relative;
				font-family: $family_jp_yuh;
				font-feature-settings : "palt";
				font-size: fs(21);
				padding: 15px 20px;
				@include mq(sp, true){
					padding-top: 18px;
					font-size: 30px;
				}
				&:before,
				&:after{
					content: "";
					display: block;
					background: url(../img/common/main_copy_frame_t_sp.png) no-repeat center top;
					background-size: contain;
					width: 100%;
					height: 10px;
					position: absolute;
					left: 0;
					@include mq(sp, true){
						background-image: url(../img/common/main_copy_frame_t.png);
						background-size: auto;
						height: 14px;
					}
				}
				&:before{
					top: 0;
				}
				&:after{
					bottom: 0;
				}
			}
			* + .elem_lead{
				margin-top: 20px;
			}
			.elem_lead{
				letter-spacing: 3px;
				margin-bottom: 30px;

				@include mq(sp) {
					letter-spacing: 1px;
					margin-bottom: 15px;
				}

				.jp {
					@include mq(sp,true) {
						font-size: fs(30);
						margin-top: 30px;
						font-weight: 500;
					}

					@include mq(sp) {
						font-size: fs(15);
						margin-top: 20px;
						font-weight: 600;
					}
				}

				.en {
					font-family: $family_en_heebo;

					@include mq(sp,true) {
						font-size: fs(20);
						font-weight: 400;
					}

					@include mq(sp) {
						font-size: fs(15);
						font-weight: 700;
					}
				}
			}
			.elem_title {
				line-height: 1.5;

				.jp {
					b {
						display: block;

						@include mq(sp, true) {
							font-size: fs(67);
							font-weight: 500;
						}

						@include mq(sp) {
							font-size: fs(30);
							margin-bottom: 10px;
							font-family: $family_jp_noto_title;
							font-weight: 900;
						}
					}

					span {
						display: block;
						@include mq(sp, true) {
							font-size: fs(50);
							font-weight: 500;
						}

						@include mq(sp) {
							font-size: fs(22);
							font-weight: 700;
						}
					}
				}

				.en {
					span {
						font-family: $family_en_heebo;
						line-height: 1.2;

						@include mq(sp, true) {
							font-size: fs(72);
							font-weight: 600;
							letter-spacing: 5px;
						}

						@include mq(sp) {
							font-size: fs(33);
							font-weight: 700;
							letter-spacing: 3px;
						}
					}

					b {
						line-height: 1.2;
						display: block;
						font-family: $family_en_heebo;

						@include mq(sp, true) {
							font-size: fs(97);
							font-weight: 600;
							letter-spacing: 5px;
						}

						@include mq(sp) {
							font-size: fs(42);
							font-weight: 700;
							letter-spacing: 3px;
						}
					}

				}
			}
			.elem_shoulder{
				font-family: $family_jp_yuh;
				font-size: fs(20);
				@include mq(sp, true){
					font-size: fs(40);
				}
			}
			.elem_shoulder + .elem_description{
				margin-top: 0;
			}
			.elem_description{
				font-size: fs(34);
				font-family: $family_jp_yuh;
				font-feature-settings : "palt";
				margin-top: 15px;
				line-height: 1.3;
				@include mq(sp, true){
					font-size: fs(56);
				}
			}
		}
	}
	&__description{
		font-weight: normal;
		line-height: 2.1;
		margin-top: 20px;
		@include mq(sp){
			text-align: left;
			font-size: fs(13);
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	@include mq(sp, true){
		//& + .cta_btn + .page_main_image__description{
		@at-root body.page > .page_main_image__description{
			display: none;
		}
	}
	@include mq(sp){
		& .page_main_image__description{
			display: none;
		}
	}
}

.kobe {
	.page_main_image{
		&__copy{
			.elem_inner{
				.elem_lead{
					letter-spacing: 3px;
					margin-bottom: 30px;

					@include mq(sp) {
						letter-spacing: 1px;
						margin-bottom: 15px;
					}

					.jp {
						font-size: inherit;

						@include mq(sp,true) {
							margin-top: 30px;
							font-weight: 500;
							letter-spacing: 5px;
						}

						@include mq(sp) {
							margin-top: 20px;
							font-weight: 600;
							letter-spacing: 2px;
						}

						span {
							@include mq(sp,true) {
								font-size: fs(42);
							}

							@include mq(sp) {
								font-size: fs(21);
							}
						}

						b {
							display: block;
							@include mq(sp, true) {
								font-size: fs(70);
								font-family: $family_en_heebo;
								margin-top: 15px;
							}

							@include mq(sp) {
								font-size: fs(35);
							}
						}
					}

				}
			}
		}
	}
}

body.tokyo{
	@include mq(sp){
		.page_main_image__copy .elem_inner .elem_title .jp span{
			font-size: 120%;
		}
	}
}
