.reservation_btn {
	background-color: $color_main;
	text-align: center;

	@include mq(sp, true) {
		padding: 75px 0;
	}

	@include mq(sp) {
		padding: 20px 0 40px;
	}

	a {
		display: block;
		background-color: #fff;
		color: $color_main;
		padding: 35px 0;
		border: #fff 2px solid;
		transition: background-color .2s;

		@include mq(sp) {
			padding: 15px 0;
		}

		&:hover {
			color: #fff;
			background-color: $color_main;
		}
		b {
			font-size: fs(28);
			font-weight: 600;
			display: block;

			@include mq(sp) {
				font-size: fs(16);
			}
		}
		span {
			font-size: fs(15);
			font-family: $family_en_heebo;
			font-weight: 600;
			display: block;
			margin-top: 10px;
			letter-spacing: 2px;

			@include mq(sp) {
				font-size: fs(10);
				margin-top: 7px;
			}
		}
	}
}

.schedule_btn {
	background-color: $color_main;
	text-align: center;

	@include mq(sp, true) {
		padding: 75px 0;
	}

	@include mq(sp) {
		padding: 20px 0 40px;
	}

	.page_section__image{
		margin: 0 auto;
		@include mq(sp){
			height: auto;
		}
		.zest_schedule,
		.yamatoda_schedule {
			background-image: url(../img/kix/image_plan_03.jpg);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			background-position: center;
			background-size: cover;
			font-weight: 600;
			@include mq(sp){
				background-image: url(../img/kix/image_plan_03_sp.jpg);
				height: auto;
				padding: 55px 0;
				display: block;
				background-size: contain;
				background-repeat: no-repeat;
				
			}
			p{
				font-size: fs(29);
				@include mq(sp){
					font-size: fs(22);
				}
			}
		}
		.tokyo_schedule{
			background-image: url(../img/tokyo/image_plan_01.jpg);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			background-position: center;
			background-size: cover;
			font-weight: 600;
			@include mq(sp){
				background-image: url(../img/tokyo/image_plan_01_sp.jpg);
				height: auto;
				padding: 55px 0;
				display: block;
				background-size: contain;
				background-repeat: no-repeat;
				
			}
			p{
				font-size: fs(29);
				@include mq(sp){
					font-size: fs(22);
				}
			}
		}
	}
}
.site_footer{

	&, a{
		color: #fff;
	}
	a{
		&:hover{
			text-decoration: none;
		}

		&.disable {
			color: #444!important;
		}
	}

	&__inner{
		background-color: #000;
		padding: 40px 23.5px;
		@include mq(sp, true){
			padding-top: 70px;
		}
	}

	&__eyecatch{
		text-align: center;
		img{
			@include mq(sp ,true){
				width: 317px;
			}
		}
	}

	&__nav{
		@include mq(sp){
			//border: 1px solid #555;
			border-top: none;
			font-size: fs(14);
			margin-top: 0;
			.top {
				//border: 1px solid #555;
				// border-bottom: none;
				// border-left: none;
				// border-right: none;
				margin-bottom: 20px;
				a {
					padding: 13px 5px;
					display: block;
					border-bottom: 1px solid #fff;

					span {
						&::before {
							content: "";
							margin-right: 8px;
							display: inline-block;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 5px 0 5px 7px;
							border-color: transparent transparent transparent #fff;
						}
					}
				}
			}
			.lv1{
				&.toggle{
					> li{
						> a{
							position: relative;
							&:before,
							&:after{
								content: "";
								background-color: #fff;
								position: absolute;
								right: 10px;
								top: 0;
								bottom: 0;
								margin: auto;
							}
							&:before{
								@include transition(height 500ms ease);
								width: 1px;
								height: 13px;
								margin-right: 6px;
							}
							&:after{
								width: 13px;
								height: 1px;
							}
						}
						&.selected{
							> a{
								&:before{
									height: 0;
								}
							}
						}
					}
				}
				> li{
					margin-bottom: 20px;

					> a{
						border-bottom: 1px solid #fff;
						display: block;
						padding: 13px 5px;

						> span {
							&::before {
								content: "";
								margin-right: 8px;
								display: inline-block;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 5px 0 5px 7px;
								border-color: transparent transparent transparent #fff;
							}
						}
					}
				}
				.lv2{
					display: none;
					> li{
						&:first-of-type {
							margin-top: 10px;
						}
						> a{
							//border-bottom: 1px solid #fff;
							display: block;
							padding: 7px 5px;
							padding-left: 5px;
							color: #D0D0D0;

							&::before {
								content: "";
								margin-right: 8px;
								display: inline-block;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 5px 0 5px 7px;
								border-color: transparent transparent transparent #848484;
							}
						}
					}
				}

			}

			.column {
				margin-bottom: 0;
			}
		}
		@include mq(sp, true){
			width: 82%;
			margin:0 auto 0;
			a{
				&:hover{
					text-decoration: underline;
				}
			}
			.top {
				width: 31%;
				a {
					font-size: fs(18);
					border-bottom: 1px solid #fff;
					display: block;
					padding-bottom: 10px;
					margin-bottom: 20px;
				}
			}

			.primary {
				a {
					font-family: $family_en_heebo;
					font-weight: 600;
					letter-spacing: 2px;
				}
			}

			.secondary {
				font-size: fs(19);
				letter-spacing: 2px;
				li {
					margin-bottom: 30px;
				}
			}
			.column{
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				width: 100%;
				> *{
					width: 31%;
				}
				.lv1.flat > li + li,
				.lv2 > li
				{
					margin-top: 10px;
				}
				.lv1{
					font-size: fs(18);
					& > li{
						margin-top: 60px;
						&:first-of-type {
							margin-top: 20px;
						}
						& > a{
							font-weight: bold;
							border-bottom: 1px solid #fff;
							display: block;
							padding-bottom: 10px;
							margin-bottom: 20px;
						}
					}
				}
				.lv2{
					display: block!important;
					font-size: fs(15);
					padding-left: 1em;
					&, a{
						color: #aaa;
					}
				}
			}
		}
	}

	&__under{
		text-align: center;
		margin-top: 60px;
		@include mq(sp, true){
			margin-top: 100px;
			width:$width_site;
			margin-left: auto;
			margin-right: auto;
			position: relative;
		}
	}

	&__logo{
		height: 58px;
		overflow: hidden;
		i{
			font-size: 175px;
			display: block;
			margin: -85px auto 0;

			@include mq(sp) {
				font-size: 160px;
				margin: -70px auto 0;
			}
		}
	}

	&__copyright{
		text-align:center;
		font-family:$family_jp_hira;
		font-size:fs(12);
		margin-top: 26px;
	}

	&__sns{
		margin-top: 28px;
		@include mq(sp, true){
			margin-top: 0;
			position: absolute;
			right: 10px;
			bottom: -10px;
		}
		ul{
			li + li{
				margin-left: 20px;
			}
			li{
				display: inline-block;
				[class^="icon--"]:before,
				[class*=" icon--"]:before{
					font-size: 28px;
				}
				@include transition(opacity 300ms ease);
				&:hover{
					@include mq(sp, true){
						@include opacity(.7);
					}
				}
			}
		}
	}

}

/* IE11 */
@media all and (-ms-high-contrast:none){
	*::-ms-backdrop, .site_footer__logo i {
		padding-top: 10px;
	}
}

.page_top{
	margin-top: -32px;
	text-align: center;
	a{
		display: inline-block;
		width: 64px;
		height: 31px;
		text-align: center;
		&:before{
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 32px 32px 32px;
			border-color: transparent transparent #000 transparent;
			position: relative;
			top: -16.25px;
		}
		i{
			display: block;
			position: relative;
			top: 0;
			@include transition(top 300ms ease);
			&:before,
			&:after
			{
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				position: absolute;
				left: 0;
				right: 0;
				top: 3px;
				bottom: 0;
				margin: auto;
			}
			&:before{
				border-width: 0 9.5px 10px 9.5px;
				border-color: transparent transparent #ffffff transparent;
			}
			&:after{
				border-width: 0 5.5px 5.5px 5.5px;
				border-color: transparent transparent #333 transparent;
				top: 6px;
				@at-root {
					@media screen and (-webkit-min-device-pixel-ratio:0) {
						.page_top a i:after{
							top: 5px;
						}
					}
				}
			}
		}

		@include mq(sp, true){
			&:hover i{
				top:-2px;
			}
		}
	}
}
