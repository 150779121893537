.module_price_table{
	$head: #{&}__head;
	$body: #{&}__body;
	$row: #{&}__row;
	$th: #{&}__th;
	$td: #{&}__td;
	width: 100%;
	table-layout: fixed;
	letter-spacing: .1em;
	&__head{
		#{$th}{
			background-color: #00a0d4;
			color: #fff;
			vertical-align: middle;
			height: 55px;
			&:first-child{
				background-color: #1d509e;
				font-weight: normal;
			}
			.elem_emphasis{
				display: inline-block;
				line-height: 1;
				font-size: fs(25);
				margin-top: -10px;
				position: relative;
				top: .05em;
			}
		}
	}
	&__th,
	&__td{
		border: 1px solid #ddd;
		text-align: center;
	}
	&__body{
		#{$th},
		#{$td}{
			padding: 20px;
		}
		#{$th}{
			background-color: #f3f3f3;
			color: #1d509e;
			vertical-align: middle;
			.elem_emphasis{
				display: inline-block;
				font-size: fs(30);
				margin-top: -10px;
			}
			.elem_default{
				font-size: fs(20);
			}
		}
		#{$td}{
			vertical-align: top;
			.elem_price{
				font-family: $family_en_display;
				font-size: fs(30);
				white-space: nowrap;
				line-height: 1;
				margin-top: -.3em;
				margin-bottom: 10px;
				letter-spacing: 0;
			}
			.elem_default{
				font-size: fs(13);
				line-height: 1.5;
			}
		}
	}
	@include mq(sp){
		letter-spacing: 0;
		&,
		&__head,
		&__body,
		&__row,
		&__th,
		&__td{
			display: block;
		}
		&__head{
			display: none;
		}
		&__body{
			#{$row} + #{$row}{
				margin-top: 20px;
			}
			#{$th}{
				background-color: #1d509e;
				color: #fff;
				border: none;
				font-weight: normal;
				//padding-top: 10px;
				//padding-bottom: 10px;
				padding-left: 10px;
				padding-right: 10px;
				.elem_emphasis{
					margin-top: -3px;
				}
				&:before{
					content: attr(data-label);
					display: inline;
					font-weight: normal;
					margin-right: 5px;
				}
			}
			#{$td}{
				@include box-sizing(border-box);
				display: table;
				width: 100%;
				text-align: left;
				border-top: none;
				&:before,
				&_inner{
					padding: 17px;
				}
				&:before{
					content: attr(data-label);
					display: table-cell;
					vertical-align: middle;
					color: #00a0d4;
					font-weight: bold;
					width: 100px;
				}
				&_inner{
					display: table-cell;
					vertical-align: middle;
				}
				.elem_price{
					font-size: fs(26);
					margin-bottom: 5px;
				}
				.elem_default{
					font-size: fs(11);
				}
			}
		}
	}
	&.attr_course{
		#{$head}{
			#{$th}{
				height: 45px;
				&:first-child{
					width: 456px;
					&.attr_fixed{
						width: auto;
					}
				}
			}
		}
		#{$body}{
			#{$th}{
				text-align: left;
				@include mq(sp){
					padding-top: 15px;
					padding-bottom: 15px;
					text-align: center;
					&-inner{
						display: inline-block;
						text-align: left;
					}
					&:before{
						display: none;
					}
				}
				.elem_emphasis{
					font-size: fs(24);
				}
				.elem_default{
					font-size: fs(18);
				}
				.elem_description{
					font-size: fs(13);
					font-weight: normal;
					margin-top: 5px;
					strong{
						font-size:fs(15,13);
					}
					@include mq(sp){
						font-size: fs(11);
						margin-top: 3px;
					}
				}
			}
		}
	}
}
