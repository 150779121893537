.module_schedule{
	&.bg--blue {
		background-color: #2F6CB5;
		padding: 65px 0;

		@include mq(sp) {
			padding: 25px 0 40px;
		}

		.module_schedule__item {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	&__item {
		@include mq(sp, true) {
			width: 80%;
			margin: 45px auto;
		}
		@include mq(sp) {
			margin-top: 35px;
		}
		a {
			display: block;
			@include mq(sp) {
				min-height: 140px;
			}

			&:hover {
				figure {
					outline-offset: 0;
					opacity: .8;

					img {
						//filter: brightness(80%);
					}
				}
			}
			figure {
				position: relative;
				outline: 1px solid #fff;
				outline-offset: -12px;
				transition: outline-offset .2s, opacity .2s;

				img {
					transition: filter .2s;
				}

				figcaption {
					position: absolute;
					left: 50%;
					top: 50%;
					width: 100%;
					text-align: center;
					transform: translate(-50%,-50%);
					color: #fff;
					font-weight: 600;
					letter-spacing: 1px;
					line-height: 1.7;

					@include mq(sp, true) {
						font-size: fs(29);
					}

					@include mq(sp) {
						font-size: fs(18);
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
}