.module_service_table{
	width: 100%;
	text-align: left;
	border-top: 4px solid #333;
	&.option {
		.module_service_table__th {
			@include mq(sp) {
				width: 45%;
			}
		}
	}
	&.service {
		.module_service_table__th {
			@include mq(sp) {
				width: 60%;
			}
		}
	}
	&__row {

	}
	&__th,
	&__td{
		line-height: 1.75;
		border-bottom: 1px solid #ddd;
		font-weight: normal;
		vertical-align: middle;

		@include mq(sp, true) {
			padding: 20px 30px 20px 20px;
		}

		@include mq(sp) {
			padding: 10px 20px 10px 15px;
		}

		&.borderbottom_none {
			border-bottom: none;
		}
	}
	&__th{
		font-weight: bold;
		@include mq(sp, true){
			width: 49%;
		}
		@include mq(sp){
			@include box-sizing(border-box);
		}
		.elem_main{
			@include mq(sp){
				font-size: fs(13);
			}
		}
		.elem_sub{
			font-size: fs(11);
		}

		&.w100p {
			width: 100%;
			@include mq(sp){
				font-size: fs(13);
			}
		}
	}
	&__td{
		.elem_price{
			display: inline-block;
			font-size: fs(28);
			font-family: $family_en_heebo;
			font-weight: 600;
			letter-spacing: 1px;
			@include mq(sp){
				font-size: fs(26);
			}
		}
		.elem_default{
			@include mq(sp){
				font-size: fs(13);
			}
		}
		.elem_emphasis{
			font-size: fs(17);
			font-weight: bold;
			@include mq(sp){
				font-size: fs(15);
			}
		}
	}
}