/*

faq page

*/

body.faq {

	.page_main_image {
		height: 100vh;

		@include mq(sp, true){
			background: url(../img/faq/hero_01.jpg) no-repeat center center;
			background-size: cover;
			max-height: 1000px;
		}
		@include mq(sp){
			background: url(../img/faq/hero_01_sp.jpg) no-repeat center center;
			background-size: cover;
			max-height: 560px;
		}

		.elem_title {
			.jp {
				b {
					font-family: $family_en_lato;
					@include mq(sp, true) {
						font-size: fs(80);
						font-weight: 600;
						letter-spacing: 5px;
					}

					@include mq(sp) {
						font-size: fs(67);
						font-weight: 700;
						letter-spacing: 3px;
					}
				}
			}
		}

		.elem_copy {
			@include mq(sp, true) {
				font-size: fs(15);
				letter-spacing: 3px;
				font-weight: 300;
				line-height: 2;
				margin-top: 20px;
			}

			@include mq(sp) {
				font-size: fs(18);
				font-weight: 300;
				letter-spacing: 1px;
			}
		}

	}

	.page_faq {
		@include mq(sp) {
			margin-bottom: 40px;
		}
		&__list {
			border-bottom: 1px solid #060606;
		}

		&__item {
			line-height: 1.8;
			&.selected {
				.page_faq__q {
					.toggle_handler {
						&::after {
							height: 0;
						}
					}
				}
			}
		}

		&__q {
			position: relative;
			border-top: 1px solid #060606;
			display: flex;
			align-items: center;
			cursor: pointer;
			font-weight: 600;

			@include mq(sp, true) {
				padding: 5px 40px;
			}

			@include mq(sp) {
				padding: 10px 40px 10px 10px;
			}

			span {
				display: block;
				@include mq(sp, true) {
					font-size: fs(23);
				}
				@include mq(sp) {
					font-size: fs(15);
				}
			}

			&::before {
				content: "Q";
				display: inline-block;
				vertical-align: middle;
				font-family: $family_en_lato;
				font-weight: 700;
				color: #060606;

				@include mq(sp, true) {
					font-size: fs(46);
					margin-right: 40px;
				}
				@include mq(sp) {
					font-size: fs(30);
					margin-right: 10px;
				}
			}
			.toggle_handler{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				@include mq(sp, true){
					width: 30px;
					height: 30px;
					right: 40px;
				}
				@include mq(sp){
					width: 15px;
					height: 15px;
					right: 15px;
				}
				&:before,
				&:after{
					content: "";
					background-color: #060606;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
				}
				&:before{
					@include mq(sp, true){
						width: 30px;
						height: 3px;
					}
					@include mq(sp){
						width: 14px;
						height: 2px;
					}
				}
				&:after{
					@include transition(height 500ms ease);
					@include mq(sp, true){
						width: 3px;
						height: 30px;
					}
					@include mq(sp){
						width: 2px;
						height: 15px;
					}
				}
			}
		}

		&__a {
			border-top: 1px solid #060606;
			letter-spacing: 1px;
			background-color: #F0F0F0;
			display: flex;
			align-items: center;

			@include mq(sp, true) {
				padding: 5px 40px;
				font-size: fs(15);
			}

			@include mq(sp) {
				padding: 10px 20px 10px 10px;
				font-size: fs(13);
			}

			&::before {
				content: "A";
				display: inline-block;
				vertical-align: middle;
				font-family: $family_en_lato;
				font-weight: 700;
				color: $color_main;

				@include mq(sp, true) {
					font-size: fs(46);
					margin-right: 40px;
				}
				@include mq(sp) {
					font-size: fs(30);
					margin-right: 10px;
				}
			}

			span {
				display: block;
			}
		}
	}

}