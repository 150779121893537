.module_caution {
	@include mq(sp) {
		margin-top: 30px !important;
	}
	.contents_section__inner {
		border-top: 1px solid #D6D5D5;
		padding-top: 100px;

		@include mq(sp) {
			padding-top: 30px;
		}
	}
	&__item {
		@include mq(sp) {
			width: 92%;
			margin: 0 auto;
		}
		margin: 0 auto;
		border: #D6D5D5 1px solid;

		@include mq(sp, true) {
			width: 72%;
		}

		dt {
			background-color: #F4F3F3;
			text-align: center;
			font-weight: 600;
			padding: 10px 0;

			@include mq(sp, true) {
				font-size: fs(18);
			}

			@include mq(sp) {
				font-size: fs(15);
			}
		}

		dd {
			line-height: 1.9;

			@include mq(sp, true) {
				padding: 10px 25px;
			}

			@include mq(sp) {
				padding: 10px 15px;
				font-size: fs(14);
			}
		}
	}
}