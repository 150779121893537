/*

no support message

*/

.noscript,
.legacy_ie_message{
	background-color:#fff9d7;
	padding:10px 0;
	text-align:center;
	font-weight:bold;
	margin-bottom:1px;
}

.legacy_ie_message{
	display:none;
	.regacy_ie &{
		display:block;
	}
}
