/*

margin and padding

5刻みで上下左右のマージンとパディングのクラスを定義
$start_value x 5の値から、$end_value x 5 までを設定

```class
.mb-5
.mt-5
.mr-5
.ml-5

.mb0
.mt0
.mr0
.ml0

.mb5
.mt5
.mr5
.ml5

.mb10
.mt10
.mr10
.ml10

.pb-5
.pt-5
.pr-5
.pl-5

.pb0
.pt0
.pr0
.pl0

.pb5
.pt5
.pr5
.pl5

.pb10
.pt10
.pr10
.pl10
```

レスポンシブ用に$breakpointsのキーを接頭辞にしたクラスも書き出します。
```class
.tablet_mb10
.sp_mb10
```

*/

$output_margin: true !default;
$output_padding: true !default;
$output_resp_margin: true !default;
$output_resp_padding: true !default;
$start_value: -10 !default;
$end_value: 16 !default;

@if $output_margin {
	@for $i from $start_value through $end_value {
		.mb#{ $i * 5 } {margin-bottom:5px * $i !important;}
		.mt#{ $i * 5 } {margin-top:5px * $i !important;}
		.mr#{ $i * 5 } {margin-right:5px * $i !important;}
		.ml#{ $i * 5 } {margin-left:5px * $i !important;}
	}
}
@if $output_resp_margin {
	@each $key, $value in $breakpoints {
		@include mq($key) {
			@for $i from $start_value through $end_value {
				.#{$key}_mb#{ $i * 5 } {margin-bottom:5px * $i !important;}
				.#{$key}_mt#{ $i * 5 } {margin-top:5px * $i !important;}
				.#{$key}_mr#{ $i * 5 } {margin-right:5px * $i !important;}
				.#{$key}_ml#{ $i * 5 } {margin-left:5px * $i !important;}
			}
		}
	}
}

@if $output_padding {
	@for $i from 0 through $end_value {
		.pb#{ $i * 5 } {padding-bottom:5px * $i !important;}
		.pt#{ $i * 5 } {padding-top:5px * $i !important;}
		.pr#{ $i * 5 } {padding-right:5px * $i !important;}
		.pl#{ $i * 5 } {padding-left:5px * $i !important;}
	}
}
@if $output_resp_padding {
	@each $key, $value in $breakpoints {
		@include mq($key) {
			@for $i from 0 through $end_value {
				.#{$key}_pb#{ $i * 5 } {padding-bottom:5px * $i !important;}
				.#{$key}_pt#{ $i * 5 } {padding-top:5px * $i !important;}
				.#{$key}_pr#{ $i * 5 } {padding-right:5px * $i !important;}
				.#{$key}_pl#{ $i * 5 } {padding-left:5px * $i !important;}
			}
		}
	}
}
