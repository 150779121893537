/*

terms page

*/

body.terms {

	.page_main_image {
		height: 100vh;

		@include mq(sp, true){
			background: url(../img/terms/hero_01.jpg) no-repeat center center;
			background-size: cover;
			max-height: 1000px;
		}
		@include mq(sp){
			background: url(../img/terms/hero_01_sp.jpg) no-repeat center center;
			background-size: cover;
			max-height: 560px;
		}

		.elem_title {
			.jp {
				b {
					@include mq(sp, true) {
						font-size: fs(80);
						font-weight: 600;
						letter-spacing: 5px;
					}

					@include mq(sp) {
						font-size: fs(45);
						font-weight: 700;
						letter-spacing: 3px;
					}
				}
			}
		}

	}

	.terms_section{

		@include mq(sp, true){
			margin-top: 100px;
			&:first-child{
				margin-top: 0;
			}
		}
		@include mq(sp) {
			margin-top: 45px;
			&:first-child{
				margin-top: 0;
			}
		}

		&__wrapper {
			@include mq(sp, true) {
				margin-bottom: 60px;
			}
			@include mq(sp) {
				margin-bottom: 40px;
			}
		}

		&__description {
			line-height: 2.1;
			@include mq(sp, true) {
				font-size: fs(20);
				margin-bottom: 45px;
			}
			@include mq(sp) {
				font-size: fs(15);
				margin-bottom: 35px;
			}
		}

		&__title{
			font-family: $family_jp_yuh;
			font-feature-settings : "palt";
			letter-spacing: .04em;
			@include mq(sp, true){
				font-size: fs(35);
			}
			@include mq(sp) {
				font-size: fs(19);
			}
			&:before{
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
				position: relative;
				top: -.1em;
				font-size: fs(32, 28);
				@include mq(sp) {
					font-size: fs(32, 24);
					top: 0;
				}
			}
		}

		&__title + &__contents{
			@include mq(sp, true){
				margin-top: 15px;
			}
			@include mq(sp) {
				margin-top: 15px;
			}
		}

		&__contents{
			line-height: 2.1;
			@include mq(sp, true) {
				margin-top: 20px;
				font-size: fs(20);
			}
			@include mq(sp) {
				margin-top: 10px;
				font-size: fs(15);
			}

			a {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&__more{
			margin-top: 30px;
		}

		* + &__headline{
			margin-top: 20px;
		}
		&__headline{
			font-weight: bold;
			@include mq(sp){
				font-size: fs(15, 13);
			}
			& + *{
				margin-top: 5px;
			}
		}

	}

}

