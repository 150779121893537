/*

tackleinfo page

*/

html.over_main_image {
	body.post-type-archive-tackle_info {
		.icon--logo:before,
		.icon--facebook:before,
		.icon--instagram:before {
			color: #fff;
		}

		.drawer_btn i {
			background-color: #fff;
		}
	}
}

body.post-type-archive-tackle_info {

	.page_main_image {
		.elem_title {
			.en {
				b {
					font-family: $family_en_lato;

					@include mq(sp, true) {
						font-size: fs(80);
						font-weight: 600;
						letter-spacing: 5px;
					}

					@include mq(sp) {
						font-size: fs(67);
						font-weight: 700;
						letter-spacing: 3px;
					}
				}
			}
		}

		.elem_lead {
			.en {
				font-size: fs(14);
				font-weight: 600;
				letter-spacing: 1px;
			}
		}
	}

	.page_content {
		.column {
			@include mq(sp) {
				margin-bottom: 40px;
			}
			&:last-of-type {
				margin-bottom: 100px;
				@include mq(sp) {
					margin-bottom: 60px;
				}
			}
			.content {
				display: flex;
				flex-direction: column;
				.headline__secondary {
					.jp {
						@include mq(sp) {
							font-size: fs(22);
						}
					}
				}
				.text {
					margin-bottom: 30px;

					> p {
						@extend .headline__lead;
					}
				}
				.btn {
					margin-top: auto;

					@include mq(sp) {
						text-align: center;
					}
				}
			}
		}
	}

}

