.cta_btn{
	position: absolute;
	z-index: 100;
	right: 10px;
	top: auto;
	margin-top: -97px - 10px;
	position: fixed;
	z-index: 1002;
	right: 20px;
	bottom: 20px;

	@include mq(sp){
		width: 88px;
		right: 0px;
	}
	@include mq(sp, true){

	}

	&--group {
		flex-direction: column;
		align-items: center;
		padding: 15px;
		border-radius: 110px;
		background-color: rgba(47, 108, 181, .29);

		@include mq(sp, true){
			display: flex;
		}

		@include mq(sp){
			width: 100px;
			padding: 6px;
			box-sizing: border-box;
		}

		a {
			&:not(:last-child) {
				margin-bottom: 20px;

				@include mq(sp){
					margin-bottom: 10px;
				}
			}
		}
	}

	a{
		@include transition(top 300ms ease);
		display: block;

		&:hover{
			text-decoration: none;
			@include mq(sp, true){
				-webkit-animation-name:fuwafuwa;
				-webkit-animation-duration:1s;
				-webkit-animation-iteration-count:infinite;
				-webkit-animation-timing-function:ease;

				-moz-animation-name:fuwafuwa;
				-moz-animation-duration:1s;
				-moz-animation-iteration-count:infinite;
				-moz-animation-timing-function:ease;
			}
		}
	}
}

@-webkit-keyframes fuwafuwa {
	0% {-webkit-transform:translate(0, 0);}
	50% {-webkit-transform:translate(0, -5px);}
	100% {-webkit-transform:translate(0, 0);}
}
@-moz-keyframes fuwafuwa {
	0% {-moz-transform:translate(0, 0);}
	50% {-moz-transform:translate(0, -5px);}
	100% {-moz-transform:translate(0, 0);}
}
