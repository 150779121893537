@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Leckerli+One);
@import url("https://fonts.googleapis.com/css?family=Pacifico");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap");
#__bs_notify__ {
  display: none !important; }

/* -------------------------------------------------------------------------
	Web font
------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------
	Foundation
------------------------------------------------------------------------- */
/* Vendor */
/*

Hata util

*/
/* Variable */
/*

base font size

*/
/* $basic_font_size : 13, 81.25%; */
/* $basic_font_size : 14, 87.5%; */
/* $basic_font_size : 16, 100%; */
/*

base color

*/
/*

width

*/
/*

text color

*/
/* Function */
/*

フォントサイズをパーセントに変換して返す関数

```sass
.heading_lv1{
	font-size:fs(30);
}
```

指定が2重になる場合は、第2引数に親要素で指定したサイズを入れる

```sass
.heading_lv1{
	font-size:fs(30);
	span{
		font-size:fs(14,30);
	}
}
```

*/
/*

パーセントを返す関数

```sass
.section{
	margin-bottom:per(30px, 960px);
}
```

*/
/**
 * [define keyframes]
 * @param  {[type]} $name [description]
 * @return {[type]}       [description]
 */
/* Mixin */
/*

メディアクエリ

ブレークポイント定義
```sass
$breakpoints:(
	tablet: 959px,
	sp: 679px
);
```

● max-widthで指定する
```sass
@include mq(sp) {
	height:40px;
}
```
```output css
@media screen and (max-width: 679px) {
	height: 40px;
}
```

● 範囲指定する場合は、引数に2つ入れる
```sass
@include mq(sp tablet) {
	height:40px;
}
```
```output css
@media screen and (min-width: 680px) and (max-width: 959px) {
	height: 40px;
}
```

● min-widthで指定する場合は第2引数をtrueにする
```sass
@include mq(tablet, true) {
	height:40px;
}
```
```output css
@media screen and (min-width: 960px) {
	height: 40px;
}
```

*/
/*

章番号用mixin (IE8以上)

キャプションに章番号をつける場合
```sass
$counterName: oreoreCounter;
.parent-section {
	@include resetCounter($counterName);
	h2 {
		@include addCounter($counterName, '第', '章');
	}
}
```

入れ子になってるリストに通し番号(1-1-1など）を付ける場合
```sass
$counterName: listCounter;
ol {
	@include resetCounter($counterName);
	li {
		@include addCounters($counterName, '-');
	}
}
```

*/
/*

グリッドレイアウト用mixin (IE8以上)

引数で分割数を指定して呼び出す
```sass
@include grid_system(12);
```

```html
<div class="grid--12 gutter--2">  ← このdivへの幅指定はNG
	<div class="grid__col--4"></div> ┐
	<div class="grid__col--4"></div> ├ 子要素は合計が分割数になるようにクラス名を付ける
	<div class="grid__col--4"></div> ┘
</div>
```

グリッドの間隔は「gutter--N」で指定する
通常はパーセントですが、「gutter--Npx」にするとピクセルになります。

ブレークポイントで変える場合は、「tablet_」「sp_」など、$breakpointsで定義したキーの接頭辞を付ける
```html
<div class="grid--12 gutter--20px tablet-gutter--15px sp_gutter--10px">
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
</div>
```

*/
/* Base */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

input, textarea {
  margin: 0;
  padding: 0; }

small {
  font-size: smaller; }

sup {
  font-size: 0.7em;
  vertical-align: top; }

sub {
  font-size: 0.7em;
  vertical-align: baseline; }

/* YUI 3.9.1 (build 5852) Copyright 2013 Yahoo! Inc. http://yuilibrary.com/license/ */
/**
 * Percents could work for IE, but for backCompat purposes, we are using keywords.
 * x-small is for IE6/7 quirks mode.
 */
body {
  font: 93.75%/1.231 arial, sans-serif;
  *font-size: small;
  /* for IE */
  *font: x-small;
  /* for IE in quirks mode */ }

/**
 * Nudge down to get to 13px equivalent for these form elements
 */
select,
input,
button,
textarea {
  font: 99% arial,sans-serif; }

/**
 * To help tables remember to inherit
 */
table {
  font-size: inherit;
  font: 100%; }

/**
 * Bump up IE to get to 13px equivalent for these fixed-width elements
 */
pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  *font-size: 108%;
  line-height: 100%; }

/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssfonts {
  display: none; }

/*

プロジェクトにおける基本的なスタイル
（ページ全体の背景や、基本的なタイポグラフィ）
※ クラスは使わない

*/
html, body {
  background-color: #fff; }
  @media screen and (min-width: 640px) {
    html, body {
      height: 100%; } }

@media screen and (min-width: 640px) and (max-width: 1200px) {
  body {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 1200px; } }

body {
  font-family: YakuHanJP_Noto, "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-x: hidden; }
  body.admin-bar .site_header {
    top: 32px; }

body {
  color: #333333; }

img {
  vertical-align: bottom; }
  @media screen and (max-width: 1139px) {
    img {
      max-width: 100%;
      height: auto; } }

a {
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  -o-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #2F6CB5;
  text-decoration: none; }

/* -------------------------------------------------------------------------
	Layout
------------------------------------------------------------------------- */
.site_header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1002; }
  .site_header a {
    color: #000; }
  html.over_main_image body.top .site_header a, html.over_main_image body.top .site_header a.background--light, html.over_main_image body.top .site_header a.background--dark {
    color: #fff !important; }
  html.over_main_image body.top .site_header .drawer_btn i,
  html.over_main_image body.top .site_header .drawer_btn.background--light i,
  html.over_main_image body.top .site_header .drawer_btn.background--dark i {
    background-color: #fff !important; }
  html.over_main_image body.top .site_header .drawer_nav .uk-offcanvas-bar .lv1 > li > a:after {
    background-color: #fff !important; }
  body.uk-offcanvas-page .drawer_nav .uk-offcanvas-bar .lv1 > li a::after {
    background-color: #fff !important; }
  .site_header {
    padding-bottom: 15px; }
    @media screen and (max-width: 639px) {
      .site_header {
        padding-bottom: 15px; } }
    .site_header:before {
      -webkit-transition: opacity 500ms ease;
      -moz-transition: opacity 500ms ease;
      -o-transition: opacity 500ms ease;
      transition: opacity 500ms ease;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.8);
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
      @media screen and (max-width: 639px) {
        body.uk-offcanvas-page .site_header:before {
          height: 75px; } }
  .site_header .site_header__id a {
    -webkit-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
    -o-transition: height 500ms ease;
    transition: height 500ms ease; }
    .site_header .site_header__id a i {
      -webkit-transition: font-size 500ms ease;
      -moz-transition: font-size 500ms ease;
      -o-transition: font-size 500ms ease;
      transition: font-size 500ms ease; }
  .site_header .site_header__nav {
    -webkit-transition: padding-top 500ms ease;
    -moz-transition: padding-top 500ms ease;
    -o-transition: padding-top 500ms ease;
    transition: padding-top 500ms ease; }
  .site_header .drawer_btn {
    -webkit-transition: top 500ms ease;
    -moz-transition: top 500ms ease;
    -o-transition: top 500ms ease;
    transition: top 500ms ease; }
  .site_header .uk-offcanvas-bar {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; }
  html.over_main_image body.page-template .site_header .drawer_nav .uk-offcanvas-bar .lv1 > li > a:after {
    background-color: #fff !important; }
  html.pass_main_image .site_header:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  @media screen and (min-width: 640px) {
    html.pass_main_image .site_header {
      padding-top: 3px; }
      html.pass_main_image .site_header .site_header__id a {
        height: 44px; }
        html.pass_main_image .site_header .site_header__id a i {
          font-size: 154px; }
      html.pass_main_image .site_header .site_header__nav {
        padding-top: 20px; }
      html.pass_main_image .site_header .drawer_btn {
        top: 18px; } }
  .site_header__inner {
    position: relative;
    padding-left: 35px;
    padding-right: 35px; }
    @media screen and (min-width: 640px) and (max-width: 1200px) {
      .site_header__inner {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding-left: 30px;
        padding-right: 40px; } }
    @media screen and (max-width: 639px) {
      .site_header__inner {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: auto;
        padding-left: 10px;
        padding-right: 10px; } }
  .site_header__id {
    float: left;
    padding-top: 17px;
    font-size: 200%;
    font-weight: normal;
    position: relative;
    z-index: 1003; }
    @media screen and (min-width: 640px) {
      .site_header__id {
        padding-top: 12px; } }
    .site_header__id a {
      display: block;
      text-decoration: none;
      height: 43px;
      overflow: hidden; }
      @media screen and (min-width: 640px) {
        .site_header__id a {
          height: 63px; } }
      .site_header__id a i {
        font-size: 123px;
        display: block;
        margin: -39px auto 0;
        line-height: 1; }
        @media screen and (min-width: 640px) {
          .site_header__id a i {
            font-size: 184px;
            margin: -55px auto 0; } }
  .site_header__nav {
    float: right;
    display: inline;
    text-align: right;
    padding-top: 23px;
    margin-right: 55px;
    position: relative;
    z-index: 1003; }
    .site_header__nav a {
      -webkit-transition: opacity 300ms ease;
      -moz-transition: opacity 300ms ease;
      -o-transition: opacity 300ms ease;
      transition: opacity 300ms ease; }
      .site_header__nav a:hover {
        text-decoration: none;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
        opacity: 0.7; }
    @media screen and (min-width: 640px) {
      .site_header__nav {
        margin-right: 90px;
        padding-top: 38px; } }
    @media screen and (min-width: 640px) and (max-width: 1200px) {
      .site_header__nav {
        margin-right: 80px; } }
    .site_header__nav li + li {
      margin-left: 10px; }
      @media screen and (min-width: 640px) {
        .site_header__nav li + li {
          margin-left: 20px; } }
    .site_header__nav li {
      display: inline-block; }
      .site_header__nav li [class^="icon--"]:before,
      .site_header__nav li [class*=" icon--"]:before {
        font-size: 28px; }

.reservation_btn {
  background-color: #2F6CB5;
  text-align: center; }
  @media screen and (min-width: 640px) {
    .reservation_btn {
      padding: 75px 0; } }
  @media screen and (max-width: 639px) {
    .reservation_btn {
      padding: 20px 0 40px; } }
  .reservation_btn a {
    display: block;
    background-color: #fff;
    color: #2F6CB5;
    padding: 35px 0;
    border: #fff 2px solid;
    transition: background-color .2s; }
    @media screen and (max-width: 639px) {
      .reservation_btn a {
        padding: 15px 0; } }
    .reservation_btn a:hover {
      color: #fff;
      background-color: #2F6CB5; }
    .reservation_btn a b {
      font-size: 187%;
      font-weight: 600;
      display: block; }
      @media screen and (max-width: 639px) {
        .reservation_btn a b {
          font-size: 107%; } }
    .reservation_btn a span {
      font-size: 100%;
      font-family: "Heebo", sans-serif;
      font-weight: 600;
      display: block;
      margin-top: 10px;
      letter-spacing: 2px; }
      @media screen and (max-width: 639px) {
        .reservation_btn a span {
          font-size: 67%;
          margin-top: 7px; } }

.schedule_btn {
  background-color: #2F6CB5;
  text-align: center; }
  @media screen and (min-width: 640px) {
    .schedule_btn {
      padding: 75px 0; } }
  @media screen and (max-width: 639px) {
    .schedule_btn {
      padding: 20px 0 40px; } }
  .schedule_btn .page_section__image {
    margin: 0 auto; }
    @media screen and (max-width: 639px) {
      .schedule_btn .page_section__image {
        height: auto; } }
    .schedule_btn .page_section__image .zest_schedule,
    .schedule_btn .page_section__image .yamatoda_schedule {
      background-image: url(../img/kix/image_plan_03.jpg);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-position: center;
      background-size: cover;
      font-weight: 600; }
      @media screen and (max-width: 639px) {
        .schedule_btn .page_section__image .zest_schedule,
        .schedule_btn .page_section__image .yamatoda_schedule {
          background-image: url(../img/kix/image_plan_03_sp.jpg);
          height: auto;
          padding: 55px 0;
          display: block;
          background-size: contain;
          background-repeat: no-repeat; } }
      .schedule_btn .page_section__image .zest_schedule p,
      .schedule_btn .page_section__image .yamatoda_schedule p {
        font-size: 193%; }
        @media screen and (max-width: 639px) {
          .schedule_btn .page_section__image .zest_schedule p,
          .schedule_btn .page_section__image .yamatoda_schedule p {
            font-size: 147%; } }
    .schedule_btn .page_section__image .tokyo_schedule {
      background-image: url(../img/tokyo/image_plan_01.jpg);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-position: center;
      background-size: cover;
      font-weight: 600; }
      @media screen and (max-width: 639px) {
        .schedule_btn .page_section__image .tokyo_schedule {
          background-image: url(../img/tokyo/image_plan_01_sp.jpg);
          height: auto;
          padding: 55px 0;
          display: block;
          background-size: contain;
          background-repeat: no-repeat; } }
      .schedule_btn .page_section__image .tokyo_schedule p {
        font-size: 193%; }
        @media screen and (max-width: 639px) {
          .schedule_btn .page_section__image .tokyo_schedule p {
            font-size: 147%; } }

.site_footer, .site_footer a {
  color: #fff; }

.site_footer a:hover {
  text-decoration: none; }

.site_footer a.disable {
  color: #444 !important; }

.site_footer__inner {
  background-color: #000;
  padding: 40px 23.5px; }
  @media screen and (min-width: 640px) {
    .site_footer__inner {
      padding-top: 70px; } }

.site_footer__eyecatch {
  text-align: center; }
  @media screen and (min-width: 640px) {
    .site_footer__eyecatch img {
      width: 317px; } }

@media screen and (max-width: 639px) {
  .site_footer__nav {
    border-top: none;
    font-size: 93%;
    margin-top: 0; }
    .site_footer__nav .top {
      margin-bottom: 20px; }
      .site_footer__nav .top a {
        padding: 13px 5px;
        display: block;
        border-bottom: 1px solid #fff; }
        .site_footer__nav .top a span::before {
          content: "";
          margin-right: 8px;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 7px;
          border-color: transparent transparent transparent #fff; }
    .site_footer__nav .lv1.toggle > li > a {
      position: relative; }
      .site_footer__nav .lv1.toggle > li > a:before, .site_footer__nav .lv1.toggle > li > a:after {
        content: "";
        background-color: #fff;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto; }
      .site_footer__nav .lv1.toggle > li > a:before {
        -webkit-transition: height 500ms ease;
        -moz-transition: height 500ms ease;
        -o-transition: height 500ms ease;
        transition: height 500ms ease;
        width: 1px;
        height: 13px;
        margin-right: 6px; }
      .site_footer__nav .lv1.toggle > li > a:after {
        width: 13px;
        height: 1px; }
    .site_footer__nav .lv1.toggle > li.selected > a:before {
      height: 0; }
    .site_footer__nav .lv1 > li {
      margin-bottom: 20px; }
      .site_footer__nav .lv1 > li > a {
        border-bottom: 1px solid #fff;
        display: block;
        padding: 13px 5px; }
        .site_footer__nav .lv1 > li > a > span::before {
          content: "";
          margin-right: 8px;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 7px;
          border-color: transparent transparent transparent #fff; }
    .site_footer__nav .lv1 .lv2 {
      display: none; }
      .site_footer__nav .lv1 .lv2 > li:first-of-type {
        margin-top: 10px; }
      .site_footer__nav .lv1 .lv2 > li > a {
        display: block;
        padding: 7px 5px;
        padding-left: 5px;
        color: #D0D0D0; }
        .site_footer__nav .lv1 .lv2 > li > a::before {
          content: "";
          margin-right: 8px;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 7px;
          border-color: transparent transparent transparent #848484; }
    .site_footer__nav .column {
      margin-bottom: 0; } }

@media screen and (min-width: 640px) {
  .site_footer__nav {
    width: 82%;
    margin: 0 auto 0; }
    .site_footer__nav a:hover {
      text-decoration: underline; }
    .site_footer__nav .top {
      width: 31%; }
      .site_footer__nav .top a {
        font-size: 120%;
        border-bottom: 1px solid #fff;
        display: block;
        padding-bottom: 10px;
        margin-bottom: 20px; }
    .site_footer__nav .primary a {
      font-family: "Heebo", sans-serif;
      font-weight: 600;
      letter-spacing: 2px; }
    .site_footer__nav .secondary {
      font-size: 127%;
      letter-spacing: 2px; }
      .site_footer__nav .secondary li {
        margin-bottom: 30px; }
    .site_footer__nav .column {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      width: 100%; }
      .site_footer__nav .column > * {
        width: 31%; }
      .site_footer__nav .column .lv1.flat > li + li,
      .site_footer__nav .column .lv2 > li {
        margin-top: 10px; }
      .site_footer__nav .column .lv1 {
        font-size: 120%; }
        .site_footer__nav .column .lv1 > li {
          margin-top: 60px; }
          .site_footer__nav .column .lv1 > li:first-of-type {
            margin-top: 20px; }
          .site_footer__nav .column .lv1 > li > a {
            font-weight: bold;
            border-bottom: 1px solid #fff;
            display: block;
            padding-bottom: 10px;
            margin-bottom: 20px; }
      .site_footer__nav .column .lv2 {
        display: block !important;
        font-size: 100%;
        padding-left: 1em; }
        .site_footer__nav .column .lv2, .site_footer__nav .column .lv2 a {
          color: #aaa; } }

.site_footer__under {
  text-align: center;
  margin-top: 60px; }
  @media screen and (min-width: 640px) {
    .site_footer__under {
      margin-top: 100px;
      width: 1140px;
      margin-left: auto;
      margin-right: auto;
      position: relative; } }

.site_footer__logo {
  height: 58px;
  overflow: hidden; }
  .site_footer__logo i {
    font-size: 175px;
    display: block;
    margin: -85px auto 0; }
    @media screen and (max-width: 639px) {
      .site_footer__logo i {
        font-size: 160px;
        margin: -70px auto 0; } }

.site_footer__copyright {
  text-align: center;
  font-family: arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 80%;
  margin-top: 26px; }

.site_footer__sns {
  margin-top: 28px; }
  @media screen and (min-width: 640px) {
    .site_footer__sns {
      margin-top: 0;
      position: absolute;
      right: 10px;
      bottom: -10px; } }
  .site_footer__sns ul li + li {
    margin-left: 20px; }
  .site_footer__sns ul li {
    display: inline-block;
    -webkit-transition: opacity 300ms ease;
    -moz-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease; }
    .site_footer__sns ul li [class^="icon--"]:before,
    .site_footer__sns ul li [class*=" icon--"]:before {
      font-size: 28px; }
    @media screen and (min-width: 640px) {
      .site_footer__sns ul li:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
        opacity: 0.7; } }

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .site_footer__logo i {
    padding-top: 10px; } }

.page_top {
  margin-top: -32px;
  text-align: center; }
  .page_top a {
    display: inline-block;
    width: 64px;
    height: 31px;
    text-align: center; }
    .page_top a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 32px 32px 32px;
      border-color: transparent transparent #000 transparent;
      position: relative;
      top: -16.25px; }
    .page_top a i {
      display: block;
      position: relative;
      top: 0;
      -webkit-transition: top 300ms ease;
      -moz-transition: top 300ms ease;
      -o-transition: top 300ms ease;
      transition: top 300ms ease; }
      .page_top a i:before, .page_top a i:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        left: 0;
        right: 0;
        top: 3px;
        bottom: 0;
        margin: auto; }
      .page_top a i:before {
        border-width: 0 9.5px 10px 9.5px;
        border-color: transparent transparent #ffffff transparent; }
      .page_top a i:after {
        border-width: 0 5.5px 5.5px 5.5px;
        border-color: transparent transparent #333 transparent;
        top: 6px; }
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          .page_top a i:after {
            top: 5px; } }
    @media screen and (min-width: 640px) {
      .page_top a:hover i {
        top: -2px; } }

.cta_btn {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: auto;
  margin-top: -107px;
  position: fixed;
  z-index: 1002;
  right: 20px;
  bottom: 20px; }
  @media screen and (max-width: 639px) {
    .cta_btn {
      width: 88px;
      right: 0px; } }
  .cta_btn--group {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 110px;
    background-color: rgba(47, 108, 181, 0.29); }
    @media screen and (min-width: 640px) {
      .cta_btn--group {
        display: flex; } }
    @media screen and (max-width: 639px) {
      .cta_btn--group {
        width: 100px;
        padding: 6px;
        box-sizing: border-box; } }
    .cta_btn--group a:not(:last-child) {
      margin-bottom: 20px; }
      @media screen and (max-width: 639px) {
        .cta_btn--group a:not(:last-child) {
          margin-bottom: 10px; } }
  .cta_btn a {
    -webkit-transition: top 300ms ease;
    -moz-transition: top 300ms ease;
    -o-transition: top 300ms ease;
    transition: top 300ms ease;
    display: block; }
    .cta_btn a:hover {
      text-decoration: none; }
      @media screen and (min-width: 640px) {
        .cta_btn a:hover {
          -webkit-animation-name: fuwafuwa;
          -webkit-animation-duration: 1s;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: ease;
          -moz-animation-name: fuwafuwa;
          -moz-animation-duration: 1s;
          -moz-animation-iteration-count: infinite;
          -moz-animation-timing-function: ease; } }

@-webkit-keyframes fuwafuwa {
  0% {
    -webkit-transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(0, -5px); }
  100% {
    -webkit-transform: translate(0, 0); } }

@-moz-keyframes fuwafuwa {
  0% {
    -moz-transform: translate(0, 0); }
  50% {
    -moz-transform: translate(0, -5px); }
  100% {
    -moz-transform: translate(0, 0); } }

/* 固定表示するオブジェクト */
/* -------------------------------------------------------------------------
	Object
------------------------------------------------------------------------- */
/* Component */
/*

no support message

*/
.noscript,
.legacy_ie_message {
  background-color: #fff9d7;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1px; }

.legacy_ie_message {
  display: none; }
  .regacy_ie .legacy_ie_message {
    display: block; }

/*

標準のマーカーを使ったリスト

*/
.list_disc > li, .list_square > li, .list_decimal > li, .list_decimal_zero > li, .list_note > li, .list_dot > li, .list_circle > li, .list_decimal_01 > li, .list_decimal_01_roman > li, .list_decimal_02 > li, .list_decimal_03 > li, .list_decimal_04 > li, .list_upper_alpha_01 > li {
  margin-bottom: 7px; }
  .list_disc > li:last-child, .list_square > li:last-child, .list_decimal > li:last-child, .list_decimal_zero > li:last-child, .list_note > li:last-child, .list_dot > li:last-child, .list_circle > li:last-child, .list_decimal_01 > li:last-child, .list_decimal_01_roman > li:last-child, .list_decimal_02 > li:last-child, .list_decimal_03 > li:last-child, .list_decimal_04 > li:last-child, .list_upper_alpha_01 > li:last-child {
    margin-bottom: 0; }

.list_disc {
  margin-left: 1.2em;
  list-style: disc; }

.list_square {
  margin-left: 1.2em;
  list-style: square; }

.list_decimal {
  margin-left: 1.7em;
  list-style: decimal; }

.list_decimal_zero {
  margin-left: 2.3em;
  list-style: decimal-leading-zero; }

/*

beforeでマーカーを作ったリスト (IE8以上)

*/
/* ---- ※ ---- */
.list_note > li {
  text-indent: -1.2em;
  margin-left: 1.2em; }
  .list_note > li:before {
    content: "※ "; }
  .list_note > li > * {
    text-indent: 0; }

/* ---- ・ ---- */
.list_dot > li {
  text-indent: -1em;
  margin-left: 1em; }
  .list_dot > li:before {
    content: "・"; }
  .list_dot > li > * {
    text-indent: 0; }

/* ---- ● ---- */
.list_circle > li {
  text-indent: -1em;
  margin-left: 1em; }
  .list_circle > li:before {
    content: "● "; }
  .list_circle > li > * {
    text-indent: 0; }

/* ---- 1. ---- */
.list_decimal_01 > li {
  counter-increment: decimal_01;
  margin-left: 1.2em;
  text-indent: -1.2em; }
  .list_decimal_01 > li:before {
    content: counter(decimal_01) ". "; }
  .list_decimal_01 > li > * {
    text-indent: 0; }

/* ---- 1. (ローマ数字)---- */
.list_decimal_01_roman > li {
  counter-increment: decimal_01_roman;
  margin-left: 1.2em;
  text-indent: -1.2em; }
  .list_decimal_01_roman > li:before {
    content: counter(decimal_01_roman, upper-roman) ". "; }
  .list_decimal_01_roman > li > * {
    text-indent: 0; }

/* ---- (1) ---- */
.list_decimal_02 > li {
  counter-increment: decimal_02;
  margin-left: 1.5em;
  text-indent: -1.5em; }
  .list_decimal_02 > li:before {
    content: "(" counter(decimal_02) ") "; }
  .list_decimal_02 > li > * {
    text-indent: 0; }

/* ---- [1] ---- */
.list_decimal_03 > li {
  counter-increment: decimal_03;
  margin-left: 1.5em;
  text-indent: -1.5em; }
  .list_decimal_03 > li:before {
    content: "[" counter(decimal_03) "] "; }
  .list_decimal_03 > li > * {
    text-indent: 0; }

/* ---- 丸数字 ---- */
.list_decimal_04 > li {
  counter-increment: decimal_04;
  margin-left: 1.5em;
  text-indent: -1.5em; }
  .list_decimal_04 > li:before {
    content: counter(decimal_04);
    border: 1px solid #000;
    padding: 0 3px;
    font-size: 10px;
    margin-right: 5px;
    text-align: center;
    line-height: 1;
    border-radius: 50%; }
  .list_decimal_04 > li > * {
    text-indent: 0; }

/* ---- upper alpha ---- */
.list_upper_alpha_01 > li {
  counter-increment: upper_alpha_01;
  margin-left: 1.5em;
  text-indent: -1.5em; }
  .list_upper_alpha_01 > li:before {
    content: counter(upper_alpha_01, upper-alpha) ") "; }
  .list_upper_alpha_01 > li > * {
    text-indent: 0; }

/*

注釈 (IE8以上)

*/
/* ---- ※（注釈） ---- */
.notes {
  margin-left: 1.3em;
  text-indent: -1.3em; }
  .notes:before {
    content: "※ "; }
  .notes > * {
    text-indent: 0; }

/* ---- ※n （番号付き注釈）---- */
.notes_num {
  counter-increment: decimal_notes;
  margin-left: 1.9em;
  text-indent: -1.9em; }
  .notes_num:before {
    content: "※" counter(decimal_notes) " "; }
  .notes_num > * {
    text-indent: 0; }

/* Project */
/*

アニメーション

*/
.animate {
  filter: brightness(300%);
  transform: scale(1.1);
  transition: filter .8s , transform 1.4s; }
  .animate__inner {
    overflow: hidden; }
  .animate.is_animated {
    filter: brightness(1);
    transform: scale(1); }
  .animate--fade-in-up {
    transform: translateY(30px);
    opacity: 0;
    transition: transform .8s, opacity .8s; }
    .animate--fade-in-up.is_animated {
      transform: translateY(0);
      opacity: 1; }

/*

ボタン

*/
.btn--default {
  border: #2F6CB5 2px solid;
  display: inline-block;
  text-align: center;
  min-width: 300px;
  box-sizing: border-box;
  position: relative; }
  @media screen and (min-width: 640px) {
    .btn--default {
      font-size: 113%;
      padding: 20px 0; } }
  @media screen and (max-width: 639px) {
    .btn--default {
      font-size: 93%;
      padding: 15px 0; } }
  .btn--default::before {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    background-color: #2F6CB5;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: scaleX(1);
    transition: transform .3s;
    transform-origin: left center; }
  .btn--default::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #2F6CB5;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    transform: scaleX(0);
    transition: transform .3s;
    transform-origin: right center; }
  .btn--default span {
    display: block;
    position: relative;
    z-index: 2;
    transition: color .2s; }
  .btn--default:hover::before {
    transform: scaleX(0);
    transform-origin: right center; }
  .btn--default:hover::after {
    transform: scaleX(1);
    transform-origin: left center; }
  .btn--default:hover span {
    color: #fff; }

/*

キャプション

*/
.caption_lv1,
.caption_lv2 {
  font-feature-settings: "palt";
  letter-spacing: .15em;
  font-weight: 500; }

.caption_lv1 {
  font-size: 253%;
  margin-bottom: 70px;
  border-bottom: 1px solid #707070;
  padding-bottom: 10px; }
  @media screen and (max-width: 639px) {
    .caption_lv1 {
      font-size: 167%;
      line-height: 1.5;
      margin-bottom: 30px; } }
  .caption_lv1 span {
    font-size: 66%;
    display: block; }
    @media screen and (max-width: 639px) {
      .caption_lv1 span {
        font-size: 64%; } }

.caption_lv2 {
  font-size: 173%;
  font-weight: 700;
  font-family: "Heebo", sans-serif;
  margin-bottom: 50px;
  padding: 10px 30px 7px 30px; }
  @media screen and (max-width: 639px) {
    .caption_lv2 {
      font-size: 113%;
      line-height: 1.5;
      padding: 10px 20px 7px 20px;
      margin-bottom: 20px; } }
  .caption_lv2.rod {
    background-color: #0C9BBF;
    color: #fff; }
  .caption_lv2.lure {
    background-color: #2F6CB5;
    color: #fff; }

.catch_btn {
  background-color: #00a0d4;
  padding: 10px;
  position: relative; }
  .catch_btn, .catch_btn:hover,
  .catch_btn i,
  .catch_btn i:before {
    color: #fff; }
  .catch_btn:after {
    -webkit-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
    -o-transition: height 500ms ease;
    transition: height 500ms ease;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3;
    content: "";
    background-color: #fff;
    width: 100%;
    height: 0;
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0; }
  .catch_btn:hover {
    text-decoration: none; }
    @media screen and (min-width: 640px) {
      .catch_btn:hover:after {
        height: 100%; } }
  .catch_btn__inner {
    position: relative;
    z-index: 1; }
  .catch_btn__main, .catch_btn__sub {
    display: block;
    text-align: center; }
  .catch_btn__main {
    font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    font-feature-settings: "palt";
    letter-spacing: .06em;
    font-size: 133%; }
    .catch_btn__main i {
      font-size: 120%;
      margin-right: 8px;
      vertical-align: middle; }
  .catch_btn__sub {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
    font-family: "Pacifico", cursive;
    font-size: 87%;
    font-weight: normal; }

.default_btn {
  font-size: 93%;
  display: inline-block;
  position: relative;
  padding: 11px 35px 10px; }
  .default_btn, .default_btn a {
    color: #333; }
    @media screen and (min-width: 640px) {
      .default_btn:hover, .default_btn a:hover {
        color: #333; } }
  .default_btn:before, .default_btn:after {
    border-color: #333; }
    @media screen and (min-width: 640px) {
      .default_btn:before, .default_btn:after {
        border-color: #333; } }
  .default_btn .default_btn__top:before, .default_btn .default_btn__top:after {
    background-color: #333; }
  .default_btn.attr_light, .default_btn.attr_light a {
    color: #fff; }
    @media screen and (min-width: 640px) {
      .default_btn.attr_light:hover, .default_btn.attr_light a:hover {
        color: #fff; } }
  .default_btn.attr_light:before, .default_btn.attr_light:after {
    border-color: #fff; }
    @media screen and (min-width: 640px) {
      .default_btn.attr_light:before, .default_btn.attr_light:after {
        border-color: #fff; } }
  .default_btn.attr_light .default_btn__top:before, .default_btn.attr_light .default_btn__top:after {
    background-color: #fff; }
  .default_btn:hover {
    text-decoration: none; }
  .default_btn__top, .default_btn:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    border: 2px solid;
    border-top: none;
    width: 100%;
    position: absolute;
    z-index: 0;
    left: 0; }
  .default_btn:after {
    bottom: 0;
    height: 50%; }
  .default_btn__top {
    -webkit-transition: height 150ms linear;
    -webkit-transition-delay: 350ms;
    -moz-transition: height 150ms linear 350ms;
    -o-transition: height 150ms linear 350ms;
    transition: height 150ms linear 350ms;
    border-bottom: none;
    bottom: 50%;
    height: 0;
    overflow: hidden; }
    .default_btn__top:before, .default_btn__top:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: width 350ms linear;
      -moz-transition: width 350ms linear;
      -o-transition: width 350ms linear;
      transition: width 350ms linear;
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      z-index: 0;
      top: 0; }
    .default_btn__top:before {
      left: 0; }
    .default_btn__top:after {
      right: 0; }
  @media screen and (min-width: 640px) {
    .default_btn:hover .default_btn__top {
      -webkit-transition: height 150ms linear;
      -moz-transition: height 150ms linear;
      -o-transition: height 150ms linear;
      transition: height 150ms linear;
      height: 50%; }
      .default_btn:hover .default_btn__top:before, .default_btn:hover .default_btn__top:after {
        -webkit-transition: width 350ms linear;
        -webkit-transition-delay: 150ms;
        -moz-transition: width 350ms linear 150ms;
        -o-transition: width 350ms linear 150ms;
        transition: width 350ms linear 150ms;
        width: 51%; } }

/*

12分割グリッド

*/
.grid--12 {
  width: auto !important; }
  .grid--12.gutter--0 {
    margin-left: 0%;
    margin-top: 0%; }
    .grid--12.gutter--0 > * {
      box-sizing: border-box;
      padding-left: 0%;
      padding-top: 0%; }
  .grid--12.gutter--1 {
    margin-left: -1%;
    margin-top: -1%; }
    .grid--12.gutter--1 > * {
      box-sizing: border-box;
      padding-left: 1%;
      padding-top: 1%; }
  .grid--12.gutter--2 {
    margin-left: -2%;
    margin-top: -2%; }
    .grid--12.gutter--2 > * {
      box-sizing: border-box;
      padding-left: 2%;
      padding-top: 2%; }
  .grid--12.gutter--3 {
    margin-left: -3%;
    margin-top: -3%; }
    .grid--12.gutter--3 > * {
      box-sizing: border-box;
      padding-left: 3%;
      padding-top: 3%; }
  .grid--12.gutter--4 {
    margin-left: -4%;
    margin-top: -4%; }
    .grid--12.gutter--4 > * {
      box-sizing: border-box;
      padding-left: 4%;
      padding-top: 4%; }
  .grid--12.gutter--5 {
    margin-left: -5%;
    margin-top: -5%; }
    .grid--12.gutter--5 > * {
      box-sizing: border-box;
      padding-left: 5%;
      padding-top: 5%; }
  .grid--12.gutter--6 {
    margin-left: -6%;
    margin-top: -6%; }
    .grid--12.gutter--6 > * {
      box-sizing: border-box;
      padding-left: 6%;
      padding-top: 6%; }
  .grid--12.gutter--7 {
    margin-left: -7%;
    margin-top: -7%; }
    .grid--12.gutter--7 > * {
      box-sizing: border-box;
      padding-left: 7%;
      padding-top: 7%; }
  .grid--12.gutter--8 {
    margin-left: -8%;
    margin-top: -8%; }
    .grid--12.gutter--8 > * {
      box-sizing: border-box;
      padding-left: 8%;
      padding-top: 8%; }
  .grid--12.gutter--9 {
    margin-left: -9%;
    margin-top: -9%; }
    .grid--12.gutter--9 > * {
      box-sizing: border-box;
      padding-left: 9%;
      padding-top: 9%; }
  .grid--12.gutter--10 {
    margin-left: -10%;
    margin-top: -10%; }
    .grid--12.gutter--10 > * {
      box-sizing: border-box;
      padding-left: 10%;
      padding-top: 10%; }
  .grid--12.gutter--11 {
    margin-left: -11%;
    margin-top: -11%; }
    .grid--12.gutter--11 > * {
      box-sizing: border-box;
      padding-left: 11%;
      padding-top: 11%; }
  .grid--12.gutter--12 {
    margin-left: -12%;
    margin-top: -12%; }
    .grid--12.gutter--12 > * {
      box-sizing: border-box;
      padding-left: 12%;
      padding-top: 12%; }
  .grid--12.gutter--13 {
    margin-left: -13%;
    margin-top: -13%; }
    .grid--12.gutter--13 > * {
      box-sizing: border-box;
      padding-left: 13%;
      padding-top: 13%; }
  .grid--12.gutter--14 {
    margin-left: -14%;
    margin-top: -14%; }
    .grid--12.gutter--14 > * {
      box-sizing: border-box;
      padding-left: 14%;
      padding-top: 14%; }
  .grid--12.gutter--15 {
    margin-left: -15%;
    margin-top: -15%; }
    .grid--12.gutter--15 > * {
      box-sizing: border-box;
      padding-left: 15%;
      padding-top: 15%; }
  .grid--12.gutter--16 {
    margin-left: -16%;
    margin-top: -16%; }
    .grid--12.gutter--16 > * {
      box-sizing: border-box;
      padding-left: 16%;
      padding-top: 16%; }
  .grid--12.gutter--17 {
    margin-left: -17%;
    margin-top: -17%; }
    .grid--12.gutter--17 > * {
      box-sizing: border-box;
      padding-left: 17%;
      padding-top: 17%; }
  .grid--12.gutter--18 {
    margin-left: -18%;
    margin-top: -18%; }
    .grid--12.gutter--18 > * {
      box-sizing: border-box;
      padding-left: 18%;
      padding-top: 18%; }
  .grid--12.gutter--19 {
    margin-left: -19%;
    margin-top: -19%; }
    .grid--12.gutter--19 > * {
      box-sizing: border-box;
      padding-left: 19%;
      padding-top: 19%; }
  .grid--12.gutter--20 {
    margin-left: -20%;
    margin-top: -20%; }
    .grid--12.gutter--20 > * {
      box-sizing: border-box;
      padding-left: 20%;
      padding-top: 20%; }
  .grid--12.gutter--0px {
    margin-left: 0px;
    margin-top: 0px; }
    .grid--12.gutter--0px > * {
      box-sizing: border-box;
      padding-left: 0px;
      padding-top: 0px; }
  .grid--12.gutter--1px {
    margin-left: -1px;
    margin-top: -1px; }
    .grid--12.gutter--1px > * {
      box-sizing: border-box;
      padding-left: 1px;
      padding-top: 1px; }
  .grid--12.gutter--2px {
    margin-left: -2px;
    margin-top: -2px; }
    .grid--12.gutter--2px > * {
      box-sizing: border-box;
      padding-left: 2px;
      padding-top: 2px; }
  .grid--12.gutter--3px {
    margin-left: -3px;
    margin-top: -3px; }
    .grid--12.gutter--3px > * {
      box-sizing: border-box;
      padding-left: 3px;
      padding-top: 3px; }
  .grid--12.gutter--4px {
    margin-left: -4px;
    margin-top: -4px; }
    .grid--12.gutter--4px > * {
      box-sizing: border-box;
      padding-left: 4px;
      padding-top: 4px; }
  .grid--12.gutter--5px {
    margin-left: -5px;
    margin-top: -5px; }
    .grid--12.gutter--5px > * {
      box-sizing: border-box;
      padding-left: 5px;
      padding-top: 5px; }
  .grid--12.gutter--6px {
    margin-left: -6px;
    margin-top: -6px; }
    .grid--12.gutter--6px > * {
      box-sizing: border-box;
      padding-left: 6px;
      padding-top: 6px; }
  .grid--12.gutter--7px {
    margin-left: -7px;
    margin-top: -7px; }
    .grid--12.gutter--7px > * {
      box-sizing: border-box;
      padding-left: 7px;
      padding-top: 7px; }
  .grid--12.gutter--8px {
    margin-left: -8px;
    margin-top: -8px; }
    .grid--12.gutter--8px > * {
      box-sizing: border-box;
      padding-left: 8px;
      padding-top: 8px; }
  .grid--12.gutter--9px {
    margin-left: -9px;
    margin-top: -9px; }
    .grid--12.gutter--9px > * {
      box-sizing: border-box;
      padding-left: 9px;
      padding-top: 9px; }
  .grid--12.gutter--10px {
    margin-left: -10px;
    margin-top: -10px; }
    .grid--12.gutter--10px > * {
      box-sizing: border-box;
      padding-left: 10px;
      padding-top: 10px; }
  .grid--12.gutter--11px {
    margin-left: -11px;
    margin-top: -11px; }
    .grid--12.gutter--11px > * {
      box-sizing: border-box;
      padding-left: 11px;
      padding-top: 11px; }
  .grid--12.gutter--12px {
    margin-left: -12px;
    margin-top: -12px; }
    .grid--12.gutter--12px > * {
      box-sizing: border-box;
      padding-left: 12px;
      padding-top: 12px; }
  .grid--12.gutter--13px {
    margin-left: -13px;
    margin-top: -13px; }
    .grid--12.gutter--13px > * {
      box-sizing: border-box;
      padding-left: 13px;
      padding-top: 13px; }
  .grid--12.gutter--14px {
    margin-left: -14px;
    margin-top: -14px; }
    .grid--12.gutter--14px > * {
      box-sizing: border-box;
      padding-left: 14px;
      padding-top: 14px; }
  .grid--12.gutter--15px {
    margin-left: -15px;
    margin-top: -15px; }
    .grid--12.gutter--15px > * {
      box-sizing: border-box;
      padding-left: 15px;
      padding-top: 15px; }
  .grid--12.gutter--16px {
    margin-left: -16px;
    margin-top: -16px; }
    .grid--12.gutter--16px > * {
      box-sizing: border-box;
      padding-left: 16px;
      padding-top: 16px; }
  .grid--12.gutter--17px {
    margin-left: -17px;
    margin-top: -17px; }
    .grid--12.gutter--17px > * {
      box-sizing: border-box;
      padding-left: 17px;
      padding-top: 17px; }
  .grid--12.gutter--18px {
    margin-left: -18px;
    margin-top: -18px; }
    .grid--12.gutter--18px > * {
      box-sizing: border-box;
      padding-left: 18px;
      padding-top: 18px; }
  .grid--12.gutter--19px {
    margin-left: -19px;
    margin-top: -19px; }
    .grid--12.gutter--19px > * {
      box-sizing: border-box;
      padding-left: 19px;
      padding-top: 19px; }
  .grid--12.gutter--20px {
    margin-left: -20px;
    margin-top: -20px; }
    .grid--12.gutter--20px > * {
      box-sizing: border-box;
      padding-left: 20px;
      padding-top: 20px; }
  .grid--12.gutter--21px {
    margin-left: -21px;
    margin-top: -21px; }
    .grid--12.gutter--21px > * {
      box-sizing: border-box;
      padding-left: 21px;
      padding-top: 21px; }
  .grid--12.gutter--22px {
    margin-left: -22px;
    margin-top: -22px; }
    .grid--12.gutter--22px > * {
      box-sizing: border-box;
      padding-left: 22px;
      padding-top: 22px; }
  .grid--12.gutter--23px {
    margin-left: -23px;
    margin-top: -23px; }
    .grid--12.gutter--23px > * {
      box-sizing: border-box;
      padding-left: 23px;
      padding-top: 23px; }
  .grid--12.gutter--24px {
    margin-left: -24px;
    margin-top: -24px; }
    .grid--12.gutter--24px > * {
      box-sizing: border-box;
      padding-left: 24px;
      padding-top: 24px; }
  .grid--12.gutter--25px {
    margin-left: -25px;
    margin-top: -25px; }
    .grid--12.gutter--25px > * {
      box-sizing: border-box;
      padding-left: 25px;
      padding-top: 25px; }
  .grid--12.gutter--26px {
    margin-left: -26px;
    margin-top: -26px; }
    .grid--12.gutter--26px > * {
      box-sizing: border-box;
      padding-left: 26px;
      padding-top: 26px; }
  .grid--12.gutter--27px {
    margin-left: -27px;
    margin-top: -27px; }
    .grid--12.gutter--27px > * {
      box-sizing: border-box;
      padding-left: 27px;
      padding-top: 27px; }
  .grid--12.gutter--28px {
    margin-left: -28px;
    margin-top: -28px; }
    .grid--12.gutter--28px > * {
      box-sizing: border-box;
      padding-left: 28px;
      padding-top: 28px; }
  .grid--12.gutter--29px {
    margin-left: -29px;
    margin-top: -29px; }
    .grid--12.gutter--29px > * {
      box-sizing: border-box;
      padding-left: 29px;
      padding-top: 29px; }
  .grid--12.gutter--30px {
    margin-left: -30px;
    margin-top: -30px; }
    .grid--12.gutter--30px > * {
      box-sizing: border-box;
      padding-left: 30px;
      padding-top: 30px; }
  .grid--12.gutter--31px {
    margin-left: -31px;
    margin-top: -31px; }
    .grid--12.gutter--31px > * {
      box-sizing: border-box;
      padding-left: 31px;
      padding-top: 31px; }
  .grid--12.gutter--32px {
    margin-left: -32px;
    margin-top: -32px; }
    .grid--12.gutter--32px > * {
      box-sizing: border-box;
      padding-left: 32px;
      padding-top: 32px; }
  .grid--12.gutter--33px {
    margin-left: -33px;
    margin-top: -33px; }
    .grid--12.gutter--33px > * {
      box-sizing: border-box;
      padding-left: 33px;
      padding-top: 33px; }
  .grid--12.gutter--34px {
    margin-left: -34px;
    margin-top: -34px; }
    .grid--12.gutter--34px > * {
      box-sizing: border-box;
      padding-left: 34px;
      padding-top: 34px; }
  .grid--12.gutter--35px {
    margin-left: -35px;
    margin-top: -35px; }
    .grid--12.gutter--35px > * {
      box-sizing: border-box;
      padding-left: 35px;
      padding-top: 35px; }
  .grid--12.gutter--36px {
    margin-left: -36px;
    margin-top: -36px; }
    .grid--12.gutter--36px > * {
      box-sizing: border-box;
      padding-left: 36px;
      padding-top: 36px; }
  .grid--12.gutter--37px {
    margin-left: -37px;
    margin-top: -37px; }
    .grid--12.gutter--37px > * {
      box-sizing: border-box;
      padding-left: 37px;
      padding-top: 37px; }
  .grid--12.gutter--38px {
    margin-left: -38px;
    margin-top: -38px; }
    .grid--12.gutter--38px > * {
      box-sizing: border-box;
      padding-left: 38px;
      padding-top: 38px; }
  .grid--12.gutter--39px {
    margin-left: -39px;
    margin-top: -39px; }
    .grid--12.gutter--39px > * {
      box-sizing: border-box;
      padding-left: 39px;
      padding-top: 39px; }
  .grid--12.gutter--40px {
    margin-left: -40px;
    margin-top: -40px; }
    .grid--12.gutter--40px > * {
      box-sizing: border-box;
      padding-left: 40px;
      padding-top: 40px; }
  .grid--12.gutter--41px {
    margin-left: -41px;
    margin-top: -41px; }
    .grid--12.gutter--41px > * {
      box-sizing: border-box;
      padding-left: 41px;
      padding-top: 41px; }
  .grid--12.gutter--42px {
    margin-left: -42px;
    margin-top: -42px; }
    .grid--12.gutter--42px > * {
      box-sizing: border-box;
      padding-left: 42px;
      padding-top: 42px; }
  .grid--12.gutter--43px {
    margin-left: -43px;
    margin-top: -43px; }
    .grid--12.gutter--43px > * {
      box-sizing: border-box;
      padding-left: 43px;
      padding-top: 43px; }
  .grid--12.gutter--44px {
    margin-left: -44px;
    margin-top: -44px; }
    .grid--12.gutter--44px > * {
      box-sizing: border-box;
      padding-left: 44px;
      padding-top: 44px; }
  .grid--12.gutter--45px {
    margin-left: -45px;
    margin-top: -45px; }
    .grid--12.gutter--45px > * {
      box-sizing: border-box;
      padding-left: 45px;
      padding-top: 45px; }
  .grid--12.gutter--46px {
    margin-left: -46px;
    margin-top: -46px; }
    .grid--12.gutter--46px > * {
      box-sizing: border-box;
      padding-left: 46px;
      padding-top: 46px; }
  .grid--12.gutter--47px {
    margin-left: -47px;
    margin-top: -47px; }
    .grid--12.gutter--47px > * {
      box-sizing: border-box;
      padding-left: 47px;
      padding-top: 47px; }
  .grid--12.gutter--48px {
    margin-left: -48px;
    margin-top: -48px; }
    .grid--12.gutter--48px > * {
      box-sizing: border-box;
      padding-left: 48px;
      padding-top: 48px; }
  .grid--12.gutter--49px {
    margin-left: -49px;
    margin-top: -49px; }
    .grid--12.gutter--49px > * {
      box-sizing: border-box;
      padding-left: 49px;
      padding-top: 49px; }
  .grid--12.gutter--50px {
    margin-left: -50px;
    margin-top: -50px; }
    .grid--12.gutter--50px > * {
      box-sizing: border-box;
      padding-left: 50px;
      padding-top: 50px; }
  .grid--12.gutter--51px {
    margin-left: -51px;
    margin-top: -51px; }
    .grid--12.gutter--51px > * {
      box-sizing: border-box;
      padding-left: 51px;
      padding-top: 51px; }
  .grid--12.gutter--52px {
    margin-left: -52px;
    margin-top: -52px; }
    .grid--12.gutter--52px > * {
      box-sizing: border-box;
      padding-left: 52px;
      padding-top: 52px; }
  .grid--12.gutter--53px {
    margin-left: -53px;
    margin-top: -53px; }
    .grid--12.gutter--53px > * {
      box-sizing: border-box;
      padding-left: 53px;
      padding-top: 53px; }
  .grid--12.gutter--54px {
    margin-left: -54px;
    margin-top: -54px; }
    .grid--12.gutter--54px > * {
      box-sizing: border-box;
      padding-left: 54px;
      padding-top: 54px; }
  .grid--12.gutter--55px {
    margin-left: -55px;
    margin-top: -55px; }
    .grid--12.gutter--55px > * {
      box-sizing: border-box;
      padding-left: 55px;
      padding-top: 55px; }
  .grid--12.gutter--56px {
    margin-left: -56px;
    margin-top: -56px; }
    .grid--12.gutter--56px > * {
      box-sizing: border-box;
      padding-left: 56px;
      padding-top: 56px; }
  .grid--12.gutter--57px {
    margin-left: -57px;
    margin-top: -57px; }
    .grid--12.gutter--57px > * {
      box-sizing: border-box;
      padding-left: 57px;
      padding-top: 57px; }
  .grid--12.gutter--58px {
    margin-left: -58px;
    margin-top: -58px; }
    .grid--12.gutter--58px > * {
      box-sizing: border-box;
      padding-left: 58px;
      padding-top: 58px; }
  .grid--12.gutter--59px {
    margin-left: -59px;
    margin-top: -59px; }
    .grid--12.gutter--59px > * {
      box-sizing: border-box;
      padding-left: 59px;
      padding-top: 59px; }
  .grid--12.gutter--60px {
    margin-left: -60px;
    margin-top: -60px; }
    .grid--12.gutter--60px > * {
      box-sizing: border-box;
      padding-left: 60px;
      padding-top: 60px; }
  @media screen and (max-width: 1139px) {
    .grid--12.liquid_gutter--0 {
      margin-left: 0%;
      margin-top: 0%; }
      .grid--12.liquid_gutter--0 > * {
        box-sizing: border-box;
        padding-left: 0%;
        padding-top: 0%; }
    .grid--12.liquid_gutter--1 {
      margin-left: -1%;
      margin-top: -1%; }
      .grid--12.liquid_gutter--1 > * {
        box-sizing: border-box;
        padding-left: 1%;
        padding-top: 1%; }
    .grid--12.liquid_gutter--2 {
      margin-left: -2%;
      margin-top: -2%; }
      .grid--12.liquid_gutter--2 > * {
        box-sizing: border-box;
        padding-left: 2%;
        padding-top: 2%; }
    .grid--12.liquid_gutter--3 {
      margin-left: -3%;
      margin-top: -3%; }
      .grid--12.liquid_gutter--3 > * {
        box-sizing: border-box;
        padding-left: 3%;
        padding-top: 3%; }
    .grid--12.liquid_gutter--4 {
      margin-left: -4%;
      margin-top: -4%; }
      .grid--12.liquid_gutter--4 > * {
        box-sizing: border-box;
        padding-left: 4%;
        padding-top: 4%; }
    .grid--12.liquid_gutter--5 {
      margin-left: -5%;
      margin-top: -5%; }
      .grid--12.liquid_gutter--5 > * {
        box-sizing: border-box;
        padding-left: 5%;
        padding-top: 5%; }
    .grid--12.liquid_gutter--6 {
      margin-left: -6%;
      margin-top: -6%; }
      .grid--12.liquid_gutter--6 > * {
        box-sizing: border-box;
        padding-left: 6%;
        padding-top: 6%; }
    .grid--12.liquid_gutter--7 {
      margin-left: -7%;
      margin-top: -7%; }
      .grid--12.liquid_gutter--7 > * {
        box-sizing: border-box;
        padding-left: 7%;
        padding-top: 7%; }
    .grid--12.liquid_gutter--8 {
      margin-left: -8%;
      margin-top: -8%; }
      .grid--12.liquid_gutter--8 > * {
        box-sizing: border-box;
        padding-left: 8%;
        padding-top: 8%; }
    .grid--12.liquid_gutter--9 {
      margin-left: -9%;
      margin-top: -9%; }
      .grid--12.liquid_gutter--9 > * {
        box-sizing: border-box;
        padding-left: 9%;
        padding-top: 9%; }
    .grid--12.liquid_gutter--10 {
      margin-left: -10%;
      margin-top: -10%; }
      .grid--12.liquid_gutter--10 > * {
        box-sizing: border-box;
        padding-left: 10%;
        padding-top: 10%; }
    .grid--12.liquid_gutter--11 {
      margin-left: -11%;
      margin-top: -11%; }
      .grid--12.liquid_gutter--11 > * {
        box-sizing: border-box;
        padding-left: 11%;
        padding-top: 11%; }
    .grid--12.liquid_gutter--12 {
      margin-left: -12%;
      margin-top: -12%; }
      .grid--12.liquid_gutter--12 > * {
        box-sizing: border-box;
        padding-left: 12%;
        padding-top: 12%; }
    .grid--12.liquid_gutter--13 {
      margin-left: -13%;
      margin-top: -13%; }
      .grid--12.liquid_gutter--13 > * {
        box-sizing: border-box;
        padding-left: 13%;
        padding-top: 13%; }
    .grid--12.liquid_gutter--14 {
      margin-left: -14%;
      margin-top: -14%; }
      .grid--12.liquid_gutter--14 > * {
        box-sizing: border-box;
        padding-left: 14%;
        padding-top: 14%; }
    .grid--12.liquid_gutter--15 {
      margin-left: -15%;
      margin-top: -15%; }
      .grid--12.liquid_gutter--15 > * {
        box-sizing: border-box;
        padding-left: 15%;
        padding-top: 15%; }
    .grid--12.liquid_gutter--16 {
      margin-left: -16%;
      margin-top: -16%; }
      .grid--12.liquid_gutter--16 > * {
        box-sizing: border-box;
        padding-left: 16%;
        padding-top: 16%; }
    .grid--12.liquid_gutter--17 {
      margin-left: -17%;
      margin-top: -17%; }
      .grid--12.liquid_gutter--17 > * {
        box-sizing: border-box;
        padding-left: 17%;
        padding-top: 17%; }
    .grid--12.liquid_gutter--18 {
      margin-left: -18%;
      margin-top: -18%; }
      .grid--12.liquid_gutter--18 > * {
        box-sizing: border-box;
        padding-left: 18%;
        padding-top: 18%; }
    .grid--12.liquid_gutter--19 {
      margin-left: -19%;
      margin-top: -19%; }
      .grid--12.liquid_gutter--19 > * {
        box-sizing: border-box;
        padding-left: 19%;
        padding-top: 19%; }
    .grid--12.liquid_gutter--20 {
      margin-left: -20%;
      margin-top: -20%; }
      .grid--12.liquid_gutter--20 > * {
        box-sizing: border-box;
        padding-left: 20%;
        padding-top: 20%; }
    .grid--12.liquid_gutter--0px {
      margin-left: 0px;
      margin-top: 0px; }
      .grid--12.liquid_gutter--0px > * {
        box-sizing: border-box;
        padding-left: 0px;
        padding-top: 0px; }
    .grid--12.liquid_gutter--1px {
      margin-left: -1px;
      margin-top: -1px; }
      .grid--12.liquid_gutter--1px > * {
        box-sizing: border-box;
        padding-left: 1px;
        padding-top: 1px; }
    .grid--12.liquid_gutter--2px {
      margin-left: -2px;
      margin-top: -2px; }
      .grid--12.liquid_gutter--2px > * {
        box-sizing: border-box;
        padding-left: 2px;
        padding-top: 2px; }
    .grid--12.liquid_gutter--3px {
      margin-left: -3px;
      margin-top: -3px; }
      .grid--12.liquid_gutter--3px > * {
        box-sizing: border-box;
        padding-left: 3px;
        padding-top: 3px; }
    .grid--12.liquid_gutter--4px {
      margin-left: -4px;
      margin-top: -4px; }
      .grid--12.liquid_gutter--4px > * {
        box-sizing: border-box;
        padding-left: 4px;
        padding-top: 4px; }
    .grid--12.liquid_gutter--5px {
      margin-left: -5px;
      margin-top: -5px; }
      .grid--12.liquid_gutter--5px > * {
        box-sizing: border-box;
        padding-left: 5px;
        padding-top: 5px; }
    .grid--12.liquid_gutter--6px {
      margin-left: -6px;
      margin-top: -6px; }
      .grid--12.liquid_gutter--6px > * {
        box-sizing: border-box;
        padding-left: 6px;
        padding-top: 6px; }
    .grid--12.liquid_gutter--7px {
      margin-left: -7px;
      margin-top: -7px; }
      .grid--12.liquid_gutter--7px > * {
        box-sizing: border-box;
        padding-left: 7px;
        padding-top: 7px; }
    .grid--12.liquid_gutter--8px {
      margin-left: -8px;
      margin-top: -8px; }
      .grid--12.liquid_gutter--8px > * {
        box-sizing: border-box;
        padding-left: 8px;
        padding-top: 8px; }
    .grid--12.liquid_gutter--9px {
      margin-left: -9px;
      margin-top: -9px; }
      .grid--12.liquid_gutter--9px > * {
        box-sizing: border-box;
        padding-left: 9px;
        padding-top: 9px; }
    .grid--12.liquid_gutter--10px {
      margin-left: -10px;
      margin-top: -10px; }
      .grid--12.liquid_gutter--10px > * {
        box-sizing: border-box;
        padding-left: 10px;
        padding-top: 10px; }
    .grid--12.liquid_gutter--11px {
      margin-left: -11px;
      margin-top: -11px; }
      .grid--12.liquid_gutter--11px > * {
        box-sizing: border-box;
        padding-left: 11px;
        padding-top: 11px; }
    .grid--12.liquid_gutter--12px {
      margin-left: -12px;
      margin-top: -12px; }
      .grid--12.liquid_gutter--12px > * {
        box-sizing: border-box;
        padding-left: 12px;
        padding-top: 12px; }
    .grid--12.liquid_gutter--13px {
      margin-left: -13px;
      margin-top: -13px; }
      .grid--12.liquid_gutter--13px > * {
        box-sizing: border-box;
        padding-left: 13px;
        padding-top: 13px; }
    .grid--12.liquid_gutter--14px {
      margin-left: -14px;
      margin-top: -14px; }
      .grid--12.liquid_gutter--14px > * {
        box-sizing: border-box;
        padding-left: 14px;
        padding-top: 14px; }
    .grid--12.liquid_gutter--15px {
      margin-left: -15px;
      margin-top: -15px; }
      .grid--12.liquid_gutter--15px > * {
        box-sizing: border-box;
        padding-left: 15px;
        padding-top: 15px; }
    .grid--12.liquid_gutter--16px {
      margin-left: -16px;
      margin-top: -16px; }
      .grid--12.liquid_gutter--16px > * {
        box-sizing: border-box;
        padding-left: 16px;
        padding-top: 16px; }
    .grid--12.liquid_gutter--17px {
      margin-left: -17px;
      margin-top: -17px; }
      .grid--12.liquid_gutter--17px > * {
        box-sizing: border-box;
        padding-left: 17px;
        padding-top: 17px; }
    .grid--12.liquid_gutter--18px {
      margin-left: -18px;
      margin-top: -18px; }
      .grid--12.liquid_gutter--18px > * {
        box-sizing: border-box;
        padding-left: 18px;
        padding-top: 18px; }
    .grid--12.liquid_gutter--19px {
      margin-left: -19px;
      margin-top: -19px; }
      .grid--12.liquid_gutter--19px > * {
        box-sizing: border-box;
        padding-left: 19px;
        padding-top: 19px; }
    .grid--12.liquid_gutter--20px {
      margin-left: -20px;
      margin-top: -20px; }
      .grid--12.liquid_gutter--20px > * {
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 20px; }
    .grid--12.liquid_gutter--21px {
      margin-left: -21px;
      margin-top: -21px; }
      .grid--12.liquid_gutter--21px > * {
        box-sizing: border-box;
        padding-left: 21px;
        padding-top: 21px; }
    .grid--12.liquid_gutter--22px {
      margin-left: -22px;
      margin-top: -22px; }
      .grid--12.liquid_gutter--22px > * {
        box-sizing: border-box;
        padding-left: 22px;
        padding-top: 22px; }
    .grid--12.liquid_gutter--23px {
      margin-left: -23px;
      margin-top: -23px; }
      .grid--12.liquid_gutter--23px > * {
        box-sizing: border-box;
        padding-left: 23px;
        padding-top: 23px; }
    .grid--12.liquid_gutter--24px {
      margin-left: -24px;
      margin-top: -24px; }
      .grid--12.liquid_gutter--24px > * {
        box-sizing: border-box;
        padding-left: 24px;
        padding-top: 24px; }
    .grid--12.liquid_gutter--25px {
      margin-left: -25px;
      margin-top: -25px; }
      .grid--12.liquid_gutter--25px > * {
        box-sizing: border-box;
        padding-left: 25px;
        padding-top: 25px; }
    .grid--12.liquid_gutter--26px {
      margin-left: -26px;
      margin-top: -26px; }
      .grid--12.liquid_gutter--26px > * {
        box-sizing: border-box;
        padding-left: 26px;
        padding-top: 26px; }
    .grid--12.liquid_gutter--27px {
      margin-left: -27px;
      margin-top: -27px; }
      .grid--12.liquid_gutter--27px > * {
        box-sizing: border-box;
        padding-left: 27px;
        padding-top: 27px; }
    .grid--12.liquid_gutter--28px {
      margin-left: -28px;
      margin-top: -28px; }
      .grid--12.liquid_gutter--28px > * {
        box-sizing: border-box;
        padding-left: 28px;
        padding-top: 28px; }
    .grid--12.liquid_gutter--29px {
      margin-left: -29px;
      margin-top: -29px; }
      .grid--12.liquid_gutter--29px > * {
        box-sizing: border-box;
        padding-left: 29px;
        padding-top: 29px; }
    .grid--12.liquid_gutter--30px {
      margin-left: -30px;
      margin-top: -30px; }
      .grid--12.liquid_gutter--30px > * {
        box-sizing: border-box;
        padding-left: 30px;
        padding-top: 30px; }
    .grid--12.liquid_gutter--31px {
      margin-left: -31px;
      margin-top: -31px; }
      .grid--12.liquid_gutter--31px > * {
        box-sizing: border-box;
        padding-left: 31px;
        padding-top: 31px; }
    .grid--12.liquid_gutter--32px {
      margin-left: -32px;
      margin-top: -32px; }
      .grid--12.liquid_gutter--32px > * {
        box-sizing: border-box;
        padding-left: 32px;
        padding-top: 32px; }
    .grid--12.liquid_gutter--33px {
      margin-left: -33px;
      margin-top: -33px; }
      .grid--12.liquid_gutter--33px > * {
        box-sizing: border-box;
        padding-left: 33px;
        padding-top: 33px; }
    .grid--12.liquid_gutter--34px {
      margin-left: -34px;
      margin-top: -34px; }
      .grid--12.liquid_gutter--34px > * {
        box-sizing: border-box;
        padding-left: 34px;
        padding-top: 34px; }
    .grid--12.liquid_gutter--35px {
      margin-left: -35px;
      margin-top: -35px; }
      .grid--12.liquid_gutter--35px > * {
        box-sizing: border-box;
        padding-left: 35px;
        padding-top: 35px; }
    .grid--12.liquid_gutter--36px {
      margin-left: -36px;
      margin-top: -36px; }
      .grid--12.liquid_gutter--36px > * {
        box-sizing: border-box;
        padding-left: 36px;
        padding-top: 36px; }
    .grid--12.liquid_gutter--37px {
      margin-left: -37px;
      margin-top: -37px; }
      .grid--12.liquid_gutter--37px > * {
        box-sizing: border-box;
        padding-left: 37px;
        padding-top: 37px; }
    .grid--12.liquid_gutter--38px {
      margin-left: -38px;
      margin-top: -38px; }
      .grid--12.liquid_gutter--38px > * {
        box-sizing: border-box;
        padding-left: 38px;
        padding-top: 38px; }
    .grid--12.liquid_gutter--39px {
      margin-left: -39px;
      margin-top: -39px; }
      .grid--12.liquid_gutter--39px > * {
        box-sizing: border-box;
        padding-left: 39px;
        padding-top: 39px; }
    .grid--12.liquid_gutter--40px {
      margin-left: -40px;
      margin-top: -40px; }
      .grid--12.liquid_gutter--40px > * {
        box-sizing: border-box;
        padding-left: 40px;
        padding-top: 40px; }
    .grid--12.liquid_gutter--41px {
      margin-left: -41px;
      margin-top: -41px; }
      .grid--12.liquid_gutter--41px > * {
        box-sizing: border-box;
        padding-left: 41px;
        padding-top: 41px; }
    .grid--12.liquid_gutter--42px {
      margin-left: -42px;
      margin-top: -42px; }
      .grid--12.liquid_gutter--42px > * {
        box-sizing: border-box;
        padding-left: 42px;
        padding-top: 42px; }
    .grid--12.liquid_gutter--43px {
      margin-left: -43px;
      margin-top: -43px; }
      .grid--12.liquid_gutter--43px > * {
        box-sizing: border-box;
        padding-left: 43px;
        padding-top: 43px; }
    .grid--12.liquid_gutter--44px {
      margin-left: -44px;
      margin-top: -44px; }
      .grid--12.liquid_gutter--44px > * {
        box-sizing: border-box;
        padding-left: 44px;
        padding-top: 44px; }
    .grid--12.liquid_gutter--45px {
      margin-left: -45px;
      margin-top: -45px; }
      .grid--12.liquid_gutter--45px > * {
        box-sizing: border-box;
        padding-left: 45px;
        padding-top: 45px; }
    .grid--12.liquid_gutter--46px {
      margin-left: -46px;
      margin-top: -46px; }
      .grid--12.liquid_gutter--46px > * {
        box-sizing: border-box;
        padding-left: 46px;
        padding-top: 46px; }
    .grid--12.liquid_gutter--47px {
      margin-left: -47px;
      margin-top: -47px; }
      .grid--12.liquid_gutter--47px > * {
        box-sizing: border-box;
        padding-left: 47px;
        padding-top: 47px; }
    .grid--12.liquid_gutter--48px {
      margin-left: -48px;
      margin-top: -48px; }
      .grid--12.liquid_gutter--48px > * {
        box-sizing: border-box;
        padding-left: 48px;
        padding-top: 48px; }
    .grid--12.liquid_gutter--49px {
      margin-left: -49px;
      margin-top: -49px; }
      .grid--12.liquid_gutter--49px > * {
        box-sizing: border-box;
        padding-left: 49px;
        padding-top: 49px; }
    .grid--12.liquid_gutter--50px {
      margin-left: -50px;
      margin-top: -50px; }
      .grid--12.liquid_gutter--50px > * {
        box-sizing: border-box;
        padding-left: 50px;
        padding-top: 50px; }
    .grid--12.liquid_gutter--51px {
      margin-left: -51px;
      margin-top: -51px; }
      .grid--12.liquid_gutter--51px > * {
        box-sizing: border-box;
        padding-left: 51px;
        padding-top: 51px; }
    .grid--12.liquid_gutter--52px {
      margin-left: -52px;
      margin-top: -52px; }
      .grid--12.liquid_gutter--52px > * {
        box-sizing: border-box;
        padding-left: 52px;
        padding-top: 52px; }
    .grid--12.liquid_gutter--53px {
      margin-left: -53px;
      margin-top: -53px; }
      .grid--12.liquid_gutter--53px > * {
        box-sizing: border-box;
        padding-left: 53px;
        padding-top: 53px; }
    .grid--12.liquid_gutter--54px {
      margin-left: -54px;
      margin-top: -54px; }
      .grid--12.liquid_gutter--54px > * {
        box-sizing: border-box;
        padding-left: 54px;
        padding-top: 54px; }
    .grid--12.liquid_gutter--55px {
      margin-left: -55px;
      margin-top: -55px; }
      .grid--12.liquid_gutter--55px > * {
        box-sizing: border-box;
        padding-left: 55px;
        padding-top: 55px; }
    .grid--12.liquid_gutter--56px {
      margin-left: -56px;
      margin-top: -56px; }
      .grid--12.liquid_gutter--56px > * {
        box-sizing: border-box;
        padding-left: 56px;
        padding-top: 56px; }
    .grid--12.liquid_gutter--57px {
      margin-left: -57px;
      margin-top: -57px; }
      .grid--12.liquid_gutter--57px > * {
        box-sizing: border-box;
        padding-left: 57px;
        padding-top: 57px; }
    .grid--12.liquid_gutter--58px {
      margin-left: -58px;
      margin-top: -58px; }
      .grid--12.liquid_gutter--58px > * {
        box-sizing: border-box;
        padding-left: 58px;
        padding-top: 58px; }
    .grid--12.liquid_gutter--59px {
      margin-left: -59px;
      margin-top: -59px; }
      .grid--12.liquid_gutter--59px > * {
        box-sizing: border-box;
        padding-left: 59px;
        padding-top: 59px; }
    .grid--12.liquid_gutter--60px {
      margin-left: -60px;
      margin-top: -60px; }
      .grid--12.liquid_gutter--60px > * {
        box-sizing: border-box;
        padding-left: 60px;
        padding-top: 60px; } }
  @media screen and (max-width: 639px) {
    .grid--12.sp_gutter--0 {
      margin-left: 0%;
      margin-top: 0%; }
      .grid--12.sp_gutter--0 > * {
        box-sizing: border-box;
        padding-left: 0%;
        padding-top: 0%; }
    .grid--12.sp_gutter--1 {
      margin-left: -1%;
      margin-top: -1%; }
      .grid--12.sp_gutter--1 > * {
        box-sizing: border-box;
        padding-left: 1%;
        padding-top: 1%; }
    .grid--12.sp_gutter--2 {
      margin-left: -2%;
      margin-top: -2%; }
      .grid--12.sp_gutter--2 > * {
        box-sizing: border-box;
        padding-left: 2%;
        padding-top: 2%; }
    .grid--12.sp_gutter--3 {
      margin-left: -3%;
      margin-top: -3%; }
      .grid--12.sp_gutter--3 > * {
        box-sizing: border-box;
        padding-left: 3%;
        padding-top: 3%; }
    .grid--12.sp_gutter--4 {
      margin-left: -4%;
      margin-top: -4%; }
      .grid--12.sp_gutter--4 > * {
        box-sizing: border-box;
        padding-left: 4%;
        padding-top: 4%; }
    .grid--12.sp_gutter--5 {
      margin-left: -5%;
      margin-top: -5%; }
      .grid--12.sp_gutter--5 > * {
        box-sizing: border-box;
        padding-left: 5%;
        padding-top: 5%; }
    .grid--12.sp_gutter--6 {
      margin-left: -6%;
      margin-top: -6%; }
      .grid--12.sp_gutter--6 > * {
        box-sizing: border-box;
        padding-left: 6%;
        padding-top: 6%; }
    .grid--12.sp_gutter--7 {
      margin-left: -7%;
      margin-top: -7%; }
      .grid--12.sp_gutter--7 > * {
        box-sizing: border-box;
        padding-left: 7%;
        padding-top: 7%; }
    .grid--12.sp_gutter--8 {
      margin-left: -8%;
      margin-top: -8%; }
      .grid--12.sp_gutter--8 > * {
        box-sizing: border-box;
        padding-left: 8%;
        padding-top: 8%; }
    .grid--12.sp_gutter--9 {
      margin-left: -9%;
      margin-top: -9%; }
      .grid--12.sp_gutter--9 > * {
        box-sizing: border-box;
        padding-left: 9%;
        padding-top: 9%; }
    .grid--12.sp_gutter--10 {
      margin-left: -10%;
      margin-top: -10%; }
      .grid--12.sp_gutter--10 > * {
        box-sizing: border-box;
        padding-left: 10%;
        padding-top: 10%; }
    .grid--12.sp_gutter--11 {
      margin-left: -11%;
      margin-top: -11%; }
      .grid--12.sp_gutter--11 > * {
        box-sizing: border-box;
        padding-left: 11%;
        padding-top: 11%; }
    .grid--12.sp_gutter--12 {
      margin-left: -12%;
      margin-top: -12%; }
      .grid--12.sp_gutter--12 > * {
        box-sizing: border-box;
        padding-left: 12%;
        padding-top: 12%; }
    .grid--12.sp_gutter--13 {
      margin-left: -13%;
      margin-top: -13%; }
      .grid--12.sp_gutter--13 > * {
        box-sizing: border-box;
        padding-left: 13%;
        padding-top: 13%; }
    .grid--12.sp_gutter--14 {
      margin-left: -14%;
      margin-top: -14%; }
      .grid--12.sp_gutter--14 > * {
        box-sizing: border-box;
        padding-left: 14%;
        padding-top: 14%; }
    .grid--12.sp_gutter--15 {
      margin-left: -15%;
      margin-top: -15%; }
      .grid--12.sp_gutter--15 > * {
        box-sizing: border-box;
        padding-left: 15%;
        padding-top: 15%; }
    .grid--12.sp_gutter--16 {
      margin-left: -16%;
      margin-top: -16%; }
      .grid--12.sp_gutter--16 > * {
        box-sizing: border-box;
        padding-left: 16%;
        padding-top: 16%; }
    .grid--12.sp_gutter--17 {
      margin-left: -17%;
      margin-top: -17%; }
      .grid--12.sp_gutter--17 > * {
        box-sizing: border-box;
        padding-left: 17%;
        padding-top: 17%; }
    .grid--12.sp_gutter--18 {
      margin-left: -18%;
      margin-top: -18%; }
      .grid--12.sp_gutter--18 > * {
        box-sizing: border-box;
        padding-left: 18%;
        padding-top: 18%; }
    .grid--12.sp_gutter--19 {
      margin-left: -19%;
      margin-top: -19%; }
      .grid--12.sp_gutter--19 > * {
        box-sizing: border-box;
        padding-left: 19%;
        padding-top: 19%; }
    .grid--12.sp_gutter--20 {
      margin-left: -20%;
      margin-top: -20%; }
      .grid--12.sp_gutter--20 > * {
        box-sizing: border-box;
        padding-left: 20%;
        padding-top: 20%; }
    .grid--12.sp_gutter--0px {
      margin-left: 0px;
      margin-top: 0px; }
      .grid--12.sp_gutter--0px > * {
        box-sizing: border-box;
        padding-left: 0px;
        padding-top: 0px; }
    .grid--12.sp_gutter--1px {
      margin-left: -1px;
      margin-top: -1px; }
      .grid--12.sp_gutter--1px > * {
        box-sizing: border-box;
        padding-left: 1px;
        padding-top: 1px; }
    .grid--12.sp_gutter--2px {
      margin-left: -2px;
      margin-top: -2px; }
      .grid--12.sp_gutter--2px > * {
        box-sizing: border-box;
        padding-left: 2px;
        padding-top: 2px; }
    .grid--12.sp_gutter--3px {
      margin-left: -3px;
      margin-top: -3px; }
      .grid--12.sp_gutter--3px > * {
        box-sizing: border-box;
        padding-left: 3px;
        padding-top: 3px; }
    .grid--12.sp_gutter--4px {
      margin-left: -4px;
      margin-top: -4px; }
      .grid--12.sp_gutter--4px > * {
        box-sizing: border-box;
        padding-left: 4px;
        padding-top: 4px; }
    .grid--12.sp_gutter--5px {
      margin-left: -5px;
      margin-top: -5px; }
      .grid--12.sp_gutter--5px > * {
        box-sizing: border-box;
        padding-left: 5px;
        padding-top: 5px; }
    .grid--12.sp_gutter--6px {
      margin-left: -6px;
      margin-top: -6px; }
      .grid--12.sp_gutter--6px > * {
        box-sizing: border-box;
        padding-left: 6px;
        padding-top: 6px; }
    .grid--12.sp_gutter--7px {
      margin-left: -7px;
      margin-top: -7px; }
      .grid--12.sp_gutter--7px > * {
        box-sizing: border-box;
        padding-left: 7px;
        padding-top: 7px; }
    .grid--12.sp_gutter--8px {
      margin-left: -8px;
      margin-top: -8px; }
      .grid--12.sp_gutter--8px > * {
        box-sizing: border-box;
        padding-left: 8px;
        padding-top: 8px; }
    .grid--12.sp_gutter--9px {
      margin-left: -9px;
      margin-top: -9px; }
      .grid--12.sp_gutter--9px > * {
        box-sizing: border-box;
        padding-left: 9px;
        padding-top: 9px; }
    .grid--12.sp_gutter--10px {
      margin-left: -10px;
      margin-top: -10px; }
      .grid--12.sp_gutter--10px > * {
        box-sizing: border-box;
        padding-left: 10px;
        padding-top: 10px; }
    .grid--12.sp_gutter--11px {
      margin-left: -11px;
      margin-top: -11px; }
      .grid--12.sp_gutter--11px > * {
        box-sizing: border-box;
        padding-left: 11px;
        padding-top: 11px; }
    .grid--12.sp_gutter--12px {
      margin-left: -12px;
      margin-top: -12px; }
      .grid--12.sp_gutter--12px > * {
        box-sizing: border-box;
        padding-left: 12px;
        padding-top: 12px; }
    .grid--12.sp_gutter--13px {
      margin-left: -13px;
      margin-top: -13px; }
      .grid--12.sp_gutter--13px > * {
        box-sizing: border-box;
        padding-left: 13px;
        padding-top: 13px; }
    .grid--12.sp_gutter--14px {
      margin-left: -14px;
      margin-top: -14px; }
      .grid--12.sp_gutter--14px > * {
        box-sizing: border-box;
        padding-left: 14px;
        padding-top: 14px; }
    .grid--12.sp_gutter--15px {
      margin-left: -15px;
      margin-top: -15px; }
      .grid--12.sp_gutter--15px > * {
        box-sizing: border-box;
        padding-left: 15px;
        padding-top: 15px; }
    .grid--12.sp_gutter--16px {
      margin-left: -16px;
      margin-top: -16px; }
      .grid--12.sp_gutter--16px > * {
        box-sizing: border-box;
        padding-left: 16px;
        padding-top: 16px; }
    .grid--12.sp_gutter--17px {
      margin-left: -17px;
      margin-top: -17px; }
      .grid--12.sp_gutter--17px > * {
        box-sizing: border-box;
        padding-left: 17px;
        padding-top: 17px; }
    .grid--12.sp_gutter--18px {
      margin-left: -18px;
      margin-top: -18px; }
      .grid--12.sp_gutter--18px > * {
        box-sizing: border-box;
        padding-left: 18px;
        padding-top: 18px; }
    .grid--12.sp_gutter--19px {
      margin-left: -19px;
      margin-top: -19px; }
      .grid--12.sp_gutter--19px > * {
        box-sizing: border-box;
        padding-left: 19px;
        padding-top: 19px; }
    .grid--12.sp_gutter--20px {
      margin-left: -20px;
      margin-top: -20px; }
      .grid--12.sp_gutter--20px > * {
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 20px; }
    .grid--12.sp_gutter--21px {
      margin-left: -21px;
      margin-top: -21px; }
      .grid--12.sp_gutter--21px > * {
        box-sizing: border-box;
        padding-left: 21px;
        padding-top: 21px; }
    .grid--12.sp_gutter--22px {
      margin-left: -22px;
      margin-top: -22px; }
      .grid--12.sp_gutter--22px > * {
        box-sizing: border-box;
        padding-left: 22px;
        padding-top: 22px; }
    .grid--12.sp_gutter--23px {
      margin-left: -23px;
      margin-top: -23px; }
      .grid--12.sp_gutter--23px > * {
        box-sizing: border-box;
        padding-left: 23px;
        padding-top: 23px; }
    .grid--12.sp_gutter--24px {
      margin-left: -24px;
      margin-top: -24px; }
      .grid--12.sp_gutter--24px > * {
        box-sizing: border-box;
        padding-left: 24px;
        padding-top: 24px; }
    .grid--12.sp_gutter--25px {
      margin-left: -25px;
      margin-top: -25px; }
      .grid--12.sp_gutter--25px > * {
        box-sizing: border-box;
        padding-left: 25px;
        padding-top: 25px; }
    .grid--12.sp_gutter--26px {
      margin-left: -26px;
      margin-top: -26px; }
      .grid--12.sp_gutter--26px > * {
        box-sizing: border-box;
        padding-left: 26px;
        padding-top: 26px; }
    .grid--12.sp_gutter--27px {
      margin-left: -27px;
      margin-top: -27px; }
      .grid--12.sp_gutter--27px > * {
        box-sizing: border-box;
        padding-left: 27px;
        padding-top: 27px; }
    .grid--12.sp_gutter--28px {
      margin-left: -28px;
      margin-top: -28px; }
      .grid--12.sp_gutter--28px > * {
        box-sizing: border-box;
        padding-left: 28px;
        padding-top: 28px; }
    .grid--12.sp_gutter--29px {
      margin-left: -29px;
      margin-top: -29px; }
      .grid--12.sp_gutter--29px > * {
        box-sizing: border-box;
        padding-left: 29px;
        padding-top: 29px; }
    .grid--12.sp_gutter--30px {
      margin-left: -30px;
      margin-top: -30px; }
      .grid--12.sp_gutter--30px > * {
        box-sizing: border-box;
        padding-left: 30px;
        padding-top: 30px; }
    .grid--12.sp_gutter--31px {
      margin-left: -31px;
      margin-top: -31px; }
      .grid--12.sp_gutter--31px > * {
        box-sizing: border-box;
        padding-left: 31px;
        padding-top: 31px; }
    .grid--12.sp_gutter--32px {
      margin-left: -32px;
      margin-top: -32px; }
      .grid--12.sp_gutter--32px > * {
        box-sizing: border-box;
        padding-left: 32px;
        padding-top: 32px; }
    .grid--12.sp_gutter--33px {
      margin-left: -33px;
      margin-top: -33px; }
      .grid--12.sp_gutter--33px > * {
        box-sizing: border-box;
        padding-left: 33px;
        padding-top: 33px; }
    .grid--12.sp_gutter--34px {
      margin-left: -34px;
      margin-top: -34px; }
      .grid--12.sp_gutter--34px > * {
        box-sizing: border-box;
        padding-left: 34px;
        padding-top: 34px; }
    .grid--12.sp_gutter--35px {
      margin-left: -35px;
      margin-top: -35px; }
      .grid--12.sp_gutter--35px > * {
        box-sizing: border-box;
        padding-left: 35px;
        padding-top: 35px; }
    .grid--12.sp_gutter--36px {
      margin-left: -36px;
      margin-top: -36px; }
      .grid--12.sp_gutter--36px > * {
        box-sizing: border-box;
        padding-left: 36px;
        padding-top: 36px; }
    .grid--12.sp_gutter--37px {
      margin-left: -37px;
      margin-top: -37px; }
      .grid--12.sp_gutter--37px > * {
        box-sizing: border-box;
        padding-left: 37px;
        padding-top: 37px; }
    .grid--12.sp_gutter--38px {
      margin-left: -38px;
      margin-top: -38px; }
      .grid--12.sp_gutter--38px > * {
        box-sizing: border-box;
        padding-left: 38px;
        padding-top: 38px; }
    .grid--12.sp_gutter--39px {
      margin-left: -39px;
      margin-top: -39px; }
      .grid--12.sp_gutter--39px > * {
        box-sizing: border-box;
        padding-left: 39px;
        padding-top: 39px; }
    .grid--12.sp_gutter--40px {
      margin-left: -40px;
      margin-top: -40px; }
      .grid--12.sp_gutter--40px > * {
        box-sizing: border-box;
        padding-left: 40px;
        padding-top: 40px; }
    .grid--12.sp_gutter--41px {
      margin-left: -41px;
      margin-top: -41px; }
      .grid--12.sp_gutter--41px > * {
        box-sizing: border-box;
        padding-left: 41px;
        padding-top: 41px; }
    .grid--12.sp_gutter--42px {
      margin-left: -42px;
      margin-top: -42px; }
      .grid--12.sp_gutter--42px > * {
        box-sizing: border-box;
        padding-left: 42px;
        padding-top: 42px; }
    .grid--12.sp_gutter--43px {
      margin-left: -43px;
      margin-top: -43px; }
      .grid--12.sp_gutter--43px > * {
        box-sizing: border-box;
        padding-left: 43px;
        padding-top: 43px; }
    .grid--12.sp_gutter--44px {
      margin-left: -44px;
      margin-top: -44px; }
      .grid--12.sp_gutter--44px > * {
        box-sizing: border-box;
        padding-left: 44px;
        padding-top: 44px; }
    .grid--12.sp_gutter--45px {
      margin-left: -45px;
      margin-top: -45px; }
      .grid--12.sp_gutter--45px > * {
        box-sizing: border-box;
        padding-left: 45px;
        padding-top: 45px; }
    .grid--12.sp_gutter--46px {
      margin-left: -46px;
      margin-top: -46px; }
      .grid--12.sp_gutter--46px > * {
        box-sizing: border-box;
        padding-left: 46px;
        padding-top: 46px; }
    .grid--12.sp_gutter--47px {
      margin-left: -47px;
      margin-top: -47px; }
      .grid--12.sp_gutter--47px > * {
        box-sizing: border-box;
        padding-left: 47px;
        padding-top: 47px; }
    .grid--12.sp_gutter--48px {
      margin-left: -48px;
      margin-top: -48px; }
      .grid--12.sp_gutter--48px > * {
        box-sizing: border-box;
        padding-left: 48px;
        padding-top: 48px; }
    .grid--12.sp_gutter--49px {
      margin-left: -49px;
      margin-top: -49px; }
      .grid--12.sp_gutter--49px > * {
        box-sizing: border-box;
        padding-left: 49px;
        padding-top: 49px; }
    .grid--12.sp_gutter--50px {
      margin-left: -50px;
      margin-top: -50px; }
      .grid--12.sp_gutter--50px > * {
        box-sizing: border-box;
        padding-left: 50px;
        padding-top: 50px; }
    .grid--12.sp_gutter--51px {
      margin-left: -51px;
      margin-top: -51px; }
      .grid--12.sp_gutter--51px > * {
        box-sizing: border-box;
        padding-left: 51px;
        padding-top: 51px; }
    .grid--12.sp_gutter--52px {
      margin-left: -52px;
      margin-top: -52px; }
      .grid--12.sp_gutter--52px > * {
        box-sizing: border-box;
        padding-left: 52px;
        padding-top: 52px; }
    .grid--12.sp_gutter--53px {
      margin-left: -53px;
      margin-top: -53px; }
      .grid--12.sp_gutter--53px > * {
        box-sizing: border-box;
        padding-left: 53px;
        padding-top: 53px; }
    .grid--12.sp_gutter--54px {
      margin-left: -54px;
      margin-top: -54px; }
      .grid--12.sp_gutter--54px > * {
        box-sizing: border-box;
        padding-left: 54px;
        padding-top: 54px; }
    .grid--12.sp_gutter--55px {
      margin-left: -55px;
      margin-top: -55px; }
      .grid--12.sp_gutter--55px > * {
        box-sizing: border-box;
        padding-left: 55px;
        padding-top: 55px; }
    .grid--12.sp_gutter--56px {
      margin-left: -56px;
      margin-top: -56px; }
      .grid--12.sp_gutter--56px > * {
        box-sizing: border-box;
        padding-left: 56px;
        padding-top: 56px; }
    .grid--12.sp_gutter--57px {
      margin-left: -57px;
      margin-top: -57px; }
      .grid--12.sp_gutter--57px > * {
        box-sizing: border-box;
        padding-left: 57px;
        padding-top: 57px; }
    .grid--12.sp_gutter--58px {
      margin-left: -58px;
      margin-top: -58px; }
      .grid--12.sp_gutter--58px > * {
        box-sizing: border-box;
        padding-left: 58px;
        padding-top: 58px; }
    .grid--12.sp_gutter--59px {
      margin-left: -59px;
      margin-top: -59px; }
      .grid--12.sp_gutter--59px > * {
        box-sizing: border-box;
        padding-left: 59px;
        padding-top: 59px; }
    .grid--12.sp_gutter--60px {
      margin-left: -60px;
      margin-top: -60px; }
      .grid--12.sp_gutter--60px > * {
        box-sizing: border-box;
        padding-left: 60px;
        padding-top: 60px; } }

.grid__col--1 {
  float: left;
  width: 8.33333%; }

.grid__col--2 {
  float: left;
  width: 16.66667%; }

.grid__col--3 {
  float: left;
  width: 25%; }

.grid__col--4 {
  float: left;
  width: 33.33333%; }

.grid__col--5 {
  float: left;
  width: 41.66667%; }

.grid__col--6 {
  float: left;
  width: 50%; }

.grid__col--7 {
  float: left;
  width: 58.33333%; }

.grid__col--8 {
  float: left;
  width: 66.66667%; }

.grid__col--9 {
  float: left;
  width: 75%; }

.grid__col--10 {
  float: left;
  width: 83.33333%; }

.grid__col--11 {
  float: left;
  width: 91.66667%; }

.grid__col--12 {
  float: left;
  width: 100%; }

@media screen and (max-width: 1139px) {
  .liquid_grid__col--1 {
    float: left;
    width: 8.33333%; }
  .liquid_grid__col--2 {
    float: left;
    width: 16.66667%; }
  .liquid_grid__col--3 {
    float: left;
    width: 25%; }
  .liquid_grid__col--4 {
    float: left;
    width: 33.33333%; }
  .liquid_grid__col--5 {
    float: left;
    width: 41.66667%; }
  .liquid_grid__col--6 {
    float: left;
    width: 50%; }
  .liquid_grid__col--7 {
    float: left;
    width: 58.33333%; }
  .liquid_grid__col--8 {
    float: left;
    width: 66.66667%; }
  .liquid_grid__col--9 {
    float: left;
    width: 75%; }
  .liquid_grid__col--10 {
    float: left;
    width: 83.33333%; }
  .liquid_grid__col--11 {
    float: left;
    width: 91.66667%; }
  .liquid_grid__col--12 {
    float: left;
    width: 100%; } }

@media screen and (max-width: 639px) {
  .sp_grid__col--1 {
    float: left;
    width: 8.33333%; }
  .sp_grid__col--2 {
    float: left;
    width: 16.66667%; }
  .sp_grid__col--3 {
    float: left;
    width: 25%; }
  .sp_grid__col--4 {
    float: left;
    width: 33.33333%; }
  .sp_grid__col--5 {
    float: left;
    width: 41.66667%; }
  .sp_grid__col--6 {
    float: left;
    width: 50%; }
  .sp_grid__col--7 {
    float: left;
    width: 58.33333%; }
  .sp_grid__col--8 {
    float: left;
    width: 66.66667%; }
  .sp_grid__col--9 {
    float: left;
    width: 75%; }
  .sp_grid__col--10 {
    float: left;
    width: 83.33333%; }
  .sp_grid__col--11 {
    float: left;
    width: 91.66667%; }
  .sp_grid__col--12 {
    float: left;
    width: 100%; } }

.heading_lv1,
.heading_lv2,
.heading_lv3,
.heading_lv4 {
  font-feature-settings: "palt";
  letter-spacing: .1em;
  font-weight: 500; }

.heading_lv1 {
  font-size: 333%;
  margin-bottom: 20px; }
  @media screen and (max-width: 639px) {
    .heading_lv1 {
      font-size: 167%;
      line-height: 1.5; } }

.heading_lv2 {
  font-size: 260%;
  line-height: 1.6;
  letter-spacing: .1em;
  margin-bottom: 20px;
  font-weight: 600; }
  @media screen and (max-width: 639px) {
    .heading_lv2 {
      font-size: 167%;
      line-height: 1.5;
      margin-bottom: 10px; } }

.heading_lv3 {
  font-size: 247%;
  line-height: 1.7;
  letter-spacing: .1em;
  margin-bottom: 20px;
  font-weight: 600; }
  @media screen and (max-width: 639px) {
    .heading_lv3 {
      font-size: 167%;
      line-height: 1.5;
      margin-bottom: 10px; } }

.heading_lv4 {
  font-weight: 500;
  border-bottom: 1px solid #707070;
  padding-bottom: 10px;
  letter-spacing: 1px;
  margin-bottom: 30px; }
  @media screen and (max-width: 639px) {
    .heading_lv4 {
      margin-bottom: 10px; } }
  .heading_lv4 .en {
    font-family: "Heebo", sans-serif;
    font-size: 193%;
    font-weight: 600;
    margin-right: 15px; }
    @media screen and (max-width: 639px) {
      .heading_lv4 .en {
        font-size: 153%; } }
  .heading_lv4 .jp {
    font-size: 100%;
    font-weight: 400; }
    @media screen and (max-width: 639px) {
      .heading_lv4 .jp {
        font-size: 93%; } }

@media screen and (min-width: 640px) {
  .headline__primary {
    margin-bottom: 60px; } }

@media screen and (max-width: 639px) {
  .headline__primary {
    margin-bottom: 25px; } }

.headline__primary .en {
  font-size: 287%;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  border-bottom: 1px solid #707070;
  margin-bottom: 10px;
  letter-spacing: .05em; }
  @media screen and (max-width: 639px) {
    .headline__primary .en {
      font-size: 167%;
      letter-spacing: .04em; } }

.headline__primary .jp {
  font-size: 100%;
  font-weight: 500;
  letter-spacing: .1em; }
  @media screen and (max-width: 639px) {
    .headline__primary .jp {
      font-size: 80%; } }

.headline__primary .desc {
  line-height: 2; }
  @media screen and (min-width: 640px) {
    .headline__primary .desc {
      margin-top: 30px;
      font-size: 133%; } }
  @media screen and (max-width: 639px) {
    .headline__primary .desc {
      margin-top: 20px; } }
  @media screen and (min-width: 640px) {
    .headline__primary .desc--sm {
      font-size: 107%; } }

.headline__secondary {
  margin-bottom: 30px; }
  @media screen and (max-width: 639px) {
    .headline__secondary {
      margin-bottom: 20px; } }
  .headline__secondary .en {
    font-size: 100%;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    border-top: 1px solid #707070;
    padding-top: 5px;
    padding-left: 3px;
    letter-spacing: .03em; }
    @media screen and (max-width: 639px) {
      .headline__secondary .en {
        font-size: 80%; } }
  .headline__secondary .jp {
    border-top: 1px solid #707070;
    padding-top: 10px;
    font-size: 173%; }

.headline__extra {
  margin-bottom: 20px; }
  @media screen and (max-width: 639px) {
    .headline__extra {
      margin-bottom: 10px; } }
  .headline__extra .en {
    font-size: 240%;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    letter-spacing: .05em;
    display: inline-block; }
    @media screen and (max-width: 639px) {
      .headline__extra .en {
        font-size: 147%; } }
  .headline__extra .jp {
    display: inline-block;
    margin-left: 30px;
    font-size: 133%;
    font-weight: 300; }
    @media screen and (max-width: 639px) {
      .headline__extra .jp {
        font-size: 93%;
        margin-left: 10px; } }

.headline__label {
  font-size: 167%;
  font-family: "Heebo", sans-serif;
  border-bottom: none;
  border-top: 1px solid #707070;
  padding-top: 10px;
  font-weight: 600;
  margin-bottom: 40px; }
  @media screen and (max-width: 639px) {
    .headline__label {
      font-size: 120%;
      margin-bottom: 20px; } }

.headline__lead, body.post-type-archive-tackle_info .page_content .column .content .text > p {
  font-size: 133%;
  line-height: 2.5; }
  @media screen and (max-width: 639px) {
    .headline__lead, body.post-type-archive-tackle_info .page_content .column .content .text > p {
      font-size: 100%;
      line-height: 2; } }

.headline__sublead {
  font-size: 100%;
  line-height: 2.5; }
  @media screen and (max-width: 639px) {
    .headline__sublead {
      font-size: 100%;
      line-height: 2; } }

.font_heebo {
  font-family: "Heebo", sans-serif; }

@font-face {
  font-family: 'icomoon';
  src: url("../font/icomoon.eot?jdd00c");
  src: url("../font/icomoon.eot?jdd00c#iefix") format("embedded-opentype"), url("../font/icomoon.ttf?jdd00c") format("truetype"), url("../font/icomoon.woff?jdd00c") format("woff"), url("../font/icomoon.svg?jdd00c#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon--"]:before, [class*=" icon--"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon--car:before {
  content: "\e906"; }

.icon--train:before {
  content: "\e907"; }

.icon--tel:before {
  content: "\e904"; }

.icon--mail:before {
  content: "\e905"; }

.icon--schedule:before {
  content: "\e903";
  color: #504f4f; }

.icon--instagram:before {
  content: "\e900"; }

.icon--logo:before {
  content: "\e901"; }

.icon--facebook:before {
  content: "\e902"; }

.img_rollover:hover img, .img_rollover a:hover img {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7; }

.img_rollover img, .img_rollover a img {
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  transition: opacity 300ms ease; }

/*

リンク

*/
a.disable {
  pointer-events: none; }

@media screen and (min-width: 640px) {
  .liquid_container {
    width: 82%;
    margin: 0 auto; }
    .liquid_container.attr_min {
      width: 960px; } }

@media screen and (max-width: 639px) {
  .liquid_container {
    width: 92%;
    margin: 0 auto; } }

@media screen and (min-width: 640px) {
  .module_area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center; } }

@media screen and (max-width: 639px) {
  .module_area {
    width: 92%;
    margin: 0 auto;
    padding-top: 0; } }

.module_area__item {
  padding-bottom: 20px; }
  @media screen and (min-width: 640px) {
    .module_area__item {
      width: 49.5%;
      border-bottom: 1px solid #D6D5D5;
      margin-bottom: 25px; } }
  @media screen and (max-width: 639px) {
    .module_area__item {
      margin-bottom: 0; } }
  .module_area__item a {
    position: relative;
    display: block;
    padding: 7.5vw 0;
    background-position: center bottom;
    background-size: cover;
    font-family: "Heebo", sans-serif;
    font-weight: 600; }
    @media screen and (max-width: 639px) {
      .module_area__item a {
        padding: 50px 0; } }
    .module_area__item a:hover {
      text-decoration: none; }
      .module_area__item a:hover .module_area__btn {
        background-color: #2F6CB5;
        color: #fff; }
        .module_area__item a:hover .module_area__btn span::before {
          border-color: #fff; }
        .module_area__item a:hover .module_area__btn span::after {
          border-color: #fff; }
    .module_area__item a.kobe {
      background-image: url(../img/top/image_location_01.jpg); }
    .module_area__item a.kix {
      background-image: url(../img/top/image_location_02.jpg); }
    .module_area__item a.zest {
      background-image: url(../img/top/image_location_03.jpg); }
    .module_area__item a.yamatoda {
      background-image: url(../img/top/image_location_04.jpg); }

.module_area__name {
  text-align: center;
  color: #fff;
  letter-spacing: 2px; }
  @media screen and (min-width: 640px) {
    .module_area__name {
      font-size: 380%; } }
  @media screen and (max-width: 639px) {
    .module_area__name {
      font-size: 193%; } }

.module_area__btn {
  position: absolute;
  right: 0;
  bottom: -20px;
  background-color: #fff;
  width: 60%;
  color: #333333;
  transition: background-color .3s; }
  @media screen and (min-width: 640px) {
    .module_area__btn {
      font-size: 167%; } }
  @media screen and (max-width: 1300px) {
    .module_area__btn {
      font-size: 147%; } }
  @media screen and (max-width: 639px) {
    .module_area__btn {
      font-size: 80%; } }
  .module_area__btn span {
    display: block;
    position: relative;
    padding: 20px 25px; }
    @media screen and (min-width: 640px) {
      .module_area__btn span {
        padding: 20px 25px; } }
    @media screen and (max-width: 639px) {
      .module_area__btn span {
        padding: 10px 15px; } }
    .module_area__btn span::before {
      content: "";
      display: block;
      border: #ACACAC 1px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: border-color .3s; }
      @media screen and (min-width: 640px) {
        .module_area__btn span::before {
          width: 34px;
          height: 34px;
          right: 25px; } }
      @media screen and (max-width: 639px) {
        .module_area__btn span::before {
          width: 17px;
          height: 17px;
          right: 15px; } }
    .module_area__btn span::after {
      content: "";
      display: block;
      border-right: 1px solid #707070;
      border-bottom: 1px solid #707070;
      transform: rotate(-45deg) translateY(-50%);
      position: absolute;
      top: 50%;
      transition: border-color .3s; }
      @media screen and (min-width: 640px) {
        .module_area__btn span::after {
          right: 37px;
          width: 8px;
          height: 8px; } }
      @media screen and (max-width: 639px) {
        .module_area__btn span::after {
          right: 20px;
          width: 5px;
          height: 5px; } }

.module_attention_table {
  width: 100%;
  text-align: left; }
  @media screen and (min-width: 640px) {
    .module_attention_table {
      margin-top: 30px;
      margin-bottom: 70px; } }
  @media screen and (max-width: 639px) {
    .module_attention_table {
      margin-top: 20px;
      margin-bottom: 30px; } }
  .module_attention_table th,
  .module_attention_table td {
    line-height: 1.75;
    padding: 5px 0px;
    font-weight: normal;
    vertical-align: middle; }
    @media screen and (max-width: 639px) {
      .module_attention_table th,
      .module_attention_table td {
        display: block; } }
  .module_attention_table th {
    font-weight: 600; }
    @media screen and (min-width: 640px) {
      .module_attention_table th {
        width: 15%; } }
  @media screen and (min-width: 640px) {
    .module_attention_table td {
      width: 85%; } }

.module_banner__item {
  display: table;
  width: 100%; }
  @media screen and (min-width: 640px) {
    .module_banner__item {
      min-height: 300px; } }
  @media screen and (max-width: 639px) {
    .module_banner__item {
      min-height: 140px; } }
  .module_banner__item.comingsoon {
    position: relative; }
    .module_banner__item.comingsoon::before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(54, 53, 53, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2; }
    .module_banner__item.comingsoon::after {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);
      color: #fff;
      content: "COMING SOON";
      font-family: "Heebo", sans-serif;
      font-weight: 600;
      letter-spacing: 3px; }
      @media screen and (min-width: 640px) {
        .module_banner__item.comingsoon::after {
          font-size: 287%; } }
      @media screen and (max-width: 639px) {
        .module_banner__item.comingsoon::after {
          font-size: 147%; } }
    .module_banner__item.comingsoon a {
      position: relative;
      z-index: 1;
      cursor: default; }
  @media screen and (min-width: 640px) {
    .module_banner__item.text_r a {
      padding-left: 50%; } }
  @media screen and (max-width: 639px) {
    .module_banner__item.text_r a {
      padding-left: 20%; } }
  .module_banner__item.text_r .module_banner__lead {
    padding-left: 0;
    display: block;
    border-bottom-color: #fff; }
  .module_banner__item.text_r .module_banner__heading {
    padding-left: 0; }
  .module_banner__item.text_r .module_banner__sublead {
    padding-left: 0; }
  .module_banner__item.text_c a {
    text-align: center; }
  .module_banner__item.text_c .module_banner__lead {
    padding-left: 0;
    display: inline-block;
    border-bottom-color: #fff; }
  .module_banner__item.text_c .module_banner__heading {
    padding-left: 0; }
  .module_banner__item.text_c .module_banner__sublead {
    padding-left: 0; }
  .module_banner__item a {
    height: 100%;
    display: table-cell;
    vertical-align: bottom;
    position: relative;
    background-position: center;
    background-size: cover;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    box-sizing: border-box;
    color: #333333;
    transition: opacity .2s; }
    @media screen and (min-width: 640px) {
      .module_banner__item a {
        padding-bottom: 30px; } }
    @media screen and (max-width: 639px) {
      .module_banner__item a {
        padding-bottom: 15px; } }
    .module_banner__item a:hover {
      text-decoration: none;
      opacity: 0.7; }
    .module_banner__item a.food_delivery {
      background-image: url(../img/top/image_fooddelivery_01.jpg); }
    .module_banner__item a.fish_guide {
      background-image: url(../img/top/image_fishguide_01.jpg);
      color: #fff; }
    .module_banner__item a.tackle_info {
      background-image: url(../img/top/image_tackleinfo_01.jpg);
      color: #fff; }
    .module_banner__item a.fish_dishes {
      background-image: url(../img/top/image_fishdishes_01.jpg);
      color: #fff; }
    .module_banner__item a.movie {
      background-image: url(../img/top/image_movie_01.jpg);
      color: #fff; }

.module_banner__lead {
  font-weight: 500;
  padding-left: 9%;
  border-bottom: 1px solid #707070;
  display: inline-block;
  padding-bottom: 4px; }
  @media screen and (min-width: 640px) {
    .module_banner__lead {
      font-size: 173%; } }
  @media screen and (max-width: 639px) {
    .module_banner__lead {
      font-size: 87%;
      line-height: 1.7; } }
  .module_banner__lead.border_white {
    border-bottom-color: #fff; }

.module_banner__heading {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: .05em; }
  @media screen and (min-width: 640px) {
    .module_banner__heading {
      font-size: 487%;
      padding-left: 9%; } }
  @media screen and (max-width: 639px) {
    .module_banner__heading {
      font-size: 240%;
      padding-left: 4%; } }

.module_banner__sublead {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 1px; }
  @media screen and (min-width: 640px) {
    .module_banner__sublead {
      font-size: 93%;
      padding-left: 9%; } }
  @media screen and (max-width: 639px) {
    .module_banner__sublead {
      font-size: 67%;
      padding-left: 4%; } }

.module_calendar .calendar_explanation {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-align-items: center;
  align-items: center; }
  @media screen and (min-width: 640px) {
    .module_calendar .calendar_explanation {
      margin-bottom: 20px;
      -webkit-justify-content: flex-end;
      justify-content: flex-end; } }
  @media screen and (max-width: 639px) {
    .module_calendar .calendar_explanation {
      margin-bottom: 10px;
      -webkit-justify-content: flex-start;
      justify-content: flex-start; } }
  @media screen and (min-width: 640px) {
    .module_calendar .calendar_explanation--left {
      -webkit-justify-content: flex-start;
      justify-content: flex-start; } }
  .module_calendar .calendar_explanation__symbol {
    font-size: 20px; }
    @media screen and (max-width: 639px) {
      .module_calendar .calendar_explanation__symbol {
        font-size: 16px; } }
  .module_calendar .calendar_explanation li:not(:last-child) {
    margin-right: 20px; }
  .module_calendar .calendar_explanation figure {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center; }
    .module_calendar .calendar_explanation figure img {
      vertical-align: middle; }
      @media screen and (max-width: 639px) {
        .module_calendar .calendar_explanation figure img {
          width: 40px; } }
    @media screen and (min-width: 640px) {
      .module_calendar .calendar_explanation figure {
        font-size: 93%;
        letter-spacing: 1px; } }
    @media screen and (max-width: 639px) {
      .module_calendar .calendar_explanation figure {
        font-size: 67%; } }

@media screen and (max-width: 639px) {
  .module_caution {
    margin-top: 30px !important; } }

.module_caution .contents_section__inner {
  border-top: 1px solid #D6D5D5;
  padding-top: 100px; }
  @media screen and (max-width: 639px) {
    .module_caution .contents_section__inner {
      padding-top: 30px; } }

.module_caution__item {
  margin: 0 auto;
  border: #D6D5D5 1px solid; }
  @media screen and (max-width: 639px) {
    .module_caution__item {
      width: 92%;
      margin: 0 auto; } }
  @media screen and (min-width: 640px) {
    .module_caution__item {
      width: 72%; } }
  .module_caution__item dt {
    background-color: #F4F3F3;
    text-align: center;
    font-weight: 600;
    padding: 10px 0; }
    @media screen and (min-width: 640px) {
      .module_caution__item dt {
        font-size: 120%; } }
    @media screen and (max-width: 639px) {
      .module_caution__item dt {
        font-size: 100%; } }
  .module_caution__item dd {
    line-height: 1.9; }
    @media screen and (min-width: 640px) {
      .module_caution__item dd {
        padding: 10px 25px; } }
    @media screen and (max-width: 639px) {
      .module_caution__item dd {
        padding: 10px 15px;
        font-size: 93%; } }

.module_cta {
  background-color: #f3f3f3;
  padding: 40px 0 72px;
  margin-top: 52px; }
  @media screen and (min-width: 640px) {
    .module_cta {
      margin-top: 90px; } }
  .module_cta a {
    font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    display: block;
    font-weight: bold;
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
  @media screen and (min-width: 640px) {
    .module_cta {
      padding: 83px 0 97px; }
      .module_cta .catch_btn {
        padding: 30px 0; }
        .module_cta .catch_btn__main {
          font-size: 240%; }
          .module_cta .catch_btn__main i {
            font-size: 40px; }
        .module_cta .catch_btn__sub {
          font-size: 133%; }
      .module_cta .default_btn {
        font-size: 160%;
        font-feature-settings: "palt";
        letter-spacing: .08em;
        padding-top: 25px;
        padding-bottom: 20px; }
        .module_cta .default_btn:before, .module_cta .default_btn:after, .module_cta .default_btn__top {
          border-width: 4px; }
        .module_cta .default_btn__top:before, .module_cta .default_btn__top:after {
          height: 4px; } }

.module_detail_table {
  width: 100%;
  text-align: left;
  border-top: 1px solid #ddd; }
  @media screen and (min-width: 640px) {
    .module_detail_table {
      margin-bottom: 30px; } }
  @media screen and (max-width: 639px) {
    .module_detail_table {
      margin-bottom: 20px; } }
  .module_detail_table table {
    width: 100%; }
  .module_detail_table tr {
    border-bottom: 1px solid #ddd; }
    .module_detail_table tr.border_none {
      border-bottom: none; }
  .module_detail_table th,
  .module_detail_table td {
    line-height: 1.75;
    padding: 20px 20px;
    font-weight: normal;
    vertical-align: middle;
    text-align: left; }
    @media screen and (max-width: 639px) {
      .module_detail_table th,
      .module_detail_table td {
        padding: 10px 20px; } }
  .module_detail_table th {
    font-size: 113%; }
    @media screen and (max-width: 639px) {
      .module_detail_table th {
        font-size: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 45%;
        padding-right: 20px; } }
  .module_detail_table td {
    font-size: 107%; }
    @media screen and (max-width: 639px) {
      .module_detail_table td {
        font-size: 100%; } }
    .module_detail_table td .price {
      display: inline-block;
      font-size: 200%;
      font-family: "Heebo", sans-serif;
      line-height: 1.3; }
      @media screen and (max-width: 639px) {
        .module_detail_table td .price {
          font-size: 173%; } }

/*

汎用的にページを構成する概要、説明

*/
@media screen and (min-width: 640px) {
  .module_instagram__list dl {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start; } }

.module_instagram__list dt {
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  border-bottom: 1px solid #707070;
  line-height: 1.5;
  letter-spacing: .01em; }
  @media screen and (min-width: 640px) {
    .module_instagram__list dt {
      width: 18%;
      font-size: 133%; } }
  @media screen and (max-width: 639px) {
    .module_instagram__list dt {
      font-size: 93%;
      margin-bottom: 10px; } }

@media screen and (min-width: 640px) {
  .module_instagram__list dd {
    width: 78%; } }

.module_instagram__list dd ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }
  .module_instagram__list dd ul li {
    margin-left: 2%;
    width: 32%;
    margin-bottom: 25px; }
    .module_instagram__list dd ul li:nth-child(1n) {
      margin-left: 0; }

.module_instagram__item + .home_instagram__item {
  margin-top: 15px; }
  @media screen and (max-width: 639px) {
    .module_instagram__item + .home_instagram__item {
      margin-top: 15px; } }

@media screen and (max-width: 639px) {
  .module_instagram #sb_instagram {
    padding-bottom: 20px !important; } }

@media screen and (max-width: 639px) {
  .module_instagram #sb_instagram #sbi_images {
    padding: 0 !important; } }

@media screen and (max-width: 639px) {
  .module_instagram #sb_instagram.sbi_col_3.sbi_disable_mobile #sbi_images .sbi_item {
    padding: 0 10px 10px 0 !important; } }

@media screen and (max-width: 639px) {
  .module_instagram #sb_instagram.sbi_col_3.sbi_disable_mobile #sbi_images .sbi_item:nth-of-type(3n) {
    padding-right: 0 !important; } }

@media screen and (max-width: 639px) {
  .module_instagram #sb_instagram #sbi_images .sbi_item {
    padding: 0 0 10px 0 !important; } }

@media screen and (max-width: 639px) {
  .page-template .module_instagram #sb_instagram.sbi_col_3.sbi_disable_mobile #sbi_images .sbi_item {
    padding: 0 0 10px 0 !important;
    width: 100%;
    float: none; } }

.module_option_table {
  width: 100%;
  text-align: left; }
  @media screen and (min-width: 640px) {
    .module_option_table {
      margin-top: 70px; } }
  .module_option_table table {
    width: 100%; }
  .module_option_table tr {
    border-bottom: 1px solid #ddd; }
    .module_option_table tr.border_none {
      border-bottom: none; }
  .module_option_table th,
  .module_option_table td {
    line-height: 1.75;
    font-weight: normal;
    vertical-align: middle;
    text-align: left; }
    @media screen and (min-width: 640px) {
      .module_option_table th,
      .module_option_table td {
        padding: 20px 20px; } }
    @media screen and (max-width: 639px) {
      .module_option_table th,
      .module_option_table td {
        padding: 10px 5px; } }
  .module_option_table th {
    font-size: 113%; }
    @media screen and (max-width: 639px) {
      .module_option_table th {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 55%;
        font-size: 87%; } }
  .module_option_table td {
    font-size: 107%; }
    @media screen and (max-width: 639px) {
      .module_option_table td {
        font-size: 87%; } }
    .module_option_table td .price {
      display: inline-block;
      font-size: 200%;
      font-family: "Heebo", sans-serif;
      line-height: 1.3; }
      @media screen and (max-width: 639px) {
        .module_option_table td .price {
          font-size: 140%; } }

.module_plan_table {
  width: 100%;
  text-align: left; }
  @media screen and (min-width: 640px) {
    .module_plan_table {
      margin-bottom: 70px; } }
  @media screen and (max-width: 639px) {
    .module_plan_table {
      margin-bottom: 30px; } }
  @media screen and (max-width: 639px) {
    .module_plan_table tr:last-child td {
      border-bottom: 2px solid #363535; } }
  .module_plan_table table {
    width: 100%;
    border: 1px solid #ddd;
    margin-bottom: 30px; }
    .module_plan_table table :last-child {
      margin-bottom: 0px; }
  .module_plan_table caption {
    font-size: 107%;
    text-align: left;
    letter-spacing: 1px;
    margin-bottom: 15px; }
    @media screen and (max-width: 639px) {
      .module_plan_table caption {
        font-size: 93%;
        line-height: 1.6; } }
    .module_plan_table caption b {
      font-size: 193%;
      margin-right: 4px; }
      @media screen and (max-width: 639px) {
        .module_plan_table caption b {
          font-size: 147%;
          display: block; } }
  .module_plan_table .course_title {
    margin-bottom: 20px;
    vertical-align: middle; }
    .module_plan_table .course_title span {
      font-size: 120%; }
    .module_plan_table .course_title .icon {
      color: white;
      background-color: #2F6CB5;
      padding: 5px 40px;
      margin-right: 15px; }
    .module_plan_table .course_title b {
      display: inline-block; }
    @media screen and (max-width: 639px) {
      .module_plan_table .course_title .course_title__time {
        display: block;
        margin-top: 5px; } }
  .module_plan_table .course_note {
    margin-top: 30px;
    margin-bottom: 0;
    line-height: 1.6; }
    @media screen and (max-width: 639px) {
      .module_plan_table .course_note {
        margin-top: 15px; } }
    .module_plan_table .course_note b {
      font-size: 113%;
      margin-right: 20px; }
      @media screen and (max-width: 639px) {
        .module_plan_table .course_note b {
          font-size: 93%;
          display: block;
          margin-right: 20px; } }
  @media screen and (max-width: 639px) {
    .module_plan_table.plan thead {
      display: none; } }
  .module_plan_table.plan thead th {
    width: auto; }
  .module_plan_table.plan tbody td {
    font-weight: 500; }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td {
        text-align: left; } }
    .module_plan_table.plan tbody td.course {
      background-color: #EFF5F9;
      text-align: left; }
      @media screen and (min-width: 640px) {
        .module_plan_table.plan tbody td.course {
          width: 40%; } }
      @media screen and (max-width: 639px) {
        .module_plan_table.plan tbody td.course {
          background-color: #2F6CB5;
          color: #fff; } }
      @media screen and (min-width: 640px) {
        .module_plan_table.plan tbody td.course.w20p {
          width: 20%; } }
      .module_plan_table.plan tbody td.course dt {
        font-size: 120%;
        letter-spacing: 1px;
        margin-bottom: 15px; }
        @media screen and (max-width: 639px) {
          .module_plan_table.plan tbody td.course dt {
            font-size: 100%;
            text-align: center;
            margin-bottom: 10px; } }
        .module_plan_table.plan tbody td.course dt b {
          font-size: 187%;
          font-weight: 600; }
          @media screen and (max-width: 639px) {
            .module_plan_table.plan tbody td.course dt b {
              font-size: 120%; } }
      .module_plan_table.plan tbody td.course dd {
        font-size: 113%; }
        @media screen and (max-width: 639px) {
          .module_plan_table.plan tbody td.course dd {
            font-size: 87%;
            padding: 0 10px; } }
    .module_plan_table.plan tbody td .price {
      display: block; }
      @media screen and (max-width: 639px) {
        .module_plan_table.plan tbody td .price {
          line-height: 1.3;
          letter-spacing: 1px; } }
    @media screen and (min-width: 640px) {
      .module_plan_table.plan tbody td.people {
        width: 16%; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-06 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-06::before {
        content: "6名ご利用時";
        display: inline-block;
        margin-right: 20px;
        font-weight: 600; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-05 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-05::before {
        content: "5名ご利用時";
        display: inline-block;
        margin-right: 20px;
        font-weight: 600; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-04 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-04::before {
        content: "４名ご利用時";
        display: inline-block;
        margin-right: 20px;
        font-weight: 600; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-03 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-03::before {
        content: "３名ご利用時";
        display: inline-block;
        margin-right: 20px;
        font-weight: 600; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-02 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-02::before {
        content: "２名ご利用時";
        display: inline-block;
        margin-right: 20px;
        font-weight: 600; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-01 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center; } }
    @media screen and (max-width: 639px) {
      .module_plan_table.plan tbody td.p-01::before {
        content: "１名ご利用時";
        display: inline-block;
        margin-right: 20px;
        font-weight: 600; } }
  @media screen and (max-width: 639px) {
    .module_plan_table.plan.plan-c table {
      border: none; } }
  @media screen and (max-width: 639px) {
    .module_plan_table.plan.plan-c tbody tr {
      display: block;
      margin-top: 30px; } }
  @media screen and (max-width: 639px) {
    .module_plan_table.plan.plan-c tbody tr:first-of-type {
      margin-top: 0; } }
  .module_plan_table thead th {
    background-color: #4F8EDC;
    color: #fff;
    border: #fff 1px solid;
    text-align: center;
    font-size: 120%;
    font-weight: 500;
    letter-spacing: 1px; }
    .module_plan_table thead th b {
      font-size: 187%;
      font-family: "Heebo", sans-serif;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px; }
  .module_plan_table tbody td {
    border: 1px solid #D6D5D5; }
  .module_plan_table th,
  .module_plan_table td {
    line-height: 1.75;
    font-weight: normal;
    vertical-align: middle; }
  .module_plan_table th {
    padding: 5px;
    color: #fff;
    font-weight: 600;
    background-color: #2F6CB5; }
    @media screen and (min-width: 640px) {
      .module_plan_table th {
        width: 40%;
        font-size: 147%; } }
    @media screen and (max-width: 639px) {
      .module_plan_table th {
        font-size: 107%;
        padding: 20px;
        display: block;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center; } }
  .module_plan_table td {
    padding: 25px 0px;
    text-align: center;
    font-weight: 600; }
    @media screen and (min-width: 640px) {
      .module_plan_table td {
        width: 60%; } }
    @media screen and (max-width: 639px) {
      .module_plan_table td {
        padding: 20px; } }
    .module_plan_table td .price {
      display: inline-block;
      font-size: 227%;
      font-family: "Heebo", sans-serif;
      line-height: 1.3;
      letter-spacing: 2px;
      margin-right: 10px; }
      @media screen and (max-width: 639px) {
        .module_plan_table td .price {
          font-size: 160%; } }

.module_plan {
  display: block;
  background: no-repeat no-repeat center center;
  background-size: cover;
  overflow: hidden;
  position: relative; }
  .module_plan, .module_plan:hover {
    color: #fff;
    text-decoration: none; }
  @media screen and (min-width: 640px) {
    .module_plan:hover .module_plan__bg {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); } }
  .module_plan__bg {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    width: 100%;
    height: 100%;
    position: relative; }
  .module_plan__balloon {
    background: url(../img/plan/limited/balloon-summer-plan.png);
    background-size: cover;
    text-align: center;
    width: 110px;
    height: 70px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-flex-direction: column;
    flex-direction: column; }
    .module_plan__balloon, .module_plan__balloon * {
      line-height: 1; }
    @media screen and (max-width: 639px) {
      .module_plan__balloon {
        top: 10px; } }
    .module_plan__balloon.attr--special {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      background: url(../img/plan/limited/balloon-summer-option.png) no-repeat center center;
      background-size: cover;
      width: 114px;
      height: 102px;
      top: 10px; }
      .module_plan__balloon.attr--special, .module_plan__balloon.attr--special * {
        line-height: 1.23; }
    .module_plan__balloon .__inner {
      -webkit-transform: rotate(12deg);
      -moz-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      -o-transform: rotate(12deg);
      transform: rotate(12deg); }
      .module_plan__balloon .__inner > * {
        display: block; }
    .module_plan__balloon .__sub {
      font-size: 80%; }
    .module_plan__balloon * + .__main {
      margin-top: 5px; }
    .module_plan__balloon .__main {
      font-size: 93%; }
  .module_plan__inner {
    text-align: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .module_plan__chapter {
    font-family: "Pacifico", cursive;
    font-size: 173%;
    margin-bottom: 30px; }
  .module_plan__bottom {
    font-size: 153%;
    font-weight: bold;
    margin-top: 15px;
    letter-spacing: .15em; }
  .module_plan__shoulder {
    font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 5px;
    position: relative; }
    @media screen and (min-width: 640px) {
      .module_plan__shoulder {
        font-size: 160%; } }
  .module_plan__title {
    display: inline-block;
    font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    font-feature-settings: "palt";
    font-weight: bold;
    font-size: 227%;
    white-space: nowrap;
    position: relative; }
    @media screen and (min-width: 640px) {
      .module_plan__title {
        font-size: 253%; } }
  .module_plan__status {
    position: absolute;
    right: -70px;
    top: -53px; }
  .module_plan__more {
    text-align: center;
    margin-top: 30px; }
  .module_plan .imitate_default_btn {
    font-size: 93%;
    display: inline-block;
    position: relative;
    padding: 11px 35px 10px; }
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      .module_plan .imitate_default_btn {
        margin-top: -1px; } }
    .module_plan .imitate_default_btn__top, .module_plan .imitate_default_btn:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      content: "";
      border: 2px solid;
      border-top: none;
      width: 100%;
      position: absolute;
      z-index: 0;
      left: 0; }
    .module_plan .imitate_default_btn:after {
      bottom: 0;
      height: 50%; }
    .module_plan .imitate_default_btn__top {
      -webkit-transition: height 150ms linear;
      -webkit-transition-delay: 350ms;
      -moz-transition: height 150ms linear 350ms;
      -o-transition: height 150ms linear 350ms;
      transition: height 150ms linear 350ms;
      border-bottom: none;
      bottom: 50%;
      height: 0;
      overflow: hidden; }
      .module_plan .imitate_default_btn__top:before, .module_plan .imitate_default_btn__top:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: width 350ms linear;
        -moz-transition: width 350ms linear;
        -o-transition: width 350ms linear;
        transition: width 350ms linear;
        content: "";
        width: 0;
        height: 2px;
        background-color: #fff;
        position: absolute;
        z-index: 0;
        top: 0; }
      .module_plan .imitate_default_btn__top:before {
        left: 0; }
      .module_plan .imitate_default_btn__top:after {
        right: 0; }
  @media screen and (min-width: 640px) {
    .module_plan:hover .imitate_default_btn__top {
      -webkit-transition: height 150ms linear;
      -moz-transition: height 150ms linear;
      -o-transition: height 150ms linear;
      transition: height 150ms linear;
      height: 50%; }
      .module_plan:hover .imitate_default_btn__top:before, .module_plan:hover .imitate_default_btn__top:after {
        -webkit-transition: width 350ms linear;
        -webkit-transition-delay: 150ms;
        -moz-transition: width 350ms linear 150ms;
        -o-transition: width 350ms linear 150ms;
        transition: width 350ms linear 150ms;
        width: 51%; } }
  .module_plan.attr_application:before {
    display: block;
    content: "";
    padding-top: 35%; }
  .module_plan.attr_application .module_plan__inner {
    position: static;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  .module_plan.attr_application .module_plan__bg {
    position: absolute;
    top: 0;
    height: auto; }
  .module_plan.attr_first .module_plan__body {
    border-left: 1px solid #ddd; }
  .module_plan__description {
    font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 107%;
    font-weight: bold;
    margin-top: 10px; }
  .module_plan__text {
    font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    margin-top: 15px; }
    .module_plan__text, .module_plan__text * {
      line-height: 1.45; }
    .module_plan__text > * + * {
      margin-top: 10px; }
  .module_plan__body {
    background-color: #fff;
    color: #333333;
    border: 1px solid #ddd;
    border-left: none;
    border-top: none;
    margin-top: 25px;
    position: relative; }
    @media screen and (max-width: 639px) {
      .module_plan__body {
        border-left: 1px solid #ddd;
        padding: 30px 10px; } }
    .module_plan__body:before {
      content: "";
      display: block;
      padding-top: 68.42105%; }
      @media screen and (max-width: 639px) {
        .module_plan__body:before {
          display: none; } }
    .module_plan__body-inner {
      text-align: center; }
      @media screen and (min-width: 640px) {
        .module_plan__body-inner {
          width: 100%;
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); } }
    .module_plan__body-shoulder {
      font-weight: bold; }
    .module_plan__body-title {
      margin-top: 10px; }
      .module_plan__body-title .elem_emphasis {
        font-size: 133%; }
    .module_plan__body-sub {
      font-size: 87%;
      color: #666; }
      @media screen and (max-width: 639px) {
        .module_plan__body-sub {
          margin-top: 3px; } }
    .module_plan__body-price {
      font-family: "Pacifico", cursive;
      font-size: 267%;
      margin-top: 0; }
    .module_plan__body .module_plan__more {
      margin-top: 20px; }
      @media screen and (max-width: 639px) {
        .module_plan__body .module_plan__more {
          margin-top: 10px; } }
      .module_plan__body .module_plan__more .imitate_default_btn__top:before, .module_plan__body .module_plan__more .imitate_default_btn__top:after {
        background-color: #333333; }

.module_price_table {
  width: 100%;
  table-layout: fixed;
  letter-spacing: .1em; }
  .module_price_table__head .module_price_table__th {
    background-color: #00a0d4;
    color: #fff;
    vertical-align: middle;
    height: 55px; }
    .module_price_table__head .module_price_table__th:first-child {
      background-color: #1d509e;
      font-weight: normal; }
    .module_price_table__head .module_price_table__th .elem_emphasis {
      display: inline-block;
      line-height: 1;
      font-size: 167%;
      margin-top: -10px;
      position: relative;
      top: .05em; }
  .module_price_table__th, .module_price_table__td {
    border: 1px solid #ddd;
    text-align: center; }
  .module_price_table__body .module_price_table__th,
  .module_price_table__body .module_price_table__td {
    padding: 20px; }
  .module_price_table__body .module_price_table__th {
    background-color: #f3f3f3;
    color: #1d509e;
    vertical-align: middle; }
    .module_price_table__body .module_price_table__th .elem_emphasis {
      display: inline-block;
      font-size: 200%;
      margin-top: -10px; }
    .module_price_table__body .module_price_table__th .elem_default {
      font-size: 133%; }
  .module_price_table__body .module_price_table__td {
    vertical-align: top; }
    .module_price_table__body .module_price_table__td .elem_price {
      font-family: "Pacifico", cursive;
      font-size: 200%;
      white-space: nowrap;
      line-height: 1;
      margin-top: -.3em;
      margin-bottom: 10px;
      letter-spacing: 0; }
    .module_price_table__body .module_price_table__td .elem_default {
      font-size: 87%;
      line-height: 1.5; }
  @media screen and (max-width: 639px) {
    .module_price_table {
      letter-spacing: 0; }
      .module_price_table, .module_price_table__head, .module_price_table__body, .module_price_table__row, .module_price_table__th, .module_price_table__td {
        display: block; }
      .module_price_table__head {
        display: none; }
      .module_price_table__body .module_price_table__row + .module_price_table__row {
        margin-top: 20px; }
      .module_price_table__body .module_price_table__th {
        background-color: #1d509e;
        color: #fff;
        border: none;
        font-weight: normal;
        padding-left: 10px;
        padding-right: 10px; }
        .module_price_table__body .module_price_table__th .elem_emphasis {
          margin-top: -3px; }
        .module_price_table__body .module_price_table__th:before {
          content: attr(data-label);
          display: inline;
          font-weight: normal;
          margin-right: 5px; }
      .module_price_table__body .module_price_table__td {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: table;
        width: 100%;
        text-align: left;
        border-top: none; }
        .module_price_table__body .module_price_table__td:before, .module_price_table__body .module_price_table__td_inner {
          padding: 17px; }
        .module_price_table__body .module_price_table__td:before {
          content: attr(data-label);
          display: table-cell;
          vertical-align: middle;
          color: #00a0d4;
          font-weight: bold;
          width: 100px; }
        .module_price_table__body .module_price_table__td_inner {
          display: table-cell;
          vertical-align: middle; }
        .module_price_table__body .module_price_table__td .elem_price {
          font-size: 173%;
          margin-bottom: 5px; }
        .module_price_table__body .module_price_table__td .elem_default {
          font-size: 73%; } }
  .module_price_table.attr_course .module_price_table__head .module_price_table__th {
    height: 45px; }
    .module_price_table.attr_course .module_price_table__head .module_price_table__th:first-child {
      width: 456px; }
      .module_price_table.attr_course .module_price_table__head .module_price_table__th:first-child.attr_fixed {
        width: auto; }
  .module_price_table.attr_course .module_price_table__body .module_price_table__th {
    text-align: left; }
    @media screen and (max-width: 639px) {
      .module_price_table.attr_course .module_price_table__body .module_price_table__th {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center; }
        .module_price_table.attr_course .module_price_table__body .module_price_table__th-inner {
          display: inline-block;
          text-align: left; }
        .module_price_table.attr_course .module_price_table__body .module_price_table__th:before {
          display: none; } }
    .module_price_table.attr_course .module_price_table__body .module_price_table__th .elem_emphasis {
      font-size: 160%; }
    .module_price_table.attr_course .module_price_table__body .module_price_table__th .elem_default {
      font-size: 120%; }
    .module_price_table.attr_course .module_price_table__body .module_price_table__th .elem_description {
      font-size: 87%;
      font-weight: normal;
      margin-top: 5px; }
      .module_price_table.attr_course .module_price_table__body .module_price_table__th .elem_description strong {
        font-size: 115%; }
      @media screen and (max-width: 639px) {
        .module_price_table.attr_course .module_price_table__body .module_price_table__th .elem_description {
          font-size: 73%;
          margin-top: 3px; } }

.module_schedule.bg--blue {
  background-color: #2F6CB5;
  padding: 65px 0; }
  @media screen and (max-width: 639px) {
    .module_schedule.bg--blue {
      padding: 25px 0 40px; } }
  .module_schedule.bg--blue .module_schedule__item {
    margin-top: 0;
    margin-bottom: 0; }

@media screen and (min-width: 640px) {
  .module_schedule__item {
    width: 80%;
    margin: 45px auto; } }

@media screen and (max-width: 639px) {
  .module_schedule__item {
    margin-top: 35px; } }

.module_schedule__item a {
  display: block; }
  @media screen and (max-width: 639px) {
    .module_schedule__item a {
      min-height: 140px; } }
  .module_schedule__item a:hover figure {
    outline-offset: 0;
    opacity: .8; }
  .module_schedule__item a figure {
    position: relative;
    outline: 1px solid #fff;
    outline-offset: -12px;
    transition: outline-offset .2s, opacity .2s; }
    .module_schedule__item a figure img {
      transition: filter .2s; }
    .module_schedule__item a figure figcaption {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1.7; }
      @media screen and (min-width: 640px) {
        .module_schedule__item a figure figcaption {
          font-size: 193%; } }
      @media screen and (max-width: 639px) {
        .module_schedule__item a figure figcaption {
          font-size: 120%;
          width: 100%;
          text-align: center; } }

.module_service_table {
  width: 100%;
  text-align: left;
  border-top: 4px solid #333; }
  @media screen and (max-width: 639px) {
    .module_service_table.option .module_service_table__th {
      width: 45%; } }
  @media screen and (max-width: 639px) {
    .module_service_table.service .module_service_table__th {
      width: 60%; } }
  .module_service_table__th, .module_service_table__td {
    line-height: 1.75;
    border-bottom: 1px solid #ddd;
    font-weight: normal;
    vertical-align: middle; }
    @media screen and (min-width: 640px) {
      .module_service_table__th, .module_service_table__td {
        padding: 20px 30px 20px 20px; } }
    @media screen and (max-width: 639px) {
      .module_service_table__th, .module_service_table__td {
        padding: 10px 20px 10px 15px; } }
    .module_service_table__th.borderbottom_none, .module_service_table__td.borderbottom_none {
      border-bottom: none; }
  .module_service_table__th {
    font-weight: bold; }
    @media screen and (min-width: 640px) {
      .module_service_table__th {
        width: 49%; } }
    @media screen and (max-width: 639px) {
      .module_service_table__th {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; } }
    @media screen and (max-width: 639px) {
      .module_service_table__th .elem_main {
        font-size: 87%; } }
    .module_service_table__th .elem_sub {
      font-size: 73%; }
    .module_service_table__th.w100p {
      width: 100%; }
      @media screen and (max-width: 639px) {
        .module_service_table__th.w100p {
          font-size: 87%; } }
  .module_service_table__td .elem_price {
    display: inline-block;
    font-size: 187%;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    letter-spacing: 1px; }
    @media screen and (max-width: 639px) {
      .module_service_table__td .elem_price {
        font-size: 173%; } }
  @media screen and (max-width: 639px) {
    .module_service_table__td .elem_default {
      font-size: 87%; } }
  .module_service_table__td .elem_emphasis {
    font-size: 113%;
    font-weight: bold; }
    @media screen and (max-width: 639px) {
      .module_service_table__td .elem_emphasis {
        font-size: 100%; } }

/*

UIKIT OffCanvas

*/
.uk-offcanvas {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.uk-offcanvas.uk-active {
  display: block; }

body > * {
  -webkit-transition: filter 500ms ease-in-out;
  -moz-transition: filter 500ms ease-in-out;
  -o-transition: filter 500ms ease-in-out;
  transition: filter 500ms ease-in-out; }

body.is_menu_active {
  height: 100vh;
  overflow: hidden;
  width: auto !important;
  margin-left: 0 !important; }
  @media screen and (min-width: 640px) {
    body.is_menu_active {
      margin-left: auto !important;
      overflow-y: auto; } }
  body.is_menu_active .drawer_btn i {
    background-color: #fff !important; }
  @media screen and (min-width: 640px) {
    body.is_menu_active .uk-offcanvas-bar-flip {
      right: 50%; } }
  @media screen and (max-width: 639px) {
    body.is_menu_active .uk-offcanvas-bar-flip {
      right: 100%; } }
  body.is_menu_active .site_header .site_header__nav a {
    color: #fff !important; }

.uk-offcanvas-page .site_header {
  height: 100%; }
  .uk-offcanvas-page .site_header__inner {
    position: relative;
    z-index: 1003; }
  @media screen and (max-width: 639px) {
    .uk-offcanvas-page .site_header .site_header__id a {
      color: #fff !important; } }
  @media screen and (min-width: 640px) {
    body.top.uk-offcanvas-page .drawer_nav .uk-offcanvas-bar .lv2 {
      margin-top: 25px;
      margin-bottom: 30px; }
      body.top.uk-offcanvas-page .drawer_nav .uk-offcanvas-bar .lv2 li + li {
        margin-top: 20px; } }

@media screen and (min-width: 640px) {
  .uk-offcanvas-page {
    padding-right: 16px; } }

.uk-offcanvas-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1001;
  width: 100%;
  background: #333;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  -ms-scroll-chaining: none; }
  @media screen and (min-width: 640px) {
    .uk-offcanvas-bar {
      width: 50%; } }

.uk-offcanvas.uk-active .uk-offcanvas-bar.uk-offcanvas-bar-show {
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }

.uk-offcanvas-bar-flip {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.uk-offcanvas .uk-panel {
  margin: 20px 15px;
  color: #777; }

.uk-offcanvas .uk-panel a:not([class]), .uk-offcanvas .uk-panel-title {
  color: #ccc; }

.uk-offcanvas .uk-panel a:not([class]):hover {
  color: #fff; }

.drawer_btn {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  cursor: pointer;
  position: absolute;
  z-index: 1003;
  top: 22px;
  right: 10px; }
  @media screen and (min-width: 640px) and (max-width: 1200px) {
    .drawer_btn {
      right: 30px; } }
  @media screen and (min-width: 640px) {
    .drawer_btn {
      right: 45px;
      top: 37px; } }
  body.is_menu_active .close {
    display: block;
    width: 40px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    z-index: 2000;
    top: 0;
    right: 0; }
  .drawer_btn a {
    display: block;
    width: 40px;
    height: 30px; }
  .drawer_btn i {
    -webkit-transition: -webkit-transform 500ms ease-in-out, top 500ms ease-in-out, opacity 500ms ease-in-out, color 0ms ease-in-out;
    -moz-transition: -moz-transform 500ms ease-in-out, top 500ms ease-in-out, opacity 500ms ease-in-out, color 0ms ease-in-out;
    -o-transition: -o-transform 500ms ease-in-out, top 500ms ease-in-out, opacity 500ms ease-in-out, color 0ms ease-in-out;
    transition: transform 500ms ease-in-out, top 500ms ease-in-out, opacity 500ms ease-in-out, color 0ms ease-in-out;
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    background: #000; }
    .drawer_btn i:nth-child(1) {
      top: 0px; }
    .drawer_btn i:nth-child(2) {
      top: 14px; }
    .drawer_btn i:nth-child(3) {
      top: 28px; }
    body.is_menu_active .drawer_btn i:nth-child(1) {
      top: 14px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    body.is_menu_active .drawer_btn i:nth-child(2) {
      opacity: 0; }
    body.is_menu_active .drawer_btn i:nth-child(3) {
      top: 14px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
  .drawer_btn.fix_header a i {
    background: black; }

.drawer_nav .uk-offcanvas-bar {
  -webkit-box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.8); }
  .drawer_nav .uk-offcanvas-bar > nav {
    margin: 0 auto; }
    @media screen and (min-width: 640px) {
      .drawer_nav .uk-offcanvas-bar > nav {
        width: 74%; } }
    @media screen and (max-width: 639px) {
      .drawer_nav .uk-offcanvas-bar > nav {
        width: 88%; } }
  @media screen and (min-width: 640px) {
    body.top .drawer_nav .uk-offcanvas-bar nav {
      display: table;
      width: 100%;
      height: 100%; }
    body.top .drawer_nav .uk-offcanvas-bar .lv1 {
      padding-top: 0;
      display: table-cell;
      vertical-align: middle; } }
  .drawer_nav .uk-offcanvas-bar .lv1 {
    padding-top: 130px; }
    @media screen and (max-width: 320px) {
      .drawer_nav .uk-offcanvas-bar .lv1 {
        padding-top: 85px; } }
    @media screen and (min-width: 640px) {
      .drawer_nav .uk-offcanvas-bar .lv1 {
        padding-top: 140px;
        padding-bottom: 100px; }
        .drawer_nav .uk-offcanvas-bar .lv1:before {
          display: block;
          font-family: "Heebo", sans-serif;
          content: "MENU";
          color: #fff;
          text-align: center;
          font-size: 307%;
          margin-bottom: 40px;
          font-weight: 600;
          letter-spacing: 2px; } }
    @media screen and (max-width: 639px) {
      .drawer_nav .uk-offcanvas-bar .lv1 {
        padding-top: 90px;
        padding-bottom: 50px; }
        .drawer_nav .uk-offcanvas-bar .lv1:before {
          display: block;
          font-family: "Heebo", sans-serif;
          content: "MENU";
          color: #fff;
          text-align: center;
          font-size: 187%;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 2px; } }
    .drawer_nav .uk-offcanvas-bar .lv1 > li + li {
      margin-top: 5px; }
      @media screen and (min-width: 640px) {
        .drawer_nav .uk-offcanvas-bar .lv1 > li + li {
          margin-bottom: 22px; }
          .drawer_nav .uk-offcanvas-bar .lv1 > li + li:last-child {
            margin-bottom: 0; } }
      @media screen and (max-width: 639px) {
        .drawer_nav .uk-offcanvas-bar .lv1 > li + li {
          margin-top: 15px; } }
    .drawer_nav .uk-offcanvas-bar .lv1 > li {
      border-bottom: 1px solid #fff; }
      .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_top {
        margin-bottom: 30px; }
      .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_top a::before, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_tackle a::before, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_charm a::before, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_faq a::before {
        border-color: transparent transparent transparent #fff; }
      .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_top a, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_place a {
        font-family: "Heebo", sans-serif;
        font-weight: 600; }
        @media screen and (min-width: 640px) {
          .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_top a, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_place a {
            font-size: 180%; } }
        @media screen and (max-width: 639px) {
          .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_top a, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_place a {
            font-size: 160%; } }
        .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_top a.disable, .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_place a.disable {
          color: #444 !important; }
      .drawer_nav .uk-offcanvas-bar .lv1 > li > a {
        display: inline-block;
        color: #fff;
        padding: 10px 0;
        text-decoration: none;
        letter-spacing: 2px;
        font-size: 133%; }
        @media screen and (min-width: 640px) {
          .drawer_nav .uk-offcanvas-bar .lv1 > li > a {
            font-size: 160%; }
            .uk-offcanvas-page .site_header .drawer_nav .uk-offcanvas-bar .lv1 > li > a {
              -webkit-transition: color 300ms ease, padding-left 300ms ease;
              -moz-transition: color 300ms ease, padding-left 300ms ease;
              -o-transition: color 300ms ease, padding-left 300ms ease;
              transition: color 300ms ease, padding-left 300ms ease; } }
        @media screen and (min-width: 640px) {
          .drawer_nav .uk-offcanvas-bar .lv1 > li > a:hover {
            padding-left: 20px; } }
  .drawer_nav .uk-offcanvas-bar .lv2 {
    display: none; }
  .drawer_nav .uk-offcanvas-bar a.arrow::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
    top: 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 13px;
    border-color: transparent transparent transparent #aaa;
    float: left; }
  .drawer_nav .uk-offcanvas-bar a.arrow > span {
    display: block;
    overflow: hidden; }

.drawer_nav__banner {
  text-align: center;
  font-size: 93%;
  margin-top: 20px; }
  .drawer_nav__banner a {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    width: 140px; }
    .drawer_nav__banner a:hover {
      text-decoration: none; }
    .drawer_nav__banner a,
    .drawer_nav__banner a i,
    .drawer_nav__banner a i:before {
      color: #fff; }
    .drawer_nav__banner a i {
      vertical-align: middle;
      font-size: 121%;
      margin-right: 5px; }

.drawer_nav .uk-offcanvas-bar .lv2 {
  font-size: 87%;
  margin-top: 10px;
  margin-bottom: 25px; }
  .drawer_nav .uk-offcanvas-bar .lv2 > li > a {
    color: #aaa !important;
    line-height: 1.5;
    display: block;
    letter-spacing: 1px; }
    .drawer_nav .uk-offcanvas-bar .lv2 > li > a:after {
      background-color: #aaa !important; }

.drawer_nav .uk-offcanvas-bar .lv1 > li.fn--toggle.selected > a:after {
  width: 100%; }

@media screen and (min-width: 640px) {
  body.about .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_about > a:after {
    width: 100%; }
  body.plan .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_plan > a:after {
    width: 100%; }
  body.attractive .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_attractive > a:after {
    width: 100%; }
  body.oneday .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_oneday > a:after {
    width: 100%; }
  body.faq .drawer_nav .uk-offcanvas-bar .lv1 > li.attr_faq > a:after {
    width: 100%; }
  .drawer_nav .uk-offcanvas-bar .lv1 > li a {
    position: relative; }
  .drawer_nav .uk-offcanvas-bar .lv2 {
    margin-top: 10px;
    margin-bottom: 35px; }
    .drawer_nav .uk-offcanvas-bar .lv2 > li > a:hover {
      text-decoration: none; } }
  @media screen and (min-width: 640px) and (min-width: 640px) {
    .drawer_nav .uk-offcanvas-bar .lv2 li + li {
      margin-top: 10px; } }
  @media screen and (min-width: 640px) and (max-width: 639px) {
    .drawer_nav .uk-offcanvas-bar .lv2 li + li {
      margin-top: 15px; } }

@media screen and (min-width: 640px) {
  .drawer_nav .uk-offcanvas-bar a {
    transition: padding-left .2s; }
    .drawer_nav .uk-offcanvas-bar a:hover {
      padding-left: 20px; } }

/*

汎用的にページを構成するページコンテナ

*/
.page_content {
  overflow: hidden; }
  @media screen and (min-width: 640px) {
    .page_content {
      margin-bottom: 80px; } }
  @media screen and (max-width: 639px) {
    .page_content {
      margin-bottom: 30px; } }
  @media screen and (max-width: 639px) {
    .page_content > * {
      margin-top: 50px; } }
  @media screen and (max-width: 639px) {
    .page_content > *:first-of-type {
      margin-top: 0; } }

@media screen and (min-width: 640px) {
  .page_section {
    padding: 90px 0; } }

@media screen and (min-width: 640px) {
  .page_section__column {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start; } }

@media screen and (min-width: 640px) {
  .page_section__content {
    padding-left: 9%;
    width: 45%; } }

@media screen and (max-width: 639px) {
  .page_section__content {
    width: 92%;
    margin: 0 auto; } }

@media screen and (min-width: 640px) {
  .page_section__thumb {
    width: 38%; } }

@media screen and (max-width: 639px) {
  .page_section__thumb {
    margin-top: 20px; } }

.page_section__title {
  font-size: 300%;
  font-weight: 600;
  margin-bottom: 30px;
  letter-spacing: 2px; }

.page_section__lead {
  font-size: 133%;
  font-weight: 400;
  line-height: 2.5; }

.page_section__image {
  width: 854px;
  height: 273px;
  margin: 30px auto 0; }
  @media screen and (max-width: 639px) {
    .page_section__image {
      width: 92%;
      margin: 20px auto 0; } }

/*

汎用的にページを構成するメインイメージ

*/
.page_main_image {
  position: relative;
  z-index: 1;
  overflow: hidden; }
  @media screen and (max-width: 639px) {
    .page_main_image {
      margin-bottom: 30px; } }
  .page_main_image:before {
    content: "";
    width: 100%;
    height: 240px;
    position: absolute;
    z-index: 52;
    left: 0;
    bottom: 0;
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); }
    @media screen and (min-width: 640px) {
      .page_main_image:before {
        height: 470px; } }
  .page_main_image img {
    max-width: 100%;
    display: inline-block; }
  .page_main_image.attr_stational:before {
    height: 140px; }
    @media screen and (min-width: 640px) {
      .page_main_image.attr_stational:before {
        height: 270px; } }
  .page_main_image .slick {
    opacity: 0;
    transition: opacity .2s; }
    .page_main_image .slick.slick-initialized {
      opacity: 1; }
  @media screen and (min-width: 640px) {
    .page_main_image {
      height: 100%;
      position: relative; }
      .page_main_image .bx-wrapper {
        background-color: #000; }
      .page_main_image #pageMainSlider {
        -webkit-transition: opacity 1000ms ease;
        -moz-transition: opacity 1000ms ease;
        -o-transition: opacity 1000ms ease;
        transition: opacity 1000ms ease;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
        .page_main_image #pageMainSlider.animated {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
          opacity: 1; }
      .page_main_image .bx-wrapper,
      .page_main_image .bx-viewport,
      .page_main_image #pageMainSlider,
      .page_main_image #pageMainSlider > li {
        width: 100% !important;
        height: 100% !important; }
      .page_main_image #pageMainSlider {
        position: relative; }
      .page_main_image__bg {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: none;
        max-height: 100%;
        position: absolute;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
        margin: auto; }
        .page_main_image__bg.ratio--wide {
          max-width: 100%;
          max-height: none; }
        .page_main_image__bg.attr_url {
          min-width: 0;
          min-height: 0;
          max-width: none;
          max-height: none;
          width: 100%;
          height: 100%;
          background: no-repeat center center;
          background-size: cover;
          left: 0;
          top: 0;
          right: auto;
          bottom: auto; } }
  @media screen and (max-width: 639px) {
    .page_main_image__bg.attr_url {
      height: 420px; } }
  @media screen and (min-width: 640px) {
    .page_main_image.attr_stational,
    .page_main_image.attr_stational .page_main_image__bg {
      height: 500px; } }
  @media screen and (max-width: 639px) {
    .page_main_image.attr_stational,
    .page_main_image.attr_stational .page_main_image__bg {
      height: 270px; } }
  @media screen and (min-width: 640px) {
    .page_main_image.attr_stational .page_main_image__copy {
      bottom: 78px; } }
  @media screen and (max-width: 639px) {
    .page_main_image.attr_stational .page_main_image__copy {
      bottom: 30px; } }
  .page_main_image.attr_stational:after {
    display: none; }
  .page_main_image__copy {
    color: #fff;
    font-weight: bold;
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 53;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 639px) {
      .page_main_image__copy .elem_inner {
        padding-left: 15px;
        padding-right: 15px; } }
    .page_main_image__copy .elem_inner .elem_catch {
      position: relative;
      font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
      font-feature-settings: "palt";
      font-size: 140%;
      padding: 15px 20px; }
      @media screen and (min-width: 640px) {
        .page_main_image__copy .elem_inner .elem_catch {
          padding-top: 18px;
          font-size: 30px; } }
      .page_main_image__copy .elem_inner .elem_catch:before, .page_main_image__copy .elem_inner .elem_catch:after {
        content: "";
        display: block;
        background: url(../img/common/main_copy_frame_t_sp.png) no-repeat center top;
        background-size: contain;
        width: 100%;
        height: 10px;
        position: absolute;
        left: 0; }
        @media screen and (min-width: 640px) {
          .page_main_image__copy .elem_inner .elem_catch:before, .page_main_image__copy .elem_inner .elem_catch:after {
            background-image: url(../img/common/main_copy_frame_t.png);
            background-size: auto;
            height: 14px; } }
      .page_main_image__copy .elem_inner .elem_catch:before {
        top: 0; }
      .page_main_image__copy .elem_inner .elem_catch:after {
        bottom: 0; }
    .page_main_image__copy .elem_inner * + .elem_lead {
      margin-top: 20px; }
    .page_main_image__copy .elem_inner .elem_lead {
      letter-spacing: 3px;
      margin-bottom: 30px; }
      @media screen and (max-width: 639px) {
        .page_main_image__copy .elem_inner .elem_lead {
          letter-spacing: 1px;
          margin-bottom: 15px; } }
      @media screen and (min-width: 640px) {
        .page_main_image__copy .elem_inner .elem_lead .jp {
          font-size: 200%;
          margin-top: 30px;
          font-weight: 500; } }
      @media screen and (max-width: 639px) {
        .page_main_image__copy .elem_inner .elem_lead .jp {
          font-size: 100%;
          margin-top: 20px;
          font-weight: 600; } }
      .page_main_image__copy .elem_inner .elem_lead .en {
        font-family: "Heebo", sans-serif; }
        @media screen and (min-width: 640px) {
          .page_main_image__copy .elem_inner .elem_lead .en {
            font-size: 133%;
            font-weight: 400; } }
        @media screen and (max-width: 639px) {
          .page_main_image__copy .elem_inner .elem_lead .en {
            font-size: 100%;
            font-weight: 700; } }
    .page_main_image__copy .elem_inner .elem_title {
      line-height: 1.5; }
      .page_main_image__copy .elem_inner .elem_title .jp b {
        display: block; }
        @media screen and (min-width: 640px) {
          .page_main_image__copy .elem_inner .elem_title .jp b {
            font-size: 447%;
            font-weight: 500; } }
        @media screen and (max-width: 639px) {
          .page_main_image__copy .elem_inner .elem_title .jp b {
            font-size: 200%;
            margin-bottom: 10px;
            font-family: "Noto Sans JP", sans-serif;
            font-weight: 900; } }
      .page_main_image__copy .elem_inner .elem_title .jp span {
        display: block; }
        @media screen and (min-width: 640px) {
          .page_main_image__copy .elem_inner .elem_title .jp span {
            font-size: 333%;
            font-weight: 500; } }
        @media screen and (max-width: 639px) {
          .page_main_image__copy .elem_inner .elem_title .jp span {
            font-size: 147%;
            font-weight: 700; } }
      .page_main_image__copy .elem_inner .elem_title .en span {
        font-family: "Heebo", sans-serif;
        line-height: 1.2; }
        @media screen and (min-width: 640px) {
          .page_main_image__copy .elem_inner .elem_title .en span {
            font-size: 480%;
            font-weight: 600;
            letter-spacing: 5px; } }
        @media screen and (max-width: 639px) {
          .page_main_image__copy .elem_inner .elem_title .en span {
            font-size: 220%;
            font-weight: 700;
            letter-spacing: 3px; } }
      .page_main_image__copy .elem_inner .elem_title .en b {
        line-height: 1.2;
        display: block;
        font-family: "Heebo", sans-serif; }
        @media screen and (min-width: 640px) {
          .page_main_image__copy .elem_inner .elem_title .en b {
            font-size: 647%;
            font-weight: 600;
            letter-spacing: 5px; } }
        @media screen and (max-width: 639px) {
          .page_main_image__copy .elem_inner .elem_title .en b {
            font-size: 280%;
            font-weight: 700;
            letter-spacing: 3px; } }
    .page_main_image__copy .elem_inner .elem_shoulder {
      font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
      font-size: 133%; }
      @media screen and (min-width: 640px) {
        .page_main_image__copy .elem_inner .elem_shoulder {
          font-size: 267%; } }
    .page_main_image__copy .elem_inner .elem_shoulder + .elem_description {
      margin-top: 0; }
    .page_main_image__copy .elem_inner .elem_description {
      font-size: 227%;
      font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
      font-feature-settings: "palt";
      margin-top: 15px;
      line-height: 1.3; }
      @media screen and (min-width: 640px) {
        .page_main_image__copy .elem_inner .elem_description {
          font-size: 373%; } }
  .page_main_image__description {
    font-weight: normal;
    line-height: 2.1;
    margin-top: 20px; }
    @media screen and (max-width: 639px) {
      .page_main_image__description {
        text-align: left;
        font-size: 87%;
        padding-left: 20px;
        padding-right: 20px; } }
  @media screen and (min-width: 640px) {
    body.page > .page_main_image__description {
      display: none; } }
  @media screen and (max-width: 639px) {
    .page_main_image .page_main_image__description {
      display: none; } }

.kobe .page_main_image__copy .elem_inner .elem_lead {
  letter-spacing: 3px;
  margin-bottom: 30px; }
  @media screen and (max-width: 639px) {
    .kobe .page_main_image__copy .elem_inner .elem_lead {
      letter-spacing: 1px;
      margin-bottom: 15px; } }
  .kobe .page_main_image__copy .elem_inner .elem_lead .jp {
    font-size: inherit; }
    @media screen and (min-width: 640px) {
      .kobe .page_main_image__copy .elem_inner .elem_lead .jp {
        margin-top: 30px;
        font-weight: 500;
        letter-spacing: 5px; } }
    @media screen and (max-width: 639px) {
      .kobe .page_main_image__copy .elem_inner .elem_lead .jp {
        margin-top: 20px;
        font-weight: 600;
        letter-spacing: 2px; } }
    @media screen and (min-width: 640px) {
      .kobe .page_main_image__copy .elem_inner .elem_lead .jp span {
        font-size: 280%; } }
    @media screen and (max-width: 639px) {
      .kobe .page_main_image__copy .elem_inner .elem_lead .jp span {
        font-size: 140%; } }
    .kobe .page_main_image__copy .elem_inner .elem_lead .jp b {
      display: block; }
      @media screen and (min-width: 640px) {
        .kobe .page_main_image__copy .elem_inner .elem_lead .jp b {
          font-size: 467%;
          font-family: "Heebo", sans-serif;
          margin-top: 15px; } }
      @media screen and (max-width: 639px) {
        .kobe .page_main_image__copy .elem_inner .elem_lead .jp b {
          font-size: 233%; } }

@media screen and (max-width: 639px) {
  body.tokyo .page_main_image__copy .elem_inner .elem_title .jp span {
    font-size: 120%; } }

.contents_section {
  padding: 50px 0; }
  @media screen and (max-width: 639px) {
    .contents_section {
      padding: 25px 0; } }
  .contents_section__head {
    margin-bottom: 45px; }
    @media screen and (max-width: 639px) {
      .contents_section__head {
        margin: 0 auto 25px; } }

.blocker {
  padding: 0;
  z-index: 1003; }

.close-modal {
  display: none !important; }

.modal {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 100%;
  box-shadow: unset;
  border-radius: 0; }
  @media screen and (min-width: 640px) {
    .modal {
      padding: 100px 0; } }
  @media screen and (max-width: 639px) {
    .modal {
      padding: 30px 4%; } }
  .modal__inner {
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    @media screen and (min-width: 640px) {
      .modal__inner {
        padding: 30px 50px 70px 50px; } }
    @media screen and (max-width: 639px) {
      .modal__inner {
        padding: 20px 20px 30px 20px; } }
  .modal_section {
    margin-bottom: 60px; }
    .modal_section:last-of-type {
      margin-bottom: 0; }
  .modal .column__item {
    margin-bottom: 60px; }
    @media screen and (max-width: 639px) {
      .modal .column__item {
        margin-bottom: 30px; } }
    .modal .column__item:last-of-type {
      margin-bottom: 0; }
  .modal .info {
    margin-bottom: 20px;
    line-height: 1.7; }
  .modal .about {
    line-height: 1.7; }
    .modal .about dt {
      font-weight: 700;
      margin-bottom: 10px; }
  .modal .table a {
    text-decoration: underline; }
    .modal .table a:hover {
      text-decoration: none; }
  .modal .btn {
    margin-right: 0;
    margin-left: auto;
    width: 46px;
    height: 46px; }
    .modal .btn a {
      display: block;
      position: relative;
      background-color: #000;
      width: 100%;
      height: 100%;
      border-radius: 50%; }
      .modal .btn a::before, .modal .btn a::after {
        content: "";
        display: block;
        width: 17px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%; }
      .modal .btn a::before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .modal .btn a::after {
        transform: translate(-50%, -50%) rotate(45deg); }

@media screen and (max-width: 639px) {
  .sp__scroll_x {
    overflow-x: auto;
    margin-bottom: 20px;
    -webkit-overflow-scrolling: touch; }
    .sp__scroll_x::-webkit-scrollbar {
      width: 12px; }
    .sp__scroll_x::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #f2f2f2; }
    .sp__scroll_x::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #2F6CB5; } }

.table {
  margin-bottom: 15px; }
  @media screen and (min-width: 640px) {
    .table {
      width: 100%; } }
  @media screen and (max-width: 639px) {
    .table {
      width: 1000px; } }
  .table thead {
    background-color: #EBF3FC; }
    .table thead th, .table thead td {
      vertical-align: middle; }
    .table thead th {
      font-weight: 700; }
  .table th, .table td {
    font-weight: 500;
    text-align: left;
    border: #707070 1px solid;
    line-height: 1.4; }
    @media screen and (min-width: 640px) {
      .table th, .table td {
        padding: 10px 15px; } }
    @media screen and (max-width: 639px) {
      .table th, .table td {
        padding: 5px 10px;
        font-size: 93%; } }
  .table.calendar thead th {
    border: none;
    background-color: #2F6CB5;
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 80%;
    position: relative; }
    .table.calendar thead th::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 80%;
      background-color: #fff; }
    .table.calendar thead th:first-of-type::before {
      display: none; }
  .table.calendar tbody tr:last-of-type th, .table.calendar tbody tr:last-of-type td {
    border-bottom: none; }
  .table.calendar tbody th, .table.calendar tbody td {
    border: none;
    border-bottom: 1px solid #707070; }
    @media screen and (min-width: 640px) {
      .table.calendar tbody th, .table.calendar tbody td {
        font-size: 100%;
        padding: 12px 10px; } }
    @media screen and (max-width: 639px) {
      .table.calendar tbody th, .table.calendar tbody td {
        font-size: 87%;
        padding: 4px 10px; } }
  .table.calendar tbody th {
    background-color: #F3F3F3;
    border-bottom: none; }
    @media screen and (min-width: 640px) {
      .table.calendar tbody th {
        width: 200px; } }
  .table.calendar tbody td {
    text-align: center; }
    .table.calendar tbody td:nth-child(odd) {
      background-color: #F3F3F3; }
    @media screen and (min-width: 640px) {
      .table.calendar tbody td {
        font-size: 160%; } }
    @media screen and (max-width: 639px) {
      .table.calendar tbody td {
        font-size: 120%; } }
    .table.calendar tbody td img {
      vertical-align: middle; }
      @media screen and (min-width: 640px) {
        .table.calendar tbody td img {
          width: 60%; } }
      @media screen and (max-width: 639px) {
        .table.calendar tbody td img {
          width: 50%; } }

@media screen and (min-width: 640px) {
  .column {
    margin-bottom: 100px; } }

@media screen and (max-width: 639px) {
  .column {
    margin-bottom: 30px; } }

@media screen and (min-width: 640px) {
  .column:last-of-type {
    margin-bottom: 0px; } }

@media screen and (min-width: 640px) {
  .column__item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: stretch;
    align-items: stretch; } }

@media screen and (min-width: 640px) {
  .column__item.reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse; } }

@media screen and (max-width: 639px) {
  .column__item > * {
    margin: 30px auto 0; } }

@media screen and (min-width: 640px) {
  .column .content {
    width: 47%; } }

.column .content__lead {
  font-size: 133%;
  line-height: 2.5; }
  @media screen and (max-width: 639px) {
    .column .content__lead {
      font-size: 100%;
      line-height: 2; } }

@media screen and (min-width: 640px) {
  .column .image {
    width: 47%; } }

.column .row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start; }
  .column .row .cell {
    width: 50%; }

.sub_section__head {
  margin-bottom: 50px; }

.sub_section__label {
  font-size: 287%;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  border-bottom: 1px solid #707070;
  margin-bottom: 10px;
  letter-spacing: .05em; }

.sub_section__heading {
  font-size: 100%;
  font-weight: 500;
  letter-spacing: .1em; }

/* Scope */
/*

attractive page

*/
@media screen and (min-width: 640px) {
  body.attractive .page_navi ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between; } }

@media screen and (min-width: 640px) {
  body.attractive .page_navi ul li {
    width: 23%; } }

@media screen and (max-width: 639px) {
  body.attractive .page_navi ul li {
    margin-bottom: 20px; } }

body.attractive .page_navi ul li a {
  position: relative;
  display: block;
  text-align: center;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  color: #333333;
  letter-spacing: 1px;
  padding: 30px 0 10px;
  border-bottom: 1px solid #707070; }
  @media screen and (min-width: 640px) {
    body.attractive .page_navi ul li a {
      font-size: 133%; } }
  @media screen and (max-width: 639px) {
    body.attractive .page_navi ul li a {
      font-size: 133%; } }
  body.attractive .page_navi ul li a::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 7px 0 7px;
    border-color: #2F6CB5 transparent transparent transparent;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -7px;
    transition: top .2s; }
  body.attractive .page_navi ul li a:hover {
    color: #2F6CB5;
    border-bottom-color: #2F6CB5; }
    body.attractive .page_navi ul li a:hover::before {
      top: 7px; }

body.attractive .page_main_image {
  height: 100vh; }
  @media screen and (min-width: 640px) {
    body.attractive .page_main_image {
      background: url(../img/attractive/hero_01.jpg) no-repeat center center;
      background-size: cover;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.attractive .page_main_image {
      background: url(../img/attractive/hero_01_sp.jpg) no-repeat center center;
      background-size: cover;
      max-height: 560px; } }
  body.attractive .page_main_image .elem_lead {
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    display: inline-block;
    padding: 10px 30px; }
    @media screen and (min-width: 640px) {
      body.attractive .page_main_image .elem_lead {
        font-size: 320%;
        font-weight: 600;
        letter-spacing: 5px; } }
    @media screen and (max-width: 639px) {
      body.attractive .page_main_image .elem_lead {
        font-size: 220%;
        font-weight: 700;
        letter-spacing: 3px; } }
    @media screen and (min-width: 640px) {
      body.attractive .page_main_image .elem_lead span {
        font-size: 80%; } }
    @media screen and (max-width: 639px) {
      body.attractive .page_main_image .elem_lead span {
        font-size: 73%; } }
  @media screen and (min-width: 640px) {
    body.attractive .page_main_image .elem_title .jp b {
      font-size: 447%;
      font-weight: 600;
      letter-spacing: 5px; } }
  @media screen and (max-width: 639px) {
    body.attractive .page_main_image .elem_title .jp b {
      font-size: 193%;
      font-weight: 700;
      letter-spacing: 3px; } }
  @media screen and (min-width: 640px) {
    body.attractive .page_main_image .elem_copy {
      font-size: 100%;
      letter-spacing: 3px;
      font-weight: 300;
      line-height: 2;
      margin-top: 20px; } }
  @media screen and (max-width: 639px) {
    body.attractive .page_main_image .elem_copy {
      font-size: 93%; } }

body.attractive .page_guide {
  background-image: url(../img/attractive/bg_01.jpg);
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-position: center top;
  color: #fff; }
  @media screen and (max-width: 639px) {
    body.attractive .page_guide {
      background-image: url(../img/attractive/bg_01_sp.jpg);
      padding-top: 35px;
      padding-bottom: 35px; } }
  body.attractive .page_guide .headline__primary .en {
    border-bottom-color: #fff; }
  @media screen and (min-width: 640px) {
    body.attractive .page_guide__list {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-align-items: flex-end;
      align-items: flex-end; } }
  @media screen and (min-width: 640px) {
    body.attractive .page_guide__item {
      box-sizing: border-box;
      padding: 0 3%;
      width: 50%;
      margin-bottom: 50px; } }
  @media screen and (max-width: 639px) {
    body.attractive .page_guide__item {
      margin-bottom: 60px; } }
  body.attractive .page_guide__item .image {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 639px) {
      body.attractive .page_guide__item .image {
        padding: 0 25px; } }
    body.attractive .page_guide__item .image img {
      max-width: 100%;
      vertical-align: middle; }
  body.attractive .page_guide__item .title {
    margin-top: 30px; }
    body.attractive .page_guide__item .title .main {
      font-weight: 600; }
      @media screen and (min-width: 640px) {
        body.attractive .page_guide__item .title .main {
          font-size: 173%; } }
      @media screen and (max-width: 639px) {
        body.attractive .page_guide__item .title .main {
          font-size: 140%; } }
    body.attractive .page_guide__item .title .sub {
      letter-spacing: 1px;
      margin-left: 5px; }
  body.attractive .page_guide__item .content {
    margin-top: 10px;
    line-height: 1.8; }
    @media screen and (min-width: 640px) {
      body.attractive .page_guide__item .content {
        letter-spacing: 1px; } }

@media screen and (min-width: 640px) {
  body.attractive .page_dishes__list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; } }

@media screen and (min-width: 640px) {
  body.attractive .page_dishes__item {
    box-sizing: border-box;
    width: 46%;
    margin-right: 8%;
    margin-bottom: 30px; } }

@media screen and (max-width: 639px) {
  body.attractive .page_dishes__item {
    margin-bottom: 40px; } }

body.attractive .page_dishes__item:nth-child(2n) {
  margin-right: 0; }

body.attractive .page_dishes__item .video {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 60%; }
  body.attractive .page_dishes__item .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

body.attractive .page_dishes__item .title {
  margin-top: 15px;
  font-weight: 600; }
  @media screen and (min-width: 640px) {
    body.attractive .page_dishes__item .title {
      font-size: 120%; } }
  @media screen and (max-width: 639px) {
    body.attractive .page_dishes__item .title {
      font-size: 120%; } }

/*

error404

*/
body.error404 .page_main_image {
  height: 100vh;
  background-size: cover; }
  @media screen and (min-width: 640px) {
    body.error404 .page_main_image {
      background: url(../img/error_404/hero_01.jpg) no-repeat center top;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.error404 .page_main_image {
      background: url(../img/error_404/hero_01_sp.jpg) no-repeat center top;
      max-height: 560px; } }
  body.error404 .page_main_image .elem_title .en b {
    font-family: "Lato", sans-serif; }
    @media screen and (min-width: 640px) {
      body.error404 .page_main_image .elem_title .en b {
        font-size: 533%;
        font-weight: 600;
        letter-spacing: 5px; } }
    @media screen and (max-width: 639px) {
      body.error404 .page_main_image .elem_title .en b {
        font-size: 300%;
        font-weight: 700;
        letter-spacing: 3px; } }

body.error404 .page_error {
  text-align: center; }
  body.error404 .page_error__title {
    font-size: 213%;
    letter-spacing: 3px;
    line-height: 2; }
    @media screen and (max-width: 639px) {
      body.error404 .page_error__title {
        font-size: 147%;
        letter-spacing: 2px;
        line-height: 1.7; } }
  body.error404 .page_error__lead {
    margin-top: 35px;
    font-size: 107%;
    line-height: 2;
    letter-spacing: 1px; }
    @media screen and (max-width: 639px) {
      body.error404 .page_error__lead {
        font-size: 100%;
        margin-top: 25px;
        text-align: left; } }
  body.error404 .page_error__btn {
    margin-top: 85px; }
    @media screen and (max-width: 639px) {
      body.error404 .page_error__btn {
        margin-top: 40px;
        margin-bottom: 40px; } }

/*

error503

*/
body.error503 .page_main_image {
  height: 100vh;
  background-size: cover; }
  @media screen and (min-width: 640px) {
    body.error503 .page_main_image {
      background: url(../img/error_503/hero_01.jpg) no-repeat center center;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.error503 .page_main_image {
      background: url(../img/error_503/hero_01_sp.jpg) no-repeat center top;
      max-height: 560px; } }
  body.error503 .page_main_image .elem_title .en b {
    font-family: "Lato", sans-serif; }
    @media screen and (min-width: 640px) {
      body.error503 .page_main_image .elem_title .en b {
        font-size: 533%;
        font-weight: 600;
        letter-spacing: 5px; } }
    @media screen and (max-width: 639px) {
      body.error503 .page_main_image .elem_title .en b {
        font-size: 300%;
        font-weight: 700;
        letter-spacing: 3px; } }
  @media screen and (min-width: 640px) {
    body.error503 .page_main_image .elem_title .en span {
      font-size: 107%;
      letter-spacing: 2px; } }
  @media screen and (max-width: 639px) {
    body.error503 .page_main_image .elem_title .en span {
      font-size: 73%;
      letter-spacing: 1px; } }

body.error503 .page_error {
  text-align: center; }
  body.error503 .page_error__title {
    font-size: 213%;
    letter-spacing: 3px;
    line-height: 2; }
    @media screen and (max-width: 639px) {
      body.error503 .page_error__title {
        font-size: 147%;
        letter-spacing: 1px;
        line-height: 1.7; } }
  body.error503 .page_error__lead {
    margin-top: 35px;
    font-size: 107%;
    line-height: 2;
    letter-spacing: 1px; }
    @media screen and (max-width: 639px) {
      body.error503 .page_error__lead {
        font-size: 100%;
        margin-top: 25px;
        text-align: left; } }
  body.error503 .page_error__btn {
    margin-top: 85px; }
    @media screen and (max-width: 639px) {
      body.error503 .page_error__btn {
        margin-top: 40px;
        margin-bottom: 40px; } }

/*

faq page

*/
body.faq .page_main_image {
  height: 100vh; }
  @media screen and (min-width: 640px) {
    body.faq .page_main_image {
      background: url(../img/faq/hero_01.jpg) no-repeat center center;
      background-size: cover;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.faq .page_main_image {
      background: url(../img/faq/hero_01_sp.jpg) no-repeat center center;
      background-size: cover;
      max-height: 560px; } }
  body.faq .page_main_image .elem_title .jp b {
    font-family: "Lato", sans-serif; }
    @media screen and (min-width: 640px) {
      body.faq .page_main_image .elem_title .jp b {
        font-size: 533%;
        font-weight: 600;
        letter-spacing: 5px; } }
    @media screen and (max-width: 639px) {
      body.faq .page_main_image .elem_title .jp b {
        font-size: 447%;
        font-weight: 700;
        letter-spacing: 3px; } }
  @media screen and (min-width: 640px) {
    body.faq .page_main_image .elem_copy {
      font-size: 100%;
      letter-spacing: 3px;
      font-weight: 300;
      line-height: 2;
      margin-top: 20px; } }
  @media screen and (max-width: 639px) {
    body.faq .page_main_image .elem_copy {
      font-size: 120%;
      font-weight: 300;
      letter-spacing: 1px; } }

@media screen and (max-width: 639px) {
  body.faq .page_faq {
    margin-bottom: 40px; } }

body.faq .page_faq__list {
  border-bottom: 1px solid #060606; }

body.faq .page_faq__item {
  line-height: 1.8; }
  body.faq .page_faq__item.selected .page_faq__q .toggle_handler::after {
    height: 0; }

body.faq .page_faq__q {
  position: relative;
  border-top: 1px solid #060606;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600; }
  @media screen and (min-width: 640px) {
    body.faq .page_faq__q {
      padding: 5px 40px; } }
  @media screen and (max-width: 639px) {
    body.faq .page_faq__q {
      padding: 10px 40px 10px 10px; } }
  body.faq .page_faq__q span {
    display: block; }
    @media screen and (min-width: 640px) {
      body.faq .page_faq__q span {
        font-size: 153%; } }
    @media screen and (max-width: 639px) {
      body.faq .page_faq__q span {
        font-size: 100%; } }
  body.faq .page_faq__q::before {
    content: "Q";
    display: inline-block;
    vertical-align: middle;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: #060606; }
    @media screen and (min-width: 640px) {
      body.faq .page_faq__q::before {
        font-size: 307%;
        margin-right: 40px; } }
    @media screen and (max-width: 639px) {
      body.faq .page_faq__q::before {
        font-size: 200%;
        margin-right: 10px; } }
  body.faq .page_faq__q .toggle_handler {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }
    @media screen and (min-width: 640px) {
      body.faq .page_faq__q .toggle_handler {
        width: 30px;
        height: 30px;
        right: 40px; } }
    @media screen and (max-width: 639px) {
      body.faq .page_faq__q .toggle_handler {
        width: 15px;
        height: 15px;
        right: 15px; } }
    body.faq .page_faq__q .toggle_handler:before, body.faq .page_faq__q .toggle_handler:after {
      content: "";
      background-color: #060606;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto; }
    @media screen and (min-width: 640px) {
      body.faq .page_faq__q .toggle_handler:before {
        width: 30px;
        height: 3px; } }
    @media screen and (max-width: 639px) {
      body.faq .page_faq__q .toggle_handler:before {
        width: 14px;
        height: 2px; } }
    body.faq .page_faq__q .toggle_handler:after {
      -webkit-transition: height 500ms ease;
      -moz-transition: height 500ms ease;
      -o-transition: height 500ms ease;
      transition: height 500ms ease; }
      @media screen and (min-width: 640px) {
        body.faq .page_faq__q .toggle_handler:after {
          width: 3px;
          height: 30px; } }
      @media screen and (max-width: 639px) {
        body.faq .page_faq__q .toggle_handler:after {
          width: 2px;
          height: 15px; } }

body.faq .page_faq__a {
  border-top: 1px solid #060606;
  letter-spacing: 1px;
  background-color: #F0F0F0;
  display: flex;
  align-items: center; }
  @media screen and (min-width: 640px) {
    body.faq .page_faq__a {
      padding: 5px 40px;
      font-size: 100%; } }
  @media screen and (max-width: 639px) {
    body.faq .page_faq__a {
      padding: 10px 20px 10px 10px;
      font-size: 87%; } }
  body.faq .page_faq__a::before {
    content: "A";
    display: inline-block;
    vertical-align: middle;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: #2F6CB5; }
    @media screen and (min-width: 640px) {
      body.faq .page_faq__a::before {
        font-size: 307%;
        margin-right: 40px; } }
    @media screen and (max-width: 639px) {
      body.faq .page_faq__a::before {
        font-size: 200%;
        margin-right: 10px; } }
  body.faq .page_faq__a span {
    display: block; }

/*

information page

*/
body.blog .page_main_image {
  height: 100vh; }
  @media screen and (min-width: 640px) {
    body.blog .page_main_image {
      background: url(../img/information/hero_01.jpg) no-repeat center center;
      background-size: cover;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.blog .page_main_image {
      background: url(../img/information/hero_01_sp.jpg) no-repeat center center;
      background-size: cover;
      max-height: 560px; } }
  @media screen and (min-width: 640px) {
    body.blog .page_main_image .elem_title .jp b {
      font-size: 533%;
      font-weight: 600;
      letter-spacing: 5px; } }
  @media screen and (max-width: 639px) {
    body.blog .page_main_image .elem_title .jp b {
      font-size: 300%;
      font-weight: 700;
      letter-spacing: 3px; } }

body.blog .page_content .archive {
  line-height: 1.75;
  border-top: 1px solid #707070; }
  @media screen and (min-width: 640px) {
    body.blog .page_content .archive {
      font-size: 133%;
      padding: 35px 2px;
      display: flex; } }
  @media screen and (max-width: 639px) {
    body.blog .page_content .archive {
      font-size: 100%;
      padding-top: 12px;
      padding-bottom: 12px; } }
  body.blog .page_content .archive__time {
    font-weight: 700; }
    @media screen and (min-width: 640px) {
      body.blog .page_content .archive__time {
        margin-top: 0;
        margin-right: 50px;
        min-width: 150px;
        width: 15%; } }
    @media screen and (max-width: 639px) {
      body.blog .page_content .archive__time {
        font-size: 133%; } }
  body.blog .page_content .archive__contents {
    line-height: 2; }

/*

location page

*/
body.kobe .page_content,
body.kix .page_content,
body.zest .page_content,
body.yamatoda .page_content,
body.tokyo .page_content {
  margin-bottom: 50px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_content,
    body.kix .page_content,
    body.zest .page_content,
    body.yamatoda .page_content,
    body.tokyo .page_content {
      margin-bottom: 45px; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_content > *,
    body.kix .page_content > *,
    body.zest .page_content > *,
    body.yamatoda .page_content > *,
    body.tokyo .page_content > * {
      margin-top: 0; } }

body.kobe .page_section,
body.kix .page_section,
body.zest .page_section,
body.yamatoda .page_section,
body.tokyo .page_section {
  padding-top: 45px;
  padding-bottom: 45px; }
  body.kobe .page_section:first-child,
  body.kix .page_section:first-child,
  body.zest .page_section:first-child,
  body.yamatoda .page_section:first-child,
  body.tokyo .page_section:first-child {
    padding-top: 100px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_section:first-child,
      body.kix .page_section:first-child,
      body.zest .page_section:first-child,
      body.yamatoda .page_section:first-child,
      body.tokyo .page_section:first-child {
        padding-top: 50px; } }
  body.kobe .page_section:last-child,
  body.kix .page_section:last-child,
  body.zest .page_section:last-child,
  body.yamatoda .page_section:last-child,
  body.tokyo .page_section:last-child {
    padding-bottom: 0; }
  @media screen and (max-width: 639px) {
    body.kobe .page_section,
    body.kix .page_section,
    body.zest .page_section,
    body.yamatoda .page_section,
    body.tokyo .page_section {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_section__content,
    body.kix .page_section__content,
    body.zest .page_section__content,
    body.yamatoda .page_section__content,
    body.tokyo .page_section__content {
      width: 38%; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_section__thumb,
    body.kix .page_section__thumb,
    body.zest .page_section__thumb,
    body.yamatoda .page_section__thumb,
    body.tokyo .page_section__thumb {
      width: 48%; } }

body.kobe .page_concept__image,
body.kix .page_concept__image,
body.zest .page_concept__image,
body.yamatoda .page_concept__image,
body.tokyo .page_concept__image {
  position: relative; }
  @media screen and (min-width: 640px) {
    body.kobe .page_concept__image .image--01,
    body.kix .page_concept__image .image--01,
    body.zest .page_concept__image .image--01,
    body.yamatoda .page_concept__image .image--01,
    body.tokyo .page_concept__image .image--01 {
      width: 72%;
      margin: 0 0 0 auto;
      position: relative;
      z-index: 2; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_concept__image .image--02,
    body.kix .page_concept__image .image--02,
    body.zest .page_concept__image .image--02,
    body.yamatoda .page_concept__image .image--02,
    body.tokyo .page_concept__image .image--02 {
      z-index: 1;
      width: 51%;
      margin-top: -8vw; } }

body.kobe .page_concept .heading_lv2,
body.kix .page_concept .heading_lv2,
body.zest .page_concept .heading_lv2,
body.yamatoda .page_concept .heading_lv2,
body.tokyo .page_concept .heading_lv2 {
  margin-bottom: 40px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_concept .heading_lv2,
    body.kix .page_concept .heading_lv2,
    body.zest .page_concept .heading_lv2,
    body.yamatoda .page_concept .heading_lv2,
    body.tokyo .page_concept .heading_lv2 {
      margin-bottom: 30px; } }

@media screen and (max-width: 639px) {
  body.kobe .page_staff,
  body.kix .page_staff,
  body.zest .page_staff,
  body.yamatoda .page_staff,
  body.tokyo .page_staff {
    margin-top: 0 !important; } }

@media screen and (min-width: 640px) {
  body.kobe .page_staff__img,
  body.kix .page_staff__img,
  body.zest .page_staff__img,
  body.yamatoda .page_staff__img,
  body.tokyo .page_staff__img {
    width: calc(100% - 9%); } }

@media screen and (max-width: 639px) {
  body.kobe .page_staff__img,
  body.kix .page_staff__img,
  body.zest .page_staff__img,
  body.yamatoda .page_staff__img,
  body.tokyo .page_staff__img {
    margin-top: 20px; } }

body.kobe .page_staff__inner,
body.kix .page_staff__inner,
body.zest .page_staff__inner,
body.yamatoda .page_staff__inner,
body.tokyo .page_staff__inner {
  color: #fff;
  background-color: rgba(47, 108, 181, 0.89);
  padding: 30px 20px; }
  @media screen and (min-width: 640px) {
    body.kobe .page_staff__inner,
    body.kix .page_staff__inner,
    body.zest .page_staff__inner,
    body.yamatoda .page_staff__inner,
    body.tokyo .page_staff__inner {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 55px 9% 60px 70px;
      width: 50%;
      position: relative;
      left: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-bottom: -180px; } }

body.kobe .page_staff__title .elem_shoulder,
body.kix .page_staff__title .elem_shoulder,
body.zest .page_staff__title .elem_shoulder,
body.yamatoda .page_staff__title .elem_shoulder,
body.tokyo .page_staff__title .elem_shoulder {
  font-size: 187%;
  letter-spacing: .06em;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_staff__title .elem_shoulder,
    body.kix .page_staff__title .elem_shoulder,
    body.zest .page_staff__title .elem_shoulder,
    body.yamatoda .page_staff__title .elem_shoulder,
    body.tokyo .page_staff__title .elem_shoulder {
      font-size: 93%;
      font-weight: 400; } }

body.kobe .page_staff__title .elem_main,
body.kix .page_staff__title .elem_main,
body.zest .page_staff__title .elem_main,
body.yamatoda .page_staff__title .elem_main,
body.tokyo .page_staff__title .elem_main {
  font-size: 393%;
  margin-right: 15px;
  line-height: 1;
  margin-top: 25px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_staff__title .elem_main,
    body.kix .page_staff__title .elem_main,
    body.zest .page_staff__title .elem_main,
    body.yamatoda .page_staff__title .elem_main,
    body.tokyo .page_staff__title .elem_main {
      font-size: 173%;
      margin-top: 15px; } }

body.kobe .page_staff__title .elem_bottom,
body.kix .page_staff__title .elem_bottom,
body.zest .page_staff__title .elem_bottom,
body.yamatoda .page_staff__title .elem_bottom,
body.tokyo .page_staff__title .elem_bottom {
  font-size: 133%;
  font-weight: normal;
  letter-spacing: .25em; }
  @media screen and (max-width: 639px) {
    body.kobe .page_staff__title .elem_bottom,
    body.kix .page_staff__title .elem_bottom,
    body.zest .page_staff__title .elem_bottom,
    body.yamatoda .page_staff__title .elem_bottom,
    body.tokyo .page_staff__title .elem_bottom {
      font-size: 80%;
      font-weight: 500;
      margin-top: 10px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_staff__title .elem_shoulder,
  body.kix .page_staff__title .elem_shoulder,
  body.zest .page_staff__title .elem_shoulder,
  body.yamatoda .page_staff__title .elem_shoulder,
  body.tokyo .page_staff__title .elem_shoulder {
    font-size: 187%;
    letter-spacing: .06em;
    font-weight: 500;
    margin-bottom: 10px; }
  body.kobe .page_staff__title .elem_main,
  body.kix .page_staff__title .elem_main,
  body.zest .page_staff__title .elem_main,
  body.yamatoda .page_staff__title .elem_main,
  body.tokyo .page_staff__title .elem_main {
    font-size: 393%;
    margin-right: 25px;
    font-weight: 500; }
  body.kobe .page_staff__title .elem_bottom,
  body.kix .page_staff__title .elem_bottom,
  body.zest .page_staff__title .elem_bottom,
  body.yamatoda .page_staff__title .elem_bottom,
  body.tokyo .page_staff__title .elem_bottom {
    font-size: 133%;
    font-weight: normal;
    margin-top: 15px;
    letter-spacing: .16em; } }

@media screen and (max-width: 639px) {
  body.kobe .page_staff__contents,
  body.kix .page_staff__contents,
  body.zest .page_staff__contents,
  body.yamatoda .page_staff__contents,
  body.tokyo .page_staff__contents {
    font-size: 87%;
    margin-top: 10px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_staff__contents,
  body.kix .page_staff__contents,
  body.zest .page_staff__contents,
  body.yamatoda .page_staff__contents,
  body.tokyo .page_staff__contents {
    margin-top: 30px; } }

body.kobe .page_staff__contents p,
body.kix .page_staff__contents p,
body.zest .page_staff__contents p,
body.yamatoda .page_staff__contents p,
body.tokyo .page_staff__contents p {
  line-height: 2.1; }

body.kobe .page_staff_group .page_staff,
body.kix .page_staff_group .page_staff,
body.zest .page_staff_group .page_staff,
body.yamatoda .page_staff_group .page_staff,
body.tokyo .page_staff_group .page_staff {
  position: relative; }
  body.kobe .page_staff_group .page_staff:not(:last-child),
  body.kix .page_staff_group .page_staff:not(:last-child),
  body.zest .page_staff_group .page_staff:not(:last-child),
  body.yamatoda .page_staff_group .page_staff:not(:last-child),
  body.tokyo .page_staff_group .page_staff:not(:last-child) {
    margin-bottom: 90px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_staff_group .page_staff:not(:last-child),
      body.kix .page_staff_group .page_staff:not(:last-child),
      body.zest .page_staff_group .page_staff:not(:last-child),
      body.yamatoda .page_staff_group .page_staff:not(:last-child),
      body.tokyo .page_staff_group .page_staff:not(:last-child) {
        margin-bottom: 30px; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_staff_group .page_staff__img,
    body.kix .page_staff_group .page_staff__img,
    body.zest .page_staff_group .page_staff__img,
    body.yamatoda .page_staff_group .page_staff__img,
    body.tokyo .page_staff_group .page_staff__img {
      position: relative;
      left: 9%;
      width: 37%; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_staff_group .page_staff__img,
    body.kix .page_staff_group .page_staff__img,
    body.zest .page_staff_group .page_staff__img,
    body.yamatoda .page_staff_group .page_staff__img,
    body.tokyo .page_staff_group .page_staff__img {
      margin-top: 0; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_staff_group .page_staff__inner,
    body.kix .page_staff_group .page_staff__inner,
    body.zest .page_staff_group .page_staff__inner,
    body.yamatoda .page_staff_group .page_staff__inner,
    body.tokyo .page_staff_group .page_staff__inner {
      position: absolute;
      top: 110px;
      left: 40%;
      width: 60%;
      transform: translateY(0); } }

body.kobe .page_field__content,
body.kix .page_field__content,
body.zest .page_field__content,
body.yamatoda .page_field__content,
body.tokyo .page_field__content {
  margin-bottom: 90px; }
  @media screen and (min-width: 640px) {
    body.kobe .page_field__content,
    body.kix .page_field__content,
    body.zest .page_field__content,
    body.yamatoda .page_field__content,
    body.tokyo .page_field__content {
      position: relative; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_field__content,
    body.kix .page_field__content,
    body.zest .page_field__content,
    body.yamatoda .page_field__content,
    body.tokyo .page_field__content {
      margin-bottom: 25px; } }

body.kobe .page_field .heading_lv1,
body.kix .page_field .heading_lv1,
body.zest .page_field .heading_lv1,
body.yamatoda .page_field .heading_lv1,
body.tokyo .page_field .heading_lv1 {
  font-size: 300%;
  font-weight: 600; }
  @media screen and (max-width: 639px) {
    body.kobe .page_field .heading_lv1,
    body.kix .page_field .heading_lv1,
    body.zest .page_field .heading_lv1,
    body.yamatoda .page_field .heading_lv1,
    body.tokyo .page_field .heading_lv1 {
      font-size: 167%; } }

@media screen and (max-width: 639px) {
  body.kobe .page_field .page_section__img,
  body.kix .page_field .page_section__img,
  body.zest .page_field .page_section__img,
  body.yamatoda .page_field .page_section__img,
  body.tokyo .page_field .page_section__img {
    margin-top: 20px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_field .map_img,
  body.kix .page_field .map_img,
  body.zest .page_field .map_img,
  body.yamatoda .page_field .map_img,
  body.tokyo .page_field .map_img {
    position: absolute;
    bottom: -90px;
    right: 0;
    width: 427px; } }

@media screen and (max-width: 639px) {
  body.kobe .page_field .map_img,
  body.kix .page_field .map_img,
  body.zest .page_field .map_img,
  body.yamatoda .page_field .map_img,
  body.tokyo .page_field .map_img {
    width: 100%;
    margin-top: 20px;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box; } }

body.kobe .page_field .map_img img,
body.kix .page_field .map_img img,
body.zest .page_field .map_img img,
body.yamatoda .page_field .map_img img,
body.tokyo .page_field .map_img img {
  display: block;
  width: 100%; }

body.kobe .page_feature .page_section__column,
body.kix .page_feature .page_section__column,
body.zest .page_feature .page_section__column,
body.yamatoda .page_feature .page_section__column,
body.tokyo .page_feature .page_section__column {
  margin-top: 60px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_feature .page_section__column,
    body.kix .page_feature .page_section__column,
    body.zest .page_feature .page_section__column,
    body.yamatoda .page_feature .page_section__column,
    body.tokyo .page_feature .page_section__column {
      margin-top: 20px; } }
  body.kobe .page_feature .page_section__column > div:last-of-type,
  body.kix .page_feature .page_section__column > div:last-of-type,
  body.zest .page_feature .page_section__column > div:last-of-type,
  body.yamatoda .page_feature .page_section__column > div:last-of-type,
  body.tokyo .page_feature .page_section__column > div:last-of-type {
    margin-top: 100px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_feature .page_section__column > div:last-of-type,
      body.kix .page_feature .page_section__column > div:last-of-type,
      body.zest .page_feature .page_section__column > div:last-of-type,
      body.yamatoda .page_feature .page_section__column > div:last-of-type,
      body.tokyo .page_feature .page_section__column > div:last-of-type {
        margin-top: 0; } }

body.kobe .page_feature .heading_lv1,
body.kix .page_feature .heading_lv1,
body.zest .page_feature .heading_lv1,
body.yamatoda .page_feature .heading_lv1,
body.tokyo .page_feature .heading_lv1 {
  font-size: 300%;
  font-weight: 600; }
  @media screen and (max-width: 639px) {
    body.kobe .page_feature .heading_lv1,
    body.kix .page_feature .heading_lv1,
    body.zest .page_feature .heading_lv1,
    body.yamatoda .page_feature .heading_lv1,
    body.tokyo .page_feature .heading_lv1 {
      font-size: 167%;
      letter-spacing: 1px; } }

body.kobe .page_feature_img_gap,
body.kix .page_feature_img_gap,
body.zest .page_feature_img_gap,
body.yamatoda .page_feature_img_gap,
body.tokyo .page_feature_img_gap {
  display: flex;
  flex-wrap: wrap;
  gap: 80px 32px;
  width: 82%;
  margin: 85px auto 0; }
  @media screen and (max-width: 639px) {
    body.kobe .page_feature_img_gap,
    body.kix .page_feature_img_gap,
    body.zest .page_feature_img_gap,
    body.yamatoda .page_feature_img_gap,
    body.tokyo .page_feature_img_gap {
      display: block;
      width: 92%;
      margin-top: 40px; } }
  body.kobe .page_feature_img_gap div,
  body.kix .page_feature_img_gap div,
  body.zest .page_feature_img_gap div,
  body.yamatoda .page_feature_img_gap div,
  body.tokyo .page_feature_img_gap div {
    max-width: calc((100% - 32px)/ 2); }
    @media screen and (max-width: 639px) {
      body.kobe .page_feature_img_gap div,
      body.kix .page_feature_img_gap div,
      body.zest .page_feature_img_gap div,
      body.yamatoda .page_feature_img_gap div,
      body.tokyo .page_feature_img_gap div {
        max-width: 100%;
        width: 100%; }
        body.kobe .page_feature_img_gap div:not(:last-child),
        body.kix .page_feature_img_gap div:not(:last-child),
        body.zest .page_feature_img_gap div:not(:last-child),
        body.yamatoda .page_feature_img_gap div:not(:last-child),
        body.tokyo .page_feature_img_gap div:not(:last-child) {
          margin-bottom: 20px; } }
    body.kobe .page_feature_img_gap div span,
    body.kix .page_feature_img_gap div span,
    body.zest .page_feature_img_gap div span,
    body.yamatoda .page_feature_img_gap div span,
    body.tokyo .page_feature_img_gap div span {
      font-size: 15px;
      margin-top: 20px;
      display: block; }
      @media screen and (max-width: 639px) {
        body.kobe .page_feature_img_gap div span,
        body.kix .page_feature_img_gap div span,
        body.zest .page_feature_img_gap div span,
        body.yamatoda .page_feature_img_gap div span,
        body.tokyo .page_feature_img_gap div span {
          margin-top: 10px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_cafe__column,
  body.kix .page_cafe__column,
  body.zest .page_cafe__column,
  body.yamatoda .page_cafe__column,
  body.tokyo .page_cafe__column {
    margin-bottom: 70px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_cafe__column__inner,
  body.kix .page_cafe__column__inner,
  body.zest .page_cafe__column__inner,
  body.yamatoda .page_cafe__column__inner,
  body.tokyo .page_cafe__column__inner {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start; } }

@media screen and (max-width: 639px) {
  body.kobe .page_cafe__column.reverse,
  body.kix .page_cafe__column.reverse,
  body.zest .page_cafe__column.reverse,
  body.yamatoda .page_cafe__column.reverse,
  body.tokyo .page_cafe__column.reverse {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse; } }

@media screen and (min-width: 640px) {
  body.kobe .page_cafe__text,
  body.kix .page_cafe__text,
  body.zest .page_cafe__text,
  body.yamatoda .page_cafe__text,
  body.tokyo .page_cafe__text {
    width: 36%;
    padding-top: 50px;
    border-top: 1px solid #707070; } }

@media screen and (min-width: 640px) {
  body.kobe .page_cafe__image,
  body.kix .page_cafe__image,
  body.zest .page_cafe__image,
  body.yamatoda .page_cafe__image,
  body.tokyo .page_cafe__image {
    width: 57%; } }

@media screen and (min-width: 640px) {
  body.kobe .page_cafe__cell,
  body.kix .page_cafe__cell,
  body.zest .page_cafe__cell,
  body.yamatoda .page_cafe__cell,
  body.tokyo .page_cafe__cell {
    width: 48.5%; } }

@media screen and (max-width: 639px) {
  body.kobe .page_cafe__cell,
  body.kix .page_cafe__cell,
  body.zest .page_cafe__cell,
  body.yamatoda .page_cafe__cell,
  body.tokyo .page_cafe__cell {
    margin-top: 20px; } }

body.kobe .page_cafe__lead,
body.kix .page_cafe__lead,
body.zest .page_cafe__lead,
body.yamatoda .page_cafe__lead,
body.tokyo .page_cafe__lead {
  margin: 20px 0 60px;
  font-size: 133%;
  font-weight: 400;
  line-height: 2.5; }
  @media screen and (max-width: 639px) {
    body.kobe .page_cafe__lead,
    body.kix .page_cafe__lead,
    body.zest .page_cafe__lead,
    body.yamatoda .page_cafe__lead,
    body.tokyo .page_cafe__lead {
      font-size: 100%;
      margin: 20px 0 0; } }

body.kobe .page_cafe__caption,
body.kix .page_cafe__caption,
body.zest .page_cafe__caption,
body.yamatoda .page_cafe__caption,
body.tokyo .page_cafe__caption {
  margin-top: 20px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_cafe__caption,
    body.kix .page_cafe__caption,
    body.zest .page_cafe__caption,
    body.yamatoda .page_cafe__caption,
    body.tokyo .page_cafe__caption {
      margin-top: 10px; } }

body.kobe .page_cafe__info,
body.kix .page_cafe__info,
body.zest .page_cafe__info,
body.yamatoda .page_cafe__info,
body.tokyo .page_cafe__info {
  border: #D6D5D5 1px solid;
  font-size: 133%;
  font-weight: 500;
  line-height: 2.5;
  letter-spacing: 1px;
  padding: 20px 30px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_cafe__info,
    body.kix .page_cafe__info,
    body.zest .page_cafe__info,
    body.yamatoda .page_cafe__info,
    body.tokyo .page_cafe__info {
      padding: 10px 15px;
      font-size: 93%;
      line-height: 1.8;
      margin-top: 20px; } }

body.kobe .page_plan__item,
body.kix .page_plan__item,
body.zest .page_plan__item,
body.yamatoda .page_plan__item,
body.tokyo .page_plan__item {
  padding-bottom: 15px; }
  @media screen and (min-width: 640px) {
    body.kobe .page_plan__item,
    body.kix .page_plan__item,
    body.zest .page_plan__item,
    body.yamatoda .page_plan__item,
    body.tokyo .page_plan__item {
      width: 49.5%; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__item,
    body.kix .page_plan__item,
    body.zest .page_plan__item,
    body.yamatoda .page_plan__item,
    body.tokyo .page_plan__item {
      width: 92%;
      margin: 0 auto 25px;
      padding-bottom: 5px; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__item:last-of-type,
    body.kix .page_plan__item:last-of-type,
    body.zest .page_plan__item:last-of-type,
    body.yamatoda .page_plan__item:last-of-type,
    body.tokyo .page_plan__item:last-of-type {
      margin-bottom: 0; } }
  body.kobe .page_plan__item a,
  body.kix .page_plan__item a,
  body.zest .page_plan__item a,
  body.yamatoda .page_plan__item a,
  body.tokyo .page_plan__item a {
    position: relative;
    display: block;
    padding: 110px 0;
    background-position: center;
    background-size: cover;
    font-family: "Heebo", sans-serif;
    font-weight: 600; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__item a,
      body.kix .page_plan__item a,
      body.zest .page_plan__item a,
      body.yamatoda .page_plan__item a,
      body.tokyo .page_plan__item a {
        padding: 50px 0; } }
    body.kobe .page_plan__item a:hover,
    body.kix .page_plan__item a:hover,
    body.zest .page_plan__item a:hover,
    body.yamatoda .page_plan__item a:hover,
    body.tokyo .page_plan__item a:hover {
      text-decoration: none; }
      body.kobe .page_plan__item a:hover .page_plan__btn,
      body.kix .page_plan__item a:hover .page_plan__btn,
      body.zest .page_plan__item a:hover .page_plan__btn,
      body.yamatoda .page_plan__item a:hover .page_plan__btn,
      body.tokyo .page_plan__item a:hover .page_plan__btn {
        background-color: #2F6CB5;
        color: #fff; }
        body.kobe .page_plan__item a:hover .page_plan__btn span::before, body.kobe .page_plan__item a:hover .page_plan__btn span::after,
        body.kix .page_plan__item a:hover .page_plan__btn span::before,
        body.kix .page_plan__item a:hover .page_plan__btn span::after,
        body.zest .page_plan__item a:hover .page_plan__btn span::before,
        body.zest .page_plan__item a:hover .page_plan__btn span::after,
        body.yamatoda .page_plan__item a:hover .page_plan__btn span::before,
        body.yamatoda .page_plan__item a:hover .page_plan__btn span::after,
        body.tokyo .page_plan__item a:hover .page_plan__btn span::before,
        body.tokyo .page_plan__item a:hover .page_plan__btn span::after {
          border-color: #fff; }
    body.kobe .page_plan__item a.shared,
    body.kix .page_plan__item a.shared,
    body.zest .page_plan__item a.shared,
    body.yamatoda .page_plan__item a.shared,
    body.tokyo .page_plan__item a.shared {
      background-image: url(../img/kix/image_plan_01.jpg); }
    body.kobe .page_plan__item a.charter,
    body.kix .page_plan__item a.charter,
    body.zest .page_plan__item a.charter,
    body.yamatoda .page_plan__item a.charter,
    body.tokyo .page_plan__item a.charter {
      background-image: url(../img/kix/image_plan_02.jpg); }
    body.kobe .page_plan__item a.plan--a,
    body.kix .page_plan__item a.plan--a,
    body.zest .page_plan__item a.plan--a,
    body.yamatoda .page_plan__item a.plan--a,
    body.tokyo .page_plan__item a.plan--a {
      background-image: url(../img/kobe/image_plan_01.jpg); }
    body.kobe .page_plan__item a.plan--b,
    body.kix .page_plan__item a.plan--b,
    body.zest .page_plan__item a.plan--b,
    body.yamatoda .page_plan__item a.plan--b,
    body.tokyo .page_plan__item a.plan--b {
      background-image: url(../img/kobe/image_plan_02.jpg); }
    body.kobe .page_plan__item a.plan--c,
    body.kix .page_plan__item a.plan--c,
    body.zest .page_plan__item a.plan--c,
    body.yamatoda .page_plan__item a.plan--c,
    body.tokyo .page_plan__item a.plan--c {
      background-image: url(../img/kobe/image_plan_03.jpg); }
    body.kobe .page_plan__item a.not_available,
    body.kix .page_plan__item a.not_available,
    body.zest .page_plan__item a.not_available,
    body.yamatoda .page_plan__item a.not_available,
    body.tokyo .page_plan__item a.not_available {
      position: relative;
      pointer-events: none;
      overflow: hidden; }
  @media screen and (min-width: 640px) {
    body.kobe .page_plan__item.col3,
    body.kix .page_plan__item.col3,
    body.zest .page_plan__item.col3,
    body.yamatoda .page_plan__item.col3,
    body.tokyo .page_plan__item.col3 {
      width: 31.5%; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__item.col3,
    body.kix .page_plan__item.col3,
    body.zest .page_plan__item.col3,
    body.yamatoda .page_plan__item.col3,
    body.tokyo .page_plan__item.col3 {
      width: 100%;
      margin: 0 auto 20px; } }
  body.kobe .page_plan__item.col3 .page_plan__name span,
  body.kix .page_plan__item.col3 .page_plan__name span,
  body.zest .page_plan__item.col3 .page_plan__name span,
  body.yamatoda .page_plan__item.col3 .page_plan__name span,
  body.tokyo .page_plan__item.col3 .page_plan__name span {
    font-size: 147%;
    font-weight: 500;
    display: block;
    margin-bottom: 5px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__item.col3 .page_plan__name span,
      body.kix .page_plan__item.col3 .page_plan__name span,
      body.zest .page_plan__item.col3 .page_plan__name span,
      body.yamatoda .page_plan__item.col3 .page_plan__name span,
      body.tokyo .page_plan__item.col3 .page_plan__name span {
        font-size: 120%; } }
  body.kobe .page_plan__item.col3 .page_plan__name b,
  body.kix .page_plan__item.col3 .page_plan__name b,
  body.zest .page_plan__item.col3 .page_plan__name b,
  body.yamatoda .page_plan__item.col3 .page_plan__name b,
  body.tokyo .page_plan__item.col3 .page_plan__name b {
    display: inline-block;
    font-size: 187%; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__item.col3 .page_plan__name b,
      body.kix .page_plan__item.col3 .page_plan__name b,
      body.zest .page_plan__item.col3 .page_plan__name b,
      body.yamatoda .page_plan__item.col3 .page_plan__name b,
      body.tokyo .page_plan__item.col3 .page_plan__name b {
        font-size: 160%; } }
  body.kobe .page_plan__item.col3 .page_plan__btn,
  body.kix .page_plan__item.col3 .page_plan__btn,
  body.zest .page_plan__item.col3 .page_plan__btn,
  body.yamatoda .page_plan__item.col3 .page_plan__btn,
  body.tokyo .page_plan__item.col3 .page_plan__btn {
    width: 100%;
    border-bottom: 1px solid #D4D0D0; }
    @media screen and (min-width: 640px) {
      body.kobe .page_plan__item.col3 .page_plan__btn b,
      body.kix .page_plan__item.col3 .page_plan__btn b,
      body.zest .page_plan__item.col3 .page_plan__btn b,
      body.yamatoda .page_plan__item.col3 .page_plan__btn b,
      body.tokyo .page_plan__item.col3 .page_plan__btn b {
        font-size: 120%; } }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__item.col3 .page_plan__btn b,
      body.kix .page_plan__item.col3 .page_plan__btn b,
      body.zest .page_plan__item.col3 .page_plan__btn b,
      body.yamatoda .page_plan__item.col3 .page_plan__btn b,
      body.tokyo .page_plan__item.col3 .page_plan__btn b {
        font-size: 120%; } }

@media screen and (min-width: 640px) {
  body.kobe .page_plan__item--tokyo,
  body.kix .page_plan__item--tokyo,
  body.zest .page_plan__item--tokyo,
  body.yamatoda .page_plan__item--tokyo,
  body.tokyo .page_plan__item--tokyo {
    width: 854px;
    margin: auto; } }

@media screen and (max-width: 639px) {
  body.kobe .page_plan__item--tokyo,
  body.kix .page_plan__item--tokyo,
  body.zest .page_plan__item--tokyo,
  body.yamatoda .page_plan__item--tokyo,
  body.tokyo .page_plan__item--tokyo {
    width: 92%; } }

body.kobe .page_plan__item--tokyo a,
body.kix .page_plan__item--tokyo a,
body.zest .page_plan__item--tokyo a,
body.yamatoda .page_plan__item--tokyo a,
body.tokyo .page_plan__item--tokyo a {
  background-image: url(../img/tokyo/image_plan_02.jpg) !important;
  padding: 40px 0 80px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__item--tokyo a,
    body.kix .page_plan__item--tokyo a,
    body.zest .page_plan__item--tokyo a,
    body.yamatoda .page_plan__item--tokyo a,
    body.tokyo .page_plan__item--tokyo a {
      padding: 50px 0; } }

body.kobe .page_plan__item--tokyo .desc,
body.kix .page_plan__item--tokyo .desc,
body.zest .page_plan__item--tokyo .desc,
body.yamatoda .page_plan__item--tokyo .desc,
body.tokyo .page_plan__item--tokyo .desc {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-top: 15px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__item--tokyo .desc,
    body.kix .page_plan__item--tokyo .desc,
    body.zest .page_plan__item--tokyo .desc,
    body.yamatoda .page_plan__item--tokyo .desc,
    body.tokyo .page_plan__item--tokyo .desc {
      font-size: 13px;
      padding: 0 20px; } }

body.kobe .page_plan .page_section__image .zest_schedule,
body.kobe .page_plan .page_section__image .yamatoda_schedule,
body.kix .page_plan .page_section__image .zest_schedule,
body.kix .page_plan .page_section__image .yamatoda_schedule,
body.zest .page_plan .page_section__image .zest_schedule,
body.zest .page_plan .page_section__image .yamatoda_schedule,
body.yamatoda .page_plan .page_section__image .zest_schedule,
body.yamatoda .page_plan .page_section__image .yamatoda_schedule,
body.tokyo .page_plan .page_section__image .zest_schedule,
body.tokyo .page_plan .page_section__image .yamatoda_schedule {
  background-image: url(../img/kix/image_plan_03.jpg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-position: center;
  background-size: cover;
  font-weight: 600;
  margin: 0 auto; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan .page_section__image .zest_schedule,
    body.kobe .page_plan .page_section__image .yamatoda_schedule,
    body.kix .page_plan .page_section__image .zest_schedule,
    body.kix .page_plan .page_section__image .yamatoda_schedule,
    body.zest .page_plan .page_section__image .zest_schedule,
    body.zest .page_plan .page_section__image .yamatoda_schedule,
    body.yamatoda .page_plan .page_section__image .zest_schedule,
    body.yamatoda .page_plan .page_section__image .yamatoda_schedule,
    body.tokyo .page_plan .page_section__image .zest_schedule,
    body.tokyo .page_plan .page_section__image .yamatoda_schedule {
      background-image: url(../img/kix/image_plan_03_sp.jpg);
      height: auto;
      padding: 55px 0;
      display: block;
      background-size: contain;
      background-repeat: no-repeat; } }
  body.kobe .page_plan .page_section__image .zest_schedule p,
  body.kobe .page_plan .page_section__image .yamatoda_schedule p,
  body.kix .page_plan .page_section__image .zest_schedule p,
  body.kix .page_plan .page_section__image .yamatoda_schedule p,
  body.zest .page_plan .page_section__image .zest_schedule p,
  body.zest .page_plan .page_section__image .yamatoda_schedule p,
  body.yamatoda .page_plan .page_section__image .zest_schedule p,
  body.yamatoda .page_plan .page_section__image .yamatoda_schedule p,
  body.tokyo .page_plan .page_section__image .zest_schedule p,
  body.tokyo .page_plan .page_section__image .yamatoda_schedule p {
    font-size: 193%; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan .page_section__image .zest_schedule p,
      body.kobe .page_plan .page_section__image .yamatoda_schedule p,
      body.kix .page_plan .page_section__image .zest_schedule p,
      body.kix .page_plan .page_section__image .yamatoda_schedule p,
      body.zest .page_plan .page_section__image .zest_schedule p,
      body.zest .page_plan .page_section__image .yamatoda_schedule p,
      body.yamatoda .page_plan .page_section__image .zest_schedule p,
      body.yamatoda .page_plan .page_section__image .yamatoda_schedule p,
      body.tokyo .page_plan .page_section__image .zest_schedule p,
      body.tokyo .page_plan .page_section__image .yamatoda_schedule p {
        font-size: 147%; } }

body.kobe .page_plan .page_section__image .tokyo_schedule,
body.kix .page_plan .page_section__image .tokyo_schedule,
body.zest .page_plan .page_section__image .tokyo_schedule,
body.yamatoda .page_plan .page_section__image .tokyo_schedule,
body.tokyo .page_plan .page_section__image .tokyo_schedule {
  background-image: url(../img/tokyo/image_plan_01.jpg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-position: center;
  background-size: cover;
  font-weight: 600;
  margin: 0 auto; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan .page_section__image .tokyo_schedule,
    body.kix .page_plan .page_section__image .tokyo_schedule,
    body.zest .page_plan .page_section__image .tokyo_schedule,
    body.yamatoda .page_plan .page_section__image .tokyo_schedule,
    body.tokyo .page_plan .page_section__image .tokyo_schedule {
      background-image: url(../img/tokyo/image_plan_01_sp.jpg);
      height: auto;
      padding: 55px 0;
      display: block;
      background-size: contain;
      background-repeat: no-repeat; } }
  body.kobe .page_plan .page_section__image .tokyo_schedule p,
  body.kix .page_plan .page_section__image .tokyo_schedule p,
  body.zest .page_plan .page_section__image .tokyo_schedule p,
  body.yamatoda .page_plan .page_section__image .tokyo_schedule p,
  body.tokyo .page_plan .page_section__image .tokyo_schedule p {
    font-size: 193%; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan .page_section__image .tokyo_schedule p,
      body.kix .page_plan .page_section__image .tokyo_schedule p,
      body.zest .page_plan .page_section__image .tokyo_schedule p,
      body.yamatoda .page_plan .page_section__image .tokyo_schedule p,
      body.tokyo .page_plan .page_section__image .tokyo_schedule p {
        font-size: 147%; } }

body.kobe .page_plan__name,
body.kix .page_plan__name,
body.zest .page_plan__name,
body.yamatoda .page_plan__name,
body.tokyo .page_plan__name {
  text-align: center;
  color: #fff;
  letter-spacing: 2px; }
  body.kobe .page_plan__name span,
  body.kix .page_plan__name span,
  body.zest .page_plan__name span,
  body.yamatoda .page_plan__name span,
  body.tokyo .page_plan__name span {
    font-size: 233%;
    display: block; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__name span,
      body.kix .page_plan__name span,
      body.zest .page_plan__name span,
      body.yamatoda .page_plan__name span,
      body.tokyo .page_plan__name span {
        font-size: 140%; } }
  body.kobe .page_plan__name b,
  body.kix .page_plan__name b,
  body.zest .page_plan__name b,
  body.yamatoda .page_plan__name b,
  body.tokyo .page_plan__name b {
    font-size: 313%;
    display: block;
    margin-bottom: 25px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__name b,
      body.kix .page_plan__name b,
      body.zest .page_plan__name b,
      body.yamatoda .page_plan__name b,
      body.tokyo .page_plan__name b {
        font-size: 187%;
        margin-bottom: 15px; } }
  body.kobe .page_plan__name p,
  body.kix .page_plan__name p,
  body.zest .page_plan__name p,
  body.yamatoda .page_plan__name p,
  body.tokyo .page_plan__name p {
    font-size: 147%; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__name p,
      body.kix .page_plan__name p,
      body.zest .page_plan__name p,
      body.yamatoda .page_plan__name p,
      body.tokyo .page_plan__name p {
        font-size: 93%; } }

body.kobe .page_plan__btn,
body.kix .page_plan__btn,
body.zest .page_plan__btn,
body.yamatoda .page_plan__btn,
body.tokyo .page_plan__btn {
  position: absolute;
  right: 0;
  bottom: -15px;
  background-color: #fff;
  width: 60%;
  color: #333333;
  padding: 15px 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: background-color .2s; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__btn,
    body.kix .page_plan__btn,
    body.zest .page_plan__btn,
    body.yamatoda .page_plan__btn,
    body.tokyo .page_plan__btn {
      bottom: -5px;
      width: auto;
      padding: 10px 15px; } }
  body.kobe .page_plan__btn b,
  body.kix .page_plan__btn b,
  body.zest .page_plan__btn b,
  body.yamatoda .page_plan__btn b,
  body.tokyo .page_plan__btn b {
    display: inline-block;
    font-size: 173%; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__btn b,
      body.kix .page_plan__btn b,
      body.zest .page_plan__btn b,
      body.yamatoda .page_plan__btn b,
      body.tokyo .page_plan__btn b {
        margin-right: 10px;
        font-size: 93%; } }
  body.kobe .page_plan__btn span,
  body.kix .page_plan__btn span,
  body.zest .page_plan__btn span,
  body.yamatoda .page_plan__btn span,
  body.tokyo .page_plan__btn span {
    font-size: 93%;
    padding-right: 45px;
    letter-spacing: 1px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__btn span,
      body.kix .page_plan__btn span,
      body.zest .page_plan__btn span,
      body.yamatoda .page_plan__btn span,
      body.tokyo .page_plan__btn span {
        padding-right: 30px;
        font-size: 67%; } }
    body.kobe .page_plan__btn span::before,
    body.kix .page_plan__btn span::before,
    body.zest .page_plan__btn span::before,
    body.yamatoda .page_plan__btn span::before,
    body.tokyo .page_plan__btn span::before {
      content: "";
      display: block;
      border: #ACACAC 1px solid;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: border .2s; }
      @media screen and (max-width: 639px) {
        body.kobe .page_plan__btn span::before,
        body.kix .page_plan__btn span::before,
        body.zest .page_plan__btn span::before,
        body.yamatoda .page_plan__btn span::before,
        body.tokyo .page_plan__btn span::before {
          width: 16px;
          height: 16px; } }
    body.kobe .page_plan__btn span::after,
    body.kix .page_plan__btn span::after,
    body.zest .page_plan__btn span::after,
    body.yamatoda .page_plan__btn span::after,
    body.tokyo .page_plan__btn span::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-right: 1px solid #707070;
      border-bottom: 1px solid #707070;
      transform: rotate(-45deg) translateY(-50%);
      position: absolute;
      right: 12px;
      top: 50%;
      transition: border .2s; }
      @media screen and (max-width: 639px) {
        body.kobe .page_plan__btn span::after,
        body.kix .page_plan__btn span::after,
        body.zest .page_plan__btn span::after,
        body.yamatoda .page_plan__btn span::after,
        body.tokyo .page_plan__btn span::after {
          width: 4px;
          height: 4px;
          right: 6px; } }
  body.kobe .page_plan__btn__inner,
  body.kix .page_plan__btn__inner,
  body.zest .page_plan__btn__inner,
  body.yamatoda .page_plan__btn__inner,
  body.tokyo .page_plan__btn__inner {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center; }

body.kobe .page_plan__availability,
body.kix .page_plan__availability,
body.zest .page_plan__availability,
body.yamatoda .page_plan__availability,
body.tokyo .page_plan__availability {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 313%;
  font-weight: bold; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__availability,
    body.kix .page_plan__availability,
    body.zest .page_plan__availability,
    body.yamatoda .page_plan__availability,
    body.tokyo .page_plan__availability {
      font-size: 187%; } }

body.kobe .page_plan .point,
body.kix .page_plan .point,
body.zest .page_plan .point,
body.yamatoda .page_plan .point,
body.tokyo .page_plan .point {
  border: #D6D5D5 1px solid;
  padding: 20px 30px;
  line-height: 1.8; }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan .point,
    body.kix .page_plan .point,
    body.zest .page_plan .point,
    body.yamatoda .page_plan .point,
    body.tokyo .page_plan .point {
      padding: 10px 20px; } }
  body.kobe .page_plan .point dt,
  body.kix .page_plan .point dt,
  body.zest .page_plan .point dt,
  body.yamatoda .page_plan .point dt,
  body.tokyo .page_plan .point dt {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 133%; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan .point dt,
      body.kix .page_plan .point dt,
      body.zest .page_plan .point dt,
      body.yamatoda .page_plan .point dt,
      body.tokyo .page_plan .point dt {
        margin-bottom: 5px; } }

body.kobe .page_plan__contact,
body.kix .page_plan__contact,
body.zest .page_plan__contact,
body.yamatoda .page_plan__contact,
body.tokyo .page_plan__contact {
  border: #707070 1px solid; }
  @media screen and (min-width: 640px) {
    body.kobe .page_plan__contact,
    body.kix .page_plan__contact,
    body.zest .page_plan__contact,
    body.yamatoda .page_plan__contact,
    body.tokyo .page_plan__contact {
      width: 80%;
      display: flex;
      align-items: center;
      margin: 100px auto 0; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__contact,
    body.kix .page_plan__contact,
    body.zest .page_plan__contact,
    body.yamatoda .page_plan__contact,
    body.tokyo .page_plan__contact {
      margin-top: 40px; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_plan__contact > *,
    body.kix .page_plan__contact > *,
    body.zest .page_plan__contact > *,
    body.yamatoda .page_plan__contact > *,
    body.tokyo .page_plan__contact > * {
      width: 50%;
      padding: 50px 40px; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_plan__contact > *,
    body.kix .page_plan__contact > *,
    body.zest .page_plan__contact > *,
    body.yamatoda .page_plan__contact > *,
    body.tokyo .page_plan__contact > * {
      padding: 15px 30px;
      text-align: center; } }
  body.kobe .page_plan__contact > *:first-child,
  body.kix .page_plan__contact > *:first-child,
  body.zest .page_plan__contact > *:first-child,
  body.yamatoda .page_plan__contact > *:first-child,
  body.tokyo .page_plan__contact > *:first-child {
    position: relative; }
    body.kobe .page_plan__contact > *:first-child::after,
    body.kix .page_plan__contact > *:first-child::after,
    body.zest .page_plan__contact > *:first-child::after,
    body.yamatoda .page_plan__contact > *:first-child::after,
    body.tokyo .page_plan__contact > *:first-child::after {
      content: "";
      display: block;
      position: absolute;
      background-color: #707070; }
      @media screen and (min-width: 640px) {
        body.kobe .page_plan__contact > *:first-child::after,
        body.kix .page_plan__contact > *:first-child::after,
        body.zest .page_plan__contact > *:first-child::after,
        body.yamatoda .page_plan__contact > *:first-child::after,
        body.tokyo .page_plan__contact > *:first-child::after {
          width: 1px;
          height: 60%;
          right: 0;
          top: 50%;
          transform: translateY(-50%); } }
      @media screen and (max-width: 639px) {
        body.kobe .page_plan__contact > *:first-child::after,
        body.kix .page_plan__contact > *:first-child::after,
        body.zest .page_plan__contact > *:first-child::after,
        body.yamatoda .page_plan__contact > *:first-child::after,
        body.tokyo .page_plan__contact > *:first-child::after {
          width: 90%;
          height: 1px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%); } }
  body.kobe .page_plan__contact__info__lead,
  body.kix .page_plan__contact__info__lead,
  body.zest .page_plan__contact__info__lead,
  body.yamatoda .page_plan__contact__info__lead,
  body.tokyo .page_plan__contact__info__lead {
    font-size: 133%;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 5px;
    margin-bottom: 10px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__contact__info__lead,
      body.kix .page_plan__contact__info__lead,
      body.zest .page_plan__contact__info__lead,
      body.yamatoda .page_plan__contact__info__lead,
      body.tokyo .page_plan__contact__info__lead {
        font-size: 120%; } }
  body.kobe .page_plan__contact__tel__num,
  body.kix .page_plan__contact__tel__num,
  body.zest .page_plan__contact__tel__num,
  body.yamatoda .page_plan__contact__tel__num,
  body.tokyo .page_plan__contact__tel__num {
    font-size: 340%;
    font-family: "Heebo", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 5px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__contact__tel__num,
      body.kix .page_plan__contact__tel__num,
      body.zest .page_plan__contact__tel__num,
      body.yamatoda .page_plan__contact__tel__num,
      body.tokyo .page_plan__contact__tel__num {
        font-size: 227%; } }
    body.kobe .page_plan__contact__tel__num i,
    body.kix .page_plan__contact__tel__num i,
    body.zest .page_plan__contact__tel__num i,
    body.yamatoda .page_plan__contact__tel__num i,
    body.tokyo .page_plan__contact__tel__num i {
      margin-right: 15px;
      vertical-align: middle;
      position: relative;
      top: -11px; }
      @media screen and (max-width: 639px) {
        body.kobe .page_plan__contact__tel__num i,
        body.kix .page_plan__contact__tel__num i,
        body.zest .page_plan__contact__tel__num i,
        body.yamatoda .page_plan__contact__tel__num i,
        body.tokyo .page_plan__contact__tel__num i {
          top: -7px;
          margin-right: 10px; } }
      @media screen and (max-width: 639px) {
        body.kobe .page_plan__contact__tel__num i img,
        body.kix .page_plan__contact__tel__num i img,
        body.zest .page_plan__contact__tel__num i img,
        body.yamatoda .page_plan__contact__tel__num i img,
        body.tokyo .page_plan__contact__tel__num i img {
          width: 18px; } }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__contact__tel__num a,
      body.kix .page_plan__contact__tel__num a,
      body.zest .page_plan__contact__tel__num a,
      body.yamatoda .page_plan__contact__tel__num a,
      body.tokyo .page_plan__contact__tel__num a {
        color: #333333; } }
    body.kobe .page_plan__contact__tel__num span,
    body.kix .page_plan__contact__tel__num span,
    body.zest .page_plan__contact__tel__num span,
    body.yamatoda .page_plan__contact__tel__num span,
    body.tokyo .page_plan__contact__tel__num span {
      display: inline-block;
      line-height: 1; }
  body.kobe .page_plan__contact__tel__lead,
  body.kix .page_plan__contact__tel__lead,
  body.zest .page_plan__contact__tel__lead,
  body.yamatoda .page_plan__contact__tel__lead,
  body.tokyo .page_plan__contact__tel__lead {
    font-size: 133%;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 5px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_plan__contact__tel__lead,
      body.kix .page_plan__contact__tel__lead,
      body.zest .page_plan__contact__tel__lead,
      body.yamatoda .page_plan__contact__tel__lead,
      body.tokyo .page_plan__contact__tel__lead {
        font-size: 120%; } }

@media screen and (min-width: 640px) {
  body.kobe .page_marina__item,
  body.kix .page_marina__item,
  body.zest .page_marina__item,
  body.yamatoda .page_marina__item,
  body.tokyo .page_marina__item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-bottom: 70px; } }

@media screen and (max-width: 639px) {
  body.kobe .page_marina__item,
  body.kix .page_marina__item,
  body.zest .page_marina__item,
  body.yamatoda .page_marina__item,
  body.tokyo .page_marina__item {
    margin-bottom: 30px; } }

body.kobe .page_marina__item:last-of-type,
body.kix .page_marina__item:last-of-type,
body.zest .page_marina__item:last-of-type,
body.yamatoda .page_marina__item:last-of-type,
body.tokyo .page_marina__item:last-of-type {
  margin-bottom: 0; }

body.kobe .page_marina__heading,
body.kix .page_marina__heading,
body.zest .page_marina__heading,
body.yamatoda .page_marina__heading,
body.tokyo .page_marina__heading {
  margin-bottom: 20px;
  font-size: 187%;
  font-weight: bold;
  font-feature-settings: "palt";
  letter-spacing: .1em; }
  @media screen and (max-width: 639px) {
    body.kobe .page_marina__heading,
    body.kix .page_marina__heading,
    body.zest .page_marina__heading,
    body.yamatoda .page_marina__heading,
    body.tokyo .page_marina__heading {
      margin-bottom: 15px;
      font-size: 160%; } }

@media screen and (min-width: 640px) {
  body.kobe .page_marina__text,
  body.kix .page_marina__text,
  body.zest .page_marina__text,
  body.yamatoda .page_marina__text,
  body.tokyo .page_marina__text {
    width: 58%; } }

@media screen and (min-width: 640px) {
  body.kobe .page_marina__text .headline__secondary,
  body.kix .page_marina__text .headline__secondary,
  body.zest .page_marina__text .headline__secondary,
  body.yamatoda .page_marina__text .headline__secondary,
  body.tokyo .page_marina__text .headline__secondary {
    margin-bottom: 20px; } }

body.kobe .page_marina__text .heading_lv2,
body.kix .page_marina__text .heading_lv2,
body.zest .page_marina__text .heading_lv2,
body.yamatoda .page_marina__text .heading_lv2,
body.tokyo .page_marina__text .heading_lv2 {
  font-weight: 700; }

body.kobe .page_marina__text .access,
body.kix .page_marina__text .access,
body.zest .page_marina__text .access,
body.yamatoda .page_marina__text .access,
body.tokyo .page_marina__text .access {
  margin-top: 20px; }
  @media screen and (min-width: 640px) {
    body.kobe .page_marina__text .access,
    body.kix .page_marina__text .access,
    body.zest .page_marina__text .access,
    body.yamatoda .page_marina__text .access,
    body.tokyo .page_marina__text .access {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      line-height: 2; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_marina__text .access,
    body.kix .page_marina__text .access,
    body.zest .page_marina__text .access,
    body.yamatoda .page_marina__text .access,
    body.tokyo .page_marina__text .access {
      line-height: 1.8; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_marina__text .access > *,
    body.kix .page_marina__text .access > *,
    body.zest .page_marina__text .access > *,
    body.yamatoda .page_marina__text .access > *,
    body.tokyo .page_marina__text .access > * {
      margin-bottom: 7px; } }
  body.kobe .page_marina__text .access dt,
  body.kix .page_marina__text .access dt,
  body.zest .page_marina__text .access dt,
  body.yamatoda .page_marina__text .access dt,
  body.tokyo .page_marina__text .access dt {
    font-weight: 600; }
    @media screen and (min-width: 640px) {
      body.kobe .page_marina__text .access dt,
      body.kix .page_marina__text .access dt,
      body.zest .page_marina__text .access dt,
      body.yamatoda .page_marina__text .access dt,
      body.tokyo .page_marina__text .access dt {
        padding-right: 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        width: 5.5em; } }
  @media screen and (min-width: 640px) {
    body.kobe .page_marina__text .access dd,
    body.kix .page_marina__text .access dd,
    body.zest .page_marina__text .access dd,
    body.yamatoda .page_marina__text .access dd,
    body.tokyo .page_marina__text .access dd {
      overflow: hidden; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_marina__text .access dd,
    body.kix .page_marina__text .access dd,
    body.zest .page_marina__text .access dd,
    body.yamatoda .page_marina__text .access dd,
    body.tokyo .page_marina__text .access dd {
      margin-bottom: 5px; } }

body.kobe .page_marina__text .list_note,
body.kix .page_marina__text .list_note,
body.zest .page_marina__text .list_note,
body.yamatoda .page_marina__text .list_note,
body.tokyo .page_marina__text .list_note {
  line-height: 2; }
  body.kobe .page_marina__text .list_note:not(:first-child),
  body.kix .page_marina__text .list_note:not(:first-child),
  body.zest .page_marina__text .list_note:not(:first-child),
  body.yamatoda .page_marina__text .list_note:not(:first-child),
  body.tokyo .page_marina__text .list_note:not(:first-child) {
    margin-top: 20px; }
  body.kobe .page_marina__text .list_note:not(:last-child),
  body.kix .page_marina__text .list_note:not(:last-child),
  body.zest .page_marina__text .list_note:not(:last-child),
  body.yamatoda .page_marina__text .list_note:not(:last-child),
  body.tokyo .page_marina__text .list_note:not(:last-child) {
    margin-bottom: 20px; }
  body.kobe .page_marina__text .list_note li,
  body.kix .page_marina__text .list_note li,
  body.zest .page_marina__text .list_note li,
  body.yamatoda .page_marina__text .list_note li,
  body.tokyo .page_marina__text .list_note li {
    margin-bottom: 0; }

@media screen and (min-width: 640px) {
  body.kobe .page_marina__image,
  body.kix .page_marina__image,
  body.zest .page_marina__image,
  body.yamatoda .page_marina__image,
  body.tokyo .page_marina__image {
    width: 36%; } }

@media screen and (max-width: 639px) {
  body.kobe .page_marina__image,
  body.kix .page_marina__image,
  body.zest .page_marina__image,
  body.yamatoda .page_marina__image,
  body.tokyo .page_marina__image {
    margin-top: 20px; } }

body.kobe .page_marina__item_group:not(:last-child),
body.kix .page_marina__item_group:not(:last-child),
body.zest .page_marina__item_group:not(:last-child),
body.yamatoda .page_marina__item_group:not(:last-child),
body.tokyo .page_marina__item_group:not(:last-child) {
  margin-bottom: 70px; }

@media screen and (min-width: 640px) {
  body.kobe .page_marina__item_group .page_marina__item:not(:last-child),
  body.kix .page_marina__item_group .page_marina__item:not(:last-child),
  body.zest .page_marina__item_group .page_marina__item:not(:last-child),
  body.yamatoda .page_marina__item_group .page_marina__item:not(:last-child),
  body.tokyo .page_marina__item_group .page_marina__item:not(:last-child) {
    margin-bottom: 20px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_marina .facilities,
  body.kix .page_marina .facilities,
  body.zest .page_marina .facilities,
  body.yamatoda .page_marina .facilities,
  body.tokyo .page_marina .facilities {
    margin-bottom: 70px; } }

@media screen and (max-width: 639px) {
  body.kobe .page_marina .facilities,
  body.kix .page_marina .facilities,
  body.zest .page_marina .facilities,
  body.yamatoda .page_marina .facilities,
  body.tokyo .page_marina .facilities {
    margin-bottom: 30px; } }

body.kobe .page_marina .facilities:last-of-type,
body.kix .page_marina .facilities:last-of-type,
body.zest .page_marina .facilities:last-of-type,
body.yamatoda .page_marina .facilities:last-of-type,
body.tokyo .page_marina .facilities:last-of-type {
  margin-bottom: 0; }

@media screen and (min-width: 640px) {
  body.kobe .page_marina .facilities__item,
  body.kix .page_marina .facilities__item,
  body.zest .page_marina .facilities__item,
  body.yamatoda .page_marina .facilities__item,
  body.tokyo .page_marina .facilities__item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: stretch;
    align-items: stretch; } }

@media screen and (min-width: 640px) {
  body.kobe .page_marina .facilities__text,
  body.kix .page_marina .facilities__text,
  body.zest .page_marina .facilities__text,
  body.yamatoda .page_marina .facilities__text,
  body.tokyo .page_marina .facilities__text {
    margin-bottom: 30px; } }

@media screen and (max-width: 639px) {
  body.kobe .page_marina .facilities__text,
  body.kix .page_marina .facilities__text,
  body.zest .page_marina .facilities__text,
  body.yamatoda .page_marina .facilities__text,
  body.tokyo .page_marina .facilities__text {
    margin-bottom: 20px; } }

body.kobe .page_marina .facilities__text .link,
body.kix .page_marina .facilities__text .link,
body.zest .page_marina .facilities__text .link,
body.yamatoda .page_marina .facilities__text .link,
body.tokyo .page_marina .facilities__text .link {
  margin-top: 10px; }
  body.kobe .page_marina .facilities__text .link a,
  body.kix .page_marina .facilities__text .link a,
  body.zest .page_marina .facilities__text .link a,
  body.yamatoda .page_marina .facilities__text .link a,
  body.tokyo .page_marina .facilities__text .link a {
    text-decoration: underline; }
    body.kobe .page_marina .facilities__text .link a:hover,
    body.kix .page_marina .facilities__text .link a:hover,
    body.zest .page_marina .facilities__text .link a:hover,
    body.yamatoda .page_marina .facilities__text .link a:hover,
    body.tokyo .page_marina .facilities__text .link a:hover {
      text-decoration: none; }

@media screen and (min-width: 640px) {
  body.kobe .page_marina .facilities__col,
  body.kix .page_marina .facilities__col,
  body.zest .page_marina .facilities__col,
  body.yamatoda .page_marina .facilities__col,
  body.tokyo .page_marina .facilities__col {
    margin-right: 30px; } }

body.kobe .page_marina .facilities__col:nth-child(2n),
body.kix .page_marina .facilities__col:nth-child(2n),
body.zest .page_marina .facilities__col:nth-child(2n),
body.yamatoda .page_marina .facilities__col:nth-child(2n),
body.tokyo .page_marina .facilities__col:nth-child(2n) {
  margin-right: 0; }

body.kobe .page_price .attention,
body.kix .page_price .attention,
body.zest .page_price .attention,
body.yamatoda .page_price .attention,
body.tokyo .page_price .attention {
  margin-top: 25px;
  line-height: 2; }

body.kobe .page_access,
body.kix .page_access,
body.zest .page_access,
body.yamatoda .page_access,
body.tokyo .page_access {
  padding-bottom: 10px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_access,
    body.kix .page_access,
    body.zest .page_access,
    body.yamatoda .page_access,
    body.tokyo .page_access {
      padding-bottom: 0; } }
  body.kobe .page_access__body,
  body.kix .page_access__body,
  body.zest .page_access__body,
  body.yamatoda .page_access__body,
  body.tokyo .page_access__body {
    font-size: 107%;
    line-height: 1.8;
    letter-spacing: 1px; }
    body.kobe .page_access__body dl,
    body.kix .page_access__body dl,
    body.zest .page_access__body dl,
    body.yamatoda .page_access__body dl,
    body.tokyo .page_access__body dl {
      margin-bottom: 20px; }
      body.kobe .page_access__body dl:last-of-type,
      body.kix .page_access__body dl:last-of-type,
      body.zest .page_access__body dl:last-of-type,
      body.yamatoda .page_access__body dl:last-of-type,
      body.tokyo .page_access__body dl:last-of-type {
        margin-bottom: 0; }
    body.kobe .page_access__body dt,
    body.kix .page_access__body dt,
    body.zest .page_access__body dt,
    body.yamatoda .page_access__body dt,
    body.tokyo .page_access__body dt {
      font-weight: 600; }
    body.kobe .page_access__body p,
    body.kix .page_access__body p,
    body.zest .page_access__body p,
    body.yamatoda .page_access__body p,
    body.tokyo .page_access__body p {
      margin-bottom: 20px; }

@media screen and (max-width: 639px) {
  body.kobe .page_gmap iframe,
  body.kix .page_gmap iframe,
  body.zest .page_gmap iframe,
  body.yamatoda .page_gmap iframe,
  body.tokyo .page_gmap iframe {
    height: 260px; } }

@media screen and (min-width: 640px) {
  body.kobe .page_contact__body,
  body.kix .page_contact__body,
  body.zest .page_contact__body,
  body.yamatoda .page_contact__body,
  body.tokyo .page_contact__body {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse; } }

body.kobe .page_contact__text,
body.kix .page_contact__text,
body.zest .page_contact__text,
body.yamatoda .page_contact__text,
body.tokyo .page_contact__text {
  line-height: 1.7; }
  @media screen and (min-width: 640px) {
    body.kobe .page_contact__text,
    body.kix .page_contact__text,
    body.zest .page_contact__text,
    body.yamatoda .page_contact__text,
    body.tokyo .page_contact__text {
      margin-right: 9%;
      width: 36%; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_contact__text,
    body.kix .page_contact__text,
    body.zest .page_contact__text,
    body.yamatoda .page_contact__text,
    body.tokyo .page_contact__text {
      width: 92%;
      margin: 0 auto 40px; } }
  body.kobe .page_contact__text .notes,
  body.kix .page_contact__text .notes,
  body.zest .page_contact__text .notes,
  body.yamatoda .page_contact__text .notes,
  body.tokyo .page_contact__text .notes {
    font-size: 93%; }
  body.kobe .page_contact__text .zestQR, body.kobe .page_contact__text .tokyoQR,
  body.kix .page_contact__text .zestQR,
  body.kix .page_contact__text .tokyoQR,
  body.zest .page_contact__text .zestQR,
  body.zest .page_contact__text .tokyoQR,
  body.yamatoda .page_contact__text .zestQR,
  body.yamatoda .page_contact__text .tokyoQR,
  body.tokyo .page_contact__text .zestQR,
  body.tokyo .page_contact__text .tokyoQR {
    width: 285px;
    margin-bottom: 20px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__text .zestQR, body.kobe .page_contact__text .tokyoQR,
      body.kix .page_contact__text .zestQR,
      body.kix .page_contact__text .tokyoQR,
      body.zest .page_contact__text .zestQR,
      body.zest .page_contact__text .tokyoQR,
      body.yamatoda .page_contact__text .zestQR,
      body.yamatoda .page_contact__text .tokyoQR,
      body.tokyo .page_contact__text .zestQR,
      body.tokyo .page_contact__text .tokyoQR {
        display: none; } }
    body.kobe .page_contact__text .zestQR img, body.kobe .page_contact__text .tokyoQR img,
    body.kix .page_contact__text .zestQR img,
    body.kix .page_contact__text .tokyoQR img,
    body.zest .page_contact__text .zestQR img,
    body.zest .page_contact__text .tokyoQR img,
    body.yamatoda .page_contact__text .zestQR img,
    body.yamatoda .page_contact__text .tokyoQR img,
    body.tokyo .page_contact__text .zestQR img,
    body.tokyo .page_contact__text .tokyoQR img {
      width: 100%; }
  body.kobe .page_contact__text .small_title,
  body.kix .page_contact__text .small_title,
  body.zest .page_contact__text .small_title,
  body.yamatoda .page_contact__text .small_title,
  body.tokyo .page_contact__text .small_title {
    font-size: 100%;
    font-weight: bold;
    border: 1px solid #060606;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 15px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__text .small_title,
      body.kix .page_contact__text .small_title,
      body.zest .page_contact__text .small_title,
      body.yamatoda .page_contact__text .small_title,
      body.tokyo .page_contact__text .small_title {
        display: block;
        text-align: center; } }

@media screen and (min-width: 640px) {
  body.kobe .page_contact__image,
  body.kix .page_contact__image,
  body.zest .page_contact__image,
  body.yamatoda .page_contact__image,
  body.tokyo .page_contact__image {
    width: 48%; } }

body.kobe .page_contact__message,
body.kix .page_contact__message,
body.zest .page_contact__message,
body.yamatoda .page_contact__message,
body.tokyo .page_contact__message {
  font-size: 107%;
  margin-bottom: 15px; }

body.kobe .page_contact__tel__label,
body.kix .page_contact__tel__label,
body.zest .page_contact__tel__label,
body.yamatoda .page_contact__tel__label,
body.tokyo .page_contact__tel__label {
  font-size: 133%;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 5px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_contact__tel__label,
    body.kix .page_contact__tel__label,
    body.zest .page_contact__tel__label,
    body.yamatoda .page_contact__tel__label,
    body.tokyo .page_contact__tel__label {
      font-size: 120%; } }

body.kobe .page_contact__tel__value,
body.kix .page_contact__tel__value,
body.zest .page_contact__tel__value,
body.yamatoda .page_contact__tel__value,
body.tokyo .page_contact__tel__value {
  font-size: 340%;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 5px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_contact__tel__value,
    body.kix .page_contact__tel__value,
    body.zest .page_contact__tel__value,
    body.yamatoda .page_contact__tel__value,
    body.tokyo .page_contact__tel__value {
      font-size: 227%; } }
  body.kobe .page_contact__tel__value i,
  body.kix .page_contact__tel__value i,
  body.zest .page_contact__tel__value i,
  body.yamatoda .page_contact__tel__value i,
  body.tokyo .page_contact__tel__value i {
    margin-right: 15px;
    vertical-align: middle;
    position: relative;
    top: -11px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__tel__value i,
      body.kix .page_contact__tel__value i,
      body.zest .page_contact__tel__value i,
      body.yamatoda .page_contact__tel__value i,
      body.tokyo .page_contact__tel__value i {
        top: -7px;
        margin-right: 10px; } }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__tel__value i img,
      body.kix .page_contact__tel__value i img,
      body.zest .page_contact__tel__value i img,
      body.yamatoda .page_contact__tel__value i img,
      body.tokyo .page_contact__tel__value i img {
        width: 18px; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_contact__tel__value a,
    body.kix .page_contact__tel__value a,
    body.zest .page_contact__tel__value a,
    body.yamatoda .page_contact__tel__value a,
    body.tokyo .page_contact__tel__value a {
      color: #333333; } }
  body.kobe .page_contact__tel__value span,
  body.kix .page_contact__tel__value span,
  body.zest .page_contact__tel__value span,
  body.yamatoda .page_contact__tel__value span,
  body.tokyo .page_contact__tel__value span {
    display: inline-block;
    line-height: 1; }

body.kobe .page_contact__tel__sub p,
body.kix .page_contact__tel__sub p,
body.zest .page_contact__tel__sub p,
body.yamatoda .page_contact__tel__sub p,
body.tokyo .page_contact__tel__sub p {
  font-size: 120%;
  margin-bottom: 15px; }

body.kobe .page_contact__tel__sub li,
body.kix .page_contact__tel__sub li,
body.zest .page_contact__tel__sub li,
body.yamatoda .page_contact__tel__sub li,
body.tokyo .page_contact__tel__sub li {
  line-height: 2; }

body.kobe .page_contact__mail a,
body.kix .page_contact__mail a,
body.zest .page_contact__mail a,
body.yamatoda .page_contact__mail a,
body.tokyo .page_contact__mail a {
  font-weight: 700;
  font-family: "Heebo", sans-serif;
  line-height: 1;
  color: #333333;
  letter-spacing: 1px; }
  @media screen and (min-width: 640px) {
    body.kobe .page_contact__mail a,
    body.kix .page_contact__mail a,
    body.zest .page_contact__mail a,
    body.yamatoda .page_contact__mail a,
    body.tokyo .page_contact__mail a {
      font-size: 220%; } }
  @media screen and (max-width: 639px) {
    body.kobe .page_contact__mail a,
    body.kix .page_contact__mail a,
    body.zest .page_contact__mail a,
    body.yamatoda .page_contact__mail a,
    body.tokyo .page_contact__mail a {
      font-size: 160%; } }
  body.kobe .page_contact__mail a i,
  body.kix .page_contact__mail a i,
  body.zest .page_contact__mail a i,
  body.yamatoda .page_contact__mail a i,
  body.tokyo .page_contact__mail a i {
    position: relative;
    top: -4px;
    margin-right: 20px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__mail a i,
      body.kix .page_contact__mail a i,
      body.zest .page_contact__mail a i,
      body.yamatoda .page_contact__mail a i,
      body.tokyo .page_contact__mail a i {
        margin-right: 15px; } }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__mail a i img,
      body.kix .page_contact__mail a i img,
      body.zest .page_contact__mail a i img,
      body.yamatoda .page_contact__mail a i img,
      body.tokyo .page_contact__mail a i img {
        width: 28px; } }
  body.kobe .page_contact__mail a:hover,
  body.kix .page_contact__mail a:hover,
  body.zest .page_contact__mail a:hover,
  body.yamatoda .page_contact__mail a:hover,
  body.tokyo .page_contact__mail a:hover {
    text-decoration: underline; }

@media screen and (max-width: 639px) {
  body.kobe .page_contact__body__zest, body.kobe .page_contact__body__tokyo,
  body.kix .page_contact__body__zest,
  body.kix .page_contact__body__tokyo,
  body.zest .page_contact__body__zest,
  body.zest .page_contact__body__tokyo,
  body.yamatoda .page_contact__body__zest,
  body.yamatoda .page_contact__body__tokyo,
  body.tokyo .page_contact__body__zest,
  body.tokyo .page_contact__body__tokyo {
    display: flex;
    flex-direction: column-reverse; } }

body.kobe .page_contact__body__zest .page_contact__image__text, body.kobe .page_contact__body__tokyo .page_contact__image__text,
body.kix .page_contact__body__zest .page_contact__image__text,
body.kix .page_contact__body__tokyo .page_contact__image__text,
body.zest .page_contact__body__zest .page_contact__image__text,
body.zest .page_contact__body__tokyo .page_contact__image__text,
body.yamatoda .page_contact__body__zest .page_contact__image__text,
body.yamatoda .page_contact__body__tokyo .page_contact__image__text,
body.tokyo .page_contact__body__zest .page_contact__image__text,
body.tokyo .page_contact__body__tokyo .page_contact__image__text {
  padding-left: 120px;
  margin-top: 50px; }
  @media screen and (max-width: 639px) {
    body.kobe .page_contact__body__zest .page_contact__image__text, body.kobe .page_contact__body__tokyo .page_contact__image__text,
    body.kix .page_contact__body__zest .page_contact__image__text,
    body.kix .page_contact__body__tokyo .page_contact__image__text,
    body.zest .page_contact__body__zest .page_contact__image__text,
    body.zest .page_contact__body__tokyo .page_contact__image__text,
    body.yamatoda .page_contact__body__zest .page_contact__image__text,
    body.yamatoda .page_contact__body__tokyo .page_contact__image__text,
    body.tokyo .page_contact__body__zest .page_contact__image__text,
    body.tokyo .page_contact__body__tokyo .page_contact__image__text {
      display: none; } }
  body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__label, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
  body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
  body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
  body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
  body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
  body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
  body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
  body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
  body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label {
    font-size: 133%;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 5px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__label, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
      body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
      body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
      body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
      body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
      body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
      body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label,
      body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__label,
      body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__label {
        font-size: 120%; } }
  body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
  body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
  body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
  body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
  body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
  body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
  body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
  body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
  body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value {
    font-size: 340%;
    font-family: "Heebo", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 5px; }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
      body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
      body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
      body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
      body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
      body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
      body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value,
      body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value,
      body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value {
        font-size: 227%; } }
    body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
    body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
    body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
    body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
    body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
    body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
    body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
    body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
    body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i {
      margin-right: 15px;
      vertical-align: middle;
      position: relative;
      top: -11px; }
      @media screen and (max-width: 639px) {
        body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
        body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
        body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
        body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
        body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
        body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
        body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i,
        body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i,
        body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i {
          top: -7px;
          margin-right: 10px; } }
      @media screen and (max-width: 639px) {
        body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i img, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i img,
        body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i img,
        body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i img,
        body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i img,
        body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i img,
        body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i img,
        body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i img,
        body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value i img,
        body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value i img {
          width: 18px; } }
    @media screen and (max-width: 639px) {
      body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value a, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value a,
      body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value a,
      body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value a,
      body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value a,
      body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value a,
      body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value a,
      body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value a,
      body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value a,
      body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value a {
        color: #333333; } }
    body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__value span, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value span,
    body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__value span,
    body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value span,
    body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__value span,
    body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value span,
    body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__value span,
    body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value span,
    body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__value span,
    body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__value span {
      display: inline-block;
      line-height: 1; }
  body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub p, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub p,
  body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub p,
  body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub p,
  body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub p,
  body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub p,
  body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub p,
  body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub p,
  body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub p,
  body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub p {
    font-size: 120%;
    margin-bottom: 15px; }
  body.kobe .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub li, body.kobe .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub li,
  body.kix .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub li,
  body.kix .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub li,
  body.zest .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub li,
  body.zest .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub li,
  body.yamatoda .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub li,
  body.yamatoda .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub li,
  body.tokyo .page_contact__body__zest .page_contact__image__text .page_contact__tel__sub li,
  body.tokyo .page_contact__body__tokyo .page_contact__image__text .page_contact__tel__sub li {
    line-height: 2; }

body.kobe .page_option .column .content,
body.kix .page_option .column .content,
body.zest .page_option .column .content,
body.yamatoda .page_option .column .content,
body.tokyo .page_option .column .content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column; }
  body.kobe .page_option .column .content > *,
  body.kix .page_option .column .content > *,
  body.zest .page_option .column .content > *,
  body.yamatoda .page_option .column .content > *,
  body.tokyo .page_option .column .content > * {
    width: 100%; }
  body.kobe .page_option .column .content .price,
  body.kix .page_option .column .content .price,
  body.zest .page_option .column .content .price,
  body.yamatoda .page_option .column .content .price,
  body.tokyo .page_option .column .content .price {
    margin-top: auto; }
    @media screen and (max-width: 639px) {
      body.kobe .page_option .column .content .price,
      body.kix .page_option .column .content .price,
      body.zest .page_option .column .content .price,
      body.yamatoda .page_option .column .content .price,
      body.tokyo .page_option .column .content .price {
        margin-top: 30px; } }
    body.kobe .page_option .column .content .price dl,
    body.kix .page_option .column .content .price dl,
    body.zest .page_option .column .content .price dl,
    body.yamatoda .page_option .column .content .price dl,
    body.tokyo .page_option .column .content .price dl {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      letter-spacing: 1px; }
      @media screen and (max-width: 639px) {
        body.kobe .page_option .column .content .price dl,
        body.kix .page_option .column .content .price dl,
        body.zest .page_option .column .content .price dl,
        body.yamatoda .page_option .column .content .price dl,
        body.tokyo .page_option .column .content .price dl {
          letter-spacing: 0; } }
      body.kobe .page_option .column .content .price dl dt,
      body.kix .page_option .column .content .price dl dt,
      body.zest .page_option .column .content .price dl dt,
      body.yamatoda .page_option .column .content .price dl dt,
      body.tokyo .page_option .column .content .price dl dt {
        font-weight: 600;
        margin-right: 20px;
        font-size: 107%; }
      body.kobe .page_option .column .content .price dl dd .en,
      body.kix .page_option .column .content .price dl dd .en,
      body.zest .page_option .column .content .price dl dd .en,
      body.yamatoda .page_option .column .content .price dl dd .en,
      body.tokyo .page_option .column .content .price dl dd .en {
        font-size: 300%;
        font-weight: 600;
        font-family: "Heebo", sans-serif;
        line-height: 1;
        margin-right: 5px; }
        @media screen and (max-width: 639px) {
          body.kobe .page_option .column .content .price dl dd .en,
          body.kix .page_option .column .content .price dl dd .en,
          body.zest .page_option .column .content .price dl dd .en,
          body.yamatoda .page_option .column .content .price dl dd .en,
          body.tokyo .page_option .column .content .price dl dd .en {
            font-size: 173%; } }
      body.kobe .page_option .column .content .price dl dd .jp,
      body.kix .page_option .column .content .price dl dd .jp,
      body.zest .page_option .column .content .price dl dd .jp,
      body.yamatoda .page_option .column .content .price dl dd .jp,
      body.tokyo .page_option .column .content .price dl dd .jp {
        font-size: 180%;
        font-weight: 600; }
        @media screen and (max-width: 639px) {
          body.kobe .page_option .column .content .price dl dd .jp,
          body.kix .page_option .column .content .price dl dd .jp,
          body.zest .page_option .column .content .price dl dd .jp,
          body.yamatoda .page_option .column .content .price dl dd .jp,
          body.tokyo .page_option .column .content .price dl dd .jp {
            font-size: 93%; } }
  body.kobe .page_option .column .content .mrb10,
  body.kix .page_option .column .content .mrb10,
  body.zest .page_option .column .content .mrb10,
  body.yamatoda .page_option .column .content .mrb10,
  body.tokyo .page_option .column .content .mrb10 {
    margin-bottom: 10px;
    display: block; }

@media screen and (max-width: 639px) {
  body.kobe .page_main_image,
  body.kix .page_main_image,
  body.zest .page_main_image,
  body.yamatoda .page_main_image,
  body.tokyo .page_main_image {
    margin-bottom: 0; } }

@media screen and (min-width: 640px) {
  body.kobe .page_main_image__copy .elem_inner .elem_lead,
  body.kix .page_main_image__copy .elem_inner .elem_lead,
  body.zest .page_main_image__copy .elem_inner .elem_lead,
  body.yamatoda .page_main_image__copy .elem_inner .elem_lead,
  body.tokyo .page_main_image__copy .elem_inner .elem_lead {
    margin-bottom: 0; } }

@media screen and (min-width: 640px) {
  body.kobe .page_main_image__copy .elem_inner .elem_title .jp b,
  body.kix .page_main_image__copy .elem_inner .elem_title .jp b,
  body.zest .page_main_image__copy .elem_inner .elem_title .jp b,
  body.yamatoda .page_main_image__copy .elem_inner .elem_title .jp b,
  body.tokyo .page_main_image__copy .elem_inner .elem_title .jp b {
    margin-top: -.2em; } }

@media screen and (min-width: 640px) {
  body.kobe .module_instagram__list dt,
  body.kix .module_instagram__list dt,
  body.zest .module_instagram__list dt,
  body.yamatoda .module_instagram__list dt,
  body.tokyo .module_instagram__list dt {
    font-size: 120%; } }

@media screen and (max-width: 639px) {
  body.kobe .module_instagram__list dt,
  body.kix .module_instagram__list dt,
  body.zest .module_instagram__list dt,
  body.yamatoda .module_instagram__list dt,
  body.tokyo .module_instagram__list dt {
    display: inline-block;
    margin-bottom: 30px; } }

@media screen and (min-width: 640px) {
  body.kobe .module_instagram__list dd ul,
  body.kix .module_instagram__list dd ul,
  body.zest .module_instagram__list dd ul,
  body.yamatoda .module_instagram__list dd ul,
  body.tokyo .module_instagram__list dd ul {
    margin-right: -10px;
    margin-bottom: -25px; } }

@media screen and (max-width: 639px) {
  body.kobe .module_instagram__list dd ul,
  body.kix .module_instagram__list dd ul,
  body.zest .module_instagram__list dd ul,
  body.yamatoda .module_instagram__list dd ul,
  body.tokyo .module_instagram__list dd ul {
    flex-direction: column; } }

@media screen and (min-width: 640px) {
  body.kobe .module_instagram__list dd ul li,
  body.kix .module_instagram__list dd ul li,
  body.zest .module_instagram__list dd ul li,
  body.yamatoda .module_instagram__list dd ul li,
  body.tokyo .module_instagram__list dd ul li {
    margin-right: 10px;
    margin-bottom: 30px; } }

@media screen and (max-width: 639px) {
  body.kobe .module_instagram__list dd ul li,
  body.kix .module_instagram__list dd ul li,
  body.zest .module_instagram__list dd ul li,
  body.yamatoda .module_instagram__list dd ul li,
  body.tokyo .module_instagram__list dd ul li {
    width: 100%;
    margin-bottom: 10px; } }

@media screen and (max-width: 639px) {
  body.kobe .module_instagram__list dd ul li:last-child,
  body.kix .module_instagram__list dd ul li:last-child,
  body.zest .module_instagram__list dd ul li:last-child,
  body.yamatoda .module_instagram__list dd ul li:last-child,
  body.tokyo .module_instagram__list dd ul li:last-child {
    margin-bottom: 0; } }

body.kobe .feature_gallery,
body.kix .feature_gallery,
body.zest .feature_gallery,
body.yamatoda .feature_gallery,
body.tokyo .feature_gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 65px -20px -20px 0; }
  @media screen and (max-width: 639px) {
    body.kobe .feature_gallery,
    body.kix .feature_gallery,
    body.zest .feature_gallery,
    body.yamatoda .feature_gallery,
    body.tokyo .feature_gallery {
      margin: 20px 0 0 0; } }
  body.kobe .feature_gallery__item,
  body.kix .feature_gallery__item,
  body.zest .feature_gallery__item,
  body.yamatoda .feature_gallery__item,
  body.tokyo .feature_gallery__item {
    width: calc((100% / 3) - 20px);
    margin-right: 20px;
    margin-bottom: 20px; }
    @media screen and (max-width: 639px) {
      body.kobe .feature_gallery__item,
      body.kix .feature_gallery__item,
      body.zest .feature_gallery__item,
      body.yamatoda .feature_gallery__item,
      body.tokyo .feature_gallery__item {
        width: 100%;
        margin-right: 0; } }
    @media screen and (min-width: 640px) {
      body.kobe .feature_gallery__item--md,
      body.kix .feature_gallery__item--md,
      body.zest .feature_gallery__item--md,
      body.yamatoda .feature_gallery__item--md,
      body.tokyo .feature_gallery__item--md {
        width: calc((100% / 2) - 20px); } }
    body.kobe .feature_gallery__item img,
    body.kix .feature_gallery__item img,
    body.zest .feature_gallery__item img,
    body.yamatoda .feature_gallery__item img,
    body.tokyo .feature_gallery__item img {
      display: block;
      width: 100%; }

@media screen and (max-width: 639px) {
  body.kobe .heading_lv4,
  body.kix .heading_lv4,
  body.zest .heading_lv4,
  body.yamatoda .heading_lv4,
  body.tokyo .heading_lv4 {
    margin-bottom: 25px; } }

@media screen and (max-width: 639px) {
  body.kobe .heading_lv4 .en,
  body.kix .heading_lv4 .en,
  body.zest .heading_lv4 .en,
  body.yamatoda .heading_lv4 .en,
  body.tokyo .heading_lv4 .en {
    line-height: 1; } }

body.kobe .heading_lv4 .jp,
body.kix .heading_lv4 .jp,
body.zest .heading_lv4 .jp,
body.yamatoda .heading_lv4 .jp,
body.tokyo .heading_lv4 .jp {
  font-size: 93%; }
  @media screen and (max-width: 639px) {
    body.kobe .heading_lv4 .jp,
    body.kix .heading_lv4 .jp,
    body.zest .heading_lv4 .jp,
    body.yamatoda .heading_lv4 .jp,
    body.tokyo .heading_lv4 .jp {
      font-size: 73%; } }

@media screen and (max-width: 639px) {
  body.kobe .headline__primary .en,
  body.kix .headline__primary .en,
  body.zest .headline__primary .en,
  body.yamatoda .headline__primary .en,
  body.tokyo .headline__primary .en {
    font-size: 187%; } }

@media screen and (max-width: 639px) {
  body.kobe .headline__secondary .en,
  body.kix .headline__secondary .en,
  body.zest .headline__secondary .en,
  body.yamatoda .headline__secondary .en,
  body.tokyo .headline__secondary .en {
    font-size: 100%; } }

body.kobe .headline__lead, body.kobe body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content body.kobe .text > p,
body.kix .headline__lead,
body.kix body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
body.kix .text > p,
body.zest .headline__lead,
body.zest body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
body.zest .text > p,
body.yamatoda .headline__lead,
body.yamatoda body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
body.yamatoda .text > p,
body.tokyo .headline__lead,
body.tokyo body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
body.tokyo .text > p {
  line-height: 2.25; }
  @media screen and (max-width: 639px) {
    body.kobe .headline__lead, body.kobe body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content body.kobe .text > p,
    body.kix .headline__lead,
    body.kix body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
    body.kix .text > p,
    body.zest .headline__lead,
    body.zest body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
    body.zest .text > p,
    body.yamatoda .headline__lead,
    body.yamatoda body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
    body.yamatoda .text > p,
    body.tokyo .headline__lead,
    body.tokyo body.post-type-archive-tackle_info .page_content .column .content .text > p, body.post-type-archive-tackle_info .page_content .column .content
    body.tokyo .text > p {
      line-height: 2; } }

body.kobe .table.calendar th,
body.kix .table.calendar th,
body.zest .table.calendar th,
body.yamatoda .table.calendar th,
body.tokyo .table.calendar th {
  box-sizing: border-box; }
  @media screen and (max-width: 639px) {
    body.kobe .table.calendar th:first-child,
    body.kix .table.calendar th:first-child,
    body.zest .table.calendar th:first-child,
    body.yamatoda .table.calendar th:first-child,
    body.tokyo .table.calendar th:first-child {
      width: 175px; } }
  body.kobe .table.calendar th:not(:first-child),
  body.kix .table.calendar th:not(:first-child),
  body.zest .table.calendar th:not(:first-child),
  body.yamatoda .table.calendar th:not(:first-child),
  body.tokyo .table.calendar th:not(:first-child) {
    width: 70px; }
    @media screen and (max-width: 639px) {
      body.kobe .table.calendar th:not(:first-child),
      body.kix .table.calendar th:not(:first-child),
      body.zest .table.calendar th:not(:first-child),
      body.yamatoda .table.calendar th:not(:first-child),
      body.tokyo .table.calendar th:not(:first-child) {
        width: 50px; } }

body.kobe .table.calendar tbody td,
body.kix .table.calendar tbody td,
body.zest .table.calendar tbody td,
body.yamatoda .table.calendar tbody td,
body.tokyo .table.calendar tbody td {
  font-size: 133%; }
  @media screen and (max-width: 639px) {
    body.kobe .table.calendar tbody td,
    body.kix .table.calendar tbody td,
    body.zest .table.calendar tbody td,
    body.yamatoda .table.calendar tbody td,
    body.tokyo .table.calendar tbody td {
      font-size: 107%; } }
  body.kobe .table.calendar tbody td img,
  body.kix .table.calendar tbody td img,
  body.zest .table.calendar tbody td img,
  body.yamatoda .table.calendar tbody td img,
  body.tokyo .table.calendar tbody td img {
    width: 85%; }

.info_table {
  line-height: 2; }
  .info_table:not(:first-child) {
    margin-top: 20px; }
  .info_table:not(:last-child) {
    margin-bottom: 20px; }
  .info_table--block {
    display: block; }
    .info_table--block > tbody > .info_table__row,
    .info_table--block > tbody > .info_table__row > .info_table__head,
    .info_table--block > tbody > .info_table__row > .info_table__content {
      display: block; }
    .info_table--block > tbody > .info_table__row > .info_table__head {
      padding-right: 0; }
  @media screen and (max-width: 639px) {
    .info_table__row--sp_preserved {
      display: flex; } }
  @media screen and (max-width: 639px) {
    .info_table__row--sp_preserved > .info_table__head {
      padding-right: .5em; } }
  .info_table__head {
    text-align: left; }
    @media screen and (min-width: 640px) {
      .info_table__head {
        padding-right: .5em;
        white-space: nowrap; } }
  @media screen and (max-width: 639px) {
    .info_table:not(.info_table--preserved) {
      display: block; } }
  @media screen and (max-width: 639px) {
    .info_table:not(.info_table--preserved) > tbody > .info_table__row:not(.info_table__row--sp_preserved),
    .info_table:not(.info_table--preserved) > tbody > .info_table__row > .info_table__head,
    .info_table:not(.info_table--preserved) > tbody > .info_table__row > .info_table__content {
      display: block; } }
  @media screen and (max-width: 639px) {
    .info_table:not(.info_table--preserved) > tbody > .info_table__row:not(:last-child) {
      margin-bottom: 15px; } }
  @media screen and (max-width: 639px) {
    .info_table:not(.info_table--preserved) > tbody > .info_table__head {
      padding-right: 0; } }
  .info_table--preserved > tbody > .info_table__row > .info_table__head {
    padding-right: .5em;
    white-space: nowrap; }

@media screen and (max-width: 639px) {
  body.zest .cta_btn {
    display: block;
    position: fixed;
    z-index: 1001;
    right: 10px;
    bottom: 10px; } }

@media screen and (min-width: 640px) {
  body.zest .module_instagram__list dt {
    width: 21%; } }

@media screen and (max-width: 639px) {
  body.zest .zest_table {
    display: block;
    overflow-x: auto;
    white-space: nowrap; } }

body.zest .zest_table tr:last-child td {
  border-bottom: 1px solid #d6d5d5; }

body.zest .zest_table th {
  background-color: #2F6CB5;
  border-top: 1px solid #d6d5d5;
  border-right: 1px solid #d6d5d5;
  border-left: 1px solid #d6d5d5; }
  @media screen and (max-width: 639px) {
    body.zest .zest_table th {
      display: table-cell; } }

body.zest .zest_table td {
  border-right: 1px solid #d6d5d5;
  border-bottom: 1px solid #d6d5d5;
  border-left: 1px solid #d6d5d5; }
  body.zest .zest_table td:first-child {
    border-left: 1px solid #d6d5d5;
    text-align: left;
    padding-left: 50px; }
  body.zest .zest_table td:last-child {
    border-right: 1px solid #d6d5d5; }
  @media screen and (max-width: 639px) {
    body.zest .zest_table td {
      display: table-cell; } }

body.zest .merge_row {
  text-align: center !important;
  background-color: #E0E9F4; }

body.zest .shared_colum_4 {
  width: 23.5%;
  text-align: center; }
  body.zest .shared_colum_4:first-child {
    width: 29.5%; }

body.zest .shared_row_4 {
  width: 24%; }
  body.zest .shared_row_4:first-child {
    width: 28%; }

body.zest .column_4 th {
  text-align: center; }
  body.zest .column_4 th:first-child, body.zest .column_4 th:last-child {
    width: 22%; }
  body.zest .column_4 th:nth-child(2) {
    width: 40%; }
  body.zest .column_4 th:nth-child(3) {
    width: 16%; }

body.zest .flex_row td {
  width: 10%; }
  body.zest .flex_row td:first-child, body.zest .flex_row td:last-child {
    width: 22%; }
  body.zest .flex_row td:nth-child(6) {
    width: 16%; }

body.zest .width-20 {
  width: 20%;
  vertical-align: top; }

body.zest .margin-20 {
  margin-top: 20px !important; }

body.zest .margin-40 {
  margin-top: 40px !important; }

body.zest .pad-20 {
  padding-left: 20px; }

body.zest .no-margin-top {
  margin-top: 0; }

body.zest .no-margin-bottom {
  margin-bottom: 0; }

body.zest .text_regular {
  font-weight: 400; }

@media screen and (min-width: 640px) {
  body.yamatoda .module_instagram__list dt {
    width: 20%; } }

body.yamatoda .no-margin-top {
  margin-top: 0; }

body.yamatoda .no-margin-bottom {
  margin-bottom: 0; }

html.over_main_image body.zest .site_header a,
html.over_main_image body.yamatoda .site_header a {
  color: #fff; }

html.over_main_image body.zest .site_header .drawer_btn i,
html.over_main_image body.yamatoda .site_header .drawer_btn i {
  background-color: #fff; }

@media screen and (min-width: 640px) {
  body.kobe .page_staff__title .elem_main {
    font-size: 360%; } }

@media screen and (min-width: 640px) {
  body.kobe .page_staff__title .elem_bottom {
    letter-spacing: .02em; } }

@media screen and (max-width: 639px) {
  body.tokyo .cta_btn {
    display: block;
    position: fixed;
    z-index: 1001;
    right: 10px;
    bottom: 10px; } }

/*

movie page

*/
body.movie .page_main_image {
  height: 100vh; }
  @media screen and (min-width: 640px) {
    body.movie .page_main_image {
      background: url(../img/movie/hero_01.jpg) no-repeat center center;
      background-size: cover;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.movie .page_main_image {
      background: url(../img/movie/hero_01_sp.jpg) no-repeat center center;
      background-size: cover;
      max-height: 560px; } }
  body.movie .page_main_image .elem_title {
    margin-bottom: 5px; }
    body.movie .page_main_image .elem_title .jp b {
      font-family: Heebo,sans-serif;
      font-size: 80px;
      line-height: 1;
      font-weight: 900; }
      @media screen and (max-width: 639px) {
        body.movie .page_main_image .elem_title .jp b {
          font-size: 67px; } }
  body.movie .page_main_image .elem_copy {
    font-family: Heebo,sans-serif;
    font-size: 14px;
    font-weight: bold; }

body.movie .movie_section {
  margin: 0 auto;
  width: 1140px; }
  @media screen and (max-width: 639px) {
    body.movie .movie_section {
      width: auto;
      padding: 0 15px; } }
  body.movie .movie_section .movie_content {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 30px; }
    @media screen and (max-width: 639px) {
      body.movie .movie_section .movie_content {
        display: block; } }
    body.movie .movie_section .movie_content .movie_block {
      width: 555px; }
      @media screen and (max-width: 639px) {
        body.movie .movie_section .movie_content .movie_block {
          width: 100%; }
          body.movie .movie_section .movie_content .movie_block:not(:last-child) {
            margin-bottom: 35px; } }
      body.movie .movie_section .movie_content .movie_block .movie {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%; }
        body.movie .movie_section .movie_content .movie_block .movie iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
      body.movie .movie_section .movie_content .movie_block .title {
        font-size: 20px;
        color: #333;
        margin-top: 15px; }
        @media screen and (max-width: 639px) {
          body.movie .movie_section .movie_content .movie_block .title {
            font-size: 15px;
            margin-top: 10px; } }

/*

privacy page

*/
body.privacy .page_main_image {
  height: 100vh; }
  @media screen and (min-width: 640px) {
    body.privacy .page_main_image {
      background: url(../img/privacy/hero_01.jpg) no-repeat center center;
      background-size: cover;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.privacy .page_main_image {
      background: url(../img/privacy/hero_01_sp.jpg) no-repeat center center;
      background-size: cover;
      max-height: 560px; } }
  @media screen and (min-width: 640px) {
    body.privacy .page_main_image .elem_title .jp b {
      font-size: 533%;
      font-weight: 600;
      letter-spacing: 5px; } }
  @media screen and (max-width: 639px) {
    body.privacy .page_main_image .elem_title .jp b {
      font-size: 300%;
      font-weight: 700;
      letter-spacing: 3px; } }

@media screen and (min-width: 640px) {
  body.privacy .privacy_section {
    margin-top: 100px; }
    body.privacy .privacy_section:first-child {
      margin-top: 0; } }

@media screen and (max-width: 639px) {
  body.privacy .privacy_section {
    margin-top: 45px; }
    body.privacy .privacy_section:first-child {
      margin-top: 0; } }

@media screen and (min-width: 640px) {
  body.privacy .privacy_section__wrapper {
    margin-bottom: 60px; } }

@media screen and (max-width: 639px) {
  body.privacy .privacy_section__wrapper {
    margin-bottom: 40px; } }

body.privacy .privacy_section__description {
  line-height: 2.1; }
  @media screen and (min-width: 640px) {
    body.privacy .privacy_section__description {
      font-size: 133%;
      margin-bottom: 45px; } }
  @media screen and (max-width: 639px) {
    body.privacy .privacy_section__description {
      font-size: 100%;
      margin-bottom: 35px; } }

body.privacy .privacy_section__title {
  font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-feature-settings: "palt";
  letter-spacing: .04em; }
  @media screen and (min-width: 640px) {
    body.privacy .privacy_section__title {
      font-size: 233%; } }
  @media screen and (max-width: 639px) {
    body.privacy .privacy_section__title {
      font-size: 127%; } }
  body.privacy .privacy_section__title:before {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    top: -.1em;
    font-size: 114%; }
    @media screen and (max-width: 639px) {
      body.privacy .privacy_section__title:before {
        font-size: 133%;
        top: 0; } }

@media screen and (min-width: 640px) {
  body.privacy .privacy_section__title + body.privacy .privacy_section__contents {
    margin-top: 15px; } }

@media screen and (max-width: 639px) {
  body.privacy .privacy_section__title + body.privacy .privacy_section__contents {
    margin-top: 15px; } }

body.privacy .privacy_section__contents {
  line-height: 2.1; }
  @media screen and (min-width: 640px) {
    body.privacy .privacy_section__contents {
      margin-top: 20px;
      font-size: 133%; } }
  @media screen and (max-width: 639px) {
    body.privacy .privacy_section__contents {
      margin-top: 10px;
      font-size: 100%; } }
  body.privacy .privacy_section__contents a {
    text-decoration: underline; }
    body.privacy .privacy_section__contents a:hover {
      text-decoration: none; }

body.privacy .privacy_section__more {
  margin-top: 30px; }

* + body.privacy .privacy_section__headline {
  margin-top: 20px; }

body.privacy .privacy_section__headline {
  font-weight: bold; }
  @media screen and (max-width: 639px) {
    body.privacy .privacy_section__headline {
      font-size: 115%; } }
  body.privacy .privacy_section__headline + * {
    margin-top: 5px; }

/*

tackleinfo page

*/
html.over_main_image body.post-type-archive-tackle_info .icon--logo:before,
html.over_main_image body.post-type-archive-tackle_info .icon--facebook:before,
html.over_main_image body.post-type-archive-tackle_info .icon--instagram:before {
  color: #fff; }

html.over_main_image body.post-type-archive-tackle_info .drawer_btn i {
  background-color: #fff; }

body.post-type-archive-tackle_info .page_main_image .elem_title .en b {
  font-family: "Lato", sans-serif; }
  @media screen and (min-width: 640px) {
    body.post-type-archive-tackle_info .page_main_image .elem_title .en b {
      font-size: 533%;
      font-weight: 600;
      letter-spacing: 5px; } }
  @media screen and (max-width: 639px) {
    body.post-type-archive-tackle_info .page_main_image .elem_title .en b {
      font-size: 447%;
      font-weight: 700;
      letter-spacing: 3px; } }

body.post-type-archive-tackle_info .page_main_image .elem_lead .en {
  font-size: 93%;
  font-weight: 600;
  letter-spacing: 1px; }

@media screen and (max-width: 639px) {
  body.post-type-archive-tackle_info .page_content .column {
    margin-bottom: 40px; } }

body.post-type-archive-tackle_info .page_content .column:last-of-type {
  margin-bottom: 100px; }
  @media screen and (max-width: 639px) {
    body.post-type-archive-tackle_info .page_content .column:last-of-type {
      margin-bottom: 60px; } }

body.post-type-archive-tackle_info .page_content .column .content {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 639px) {
    body.post-type-archive-tackle_info .page_content .column .content .headline__secondary .jp {
      font-size: 147%; } }
  body.post-type-archive-tackle_info .page_content .column .content .text {
    margin-bottom: 30px; }
  body.post-type-archive-tackle_info .page_content .column .content .btn {
    margin-top: auto; }
    @media screen and (max-width: 639px) {
      body.post-type-archive-tackle_info .page_content .column .content .btn {
        text-align: center; } }

/*

terms page

*/
body.terms .page_main_image {
  height: 100vh; }
  @media screen and (min-width: 640px) {
    body.terms .page_main_image {
      background: url(../img/terms/hero_01.jpg) no-repeat center center;
      background-size: cover;
      max-height: 1000px; } }
  @media screen and (max-width: 639px) {
    body.terms .page_main_image {
      background: url(../img/terms/hero_01_sp.jpg) no-repeat center center;
      background-size: cover;
      max-height: 560px; } }
  @media screen and (min-width: 640px) {
    body.terms .page_main_image .elem_title .jp b {
      font-size: 533%;
      font-weight: 600;
      letter-spacing: 5px; } }
  @media screen and (max-width: 639px) {
    body.terms .page_main_image .elem_title .jp b {
      font-size: 300%;
      font-weight: 700;
      letter-spacing: 3px; } }

@media screen and (min-width: 640px) {
  body.terms .terms_section {
    margin-top: 100px; }
    body.terms .terms_section:first-child {
      margin-top: 0; } }

@media screen and (max-width: 639px) {
  body.terms .terms_section {
    margin-top: 45px; }
    body.terms .terms_section:first-child {
      margin-top: 0; } }

@media screen and (min-width: 640px) {
  body.terms .terms_section__wrapper {
    margin-bottom: 60px; } }

@media screen and (max-width: 639px) {
  body.terms .terms_section__wrapper {
    margin-bottom: 40px; } }

body.terms .terms_section__description {
  line-height: 2.1; }
  @media screen and (min-width: 640px) {
    body.terms .terms_section__description {
      font-size: 133%;
      margin-bottom: 45px; } }
  @media screen and (max-width: 639px) {
    body.terms .terms_section__description {
      font-size: 100%;
      margin-bottom: 35px; } }

body.terms .terms_section__title {
  font-family: arial, "Yu Gothic", YuGothic, "游ゴシック", arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-feature-settings: "palt";
  letter-spacing: .04em; }
  @media screen and (min-width: 640px) {
    body.terms .terms_section__title {
      font-size: 233%; } }
  @media screen and (max-width: 639px) {
    body.terms .terms_section__title {
      font-size: 127%; } }
  body.terms .terms_section__title:before {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    top: -.1em;
    font-size: 114%; }
    @media screen and (max-width: 639px) {
      body.terms .terms_section__title:before {
        font-size: 133%;
        top: 0; } }

@media screen and (min-width: 640px) {
  body.terms .terms_section__title + body.terms .terms_section__contents {
    margin-top: 15px; } }

@media screen and (max-width: 639px) {
  body.terms .terms_section__title + body.terms .terms_section__contents {
    margin-top: 15px; } }

body.terms .terms_section__contents {
  line-height: 2.1; }
  @media screen and (min-width: 640px) {
    body.terms .terms_section__contents {
      margin-top: 20px;
      font-size: 133%; } }
  @media screen and (max-width: 639px) {
    body.terms .terms_section__contents {
      margin-top: 10px;
      font-size: 100%; } }
  body.terms .terms_section__contents a {
    text-decoration: underline; }
    body.terms .terms_section__contents a:hover {
      text-decoration: none; }

body.terms .terms_section__more {
  margin-top: 30px; }

* + body.terms .terms_section__headline {
  margin-top: 20px; }

body.terms .terms_section__headline {
  font-weight: bold; }
  @media screen and (max-width: 639px) {
    body.terms .terms_section__headline {
      font-size: 115%; } }
  body.terms .terms_section__headline + * {
    margin-top: 5px; }

/*

home page

*/
body.home .splitting .word {
  overflow-y: hidden;
  line-height: 1.2; }

body.home .splitting .char {
  animation: up-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
  animation-delay: calc(40ms * var(--char-index));
  display: inline-block; }

@keyframes up-in {
  from {
    transform: translateY(1em);
    opacity: 0; } }

body.home .home_content {
  overflow: hidden; }
  @media screen and (min-width: 640px) {
    body.home .home_content {
      margin-bottom: 80px; } }
  @media screen and (max-width: 639px) {
    body.home .home_content {
      margin-bottom: 50px; } }

body.home .home_main_image__inner {
  padding: 9vw 0 0;
  background-color: #fff; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__inner {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: flex-end;
      align-items: flex-end; } }
  @media screen and (max-width: 639px) {
    body.home .home_main_image__inner {
      width: 92%;
      margin: 0 auto; } }

body.home .home_main_image__title {
  font-weight: 600;
  letter-spacing: 4px;
  position: fixed;
  z-index: 10;
  line-height: 1;
  transition: color .2s; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__title {
      top: 7.8vw;
      left: 9%;
      font-size: 7.9vw; } }
  @media screen and (max-width: 639px) {
    body.home .home_main_image__title {
      top: 24vw;
      left: 4%;
      font-size: 360%; } }
  body.home .home_main_image__title--tracer {
    letter-spacing: 4px;
    z-index: 10;
    transition: color .2s;
    opacity: 0;
    font-size: 7.9vw;
    margin-left: 9%;
    position: relative;
    z-index: 10; }
    @media screen and (min-width: 640px) {
      body.home .home_main_image__title--tracer {
        top: -100px; } }
    @media screen and (max-width: 639px) {
      body.home .home_main_image__title--tracer {
        font-size: 360%;
        margin-left: 4%;
        margin-top: 5vw; } }
    body.home .home_main_image__title--tracer b {
      font-weight: 600; }
  body.home .home_main_image__title b {
    color: #2F6CB5;
    font-weight: 600;
    transition: color .2s; }
  body.home .home_main_image__title.is_onlayer {
    color: #fff; }
    body.home .home_main_image__title.is_onlayer b {
      color: #fff; }
  body.home .home_main_image__title.is_static {
    position: absolute;
    z-index: 100;
    color: #fff; }
    body.home .home_main_image__title.is_static b {
      color: #fff; }
    @media screen and (min-width: 640px) {
      body.home .home_main_image__title.is_static {
        left: 9%;
        top: auto;
        margin-top: -200px; } }
    @media screen and (max-width: 639px) {
      body.home .home_main_image__title.is_static {
        top: 5vw;
        left: 4%; } }

body.home .home_main_image__catch {
  color: #fff;
  line-height: 2;
  font-weight: 500;
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 0; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__catch {
      z-index: 20;
      top: 50%;
      right: 9%;
      width: 35%;
      transform: translateY(-50%);
      letter-spacing: 1px;
      position: absolute;
      font-size: 107%; } }
  @media screen and (max-width: 639px) {
    body.home .home_main_image__catch {
      position: relative;
      margin: 20px auto 30px;
      width: 92%;
      font-size: 93%;
      z-index: 10; } }

body.home .home_main_image__text {
  background-color: #fff;
  position: relative;
  z-index: 20;
  padding-bottom: 7vw;
  box-sizing: border-box; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__text {
      width: 50%;
      margin-left: 9%; } }
  @media screen and (max-width: 639px) {
    body.home .home_main_image__text {
      margin-top: 280px;
      margin-left: 0;
      padding-top: 10px; } }

body.home .home_main_image__lead {
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  margin-top: 20px; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__lead {
      font-size: 3vw;
      letter-spacing: 3px; } }
  @media screen and (max-width: 639px) {
    body.home .home_main_image__lead {
      font-size: 160%;
      letter-spacing: 2px; } }

body.home .home_main_image__sublead {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-top: 5px; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__sublead {
      font-size: 1.1vw;
      letter-spacing: 2px; } }
  @media screen and (max-width: 639px) {
    body.home .home_main_image__sublead {
      font-size: 80%;
      letter-spacing: 1px; } }

body.home .home_main_image .slick {
  opacity: 0; }
  body.home .home_main_image .slick.slick-initialized {
    opacity: 1; }

body.home .home_main_image__gallery {
  padding-bottom: 9vw;
  background-color: #fff;
  position: relative;
  z-index: 20;
  overflow-y: hidden; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__gallery {
      width: 42%; } }

body.home .home_main_image__video {
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 640px) {
    body.home .home_main_image__video {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;
      padding: 120px 0; } }
  body.home .home_main_image__video video {
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (min-width: 640px) {
      body.home .home_main_image__video video {
        width: 100%;
        height: auto; } }

body.home .home_main_image__scroll {
  position: relative;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  line-height: 1;
  font-size: 87%;
  letter-spacing: 1px;
  margin-top: 30px; }
  body.home .home_main_image__scroll span {
    display: inline-block;
    vertical-align: middle;
    width: 46px;
    margin-right: 15px; }

@media screen and (max-width: 639px) {
  body.home .home_concept .column {
    margin-bottom: 0; } }

@media screen and (max-width: 639px) {
  body.home .home_concept .column .row {
    -webkit-flex-direction: column;
    flex-direction: column; } }

@media screen and (max-width: 639px) {
  body.home .home_concept .column .row .cell {
    width: 108%;
    margin-left: -4%; } }

@media screen and (max-width: 639px) {
  body.home .home_point {
    width: 92%;
    margin: 0 auto; } }

@media screen and (min-width: 640px) {
  body.home .home_point__item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    padding: 50px 0; } }

@media screen and (max-width: 639px) {
  body.home .home_point__item {
    padding: 0;
    margin-top: 30px; } }

@media screen and (max-width: 639px) {
  body.home .home_point__item:first-of-type {
    margin-top: 0; } }

@media screen and (min-width: 640px) {
  body.home .home_point__content {
    padding-left: 9%;
    width: 45%; } }

@media screen and (max-width: 639px) {
  body.home .home_point__content {
    margin-bottom: 40px; } }

body.home .home_point__thumb {
  position: relative; }
  @media screen and (min-width: 640px) {
    body.home .home_point__thumb {
      width: 38%; } }
  body.home .home_point__thumb .num {
    position: absolute;
    right: 30px;
    top: -.5em;
    z-index: 10;
    color: #2F6CB5;
    font-size: 540%;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    line-height: 1; }
    @media screen and (max-width: 639px) {
      body.home .home_point__thumb .num {
        font-size: 353%; } }
    body.home .home_point__thumb .num:first-letter {
      color: #333333; }

@media screen and (min-width: 640px) {
  body.home .home_place {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center; } }

@media screen and (max-width: 639px) {
  body.home .home_place {
    width: 92%;
    margin: 0 auto;
    padding-top: 0; } }

body.home .home_place__item {
  padding-bottom: 20px; }
  @media screen and (min-width: 640px) {
    body.home .home_place__item {
      width: 49.5%;
      border-bottom: 1px solid #D6D5D5;
      margin-bottom: 25px; } }
  @media screen and (max-width: 639px) {
    body.home .home_place__item {
      margin-bottom: 0; } }
  body.home .home_place__item a {
    position: relative;
    display: block;
    padding: 7.5vw 0;
    background-position: center bottom;
    background-size: cover;
    font-family: "Heebo", sans-serif;
    font-weight: 600; }
    @media screen and (max-width: 639px) {
      body.home .home_place__item a {
        padding: 50px 0; } }
    body.home .home_place__item a:hover {
      text-decoration: none; }
      body.home .home_place__item a:hover .home_place__btn {
        background-color: #2F6CB5;
        color: #fff; }
        body.home .home_place__item a:hover .home_place__btn span::before {
          border-color: #fff; }
        body.home .home_place__item a:hover .home_place__btn span::after {
          border-color: #fff; }
    body.home .home_place__item a.kobe {
      background-image: url(../img/top/image_location_01.jpg); }
    body.home .home_place__item a.kix {
      background-image: url(../img/top/image_location_02.jpg); }
    body.home .home_place__item a.zest {
      background-image: url(../img/top/image_location_03.jpg); }
    body.home .home_place__item a.yamatoda {
      background-image: url(../img/top/image_location_04.jpg); }
    body.home .home_place__item a.tokyo {
      background-image: url(../img/top/image_location_05.jpg); }

body.home .home_place__name {
  text-align: center;
  color: #fff;
  letter-spacing: 2px; }
  @media screen and (min-width: 640px) {
    body.home .home_place__name {
      font-size: 380%; } }
  @media screen and (max-width: 639px) {
    body.home .home_place__name {
      font-size: 193%; } }

body.home .home_place__btn {
  position: absolute;
  right: 0;
  bottom: -20px;
  background-color: #fff;
  width: 60%;
  color: #333333;
  transition: background-color .3s; }
  @media screen and (min-width: 640px) {
    body.home .home_place__btn {
      font-size: 167%; } }
  @media screen and (max-width: 1300px) {
    body.home .home_place__btn {
      font-size: 147%; } }
  @media screen and (max-width: 639px) {
    body.home .home_place__btn {
      font-size: 80%; } }
  body.home .home_place__btn span {
    display: block;
    position: relative;
    padding: 20px 25px; }
    @media screen and (min-width: 640px) {
      body.home .home_place__btn span {
        padding: 20px 25px; } }
    @media screen and (max-width: 639px) {
      body.home .home_place__btn span {
        padding: 10px 15px; } }
    body.home .home_place__btn span::before {
      content: "";
      display: block;
      border: #ACACAC 1px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: border-color .3s; }
      @media screen and (min-width: 640px) {
        body.home .home_place__btn span::before {
          width: 34px;
          height: 34px;
          right: 25px; } }
      @media screen and (max-width: 639px) {
        body.home .home_place__btn span::before {
          width: 17px;
          height: 17px;
          right: 15px; } }
    body.home .home_place__btn span::after {
      content: "";
      display: block;
      border-right: 1px solid #707070;
      border-bottom: 1px solid #707070;
      transform: rotate(-45deg) translateY(-50%);
      position: absolute;
      top: 50%;
      transition: border-color .3s; }
      @media screen and (min-width: 640px) {
        body.home .home_place__btn span::after {
          right: 37px;
          width: 8px;
          height: 8px; } }
      @media screen and (max-width: 639px) {
        body.home .home_place__btn span::after {
          right: 20px;
          width: 5px;
          height: 5px; } }

@media screen and (min-width: 640px) {
  body.home .home_modal__inner {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; } }

body.home .home_modal__inner > div {
  margin-bottom: 20px; }
  @media screen and (min-width: 640px) {
    body.home .home_modal__inner > div {
      width: 49.3%; } }
  body.home .home_modal__inner > div.wide {
    width: 100%; }
  body.home .home_modal__inner > div a {
    display: block; }
    @media screen and (max-width: 639px) {
      body.home .home_modal__inner > div a {
        min-height: 140px; } }
    body.home .home_modal__inner > div a:hover figure {
      outline-offset: 0;
      opacity: .8; }
    body.home .home_modal__inner > div a figure {
      position: relative;
      outline: 1px solid #fff;
      outline-offset: -12px;
      transition: outline-offset .2s, opacity .2s; }
      body.home .home_modal__inner > div a figure img {
        transition: filter .2s; }
      body.home .home_modal__inner > div a figure figcaption {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);
        color: #fff;
        font-weight: 500;
        letter-spacing: 1px; }
        @media screen and (min-width: 640px) {
          body.home .home_modal__inner > div a figure figcaption {
            font-size: 147%; } }
        @media screen and (max-width: 639px) {
          body.home .home_modal__inner > div a figure figcaption {
            font-size: 93%;
            width: 100%;
            text-align: center; } }

@media screen and (min-width: 640px) {
  body.home .home_information__list {
    width: 72%;
    margin: 0 auto; } }

body.home .home_information__item {
  border-bottom: 1px solid #D6D5D5;
  display: block;
  color: #333333;
  padding: 10px 0;
  line-height: 1.7; }
  @media screen and (min-width: 640px) {
    body.home .home_information__item {
      font-size: 93%;
      margin-bottom: 15px; } }
  @media screen and (max-width: 639px) {
    body.home .home_information__item {
      font-size: 93%;
      margin-bottom: 0; } }
  body.home .home_information__item time {
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    float: left; }
    @media screen and (min-width: 640px) {
      body.home .home_information__item time {
        margin-right: 30px; } }
    @media screen and (max-width: 639px) {
      body.home .home_information__item time {
        display: block;
        float: none;
        margin-bottom: 5px; } }
  body.home .home_information__item > span {
    display: block;
    overflow: hidden; }

body.home .home_information__btn {
  text-align: center;
  margin-top: 60px; }
  @media screen and (max-width: 639px) {
    body.home .home_information__btn {
      margin-top: 30px; } }

body.home .site_footer__reservation {
  display: none; }

/* Utility */
/*for modern browser*/
.clear_fix:after, .grid--12:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  font-size: 0;
  line-height: 0;
  visibility: hidden; }

/*for IE 5.5-7*/
.clear_fix, .grid--12 {
  zoom: 1; }

.clear_both {
  clear: both;
  height: 1px;
  font-size: 1px;
  text-align: center;
  line-height: 0; }

.clear {
  clear: both; }

.align_l {
  text-align: left !important; }

.align_r {
  text-align: right !important; }

.align_c {
  text-align: center !important; }

.valign_m {
  vertical-align: middle; }

.valign_t {
  vertical-align: top; }

.valign_b {
  vertical-align: bottom; }

@media screen and (max-width: 1139px) {
  .liquid_align_l {
    text-align: left !important; }
  .liquid_align_r {
    text-align: right !important; }
  .liquid_align_c {
    text-align: center !important; }
  .liquid_valign_m {
    vertical-align: middle; }
  .liquid_valign_t {
    vertical-align: top; }
  .liquid_valign_b {
    vertical-align: bottom; } }

@media screen and (max-width: 639px) {
  .sp_align_l {
    text-align: left !important; }
  .sp_align_r {
    text-align: right !important; }
  .sp_align_c {
    text-align: center !important; }
  .sp_valign_m {
    vertical-align: middle; }
  .sp_valign_t {
    vertical-align: top; }
  .sp_valign_b {
    vertical-align: bottom; } }

.float_r {
  float: right;
  display: inline; }

.float_l {
  float: left;
  display: inline; }

/*

font

*/
.smaller {
  font-size: 85% !important; }

.larger {
  font-size: larger !important; }

.bold {
  font-weight: bold !important; }

.weight_normal {
  font-weight: normal !important; }

.style_normal {
  font-style: normal !important; }

/*

line height

*/
.lh_l {
  line-height: 1.75 !important; }

.lh_m {
  line-height: 1.5 !important; }

.lh_s {
  line-height: 1.3 !important; }

.lh_xs {
  line-height: 1 !important; }

/*

kerning

*/
.kerning {
  letter-spacing: -0.25em !important; }

/*

font-feature-settings

*/
.ffs_palt {
  font-feature-settings: "palt" !important; }

.ffs_normal {
  font-feature-settings: normal !important; }

/*

display用クラス

PC向け
.none
.block
.inline_block
.inline
.table
.table_cell

タブレット向け
.tablet_none
.tablet_block
.tablet_inline_block
.tablet_inline
.tablet_table
.tablet_table_cell

スマホ向け
.sp_none
.sp_block
.sp_inline_block
.sp_inline
.sp_table
.sp_table_cell

*/
.none {
  display: none !important; }

.block {
  display: block !important; }

.inline_block {
  display: inline-block  !important; }

.inline {
  display: inline !important; }

.table {
  display: table !important; }

.table_cell {
  display: table-cell !important; }

@media screen and (max-width: 1139px) {
  .liquid_none {
    display: none !important; } }

@media screen and (max-width: 639px) {
  .sp_none {
    display: none !important; } }

@media screen and (max-width: 1139px) {
  .liquid_flex {
    display: flex !important; }
  .liquid_block {
    display: block !important; }
  .liquid_inline_block {
    display: inline-block !important; }
  .liquid_inline {
    display: inline !important; }
  .liquid_table {
    display: table !important; }
  .liquid_cell {
    display: table-cell !important; } }

@media screen and (max-width: 639px) {
  .sp_flex {
    display: flex !important; }
  .sp_block {
    display: block !important; }
  .sp_inline_block {
    display: inline-block !important; }
  .sp_inline {
    display: inline !important; }
  .sp_table {
    display: table !important; }
  .sp_cell {
    display: table-cell !important; } }

/*

fluid image

*/
.fluid_img {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* IE8 */ }

/*

justify layout

```html
<ul class="just_layout">
	<li class ="just_layout__item"></li>
	<li class ="just_layout__item"></li>
</ul>
```

*/
.just_layout {
  text-align: justify;
  text-justify: distribute-all-lines;
  zoom: 1;
  display: block;
  line-height: 0; }
  .just_layout:after {
    line-height: 0;
    visibility: hidden;
    content: "";
    display: inline-block;
    width: 100%; }
  .just_layout__item {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    line-height: normal; }

* html .just_layout .just_layout__item {
  display: inline;
  zoom: 1; }

*:first-child + html .just_layout .just_layout__item {
  display: inline;
  zoom: 1; }

/*

margin and padding

5刻みで上下左右のマージンとパディングのクラスを定義
$start_value x 5の値から、$end_value x 5 までを設定

```class
.mb-5
.mt-5
.mr-5
.ml-5

.mb0
.mt0
.mr0
.ml0

.mb5
.mt5
.mr5
.ml5

.mb10
.mt10
.mr10
.ml10

.pb-5
.pt-5
.pr-5
.pl-5

.pb0
.pt0
.pr0
.pl0

.pb5
.pt5
.pr5
.pl5

.pb10
.pt10
.pr10
.pl10
```

レスポンシブ用に$breakpointsのキーを接頭辞にしたクラスも書き出します。
```class
.tablet_mb10
.sp_mb10
```

*/
.mb-50 {
  margin-bottom: -50px !important; }

.mt-50 {
  margin-top: -50px !important; }

.mr-50 {
  margin-right: -50px !important; }

.ml-50 {
  margin-left: -50px !important; }

.mb-45 {
  margin-bottom: -45px !important; }

.mt-45 {
  margin-top: -45px !important; }

.mr-45 {
  margin-right: -45px !important; }

.ml-45 {
  margin-left: -45px !important; }

.mb-40 {
  margin-bottom: -40px !important; }

.mt-40 {
  margin-top: -40px !important; }

.mr-40 {
  margin-right: -40px !important; }

.ml-40 {
  margin-left: -40px !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.mt-35 {
  margin-top: -35px !important; }

.mr-35 {
  margin-right: -35px !important; }

.ml-35 {
  margin-left: -35px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mr-30 {
  margin-right: -30px !important; }

.ml-30 {
  margin-left: -30px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mr-25 {
  margin-right: -25px !important; }

.ml-25 {
  margin-left: -25px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mr-20 {
  margin-right: -20px !important; }

.ml-20 {
  margin-left: -20px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mr-15 {
  margin-right: -15px !important; }

.ml-15 {
  margin-left: -15px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mr-10 {
  margin-right: -10px !important; }

.ml-10 {
  margin-left: -10px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.mt-5 {
  margin-top: -5px !important; }

.mr-5 {
  margin-right: -5px !important; }

.ml-5 {
  margin-left: -5px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

@media screen and (max-width: 1139px) {
  .liquid_mb-50 {
    margin-bottom: -50px !important; }
  .liquid_mt-50 {
    margin-top: -50px !important; }
  .liquid_mr-50 {
    margin-right: -50px !important; }
  .liquid_ml-50 {
    margin-left: -50px !important; }
  .liquid_mb-45 {
    margin-bottom: -45px !important; }
  .liquid_mt-45 {
    margin-top: -45px !important; }
  .liquid_mr-45 {
    margin-right: -45px !important; }
  .liquid_ml-45 {
    margin-left: -45px !important; }
  .liquid_mb-40 {
    margin-bottom: -40px !important; }
  .liquid_mt-40 {
    margin-top: -40px !important; }
  .liquid_mr-40 {
    margin-right: -40px !important; }
  .liquid_ml-40 {
    margin-left: -40px !important; }
  .liquid_mb-35 {
    margin-bottom: -35px !important; }
  .liquid_mt-35 {
    margin-top: -35px !important; }
  .liquid_mr-35 {
    margin-right: -35px !important; }
  .liquid_ml-35 {
    margin-left: -35px !important; }
  .liquid_mb-30 {
    margin-bottom: -30px !important; }
  .liquid_mt-30 {
    margin-top: -30px !important; }
  .liquid_mr-30 {
    margin-right: -30px !important; }
  .liquid_ml-30 {
    margin-left: -30px !important; }
  .liquid_mb-25 {
    margin-bottom: -25px !important; }
  .liquid_mt-25 {
    margin-top: -25px !important; }
  .liquid_mr-25 {
    margin-right: -25px !important; }
  .liquid_ml-25 {
    margin-left: -25px !important; }
  .liquid_mb-20 {
    margin-bottom: -20px !important; }
  .liquid_mt-20 {
    margin-top: -20px !important; }
  .liquid_mr-20 {
    margin-right: -20px !important; }
  .liquid_ml-20 {
    margin-left: -20px !important; }
  .liquid_mb-15 {
    margin-bottom: -15px !important; }
  .liquid_mt-15 {
    margin-top: -15px !important; }
  .liquid_mr-15 {
    margin-right: -15px !important; }
  .liquid_ml-15 {
    margin-left: -15px !important; }
  .liquid_mb-10 {
    margin-bottom: -10px !important; }
  .liquid_mt-10 {
    margin-top: -10px !important; }
  .liquid_mr-10 {
    margin-right: -10px !important; }
  .liquid_ml-10 {
    margin-left: -10px !important; }
  .liquid_mb-5 {
    margin-bottom: -5px !important; }
  .liquid_mt-5 {
    margin-top: -5px !important; }
  .liquid_mr-5 {
    margin-right: -5px !important; }
  .liquid_ml-5 {
    margin-left: -5px !important; }
  .liquid_mb0 {
    margin-bottom: 0px !important; }
  .liquid_mt0 {
    margin-top: 0px !important; }
  .liquid_mr0 {
    margin-right: 0px !important; }
  .liquid_ml0 {
    margin-left: 0px !important; }
  .liquid_mb5 {
    margin-bottom: 5px !important; }
  .liquid_mt5 {
    margin-top: 5px !important; }
  .liquid_mr5 {
    margin-right: 5px !important; }
  .liquid_ml5 {
    margin-left: 5px !important; }
  .liquid_mb10 {
    margin-bottom: 10px !important; }
  .liquid_mt10 {
    margin-top: 10px !important; }
  .liquid_mr10 {
    margin-right: 10px !important; }
  .liquid_ml10 {
    margin-left: 10px !important; }
  .liquid_mb15 {
    margin-bottom: 15px !important; }
  .liquid_mt15 {
    margin-top: 15px !important; }
  .liquid_mr15 {
    margin-right: 15px !important; }
  .liquid_ml15 {
    margin-left: 15px !important; }
  .liquid_mb20 {
    margin-bottom: 20px !important; }
  .liquid_mt20 {
    margin-top: 20px !important; }
  .liquid_mr20 {
    margin-right: 20px !important; }
  .liquid_ml20 {
    margin-left: 20px !important; }
  .liquid_mb25 {
    margin-bottom: 25px !important; }
  .liquid_mt25 {
    margin-top: 25px !important; }
  .liquid_mr25 {
    margin-right: 25px !important; }
  .liquid_ml25 {
    margin-left: 25px !important; }
  .liquid_mb30 {
    margin-bottom: 30px !important; }
  .liquid_mt30 {
    margin-top: 30px !important; }
  .liquid_mr30 {
    margin-right: 30px !important; }
  .liquid_ml30 {
    margin-left: 30px !important; }
  .liquid_mb35 {
    margin-bottom: 35px !important; }
  .liquid_mt35 {
    margin-top: 35px !important; }
  .liquid_mr35 {
    margin-right: 35px !important; }
  .liquid_ml35 {
    margin-left: 35px !important; }
  .liquid_mb40 {
    margin-bottom: 40px !important; }
  .liquid_mt40 {
    margin-top: 40px !important; }
  .liquid_mr40 {
    margin-right: 40px !important; }
  .liquid_ml40 {
    margin-left: 40px !important; }
  .liquid_mb45 {
    margin-bottom: 45px !important; }
  .liquid_mt45 {
    margin-top: 45px !important; }
  .liquid_mr45 {
    margin-right: 45px !important; }
  .liquid_ml45 {
    margin-left: 45px !important; }
  .liquid_mb50 {
    margin-bottom: 50px !important; }
  .liquid_mt50 {
    margin-top: 50px !important; }
  .liquid_mr50 {
    margin-right: 50px !important; }
  .liquid_ml50 {
    margin-left: 50px !important; }
  .liquid_mb55 {
    margin-bottom: 55px !important; }
  .liquid_mt55 {
    margin-top: 55px !important; }
  .liquid_mr55 {
    margin-right: 55px !important; }
  .liquid_ml55 {
    margin-left: 55px !important; }
  .liquid_mb60 {
    margin-bottom: 60px !important; }
  .liquid_mt60 {
    margin-top: 60px !important; }
  .liquid_mr60 {
    margin-right: 60px !important; }
  .liquid_ml60 {
    margin-left: 60px !important; }
  .liquid_mb65 {
    margin-bottom: 65px !important; }
  .liquid_mt65 {
    margin-top: 65px !important; }
  .liquid_mr65 {
    margin-right: 65px !important; }
  .liquid_ml65 {
    margin-left: 65px !important; }
  .liquid_mb70 {
    margin-bottom: 70px !important; }
  .liquid_mt70 {
    margin-top: 70px !important; }
  .liquid_mr70 {
    margin-right: 70px !important; }
  .liquid_ml70 {
    margin-left: 70px !important; }
  .liquid_mb75 {
    margin-bottom: 75px !important; }
  .liquid_mt75 {
    margin-top: 75px !important; }
  .liquid_mr75 {
    margin-right: 75px !important; }
  .liquid_ml75 {
    margin-left: 75px !important; }
  .liquid_mb80 {
    margin-bottom: 80px !important; }
  .liquid_mt80 {
    margin-top: 80px !important; }
  .liquid_mr80 {
    margin-right: 80px !important; }
  .liquid_ml80 {
    margin-left: 80px !important; } }

@media screen and (max-width: 639px) {
  .sp_mb-50 {
    margin-bottom: -50px !important; }
  .sp_mt-50 {
    margin-top: -50px !important; }
  .sp_mr-50 {
    margin-right: -50px !important; }
  .sp_ml-50 {
    margin-left: -50px !important; }
  .sp_mb-45 {
    margin-bottom: -45px !important; }
  .sp_mt-45 {
    margin-top: -45px !important; }
  .sp_mr-45 {
    margin-right: -45px !important; }
  .sp_ml-45 {
    margin-left: -45px !important; }
  .sp_mb-40 {
    margin-bottom: -40px !important; }
  .sp_mt-40 {
    margin-top: -40px !important; }
  .sp_mr-40 {
    margin-right: -40px !important; }
  .sp_ml-40 {
    margin-left: -40px !important; }
  .sp_mb-35 {
    margin-bottom: -35px !important; }
  .sp_mt-35 {
    margin-top: -35px !important; }
  .sp_mr-35 {
    margin-right: -35px !important; }
  .sp_ml-35 {
    margin-left: -35px !important; }
  .sp_mb-30 {
    margin-bottom: -30px !important; }
  .sp_mt-30 {
    margin-top: -30px !important; }
  .sp_mr-30 {
    margin-right: -30px !important; }
  .sp_ml-30 {
    margin-left: -30px !important; }
  .sp_mb-25 {
    margin-bottom: -25px !important; }
  .sp_mt-25 {
    margin-top: -25px !important; }
  .sp_mr-25 {
    margin-right: -25px !important; }
  .sp_ml-25 {
    margin-left: -25px !important; }
  .sp_mb-20 {
    margin-bottom: -20px !important; }
  .sp_mt-20 {
    margin-top: -20px !important; }
  .sp_mr-20 {
    margin-right: -20px !important; }
  .sp_ml-20 {
    margin-left: -20px !important; }
  .sp_mb-15 {
    margin-bottom: -15px !important; }
  .sp_mt-15 {
    margin-top: -15px !important; }
  .sp_mr-15 {
    margin-right: -15px !important; }
  .sp_ml-15 {
    margin-left: -15px !important; }
  .sp_mb-10 {
    margin-bottom: -10px !important; }
  .sp_mt-10 {
    margin-top: -10px !important; }
  .sp_mr-10 {
    margin-right: -10px !important; }
  .sp_ml-10 {
    margin-left: -10px !important; }
  .sp_mb-5 {
    margin-bottom: -5px !important; }
  .sp_mt-5 {
    margin-top: -5px !important; }
  .sp_mr-5 {
    margin-right: -5px !important; }
  .sp_ml-5 {
    margin-left: -5px !important; }
  .sp_mb0 {
    margin-bottom: 0px !important; }
  .sp_mt0 {
    margin-top: 0px !important; }
  .sp_mr0 {
    margin-right: 0px !important; }
  .sp_ml0 {
    margin-left: 0px !important; }
  .sp_mb5 {
    margin-bottom: 5px !important; }
  .sp_mt5 {
    margin-top: 5px !important; }
  .sp_mr5 {
    margin-right: 5px !important; }
  .sp_ml5 {
    margin-left: 5px !important; }
  .sp_mb10 {
    margin-bottom: 10px !important; }
  .sp_mt10 {
    margin-top: 10px !important; }
  .sp_mr10 {
    margin-right: 10px !important; }
  .sp_ml10 {
    margin-left: 10px !important; }
  .sp_mb15 {
    margin-bottom: 15px !important; }
  .sp_mt15 {
    margin-top: 15px !important; }
  .sp_mr15 {
    margin-right: 15px !important; }
  .sp_ml15 {
    margin-left: 15px !important; }
  .sp_mb20 {
    margin-bottom: 20px !important; }
  .sp_mt20 {
    margin-top: 20px !important; }
  .sp_mr20 {
    margin-right: 20px !important; }
  .sp_ml20 {
    margin-left: 20px !important; }
  .sp_mb25 {
    margin-bottom: 25px !important; }
  .sp_mt25 {
    margin-top: 25px !important; }
  .sp_mr25 {
    margin-right: 25px !important; }
  .sp_ml25 {
    margin-left: 25px !important; }
  .sp_mb30 {
    margin-bottom: 30px !important; }
  .sp_mt30 {
    margin-top: 30px !important; }
  .sp_mr30 {
    margin-right: 30px !important; }
  .sp_ml30 {
    margin-left: 30px !important; }
  .sp_mb35 {
    margin-bottom: 35px !important; }
  .sp_mt35 {
    margin-top: 35px !important; }
  .sp_mr35 {
    margin-right: 35px !important; }
  .sp_ml35 {
    margin-left: 35px !important; }
  .sp_mb40 {
    margin-bottom: 40px !important; }
  .sp_mt40 {
    margin-top: 40px !important; }
  .sp_mr40 {
    margin-right: 40px !important; }
  .sp_ml40 {
    margin-left: 40px !important; }
  .sp_mb45 {
    margin-bottom: 45px !important; }
  .sp_mt45 {
    margin-top: 45px !important; }
  .sp_mr45 {
    margin-right: 45px !important; }
  .sp_ml45 {
    margin-left: 45px !important; }
  .sp_mb50 {
    margin-bottom: 50px !important; }
  .sp_mt50 {
    margin-top: 50px !important; }
  .sp_mr50 {
    margin-right: 50px !important; }
  .sp_ml50 {
    margin-left: 50px !important; }
  .sp_mb55 {
    margin-bottom: 55px !important; }
  .sp_mt55 {
    margin-top: 55px !important; }
  .sp_mr55 {
    margin-right: 55px !important; }
  .sp_ml55 {
    margin-left: 55px !important; }
  .sp_mb60 {
    margin-bottom: 60px !important; }
  .sp_mt60 {
    margin-top: 60px !important; }
  .sp_mr60 {
    margin-right: 60px !important; }
  .sp_ml60 {
    margin-left: 60px !important; }
  .sp_mb65 {
    margin-bottom: 65px !important; }
  .sp_mt65 {
    margin-top: 65px !important; }
  .sp_mr65 {
    margin-right: 65px !important; }
  .sp_ml65 {
    margin-left: 65px !important; }
  .sp_mb70 {
    margin-bottom: 70px !important; }
  .sp_mt70 {
    margin-top: 70px !important; }
  .sp_mr70 {
    margin-right: 70px !important; }
  .sp_ml70 {
    margin-left: 70px !important; }
  .sp_mb75 {
    margin-bottom: 75px !important; }
  .sp_mt75 {
    margin-top: 75px !important; }
  .sp_mr75 {
    margin-right: 75px !important; }
  .sp_ml75 {
    margin-left: 75px !important; }
  .sp_mb80 {
    margin-bottom: 80px !important; }
  .sp_mt80 {
    margin-top: 80px !important; }
  .sp_mr80 {
    margin-right: 80px !important; }
  .sp_ml80 {
    margin-left: 80px !important; } }

.pb0 {
  padding-bottom: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

@media screen and (max-width: 1139px) {
  .liquid_pb0 {
    padding-bottom: 0px !important; }
  .liquid_pt0 {
    padding-top: 0px !important; }
  .liquid_pr0 {
    padding-right: 0px !important; }
  .liquid_pl0 {
    padding-left: 0px !important; }
  .liquid_pb5 {
    padding-bottom: 5px !important; }
  .liquid_pt5 {
    padding-top: 5px !important; }
  .liquid_pr5 {
    padding-right: 5px !important; }
  .liquid_pl5 {
    padding-left: 5px !important; }
  .liquid_pb10 {
    padding-bottom: 10px !important; }
  .liquid_pt10 {
    padding-top: 10px !important; }
  .liquid_pr10 {
    padding-right: 10px !important; }
  .liquid_pl10 {
    padding-left: 10px !important; }
  .liquid_pb15 {
    padding-bottom: 15px !important; }
  .liquid_pt15 {
    padding-top: 15px !important; }
  .liquid_pr15 {
    padding-right: 15px !important; }
  .liquid_pl15 {
    padding-left: 15px !important; }
  .liquid_pb20 {
    padding-bottom: 20px !important; }
  .liquid_pt20 {
    padding-top: 20px !important; }
  .liquid_pr20 {
    padding-right: 20px !important; }
  .liquid_pl20 {
    padding-left: 20px !important; }
  .liquid_pb25 {
    padding-bottom: 25px !important; }
  .liquid_pt25 {
    padding-top: 25px !important; }
  .liquid_pr25 {
    padding-right: 25px !important; }
  .liquid_pl25 {
    padding-left: 25px !important; }
  .liquid_pb30 {
    padding-bottom: 30px !important; }
  .liquid_pt30 {
    padding-top: 30px !important; }
  .liquid_pr30 {
    padding-right: 30px !important; }
  .liquid_pl30 {
    padding-left: 30px !important; }
  .liquid_pb35 {
    padding-bottom: 35px !important; }
  .liquid_pt35 {
    padding-top: 35px !important; }
  .liquid_pr35 {
    padding-right: 35px !important; }
  .liquid_pl35 {
    padding-left: 35px !important; }
  .liquid_pb40 {
    padding-bottom: 40px !important; }
  .liquid_pt40 {
    padding-top: 40px !important; }
  .liquid_pr40 {
    padding-right: 40px !important; }
  .liquid_pl40 {
    padding-left: 40px !important; }
  .liquid_pb45 {
    padding-bottom: 45px !important; }
  .liquid_pt45 {
    padding-top: 45px !important; }
  .liquid_pr45 {
    padding-right: 45px !important; }
  .liquid_pl45 {
    padding-left: 45px !important; }
  .liquid_pb50 {
    padding-bottom: 50px !important; }
  .liquid_pt50 {
    padding-top: 50px !important; }
  .liquid_pr50 {
    padding-right: 50px !important; }
  .liquid_pl50 {
    padding-left: 50px !important; }
  .liquid_pb55 {
    padding-bottom: 55px !important; }
  .liquid_pt55 {
    padding-top: 55px !important; }
  .liquid_pr55 {
    padding-right: 55px !important; }
  .liquid_pl55 {
    padding-left: 55px !important; }
  .liquid_pb60 {
    padding-bottom: 60px !important; }
  .liquid_pt60 {
    padding-top: 60px !important; }
  .liquid_pr60 {
    padding-right: 60px !important; }
  .liquid_pl60 {
    padding-left: 60px !important; }
  .liquid_pb65 {
    padding-bottom: 65px !important; }
  .liquid_pt65 {
    padding-top: 65px !important; }
  .liquid_pr65 {
    padding-right: 65px !important; }
  .liquid_pl65 {
    padding-left: 65px !important; }
  .liquid_pb70 {
    padding-bottom: 70px !important; }
  .liquid_pt70 {
    padding-top: 70px !important; }
  .liquid_pr70 {
    padding-right: 70px !important; }
  .liquid_pl70 {
    padding-left: 70px !important; }
  .liquid_pb75 {
    padding-bottom: 75px !important; }
  .liquid_pt75 {
    padding-top: 75px !important; }
  .liquid_pr75 {
    padding-right: 75px !important; }
  .liquid_pl75 {
    padding-left: 75px !important; }
  .liquid_pb80 {
    padding-bottom: 80px !important; }
  .liquid_pt80 {
    padding-top: 80px !important; }
  .liquid_pr80 {
    padding-right: 80px !important; }
  .liquid_pl80 {
    padding-left: 80px !important; } }

@media screen and (max-width: 639px) {
  .sp_pb0 {
    padding-bottom: 0px !important; }
  .sp_pt0 {
    padding-top: 0px !important; }
  .sp_pr0 {
    padding-right: 0px !important; }
  .sp_pl0 {
    padding-left: 0px !important; }
  .sp_pb5 {
    padding-bottom: 5px !important; }
  .sp_pt5 {
    padding-top: 5px !important; }
  .sp_pr5 {
    padding-right: 5px !important; }
  .sp_pl5 {
    padding-left: 5px !important; }
  .sp_pb10 {
    padding-bottom: 10px !important; }
  .sp_pt10 {
    padding-top: 10px !important; }
  .sp_pr10 {
    padding-right: 10px !important; }
  .sp_pl10 {
    padding-left: 10px !important; }
  .sp_pb15 {
    padding-bottom: 15px !important; }
  .sp_pt15 {
    padding-top: 15px !important; }
  .sp_pr15 {
    padding-right: 15px !important; }
  .sp_pl15 {
    padding-left: 15px !important; }
  .sp_pb20 {
    padding-bottom: 20px !important; }
  .sp_pt20 {
    padding-top: 20px !important; }
  .sp_pr20 {
    padding-right: 20px !important; }
  .sp_pl20 {
    padding-left: 20px !important; }
  .sp_pb25 {
    padding-bottom: 25px !important; }
  .sp_pt25 {
    padding-top: 25px !important; }
  .sp_pr25 {
    padding-right: 25px !important; }
  .sp_pl25 {
    padding-left: 25px !important; }
  .sp_pb30 {
    padding-bottom: 30px !important; }
  .sp_pt30 {
    padding-top: 30px !important; }
  .sp_pr30 {
    padding-right: 30px !important; }
  .sp_pl30 {
    padding-left: 30px !important; }
  .sp_pb35 {
    padding-bottom: 35px !important; }
  .sp_pt35 {
    padding-top: 35px !important; }
  .sp_pr35 {
    padding-right: 35px !important; }
  .sp_pl35 {
    padding-left: 35px !important; }
  .sp_pb40 {
    padding-bottom: 40px !important; }
  .sp_pt40 {
    padding-top: 40px !important; }
  .sp_pr40 {
    padding-right: 40px !important; }
  .sp_pl40 {
    padding-left: 40px !important; }
  .sp_pb45 {
    padding-bottom: 45px !important; }
  .sp_pt45 {
    padding-top: 45px !important; }
  .sp_pr45 {
    padding-right: 45px !important; }
  .sp_pl45 {
    padding-left: 45px !important; }
  .sp_pb50 {
    padding-bottom: 50px !important; }
  .sp_pt50 {
    padding-top: 50px !important; }
  .sp_pr50 {
    padding-right: 50px !important; }
  .sp_pl50 {
    padding-left: 50px !important; }
  .sp_pb55 {
    padding-bottom: 55px !important; }
  .sp_pt55 {
    padding-top: 55px !important; }
  .sp_pr55 {
    padding-right: 55px !important; }
  .sp_pl55 {
    padding-left: 55px !important; }
  .sp_pb60 {
    padding-bottom: 60px !important; }
  .sp_pt60 {
    padding-top: 60px !important; }
  .sp_pr60 {
    padding-right: 60px !important; }
  .sp_pl60 {
    padding-left: 60px !important; }
  .sp_pb65 {
    padding-bottom: 65px !important; }
  .sp_pt65 {
    padding-top: 65px !important; }
  .sp_pr65 {
    padding-right: 65px !important; }
  .sp_pl65 {
    padding-left: 65px !important; }
  .sp_pb70 {
    padding-bottom: 70px !important; }
  .sp_pt70 {
    padding-top: 70px !important; }
  .sp_pr70 {
    padding-right: 70px !important; }
  .sp_pl70 {
    padding-left: 70px !important; }
  .sp_pb75 {
    padding-bottom: 75px !important; }
  .sp_pt75 {
    padding-top: 75px !important; }
  .sp_pr75 {
    padding-right: 75px !important; }
  .sp_pl75 {
    padding-left: 75px !important; }
  .sp_pb80 {
    padding-bottom: 80px !important; }
  .sp_pt80 {
    padding-top: 80px !important; }
  .sp_pr80 {
    padding-right: 80px !important; }
  .sp_pl80 {
    padding-left: 80px !important; } }

.c_red {
  color: #D91141; }
