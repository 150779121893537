.site_header{
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1002;
	// @at-root body.page &{
	// 	@include mq(sp, true){
	// 		min-width: $width_site + 70;
	// 	}
	// }
	a{
		color:#000;
	}
	@at-root html.over_main_image body.top &{
		a{
			&,
			&.background--light,
			&.background--dark{
				color: #fff!important;
			}
		}
		.drawer_btn{
			i,
			&.background--light i,
			&.background--dark i{
				background-color: #fff!important;
			}
		}
		.drawer_nav .uk-offcanvas-bar .lv1 > li > a:after{
			background-color: #fff!important;
		}
	}
	@at-root{
		body.uk-offcanvas-page .drawer_nav .uk-offcanvas-bar .lv1 > li a::after{
			background-color: #fff!important;
		}
	}
	// fixed時背景色設定
	&{
		padding-bottom: 15px;
		@include mq(sp){
			padding-bottom: 15px;
		}
		&:before{
			@include transition(opacity 500ms ease);
			@include opacity(0);
			background-color: rgba(#fff, .8);
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			@at-root body.uk-offcanvas-page &{
				@include mq(sp){
					height: 75px;
				}
			}
		}
	}
	// トランジション設定
	&{
		.site_header__id a{
			@include transition(height 500ms ease);
			i{
				@include transition(font-size 500ms ease);
			}
		}
		.site_header__nav{
			@include transition(padding-top 500ms ease);
		}
		.drawer_btn{
			@include transition(top 500ms ease);
		}
		.uk-offcanvas-bar{
			@include transition(all 500ms ease);
		}
	}

	@at-root html.over_main_image body.page-template &{
		a{
			&,
			&.background--light,
			&.background--dark{
				//color: #fff!important;
			}
		}
		.drawer_btn{
			i,
			&.background--light i,
			&.background--dark i{
				//background-color: #fff!important;
			}
		}
		.drawer_nav .uk-offcanvas-bar .lv1 > li > a:after{
			background-color: #fff!important;
		}
	}
	// スクロールがメインイメージを超えたとき
	@at-root html.pass_main_image &{
		&:before{
			@include opacity(1);
		}
		@include mq(sp, true){
			padding-top: 3px;
			.site_header__id a{
				height: 44px;
				i{
					font-size: 154px;
				}
			}
			.site_header__nav{
				padding-top: 20px;
			}
			.drawer_btn{
				top: 18px;
			}
		}
	}
	&__inner{
		position: relative;

		padding-left: 35px;
		padding-right: 35px;

		@include mq(sp $width_liquid){
			@include box-sizing(border-box);
			width: 100%;
			padding-left:$padding_liquid;
			padding-right:$padding_liquid + 10;
		}

		@include mq(sp) {
			@include box-sizing(border-box);
			width: auto;
			padding-left:10px;
			padding-right:10px;
		}
	}
	&__id{
		float:left;
		padding-top:17px;
		font-size:fs(30);
		font-weight:normal;
		position: relative;
		z-index: 1003;
		@include mq(sp, true){
			padding-top: 12px;
		}

		@include mq(sp) {
			//padding-top: 0;
		}

		a{
			display: block;
			text-decoration:none;
			height: 43px;
			overflow: hidden;
			@include mq(sp, true){
				height: 63px;
			}
			i{
				font-size: 123px;
				display: block;
				margin: -39px auto 0;
				line-height: 1;
				@include mq(sp, true){
					font-size: 184px;
					margin: -55px auto 0;
				}
			}
		}
	}
	&__nav{
		float:right;
		display: inline;
		text-align:right;
		padding-top: 23px;
		margin-right: 55px;
		position: relative;
		z-index: 1003;
		a{
			@include transition(opacity 300ms ease);
			&:hover{
				text-decoration: none;
				@include opacity(.7);
			}
		}
		@include mq(sp, true){
			margin-right: 90px;
			padding-top: 38px;
			// PC下層は通常のナビ
			// @at-root body.page &{
			// 	margin-right: 0;
			// }
		}
		@include mq(sp $width_liquid){
			margin-right: 80px;
		}
		li + li{
			margin-left:10px;
			@include mq(sp, true) {
				margin-left: 20px;
			}
		}
		li{
			display: inline-block;

			@include mq(sp) {
			}
			[class^="icon--"]:before,
			[class*=" icon--"]:before{
				font-size: 28px;
			}
		}
	}
}