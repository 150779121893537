.module_calendar {
	.calendar_explanation {
		@include display-flex;
		@include align-items(flex-start);
		@include align-items(center);

		@include mq(sp, true) {
			margin-bottom: 20px;
			@include justify-content(flex-end);
		}

		@include mq(sp) {
			margin-bottom: 10px;
			@include justify-content(flex-start);
		}

		&--left {
			@include mq(sp, true) {
				@include justify-content(flex-start);
			}
		}

		&__symbol {
			font-size: 20px;

			@include mq(sp) {
				font-size: 16px;
			}
		}

		li {
			&:not(:last-child) {
				margin-right: 20px;
			}
		}

		figure {
			@include display-flex;
			@include justify-content(flex-start);
			@include align-items(center);
			img {
				vertical-align: middle;

				@include mq(sp) {
					width: 40px;
				}
			}
			@include mq(sp, true) {
				font-size: fs(14);
				letter-spacing: 1px;
			}

			@include mq(sp) {
				font-size: fs(10);
			}
		}
	}
}