/*

information page

*/

body.blog {

	.page_main_image {
		height: 100vh;

		@include mq(sp, true){
			background: url(../img/information/hero_01.jpg) no-repeat center center;
			background-size: cover;
			max-height: 1000px;
		}
		@include mq(sp){
			background: url(../img/information/hero_01_sp.jpg) no-repeat center center;
			background-size: cover;
			max-height: 560px;
		}

		.elem_title {
			.jp {
				b {
					@include mq(sp, true) {
						font-size: fs(80);
						font-weight: 600;
						letter-spacing: 5px;
					}

					@include mq(sp) {
						font-size: fs(45);
						font-weight: 700;
						letter-spacing: 3px;
					}
				}
			}
		}

	}

	.page_content {

		.archive{
			line-height: 1.75;
			border-top: 1px solid $color_line;

			@include mq(sp, true){
				font-size: fs(20);
				padding: 35px 2px;
				display: flex;
			}
			@include mq(sp) {
				font-size: fs(15);
				padding-top: 12px;
				padding-bottom: 12px;
			}

			&__time{
				font-weight: 700;
				@include mq(sp, true){
					margin-top: 0;
					margin-right: 50px;
					min-width: 150px;
					width: 15%;
				}
				@include mq(sp) {
					font-size: fs(20);
				}
			}
			&__contents{
				line-height: 2;
			}

		}
	}

}

