/*

プロジェクトにおける基本的なスタイル
（ページ全体の背景や、基本的なタイポグラフィ）
※ クラスは使わない

*/

html, body{
	background-color: #fff;
	//font-size: calc(100vw / 13.80);
	@include mq(sp, true){
		height: 100%;
	}
}

body{
	@include mq(sp $width_liquid){
		@include box-sizing(border-box);
		min-width: $width_liquid;
	}
}

body{
	font-family: $family_jp_noto;
	-webkit-text-size-adjust:100%;
	word-wrap:break-word;
	overflow-x: hidden;

	&.admin-bar {
		.site_header {
			top: 32px;
		}
	}
}

body{
	color: $color_text;
}

img {
	vertical-align: bottom;
	@include mq(liquid) {
		max-width:100%;
		height:auto;
	}
}

a{
	@include transition(color 300ms ease);
	color: $color_main;
	text-decoration:none;
	// &:hover{
	// 	text-decoration:underline;
	// 	@include mq(sp, true){
	// 		color: #00a0d4;
	// 	}
	// }
}
