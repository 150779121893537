.liquid_container{
	@include mq(sp, true) {
		width:82%;
		margin:0 auto;
		&.attr_min{
			width: 960px;
		}
	}

	@include mq(sp) {
		width: 92%;
		margin: 0 auto;
	}
}