/*

error503

*/
body.error503{

	.page_main_image{
		height: 100vh;
		background-size: cover;

		@include mq(sp, true){
			background: url(../img/error_503/hero_01.jpg) no-repeat center center;
			max-height: 1000px;
		}
		@include mq(sp){
			background: url(../img/error_503/hero_01_sp.jpg) no-repeat center top;
			max-height: 560px;
		}

		.elem_title {
			.en {
				b {
					font-family: $family_en_lato;
					@include mq(sp, true) {
						font-size: fs(80);
						font-weight: 600;
						letter-spacing: 5px;
					}

					@include mq(sp) {
						font-size: fs(45);
						font-weight: 700;
						letter-spacing: 3px;
					}
				}

				span {
					@include mq(sp, true) {
						font-size: fs(16);
						letter-spacing: 2px;
					}
					@include mq(sp) {
						font-size: fs(11);
						letter-spacing: 1px;
					}
				}
			}
		}
	}

	.page_error {
		text-align: center;

		&__title {
			font-size: fs(32);
			letter-spacing: 3px;
			line-height: 2;

			@include mq(sp) {
				font-size: fs(22);
				letter-spacing: 1px;
				line-height: 1.7;
			}
		}

		&__lead {
			margin-top: 35px;
			font-size: fs(16);
			line-height: 2;
			letter-spacing: 1px;

			@include mq(sp) {
				font-size: fs(15);
				margin-top: 25px;
				text-align: left;
			}
		}

		&__btn {
			margin-top: 85px;

			@include mq(sp) {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}
	}
}
