/*

アニメーション

*/

.animate {
    &__inner {
        overflow: hidden;
    }
    filter: brightness(300%);
    transform: scale(1.1);
    transition: filter .8s , transform 1.4s;

    &.is_animated {
        filter: brightness(1);
        transform: scale(1);
    }

    &--fade-in-up {
        transform: translateY(30px);
        opacity: 0;
        transition: transform .8s, opacity .8s;

        &.is_animated {
            transform: translateY(0);
            opacity: 1;
        }
    }
}