/*

movie page

*/

body.movie {


	.page_main_image {
		height: 100vh;

		@include mq(sp, true){
			background: url(../img/movie/hero_01.jpg) no-repeat center center;
			background-size: cover;
			max-height: 1000px;
		}
		@include mq(sp){
			background: url(../img/movie/hero_01_sp.jpg) no-repeat center center;
			background-size: cover;
			max-height: 560px;
		}
		.elem_title{
			margin-bottom: 5px;
			.jp{
				b{
					font-family: Heebo,sans-serif;
					font-size: 80px;
					line-height: 1;
					font-weight: 900;
					@include mq(sp){
						font-size: 67px;
					}
				}
			}
			
	
		}
		.elem_copy{
			font-family: Heebo,sans-serif;
			font-size: 14px;
			font-weight: bold;
			
		}
		
	}
	.movie_section{
		margin: 0 auto;
		width: 1140px;
		@include mq(sp){
			width: auto;
			padding: 0 15px;
		}
		.movie_content{
			display: flex;
			flex-wrap: wrap;
			gap: 50px 30px;
			@include mq(sp){
				display: block;
			}
			.movie_block{
				width: 555px;
				@include mq(sp){
					width: 100%;
					
					&:not(:last-child){
						margin-bottom: 35px;
					}
				}
				.movie{
					position: relative;
					width: 100%;
					height: 0;
					padding-top: 56.25%;
					iframe{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				.title{
					font-size: 20px;
					color: #333;
					margin-top: 15px;
					@include mq(sp){
						font-size: 15px;
						margin-top: 10px;
					}
				}
			}
			
		}
	}
	
	
}

