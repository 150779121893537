.module_plan_table{
	width: 100%;
	text-align: left;

    @include mq(sp, true) {
        margin-bottom: 70px;
    }

    @include mq(sp) {
        margin-bottom: 30px;
    }

	tr {
		&:last-child {
			 @include mq(sp) {
			td {
				border-bottom: 2px solid #363535;
			}
			 }
		}			
	}

	table {
		width: 100%;
		border: 1px solid #ddd;
		margin-bottom:30px;
		:last-child {
			margin-bottom:0px;
		}
	}

	caption {
		font-size: fs(16);
		text-align: left;
		letter-spacing: 1px;
		margin-bottom: 15px;

		@include mq(sp) {
			font-size: fs(14);
			line-height: 1.6;
		}

		b {
			font-size: fs(29);
			margin-right: 4px;

			@include mq(sp) {
				font-size: fs(22);
				display: block;
			}
		}
	}
	
	.course_title{
		margin-bottom: 20px;
		vertical-align:middle;
		span {
		font-size: fs(18);
		}
		.icon {
			color:white;
			background-color:$color_main;
			padding:5px 40px;
			margin-right:15px;
		}
		b{
			display:inline-block;
		}
		.course_title__time{
			@include mq(sp){
				display: block;
				margin-top: 5px;
			}
		}
	}
	
	.course_note{
		margin-top:30px;
		margin-bottom:0;
		line-height:1.6;
		@include mq(sp) {
				margin-top:15px;
			}
		b {font-size: fs(17);
			margin-right:20px;
			@include mq(sp) {
				font-size: fs(14);
				display: block;
				margin-right:20px;
			}
	}
		}

	&.plan {
		thead {
			@include mq(sp) {
				display: none;
			}

			th {
				width: auto;
			}
		}
		tbody {
			td{
				font-weight: 500;

				@include mq(sp) {
					text-align: left;
				}

				&.course {
					background-color: #EFF5F9;
					text-align: left;

					@include mq(sp,true) {
						width: 40%;
					}

					@include mq(sp) {
						background-color: $color_main;
						color: #fff;
					}

					&.w20p {
						@include mq(sp,true) {
							width: 20%;
						}
					}

					dt {
						font-size: fs(18);
						letter-spacing: 1px;
						margin-bottom: 15px;

						@include mq(sp) {
							font-size: fs(15);
							text-align: center;
							margin-bottom: 10px;
						}
						b {
							font-size: fs(28);
							font-weight: 600;

							@include mq(sp) {
								font-size: fs(18);
							}
						}
					}

					dd {
						font-size: fs(17);

						@include mq(sp) {
							font-size: fs(13);
							padding: 0 10px;
						}
					}
				}

				.price {
					display: block;
					@include mq(sp) {
						line-height: 1.3;
						letter-spacing: 1px;
					}
				}

				&.people {
					@include mq(sp, true) {
						width: 16%;
					}
				}
				
				&.p-06 {
					@include mq(sp) {
						@include display-flex;
						@include align-items(center);
					}
					&::before {
						@include mq(sp) {
							content: "6名ご利用時";
							display: inline-block;
							margin-right: 20px;
							font-weight: 600;
						}
					}
				}
				
				&.p-05 {
					@include mq(sp) {
						@include display-flex;
						@include align-items(center);
					}
					&::before {
						@include mq(sp) {
							content: "5名ご利用時";
							display: inline-block;
							margin-right: 20px;
							font-weight: 600;
						}
					}
				}

				&.p-04 {
					@include mq(sp) {
						@include display-flex;
						@include align-items(center);
					}
					&::before {
						@include mq(sp) {
							content: "４名ご利用時";
							display: inline-block;
							margin-right: 20px;
							font-weight: 600;
						}
					}
				}

				&.p-03 {
					@include mq(sp) {
						@include display-flex;
						@include align-items(center);
					}
					&::before {
						@include mq(sp) {
							content: "３名ご利用時";
							display: inline-block;
							margin-right: 20px;
							font-weight: 600;
						}
					}
				}

				&.p-02 {
					@include mq(sp) {
						@include display-flex;
						@include align-items(center);
					}
					&::before {
						@include mq(sp) {
							content: "２名ご利用時";
							display: inline-block;
							margin-right: 20px;
							font-weight: 600;
						}
					}
				}

				&.p-01 {
					@include mq(sp) {
						@include display-flex;
						@include align-items(center);
					}
					&::before {
						@include mq(sp) {
							content: "１名ご利用時";
							display: inline-block;
							margin-right: 20px;
							font-weight: 600;
						}
					}
				}
			}
		}

		&.plan-c {
			table {
				@include mq(sp) {
					border: none;
				}
			}
			tbody {
				tr {
					@include mq(sp) {
						display: block;
						margin-top: 30px;
					}

					&:first-of-type {
						@include mq(sp) {
							margin-top: 0;
						}
					}
				}
			}
		}
	}

	thead {
		th {
			background-color: #4F8EDC;
			color: #fff;
			border: #fff 1px solid;
			text-align: center;
			font-size: fs(18);
			font-weight: 500;
			letter-spacing: 1px;

			b {
				font-size: fs(28);
				font-family: $family_en_heebo;
				font-weight: 600;
				display: inline-block;
				vertical-align: middle;
				margin-right: 4px;
			}
		}
	}

	tbody {
		td {
			border: 1px solid #D6D5D5;
		}
	}

	th,
	td{
		line-height: 1.75;
		font-weight: normal;
		vertical-align: middle;
	}
    th{
        padding: 5px;
		color: #fff;
        font-weight: 600;
        background-color: $color_main;

        @include mq(sp, true){
            width: 40%;
            font-size: fs(22);
        }

        @include mq(sp){
            font-size: fs(16);
			padding: 20px;
			display: block;
            @include box-sizing(border-box);
            text-align: center;
        }
    }
    td{
        padding: 25px 0px;
		text-align: center;
        font-weight: 600;
        @include mq(sp,true) {
            width: 60%;
        }
		@include mq(sp){
			padding: 20px;
		}
        .price{
            display: inline-block;
            font-size: fs(34);
            font-family: $family_en_heebo;
            line-height: 1.3;
            letter-spacing: 2px;
            margin-right: 10px;
            @include mq(sp){
                font-size: fs(24);
            }
        }
    }
}
