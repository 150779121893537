.heading_lv1,
.heading_lv2,
.heading_lv3,
.heading_lv4{
	font-feature-settings: "palt";
	letter-spacing: .1em;
	font-weight: 500;
}

.heading_lv1{
	font-size: fs(50);
	//font-size: calc(1rem + 4vw);
	margin-bottom: 20px;

	@include mq(sp) {
		font-size: fs(25);
		line-height: 1.5;
	}
}

.heading_lv2{
	font-size: fs(39);
	//font-size: .41rem;
	line-height: 1.6;
	letter-spacing: .1em;
	margin-bottom: 20px;
	font-weight: 600;

	@include mq(sp) {
		font-size: fs(25);
		line-height: 1.5;
		margin-bottom: 10px;
	}
}

.heading_lv3{
	font-size: fs(37);
	//font-size: .41rem;
	line-height: 1.7;
	letter-spacing: .1em;
	margin-bottom: 20px;
	font-weight: 600;

	@include mq(sp) {
		font-size: fs(25);
		line-height: 1.5;
		margin-bottom: 10px;
	}
}

.heading_lv4{
	font-weight: 500;
	border-bottom: 1px solid $color_line;
	padding-bottom: 10px;
	letter-spacing: 1px;
	margin-bottom: 30px;

	@include mq(sp) {
		margin-bottom: 10px;
	}

	.en {
		font-family: $family_en_heebo;
		font-size: fs(29);
		font-weight: 600;
		margin-right: 15px;

		@include mq(sp) {
			font-size: fs(23);
		}
	}

	.jp {
		font-size: fs(15);
		font-weight: 400;

		@include mq(sp) {
			font-size: fs(14);
		}
	}
}

.headline {
	&__primary {
		@include mq(sp, true) {
			margin-bottom: 60px;
		}

		@include mq(sp) {
			margin-bottom: 25px;
		}
		.en {
			font-size: fs(43);
			font-family: $family_en_heebo;
			font-weight: 600;
			border-bottom: 1px solid $color_line;
			margin-bottom: 10px;
			letter-spacing: .05em;

			@include mq(sp) {
				font-size: fs(25);
				letter-spacing: .04em;
			}
		}

		.jp {
			font-size: fs(15);
			font-weight: 500;
			letter-spacing: .1em;

			@include mq(sp) {
				font-size: fs(12);
			}
		}

		.desc {
			line-height: 2;

			@include mq(sp, true) {
				margin-top: 30px;
				font-size: fs(20);
			}

			@include mq(sp) {
				margin-top: 20px;
			}

			&--sm {
				@include mq(sp, true) {
					font-size: fs(16);
				}
			}
		}
	}

	&__secondary {
		margin-bottom: 30px;

		@include mq(sp) {
			margin-bottom: 20px;
		}
		.en {
			font-size:fs(15);
			font-family: $family_en_heebo;
			font-weight: 600;
			border-top: 1px solid $color_line;
			padding-top: 5px;
			padding-left: 3px;
			letter-spacing: .03em;

			@include mq(sp) {
				font-size:fs(12);
			}
		}

		.jp {
			border-top: 1px solid $color_line;
			padding-top: 10px;
			font-size:fs(26);
		}
	}

	&__extra {
		margin-bottom: 20px;
		@include mq(sp) {
			margin-bottom: 10px;
		}
		.en {
			font-size: fs(36);
			font-family: $family_en_heebo;
			font-weight: 600;
			letter-spacing: .05em;
			display: inline-block;

			@include mq(sp) {
				font-size:fs(22);
			}
		}
		.jp {
			display: inline-block;
			margin-left: 30px;
			font-size: fs(20);
			font-weight: 300;

			@include mq(sp) {
				font-size:fs(14);
				margin-left: 10px;
			}
		}
	}

	&__label {
		font-size: fs(25);
		font-family: $family_en_heebo;
		border-bottom: none;
		border-top: 1px solid $color_line;
		padding-top: 10px;
		font-weight: 600;
		margin-bottom: 40px;

		@include mq(sp) {
			font-size:fs(18);
			margin-bottom: 20px;
		}
	}
	&__lead {
		font-size:fs(20);
		line-height: 2.5;

		@include mq(sp) {
			font-size:fs(15);
			line-height: 2;
		}
	}

	&__sublead {
		font-size:fs(15);
		line-height: 2.5;

		@include mq(sp) {
			font-size:fs(15);
			line-height: 2;
		}
	}
}

.font_heebo {
	font-family: $family_en_heebo;
}