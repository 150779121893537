.module_banner {

	&__item {
		display: table;
		width: 100%;
		@include mq(sp, true) {
			min-height: 300px;
		}

		@include mq(sp) {
			min-height: 140px;
		}

		&.comingsoon {
			position: relative;

			&::before {
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				background-color: rgba(#363535,.8);
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
			}

			&::after {
				position: absolute;
				left: 50%;
				top: 50%;
				z-index: 3;
				transform: translate(-50%,-50%);
				color: #fff;
				content: "COMING SOON";
				font-family: $family_en_heebo;
				font-weight: 600;
				letter-spacing: 3px;

				@include mq(sp, true) {
					font-size: fs(43);
				}

				@include mq(sp) {
					font-size: fs(22);
				}
			}

			a {
				position: relative;
				z-index: 1;
				cursor: default;
			}
		}

		&.text_r {
			a {
				@include mq(sp, true) {
					padding-left: 50%;
				}

				@include mq(sp) {
					padding-left: 20%;
				}
			}

			.module_banner__lead {
				padding-left: 0;
				display: block;
				border-bottom-color: #fff;
			}

			.module_banner__heading {
				padding-left: 0;
			}

			.module_banner__sublead {
				padding-left: 0;
			}
		}

		&.text_c {
			a {
				text-align: center;
			}

			.module_banner__lead {
				padding-left: 0;
				display: inline-block;
				border-bottom-color: #fff;
			}

			.module_banner__heading {
				padding-left: 0;
			}

			.module_banner__sublead {
				padding-left: 0;
			}
		}

		a {
			height: 100%;
			display: table-cell;
			vertical-align: bottom;
			position: relative;
			background-position: center;
			background-size: cover;
			font-family: $family_en_heebo;
			font-weight: 600;
			box-sizing: border-box;
			color: $color_text;
			transition: opacity .2s;

			@include mq(sp, true) {
				padding-bottom: 30px;
			}

			@include mq(sp) {
				padding-bottom: 15px;
			}

			&:hover {
				text-decoration: none;
				opacity: 0.7;
			}

			&.food_delivery {
				background-image: url(../img/top/image_fooddelivery_01.jpg);
			}

			&.fish_guide {
				background-image: url(../img/top/image_fishguide_01.jpg);
				color: #fff;
			}

			&.tackle_info {
				background-image: url(../img/top/image_tackleinfo_01.jpg);
				color: #fff;
			}

			&.fish_dishes {
				background-image: url(../img/top/image_fishdishes_01.jpg);
				color: #fff;
			}

			&.movie {
				background-image: url(../img/top/image_movie_01.jpg);
				color: #fff;
			}
		}
	}

	&__lead {
		font-weight: 500;
		padding-left: 9%;
		border-bottom: 1px solid $color_line;
		display: inline-block;
		padding-bottom: 4px;

		@include mq(sp, true) {
			font-size: fs(26);
		}

		@include mq(sp) {
			font-size: fs(13);
			line-height: 1.7;
		}

		&.border_white {
			border-bottom-color: #fff;
		}
	}

	&__heading {
		font-family: $family_en_lato;
		font-weight: 900;
		letter-spacing: .05em;

		@include mq(sp, true) {
			font-size: fs(73);
			padding-left: 9%;
		}

		@include mq(sp) {
			font-size: fs(36);
			padding-left: 4%;
		}
	}

	&__sublead {
		font-family: $family_en_lato;
		font-weight: 600;
		letter-spacing: 1px;

		@include mq(sp, true) {
			font-size: fs(14);
			padding-left: 9%;
		}

		@include mq(sp) {
			font-size: fs(10);
			padding-left: 4%;
		}
	}

}