/*

注釈 (IE8以上)

*/

/* ---- ※（注釈） ---- */

.notes{
	margin-left:1.3em;
	text-indent:-1.3em;
	&:before{
		content:"※ ";
	}
	& > *{
		text-indent:0;
	}
}


/* ---- ※n （番号付き注釈）---- */

.notes_num{
	counter-increment:decimal_notes;
	margin-left:1.9em;
	text-indent:-1.9em;
	&:before{
		content:"※"counter(decimal_notes)" ";
	}
	& > *{
		text-indent:0;
	}
}
