/*

attractive page

*/

body.attractive {

	.page_navi {
		ul {
			@include mq(sp, true) {
				@include display-flex;
				@include justify-content(space-between);
			}

			li {
				@include mq(sp, true) {
					width: 23%;
				}

				@include mq(sp) {
					margin-bottom: 20px;
				}

				a {
					position: relative;
					display: block;
					text-align: center;
					font-family: $family_en_heebo;
					font-weight: 700;
					color: $color_text;
					letter-spacing: 1px;
					padding: 30px 0 10px;
					border-bottom: 1px solid $color_line;
					@include mq(sp, true) {
						font-size: fs(20);
					}
					@include mq(sp) {
						font-size: fs(20);
					}
					&::before {
						content: "";
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 12px 7px 0 7px;
						border-color: $color_main transparent transparent transparent;
						position: absolute;
						left: 50%;
						top: 0;
						margin-left: -7px;
						transition: top .2s;
					}

					&:hover {
						color: $color_main;
						border-bottom-color: $color_main;
						&::before {
							top: 7px;
						}
					}
				}
			}
		}
	}

	.page_main_image {
		height: 100vh;

		@include mq(sp, true){
			background: url(../img/attractive/hero_01.jpg) no-repeat center center;
			background-size: cover;
			max-height: 1000px;
		}
		@include mq(sp){
			background: url(../img/attractive/hero_01_sp.jpg) no-repeat center center;
			background-size: cover;
			max-height: 560px;
		}

		.elem_lead {
			border-top: 3px solid #fff;
			border-bottom: 3px solid #fff;
			display: inline-block;
			padding: 10px 30px;

			@include mq(sp, true) {
				font-size: fs(48);
				font-weight: 600;
				letter-spacing: 5px;
			}

			@include mq(sp) {
				font-size: fs(33);
				font-weight: 700;
				letter-spacing: 3px;
			}

			span {
				@include mq(sp, true) {
					font-size: fs(12);
				}

				@include mq(sp) {
					font-size: fs(11);
				}
			}
		}

		.elem_title {
			.jp {
				b {
					@include mq(sp, true) {
						font-size: fs(67);
						font-weight: 600;
						letter-spacing: 5px;
					}

					@include mq(sp) {
						font-size: fs(29);
						font-weight: 700;
						letter-spacing: 3px;
					}
				}
			}
		}

		.elem_copy {
			@include mq(sp, true) {
				font-size: fs(15);
				letter-spacing: 3px;
				font-weight: 300;
				line-height: 2;
				margin-top: 20px;
			}

			@include mq(sp) {
				font-size: fs(14);
			}
		}

	}

	.page_guide {
		background-image: url(../img/attractive/bg_01.jpg);
		background-repeat: repeat-y;
		background-size: 100% auto;
		background-position: center top;
		color: #fff;

		@include mq(sp) {
			background-image: url(../img/attractive/bg_01_sp.jpg);
			padding-top: 35px;
			padding-bottom: 35px;
		}

		.headline__primary {
			.en {
				border-bottom-color: #fff;
			}
		}

		&__list {
			@include mq(sp, true) {
				@include display-flex;
				@include flex-wrap(wrap);
				@include align-items(flex-end);
			}
		}

		&__item {
			@include mq(sp, true) {
				box-sizing: border-box;
				padding: 0 3%;
				width: 50%;
				margin-bottom: 50px;
			}

			@include mq(sp) {
				margin-bottom: 60px;
			}

			.image {
				display: flex;
				align-items: center;

				@include mq(sp) {
					padding: 0 25px;
				}
				img {
					max-width: 100%;
					vertical-align: middle;
				}
			}

			.title {
				margin-top: 30px;

				.main {
					font-weight: 600;
					@include mq(sp, true) {
						font-size: fs(26);
					}
					@include mq(sp) {
						font-size: fs(21);
					}
				}

				.sub {
					letter-spacing: 1px;
					margin-left: 5px;
				}
			}

			.content {
				margin-top: 10px;
				line-height: 1.8;

				@include mq(sp, true) {
					letter-spacing: 1px;
				}
			}
		}
	}

	.page_dishes {
		&__list {
			@include mq(sp, true) {
				@include display-flex;
				@include flex-wrap(wrap);
			}
		}

		&__item {
			@include mq(sp, true) {
				box-sizing: border-box;
				width: 46%;
				margin-right: 8%;
				margin-bottom: 30px;
			}

			@include mq(sp) {
				margin-bottom: 40px;
			}

			&:nth-child(2n) {
				margin-right: 0;
			}

			.video {
				position:relative;
				width:100%;
				height:0;
				padding-top:60%;
				iframe {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
				}
			}

			.title {
				margin-top: 15px;
				font-weight: 600;
				@include mq(sp, true) {
					font-size: fs(18);
				}
				@include mq(sp) {
					font-size: fs(18);
				}
			}
		}
	}

}

