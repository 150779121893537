.module_plan{
	display: block;
	background: no-repeat no-repeat center center;
	background-size: cover;
	overflow: hidden;
	position: relative;
	&, &:hover{
		color: #fff;
		text-decoration: none;
	}
	&:hover &__bg{
		@include mq(sp, true){
			@include transform( scale(1.1) );
		}
	}
	&__bg{
		@include transition(all 500ms ease);
		width: 100%;
		height: 100%;
		position: relative;
	}

	&__balloon{
		background: url(../img/plan/limited/balloon-summer-plan.png);
		background-size: cover;
		text-align: center;
		&, *{
			line-height: 1;
		}

		width: 110px;
		height: 70px;

		position: absolute;
		z-index: 1;
		right: 10px;
		top: 0;
		@include translateY(-100%);
		@include mq(sp){
			top: 10px;
		}

		&.attr--special{
			@include translateY(0);
			background: url(../img/plan/limited/balloon-summer-option.png) no-repeat center center;
			background-size: cover;
			width: 114px;
			height: 102px;
			top: 10px;
			&, *{
				line-height: 1.23;
			}
		}

		@include display-flex;
		@include flex-wrap(wrap);
		@include justify-content(center);
		@include align-items(center);
		@include align-content(center);
		@include flex-direction(column);

		.__inner{
			@include rotate(12deg);
			> *{
				display: block;
			}
		}
		.__sub{
			font-size: fs(12);
		}
		* + .__main{
			margin-top: 5px;
		}
		.__main{
			font-size: fs(14);
		}

	}

	&__inner{
		text-align: center;
		position: absolute;
		z-index: 1;
		width: 100%;
		top: 50%;
		@include translateY(-50%);
	}
	&__chapter{
		font-family: $family_en_display;
		font-size: fs(26);
		margin-bottom: 30px;
	}
	&__bottom{
		font-size: fs(23);
		font-weight: bold;
		margin-top: 15px;
		letter-spacing: .15em;
	}
	&__shoulder{
		font-family: $family_jp_yuh;
		display: inline-block;
		font-weight: bold;
		margin-bottom: 5px;
		position: relative;
		@include mq(sp, true){
			font-size: fs(24);
		}
	}
	&__title{
		display: inline-block;
		font-family: $family_jp_yuh;
		font-feature-settings : "palt";
		font-weight: bold;
		font-size: fs(34);
		white-space: nowrap;
		position: relative;
		@include mq(sp, true){
			font-size: fs(38);
		}
	}
	&__status{
		position: absolute;
		right: -70px;
		top: -53px;
	}
	&__more{
		text-align: center;
		margin-top: 30px;
	}
	.imitate_default_btn{

		font-size: fs(14);
		display: inline-block;
		position: relative;
		padding: 11px 35px 10px;
		@at-root{
			// chrome ハック
			@media screen and (-webkit-min-device-pixel-ratio:0) {
				&{
					margin-top: -1px;
				}
			}
		}

		&__top,
		&:after{
			@include box-sizing(border-box);
			content: "";
			border: 2px solid;
			border-top: none;
			width: 100%;
			position: absolute;
			z-index: 0;
			left: 0;
		}
		&:after{
			bottom: 0;
			height: 50%;
		}
		&__top{
			@include transition(height 150ms linear 350ms);
			border-bottom: none;
			bottom: 50%;
			height: 0;
			overflow: hidden;
			&:before,
			&:after{
				@include box-sizing(border-box);
				@include transition(width 350ms linear);
				content: "";
				width: 0;
				height: 2px;
				background-color: #fff;
				position: absolute;
				z-index: 0;
				top: 0;
			}
			&:before{
				left: 0;
			}
			&:after{
				right: 0;
			}
		}

	}

	@include mq(sp, true){
		&:hover .imitate_default_btn__top{
			@include transition(height 150ms linear);
			height: 50%;
			&:before,
			&:after{
				@include transition(width 350ms linear 150ms);
				width: 51%;
			}
		}
	}

	&.attr_application{
		&:before{
			display: block;
			content: "";
			padding-top: 35%;
		}
		.module_plan__inner{
			position: static;
			@include translateY(0);
		}
		.module_plan__bg{
			position: absolute;
			top: 0;
			height: auto;
		}
	}
	&.attr_first{
		.module_plan__body{
			border-left: 1px solid #ddd;
		}
	}
	&__description{
		font-family: $family_jp_yuh;
		font-size: fs(16);
		font-weight: bold;
		margin-top: 10px;
	}
	&__text{
		font-family: $family_jp_yuh;
		margin-top: 15px;
		&, *{
			line-height: 1.45;
		}
		> * + *{
			margin-top: 10px;
		}
	}
	&__body{
		background-color: #fff;
		color: $color_text;
		border: 1px solid #ddd;
		border-left: none;
		border-top: none;
		margin-top: 25px;
		position: relative;
		@include mq(sp){
			border-left: 1px solid #ddd;
			padding: 30px 10px;
		}
		&:before{
			content: "";
			display: block;
			padding-top: percentage(260 / 380);
			@include mq(sp){
				display: none;
			}
		}
		&-inner{
			text-align: center;
			@include mq(sp, true){
				width: 100%;
				position: absolute;
				left: 0;
				top: 50%;
				@include translateY(-50%);
			}
		}
		&-shoulder{
			font-weight: bold;
		}
		&-title{
			margin-top: 10px;
			.elem_emphasis{
				font-size: fs(20);
			}
		}
		&-sub{
			font-size: fs(13);
			color: #666;
			@include mq(sp){
				margin-top: 3px;
			}
		}
		&-price{
			font-family: $family_en_display;
			font-size: fs(40);
			margin-top: 0;
		}
		.module_plan__more{
			margin-top: 20px;
			@include mq(sp){
				margin-top: 10px;
			}
			.imitate_default_btn{
				&__top{
					&:before,
					&:after{
						background-color: $color_text;
					}
				}
			}
		}
	}
}