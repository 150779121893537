/*

font

*/

.smaller {
	font-size:85% !important;
}

.larger {
	font-size:larger !important;
}

.bold{
	font-weight:bold !important;
}

.weight_normal{
	font-weight:normal !important;
}

.style_normal{
	font-style:normal !important;
}


/*

line height

*/

.lh_l{
	line-height: 1.75 !important;
}

.lh_m{
	line-height: 1.5 !important;
}

.lh_s{
	line-height: 1.3 !important;
}

.lh_xs{
	line-height: 1 !important;
}


/*

kerning

*/
.kerning{
	letter-spacing: -0.25em !important;
}


/*

font-feature-settings

*/
.ffs_palt{
	font-feature-settings : "palt" !important;
}
.ffs_normal{
	font-feature-settings : normal !important;
}
