.contents_section{
	padding: 50px 0;

	@include mq(sp) {
		padding: 25px 0;
	}

	&__head {
		margin-bottom: 45px;

		@include mq(sp) {
			//width: 92%;
			margin: 0 auto 25px;
		}
	}

}

.blocker {
	padding: 0;
	z-index: 1003;
}

.close-modal {
	display: none !important;
}

.modal {
	width: 100%;
	background-color: rgba(#000, .5);
	max-width: 100%;
	box-shadow: unset;
	border-radius: 0;

	@include mq(sp,true) {
		padding: 100px 0;
	}

	@include mq(sp) {
		padding: 30px 4%;
	}

	&__inner {
		background-color: #fff;
		@include box-sizing(border-box);

		@include mq(sp,true) {
			padding: 30px 50px 70px 50px;
		}

		@include mq(sp) {
			padding: 20px 20px 30px 20px;
		}
	}

	&_section {
		margin-bottom: 60px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.column__item {
		margin-bottom: 60px;

		@include mq(sp) {
			margin-bottom: 30px;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.info {
		margin-bottom: 20px;
		line-height: 1.7;
	}

	.about {
		line-height: 1.7;
		dt {
			font-weight: 700;
			margin-bottom: 10px;
		}
	}

	.table {
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.btn {
		margin-right: 0;
		margin-left: auto;
		width: 46px;
		height: 46px;
		a {
			display: block;
			position: relative;
			background-color: #000;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			&::before,
			&::after {
				content: "";
				display: block;
				width: 17px;
				height: 1px;
				background-color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
			}

			&::before {
				transform: translate(-50%,-50%) rotate(-45deg);
			}

			&::after {
				transform: translate(-50%,-50%) rotate(45deg);
			}
		}
	}
}

.sp__scroll_x {
	@include mq(sp) {
		overflow-x: auto;
		margin-bottom: 20px;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			width: 12px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background: #f2f2f2;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background:$color_main;
		}
	}
}

.table {
	margin-bottom: 15px;

	@include mq(sp,true) {
		width: 100%;
	}

	@include mq(sp) {
		width: 1000px;
	}

	thead {
		background-color: #EBF3FC;

		th,td {
			vertical-align: middle;
		}

		th {
			font-weight: 700;
		}
	}

	th,td {
		font-weight: 500;
		text-align: left;
		border: $color_line 1px solid;
		line-height: 1.4;

		@include mq(sp, true) {
			padding: 10px 15px;
		}

		@include mq(sp) {
			padding: 5px 10px;
			font-size:fs(14);
		}
	}

	&.calendar {
		thead {
			th {
				border: none;
				background-color: $color_main;
				color: #fff;
				text-align: center;
				font-weight: 400;
				font-size: fs(12);
				position: relative;

				&::before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 1px;
					height: 80%;
					background-color: #fff;
				}

				&:first-of-type {
					&::before {
						display: none;
					}
				}
			}
		}
		tbody {
			tr {
				&:last-of-type {
					th,td {
						border-bottom: none;
					}
				}
			}
			th,td {
				border: none;
				border-bottom: 1px solid $color_line;

				@include mq(sp, true) {
					font-size: fs(15);
					padding: 12px 10px;
				}

				@include mq(sp) {
					font-size: fs(13);
					padding: 4px 10px;
				}
			}

			th {
				background-color: #F3F3F3;
				border-bottom: none;

				@include mq(sp,true) {
					width: 200px;
				}
			}

			td {
				text-align: center;
				&:nth-child(odd){
					background-color: #F3F3F3;
				}

				@include mq(sp, true) {
					font-size: fs(24);
				}

				@include mq(sp) {
					font-size: fs(18);
				}

				img {
					vertical-align: middle;

					@include mq(sp, true) {
						width: 60%;
					}
					@include mq(sp) {
						width: 50%;
					}
				}
			}
		}
	}
}

.column {
	@include mq(sp, true) {
		margin-bottom: 100px;
	}
	@include mq(sp) {
		margin-bottom: 30px;
	}
	&:last-of-type {
		@include mq(sp, true) {
			margin-bottom: 0px;
		}
	}
	&__item {
		@include mq(sp, true) {
			@include display-flex;
			@include justify-content(space-between);
			@include align-items(stretch);
		}

		&.reverse {
			@include mq(sp, true) {
				@include flex-direction(row-reverse);
			}
		}

		> * {
			@include mq(sp) {
				margin: 30px auto 0;
			}
		}
	}

	.content {
		@include mq(sp, true) {
			width: 47%;
		}

		// @include mq(sp) {
		// 	width: 92%;
		// 	margin: 0 auto;
		// }

		&__lead {
			font-size:fs(20);
			line-height: 2.5;

			@include mq(sp) {
				font-size:fs(15);
				line-height: 2;
			}
		}
	}

	.image {
		@include mq(sp, true) {
			width: 47%;
		}
	}

	.row {
		@include display-flex;
		@include justify-content(space-between);
		@include align-items(flex-start);

		.cell {
			width: 50%;
		}
	}
}