.img_rollover{
	&, a{
		&:hover{
			img{
				@include opacity(.7);
			}
		}
		img{
			@include transition(opacity 300ms ease);
		}
	}
}