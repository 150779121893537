/*

home page

*/

body.home{

	.splitting .word {
		overflow-y: hidden;
		line-height: 1.2;
	}

	.splitting .char {
		animation: up-in 1s cubic-bezier(.5, 0, .5, 1) both;
		animation-delay: calc(40ms * var(--char-index));
		display: inline-block;
	}

	@keyframes up-in {
		from {
			transform: translateY(1em);
			opacity: 0;
		}
	}

	.home_content {
		overflow: hidden;

		@include mq(sp, true) {
			margin-bottom: 80px;
		}

		@include mq(sp) {
			margin-bottom: 50px;
		}
	}

	.home_main_image {
		&__inner {
			padding: 9vw 0 0;
			background-color: #fff;

			@include mq(sp, true) {
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(flex-end);
			}

			@include mq(sp) {
				width: 92%;
				margin: 0 auto;
			}
		}
		&__title {
			//font-size: fs(124);
			font-weight: 600;
			// position: sticky;
			// top: 0;
			// left: 0;
			letter-spacing: 4px;
			position: fixed;
			z-index: 10;
			line-height: 1;
			transition: color .2s;

			@include mq(sp, true) {
				top: 7.8vw;
				left: 9%;
				font-size: 7.9vw;
			}

			@include mq(sp) {
				top: 24vw;
				left: 4%;
				font-size: fs(54);
			}

			&--tracer {
				letter-spacing: 4px;
				z-index: 10;
				transition: color .2s;
				opacity: 0;
				//color: #f00;
				font-size: 7.9vw;
				margin-left: 9%;
				position: relative;
				z-index: 10;

				@include mq(sp,true) {
					top: -100px;
				}

				@include mq(sp) {
					font-size: fs(54);
					margin-left: 4%;
					margin-top: 5vw;
				}

				b {
					font-weight: 600;
				}
			}

			b {
				color: $color_main;
				font-weight: 600;
				transition: color .2s;
			}

			&.is_onlayer {
				color: #fff;

				b {
					color: #fff;
				}
			}

			&.is_static {
				position: absolute;
				z-index: 100;
				color: #fff;

				b {
					color: #fff;
				}

				@include mq(sp,true) {
					left: 9%;
					top: auto;
					margin-top: -200px;
				}

				@include mq(sp) {
					top: 5vw;
					left: 4%;
				}
			}

			// &.transparent {
			// 	opacity: 1;
			// 	color: red;
			// 	position: absolute;
			// 	left: 4%;
			// 	top: 30px;
			// }
		}
		&__catch {
			color: #fff;
			line-height: 2;
			font-weight: 500;
			text-shadow: rgba(#000, .3) 1px 1px 0;

			@include mq(sp,true) {
				z-index: 20;
				top: 50%;
				right: 9%;
				width: 35%;
				transform: translateY(-50%);
				letter-spacing: 1px;
				position: absolute;
				font-size: fs(16);
			}

			@include mq(sp) {
				// position: absolute;
				// bottom: 30px;
				// left: 4%;
				position: relative;
				margin: 20px auto 30px;
				width: 92%;
				font-size: fs(14);
				z-index: 10;
			}
		}
		&__text {
			background-color: #fff;
			position: relative;
			z-index: 20;
			padding-bottom: 7vw;
			box-sizing: border-box;

			@include mq(sp, true) {
				width: 50%;
				margin-left: 9%;
			}

			@include mq(sp) {
				margin-top: 280px;
				margin-left: 0;
				padding-top: 10px;
			}
		}
		&__lead {
			//font-size:fs(49);
			font-family: $family_en_heebo;
			font-weight: 700;
			margin-top: 20px;

			@include mq(sp, true) {
				font-size: 3vw;
				letter-spacing: 3px;
			}

			@include mq(sp) {
				font-size:fs(24);
				letter-spacing: 2px;
			}
		}
		&__sublead {
			//font-size:fs(17);
			font-family: $family_en_lato;
			font-weight: 700;
			margin-top: 5px;

			@include mq(sp, true) {
				font-size: 1.1vw;
				letter-spacing: 2px;
			}

			@include mq(sp) {
				font-size:fs(12);
				letter-spacing: 1px;
			}
		}

		.slick {
			opacity: 0;

			&.slick-initialized {
				opacity: 1;
			}
		}
		&__gallery {
			// margin-right: 0;
			// margin-left: auto;
			padding-bottom: 9vw;
			background-color: #fff;
			position: relative;
			z-index: 20;
			overflow-y: hidden;

			@include mq(sp, true) {
				width: 42%;
			}
		}
		&__video {
			position: relative;
			overflow: hidden;
			//min-height: 560px;

			@include mq(sp, true) {
				//min-height: 40vw;
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(center);
				padding: 120px 0;
			}

			@include mq(sp) {
				//min-height: 100vw;
				//min-height: 60vh;
			}

			video {
				@include mq(sp, true) {
					width: 100%;
					height: auto;
				}
				width: auto;
				height: 100%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}
		&__scroll {
			position: relative;
			font-family: $family_en_heebo;
			font-weight: 700;
			line-height: 1;
			font-size:fs(13);
			letter-spacing: 1px;
			margin-top: 30px;

			span {
				display: inline-block;
				vertical-align: middle;
				width: 46px;
				margin-right: 15px;
			}

		}
	}

	.home_concept {
		.column {
			@include mq(sp) {
				margin-bottom: 0;
			}
			.row {
				@include mq(sp) {
					@include flex-direction(column);
				}

				.cell {
					@include mq(sp) {
						width: 108%;
						margin-left: -4%;
					}
				}
			}
		}
	}

	.home_point {
		@include mq(sp) {
			width: 92%;
			margin: 0 auto;
		}
		&__item {
			@include mq(sp,true) {
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				padding: 50px 0;
			}

			@include mq(sp) {
				padding: 0;
				margin-top: 30px;
			}
			&:first-of-type {
				@include mq(sp) {
					margin-top: 0;
				}
			}
		}
		&__content {
			@include mq(sp,true) {
				padding-left: 9%;
				width: 45%;
			}

			@include mq(sp) {
				margin-bottom: 40px;
			}
		}
		&__thumb {
			@include mq(sp,true) {
				width: 38%;
			}
			position: relative;

			.num {
				position: absolute;
				right: 30px;
				top: -.5em;
				z-index: 10;
				color: #2F6CB5;
				font-size:fs(81);
				font-family: $family_en_heebo;
				font-weight: 600;
				line-height: 1;

				@include mq(sp) {
					font-size:fs(53);
				}
				&:first-letter {
					color: $color_text;
				}
			}
		}
	}

	.home_place {
		@include mq(sp, true) {
			@include display-flex;
			@include flex-wrap(wrap);
			@include justify-content(space-between);
			@include align-items(center);
		}

		@include mq(sp) {
			width: 92%;
			margin: 0 auto;
			padding-top: 0;
		}

		&__item {
			padding-bottom: 20px;

			@include mq(sp, true) {
				width: 49.5%;
				border-bottom: 1px solid #D6D5D5;
				margin-bottom: 25px;
			}

			@include mq(sp) {
				margin-bottom: 0;
			}

			a {
				position: relative;
				display: block;
				padding: 7.5vw 0;
				background-position: center bottom;
				background-size: cover;
				font-family: $family_en_heebo;
				font-weight: 600;

				@include mq(sp) {
					padding: 50px 0;
				}

				&:hover {
					text-decoration: none;

					.home_place__btn {
						background-color: $color_main;
						color: #fff;

						span {
							&::before {
								border-color: #fff;
							}

							&::after {
								border-color: #fff;
							}
						}
					}
				}

				&.kobe {
					background-image: url(../img/top/image_location_01.jpg);
				}

				&.kix {
					background-image: url(../img/top/image_location_02.jpg);
				}

				&.zest {
					background-image: url(../img/top/image_location_03.jpg);
				}

				&.yamatoda {
					background-image: url(../img/top/image_location_04.jpg);
				}

				&.tokyo {
					background-image: url(../img/top/image_location_05.jpg);
				}


			}
		}

		&__name {
			text-align: center;
			color: #fff;
			letter-spacing: 2px;

			@include mq(sp, true) {
				font-size: fs(57);
			}

			@include mq(sp) {
				font-size: fs(29);
			}
		}

		&__btn {
			position: absolute;
			right: 0;
			bottom: -20px;
			background-color: #fff;
			width: 60%;
			color: $color_text;
			transition: background-color .3s;

			@include mq(sp, true) {
				font-size: fs(25);
			}

			@media screen and (max-width: 1300px) {
				font-size: fs(22);
			}

			@include mq(sp) {
				font-size: fs(12);
			}

			span {
				display: block;
				position: relative;
				padding: 20px 25px;

				@include mq(sp, true) {
					padding: 20px 25px;
				}

				@include mq(sp) {
					padding: 10px 15px;
				}

				&::before {
					content: "";
					display: block;
					border: #ACACAC 1px solid;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: border-color .3s;

					@include mq(sp, true) {
						width: 34px;
						height: 34px;
						right: 25px;
					}

					@include mq(sp) {
						width: 17px;
						height: 17px;
						right: 15px;
					}
				}

				&::after {
					content: "";
					display: block;
					border-right: 1px solid $color_line;
					border-bottom: 1px solid $color_line;
					transform: rotate(-45deg) translateY(-50%);
					position: absolute;
					top: 50%;
					transition: border-color .3s;

					@include mq(sp, true) {
						right: 37px;
						width: 8px;
						height: 8px;
					}

					@include mq(sp) {
						right: 20px;
						width: 5px;
						height: 5px;
					}
				}
			}
		}
	}

	.home_modal {
		&__inner {
			@include mq(sp, true) {
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				@include flex-wrap(wrap);
			}

			> div {
				margin-bottom: 20px;

				@include mq(sp, true) {
					width: 49.3%;
				}

				&.wide {
					width: 100%;
				}

				a {
					display: block;
					@include mq(sp) {
						min-height: 140px;
					}

					&:hover {
						figure {
							outline-offset: 0;
							opacity: .8;

							img {
								//filter: brightness(80%);
							}
						}
					}
					figure {
						position: relative;
						outline: 1px solid #fff;
						outline-offset: -12px;
						transition: outline-offset .2s, opacity .2s;

						img {
							transition: filter .2s;
						}

						figcaption {
							position: absolute;
							left: 50%;
							top: 50%;
							width: 100%;
							text-align: center;
							transform: translate(-50%,-50%);
							color: #fff;
							font-weight: 500;
							letter-spacing: 1px;

							@include mq(sp, true) {
								font-size: fs(22);
							}

							@include mq(sp) {
								font-size: fs(14);
								width: 100%;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}

	.home_information {

		&__list {
			@include mq(sp, true) {
				width: 72%;
				margin: 0 auto;
			}
		}
		&__item {
			border-bottom: 1px solid #D6D5D5;
			display: block;
			color: $color_text;
			padding: 10px 0;
			line-height: 1.7;

			@include mq(sp, true) {
				font-size: fs(14);
				margin-bottom: 15px;
			}

			@include mq(sp) {
				font-size: fs(14);
				margin-bottom: 0;
			}

			time {
				font-family: $family_en_heebo;
				font-weight: 600;
				float: left;

				@include mq(sp, true) {
					margin-right: 30px;
				}

				@include mq(sp) {
					display: block;
					float: none;
					margin-bottom: 5px;
				}
			}

			> span {
				display: block;
				overflow: hidden;
			}

		}

		&__btn {
			text-align: center;
			margin-top: 60px;

			@include mq(sp) {
				margin-top: 30px;
			}
		}
	}

	.site_footer__reservation {
		display: none;
	}

}
