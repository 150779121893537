@charset "UTF-8";

// browser-sync 邪魔なメッセージを削除
@at-root #__bs_notify__{
	display: none!important;
}

/* -------------------------------------------------------------------------
	Web font
------------------------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Leckerli+One);

/* -------------------------------------------------------------------------
	Foundation
------------------------------------------------------------------------- */
/* Vendor */
@import "foundation/vendor/compass/compass";
@import "foundation/vendor/org";

/* Variable */
@import "foundation/variable/font";
@import "foundation/variable/brakepoints";
@import "foundation/variable/width";
@import "foundation/variable/color";

/* Function */
@import "foundation/function/font";
@import "foundation/function/math";
@import "foundation/function/animation";

/* Mixin */
@import "foundation/mixin/media_query";
@import "foundation/mixin/counter";
@import "foundation/mixin/grid";
@import "foundation/mixin/typography";
@import "foundation/mixin/hover";

/* Base */
@import "foundation/base/reset";
@import "foundation/base/base";

/* -------------------------------------------------------------------------
	Layout
------------------------------------------------------------------------- */
@import "layout/header";
@import "layout/footer";
@import "layout/floating"; /* 固定表示するオブジェクト */

/* -------------------------------------------------------------------------
	Object
------------------------------------------------------------------------- */

/* Component */
@import "object/component/message";
@import "object/component/list";
@import "object/component/notes";

/* Project */
// @import "object/project/icon";
// @import "object/project/grid";
// @import "object/project/headings";
// @import "object/project/section";
// @import "object/project/modules";
// @import "object/project/page_structure"; /* 汎用的にページを構成する要素 */
// @import "object/project/offcanvas"; /* ハンバーガーメニュー */
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_animate.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_btn.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_caption.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_catch_btn.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_default_btn.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_grid.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_headings.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_icon.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_img_rollover.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_links.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_liquid_container.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_area.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_attention_table.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_banner.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_calendar.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_caution.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_cta.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_detail_table.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_instagram.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_option_table.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_plan_table.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_plan.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_price_table.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_schedule.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_module_service_table.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_offcanvas.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_page_content.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_page_main_image.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_section.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/project/_sub_section.scss";


/* Scope */
// @import "object/scope/top";
// @import "object/scope/about";
// @import "object/scope/plan/index";
// @import "object/scope/plan/family";
// @import "object/scope/plan/friend";
// @import "object/scope/plan/angler";
// @import "object/scope/faq";
// @import "object/scope/clause";
// @import "object/scope/information";
// @import "object/scope/access";
// @import "object/scope/oneday";
// @import "object/scope/attractive";
// @import "object/scope/system_404";
// @import "object/scope/system_500";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_attractive.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_error_404.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_error_503.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_faq.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_information.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_location.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_movie.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_privacy.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_tackleinfo.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_terms.scss";
@import "C:/Users/hirata/work/git/FxF-Renewal/src/assets/sass/object/scope/_top.scss";


/* Utility */
@import "object/utility/clear";
@import "object/utility/align";
@import "object/utility/float";
@import "object/utility/text";
@import "object/utility/display";
@import "object/utility/image";
@import "object/utility/just_layout";
@import "object/utility/margin_padding";
@import "object/utility/color";
