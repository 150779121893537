@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?jdd00c');
  src:  url('../font/icomoon.eot?jdd00c#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?jdd00c') format('truetype'),
    url('../font/icomoon.woff?jdd00c') format('woff'),
    url('../font/icomoon.svg?jdd00c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon--"]:before, [class*=" icon--"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--car:before {
  content: "\e906";
}
.icon--train:before {
  content: "\e907";
}
.icon--tel:before {
  content: "\e904";
}
.icon--mail:before {
  content: "\e905";
}
.icon--schedule:before {
  content: "\e903";
  color: #504f4f;
}
.icon--instagram:before {
  content: "\e900";
}
.icon--logo:before {
  content: "\e901";
}
.icon--facebook:before {
  content: "\e902";
}