.sub_section{
	&__head {
		margin-bottom: 50px;
	}

	&__label {
		font-size: fs(43);
		font-family: $family_en_heebo;
		font-weight: 600;
		border-bottom: 1px solid $color_line;
		margin-bottom: 10px;
		letter-spacing: .05em;
	}

	&__heading {
		font-size: fs(15);
		font-weight: 500;
		letter-spacing: .1em;
	}
}