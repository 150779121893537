.module_cta{
	background-color: #f3f3f3;
	padding: 40px 0 72px;
	margin-top: 52px;
	@include mq(sp, true){
		margin-top: 90px;
	}
	a{
		font-family: $family_jp_yuh;
		display: block;
		font-weight: bold;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
	}
	@include mq(sp, true){
		padding: 83px 0 97px;
		.catch_btn{
			padding: 30px 0;
			&__main{
				font-size: fs(36);
				i{
					font-size: 40px;
				}
			}
			&__sub{
				font-size: fs(20);
			}
		}
		.default_btn{
			font-size: fs(24);
			font-feature-settings : "palt";
			letter-spacing: .08em;
			padding-top: 25px;
			padding-bottom: 20px;
			&:before,
			&:after,
			&__top{
				border-width: 4px;
			}

			&__top{
				&:before,
				&:after{
					height: 4px;
				}
			}
		}
	}
}