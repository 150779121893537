/*

location page

*/

body.kobe,
body.kix,
body.zest,
body.yamatoda,
body.tokyo {
	.page_content {
		margin-bottom: 50px;

		@include mq(sp) {
			margin-bottom: 45px;
		}

		> * {
			@include mq(sp) {
				margin-top: 0;
			}
		}
	}

	.page_section {
		padding-top: 45px;
		padding-bottom: 45px;

		&:first-child {
			padding-top: 100px;

			@include mq(sp) {
				padding-top: 50px;
			}
		}

		&:last-child {
			padding-bottom: 0;
		}

		@include mq(sp) {
			padding-top: 25px;
			padding-bottom: 25px;
		}

		&__content {
			@include mq(sp, true) {
				width: 38%;
			}
		}

		&__thumb {
			@include mq(sp, true) {
				width: 48%;
			}
		}
	}

	.page_concept {
		&__image {
			position: relative;

			.image{
				&--01 {
					@include mq(sp,true) {
						width: 72%;
						margin: 0 0 0 auto;
						position: relative;
						z-index: 2;
					}
				}
				&--02 {
					@include mq(sp,true) {
						//position: absolute;
						//left: 0;
						//bottom: -165px;
						z-index: 1;
						width: 51%;
						margin-top: -8vw;
					}
				}
			}
		}

		.heading_lv2 {
			margin-bottom: 40px;

			@include mq(sp) {
				margin-bottom: 30px;
			}
		}
	}

	.page_staff{
		@include mq(sp) {
			margin-top: 0 !important;
		}
		&__img {
			@include mq(sp, true){
				width: calc(100% - 9%);
			}

			@include mq(sp) {
				margin-top: 20px;
			}
		}
		&__inner{
			color: #fff;
			background-color: rgba($color_main,.89);
			padding: 30px 20px;
			@include mq(sp, true){
				@include box-sizing(border-box);
				padding: 55px 9% 60px 70px;
				width: 50%;
				position: relative;
				left: 50%;
				@include translateY(-50%);
				margin-bottom: -180px;
			}
		}
		&__title{
			.elem_shoulder{
				font-size: fs(28);
				letter-spacing: .06em;
				border-bottom: 1px solid #fff;
				padding-bottom: 3px;

				@include mq(sp) {
					font-size: fs(14);
					font-weight: 400;
				}
			}
			.elem_name {
				// @include display-flex;
				// @include align-items(flex-end);
			}
			.elem_main{
				font-size: fs(59);
				margin-right: 15px;
				line-height: 1;
				margin-top: 25px;

				@include mq(sp) {
					font-size: fs(26);
					margin-top: 15px;
				}
			}
			.elem_bottom{
				font-size: fs(20);
				font-weight: normal;
				letter-spacing: .25em;

				@include mq(sp) {
					font-size: fs(12);
					font-weight: 500;
					margin-top: 10px;
				}
			}
			@include mq(sp, true){
				.elem_shoulder{
					font-size: fs(28);
					letter-spacing: .06em;
					font-weight: 500;
					margin-bottom: 10px;
				}
				.elem_main{
					font-size: fs(59);
					margin-right: 25px;
					font-weight: 500;
				}
				.elem_bottom{
					font-size: fs(20);
					font-weight: normal;
					margin-top: 15px;
					letter-spacing: .16em;
				}
			}
		}
		&__contents{
			@include mq(sp){
				font-size: fs(13);
				margin-top: 10px;
			}
			@include mq(sp, true){
				margin-top: 30px;
			}
			p{
				line-height: 2.1;
			}
		}
	}

	.page_staff_group {
		.page_staff {
			position: relative;

			&:not(:last-child) {
				margin-bottom: 90px;

				@include mq(sp) {
					margin-bottom: 30px;
				}
			}

			&__img {
				@include mq(sp, true) {
					position: relative;
					left: 9%;
					width: 37%;
				}

				@include mq(sp) {
					margin-top: 0;
				}
			}

			&__inner {
				@include mq(sp, true) {
					position: absolute;
					top: 110px;
					left: 40%;
					width: 60%;
					transform: translateY(0);
				}
			}
		}
	}

	.page_field {
		&__content {
			margin-bottom: 90px;

			@include mq(sp, true) {
				position: relative;
			}

			@include mq(sp) {
				margin-bottom: 25px;
			}
		}

		.heading_lv1 {
			font-size: fs(45);
			font-weight: 600;

			@include mq(sp) {
				font-size: fs(25);
			}
		}

		.page_section__img {
			@include mq(sp) {
				margin-top: 20px;
			}
		}

		.map_img {
			@include mq(sp, true) {
				position: absolute;
				bottom: -90px;
				right: 0;
				width: 427px;
			}

			@include mq(sp) {
				width: 100%;
				margin-top: 20px;
				padding-right: 15px;
				padding-left: 15px;
				box-sizing: border-box;
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}

	.page_feature {
		.page_section__column {
			margin-top: 60px;

			@include mq(sp) {
				margin-top: 20px;
			}

			> div {
				&:last-of-type {
					margin-top: 100px;
					@include mq(sp) {
						margin-top: 0;
					}
				}
			}
		}

		.heading_lv1 {
			font-size: fs(45);
			font-weight: 600;

			@include mq(sp) {
				font-size: fs(25);
				letter-spacing: 1px;
			}
		}
	}
	.page_feature_img_gap{
		display: flex;
		flex-wrap: wrap;
		gap: 80px 32px;
		width: 82%;
		margin: 85px auto 0;
		@include mq(sp){
			display: block;
			width: 92%;
			margin-top: 40px;
		}
		div{
			max-width: calc((100% - 32px)/ 2);
			@include mq(sp){
				max-width: 100%;
				width: 100%;
				&:not(:last-child){
					margin-bottom: 20px;
				}
			}
			span{
				font-size: 15px;
				margin-top: 20px;
				display: block;
				@include mq(sp){
					margin-top: 10px;
				}
			}
		}
	}

	.page_cafe {

		&__column {
			@include mq(sp,true) {
				margin-bottom: 70px;
			}
			&__inner {
				@include mq(sp,true) {
					@include display-flex;
					@include justify-content(space-between);
					@include align-items(flex-start);
				}
			}

			&.reverse {
				@include mq(sp) {
					@include display-flex;
					@include flex-direction(column-reverse);
				}
			}
		}

		&__text {
			@include mq(sp,true) {
				width: 36%;
				padding-top: 50px;
				border-top: 1px solid $color_line;
			}
		}

		&__image {
			@include mq(sp,true) {
				width: 57%;
			}
		}

		&__cell {
			@include mq(sp,true) {
				width: 48.5%;
			}

			@include mq(sp) {
				margin-top: 20px;
			}
		}

		&__lead {
			margin: 20px 0 60px;
			font-size: fs(20);
			font-weight: 400;
			line-height: 2.5;

			@include mq(sp) {
				font-size: fs(15);
				margin: 20px 0 0;
			}
		}

		&__caption {
			margin-top: 20px;

			@include mq(sp) {
				margin-top: 10px;
			}
		}

		&__info {
			border: #D6D5D5 1px solid;
			font-size: fs(20);
			font-weight: 500;
			line-height: 2.5;
			letter-spacing: 1px;
			padding: 20px 30px;

			@include mq(sp) {
				padding: 10px 15px;
				font-size: fs(14);
				line-height: 1.8;
				margin-top: 20px;
			}
		}
	}

	.page_plan {
		&__item {
			padding-bottom: 15px;

			@include mq(sp, true) {
				width: 49.5%;
			}

			@include mq(sp) {
				width: 92%;
				margin: 0 auto 25px;
				padding-bottom: 5px;
			}
			&:last-of-type {
				@include mq(sp) {
					margin-bottom: 0;
				}
			}

			a {
				position: relative;
				display: block;
				padding: 110px 0;
				background-position: center;
				background-size: cover;
				font-family: $family_en_heebo;
				font-weight: 600;

				@include mq(sp) {
					padding: 50px 0;
				}

				&:hover {
					text-decoration: none;

					.page_plan__btn {
						background-color: $color_main;
						color: #fff;

						span {
							&::before,
							&::after {
								border-color: #fff;
							}
						}
					}
				}

				&.shared {
					background-image: url(../img/kix/image_plan_01.jpg);
				}

				&.charter {
					background-image: url(../img/kix/image_plan_02.jpg);
				}

				&.plan {
					&--a {
						background-image: url(../img/kobe/image_plan_01.jpg);
					}
					&--b {
						background-image: url(../img/kobe/image_plan_02.jpg);
					}
					&--c {
						background-image: url(../img/kobe/image_plan_03.jpg);
					}
				}

				&.not_available {
					position: relative;
					pointer-events: none;
					overflow: hidden;
				}

			}
			&.col3 {
				@include mq(sp, true) {
					width: 31.5%;
				}

				@include mq(sp) {
					width: 100%;
					margin: 0 auto 20px;
				}

				.page_plan {
					&__name {
						span {
							font-size: fs(22);
							font-weight: 500;
							display: block;
							margin-bottom: 5px;

							@include mq(sp) {
								font-size: fs(18);
							}
						}
						b {
							display: inline-block;
							font-size: fs(28);

							@include mq(sp) {
								font-size: fs(24);
							}
						}
					}

					&__btn {
						width: 100%;
						border-bottom: 1px solid #D4D0D0;
						b {
							@include mq(sp, true) {
								font-size: fs(18);
							}

							@include mq(sp) {
								font-size: fs(18);
							}
						}
					}
				}
			}

			
		}
		&__item--tokyo{
			
			@include mq(sp, true) {
				width: 854px;
				
				margin: auto;
			}

			@include mq(sp) {
				width: 92%;
			}
			a{
				background-image: url(../img/tokyo/image_plan_02.jpg) !important;
				padding: 40px 0 80px;
				@include mq(sp){
					padding: 50px 0 ;
				}
			}
			.desc{
				font-size: 15px;
				line-height: 1.5;
				font-weight: 400;
				color: #fff;
				text-align: center;
				margin-top: 15px;
				@include mq(sp){
					font-size: 13px;
					padding: 0 20px;
				}
			}
		}
		.page_section__image{
			.zest_schedule,
			.yamatoda_schedule{
				background-image: url(../img/kix/image_plan_03.jpg);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				background-position: center;
				background-size: cover;
				font-weight: 600;
				margin: 0 auto;
				@include mq(sp){
					background-image: url(../img/kix/image_plan_03_sp.jpg);
					height: auto;
					padding: 55px 0;
					display: block;
					background-size: contain;
					background-repeat: no-repeat;
					
				}
				p{
					font-size: fs(29);
					@include mq(sp){
						font-size: fs(22);
					}
				}
			}
			.tokyo_schedule{
				background-image: url(../img/tokyo/image_plan_01.jpg);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				background-position: center;
				background-size: cover;
				font-weight: 600;
				margin: 0 auto;
				@include mq(sp){
					background-image: url(../img/tokyo/image_plan_01_sp.jpg);
					height: auto;
					padding: 55px 0;
					display: block;
					background-size: contain;
					background-repeat: no-repeat;
					
				}
				p{
					font-size: fs(29);
					@include mq(sp){
						font-size: fs(22);
					}
				}
			}
		}

		&__name {
			text-align: center;
			color: #fff;
			letter-spacing: 2px;
			span{
				font-size: fs(35);
				display: block;

				@include mq(sp) {
					font-size: fs(21);
				}
			}

			b {
				font-size: fs(47);
				display: block;
				margin-bottom: 25px;

				@include mq(sp) {
					font-size: fs(28);
					margin-bottom: 15px;
				}
			}

			p {
				font-size: fs(22);

				@include mq(sp) {
					font-size: fs(14);
				}
			}
		}

		&__btn {
			position: absolute;
			right: 0;
			bottom: -15px;
			background-color: #fff;
			width: 60%;
			color: $color_text;
			padding: 15px 25px;
			@include box-sizing(border-box);
			transition: background-color .2s;

			@include mq(sp) {
				bottom: -5px;
				width: auto;
				padding: 10px 15px;
			}


			b {
				display: inline-block;
				font-size: fs(26);

				@include mq(sp) {
					margin-right: 10px;
					font-size: fs(14);
				}
			}

			span {
				font-size: fs(14);
				padding-right: 45px;
				letter-spacing: 1px;

				@include mq(sp) {
					padding-right: 30px;
					font-size: fs(10);
				}

				&::before {
					content: "";
					display: block;
					border: #ACACAC 1px solid;
					width: 32px;
					height: 32px;
					border-radius: 50%;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					transition: border .2s;

					@include mq(sp) {
						width: 16px;
						height: 16px;
					}
				}

				&::after {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					border-right: 1px solid $color_line;
					border-bottom: 1px solid $color_line;
					transform: rotate(-45deg) translateY(-50%);
					position: absolute;
					right: 12px;
					top: 50%;
					transition: border .2s;

					@include mq(sp) {
						width: 4px;
						height: 4px;
						right: 6px;
					}
				}
			}

			&__inner {
				position: relative;
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(center);
			}
		}

		&__availability {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, .7);
			color: #fff;
			font-size: fs(47);
			font-weight: bold;

			@include mq(sp) {
				font-size: fs(28);
			}
		}

		.point {
			border: #D6D5D5 1px solid;
			padding: 20px 30px;
			line-height: 1.8;

			@include mq(sp) {
				padding: 10px 20px;
			}

			dt {
				font-weight: 600;
				margin-bottom: 10px;
				font-size: fs(20);

				@include mq(sp) {
					margin-bottom: 5px;
				}
			}
		}

		&__contact {
			border: $color_line 1px solid;

			@include mq(sp, true) {
				width: 80%;
				display: flex;
				align-items: center;
				margin: 100px auto 0;
			}

			@include mq(sp) {
				margin-top: 40px;
			}

			> * {
				@include mq(sp, true) {
					width: 50%;
					padding: 50px 40px;
				}

				@include mq(sp) {
					padding: 15px 30px;
					text-align: center;
				}

				&:first-child {
					position: relative;
					&::after {
						content: "";
						display: block;
						position: absolute;
						background-color: $color_line;

						@include mq(sp, true) {
							width: 1px;
							height: 60%;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}

						@include mq(sp) {
							width: 90%;
							height: 1px;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			&__info {
				&__lead {
					font-size: fs(20);
					font-weight: 600;
					letter-spacing: 1px;
					margin-bottom: 5px;
					margin-bottom: 10px;

					@include mq(sp) {
						font-size: fs(18);
					}
				}

				.notes {

				}
			}

			&__tel {
				&__num {
					font-size: fs(51);
					font-family: $family_en_heebo;
					font-weight: 500;
					line-height: 1;
					letter-spacing: 1px;
					margin-top: 10px;
					margin-bottom: 5px;

					@include mq(sp) {
						font-size: fs(34);
					}

					i {
						margin-right: 15px;
						vertical-align: middle;
						position: relative;
						top: -11px;

						@include mq(sp) {
							top: -7px;
							margin-right: 10px;
						}

						img {
							@include mq(sp) {
								width: 18px;
							}
						}
					}

					a {
						@include mq(sp) {
							color: $color_text;
						}
					}

					span {
						display: inline-block;
						line-height: 1;
					}
				}

				&__lead {
					font-size: fs(20);
					font-weight: 600;
					letter-spacing: 1px;
					margin-bottom: 5px;

					@include mq(sp) {
						font-size: fs(18);
					}
				}
			}
		}
	}

	.page_marina {
		&__item {
			@include mq(sp, true) {
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				@include flex-direction(row-reverse);
				margin-bottom: 70px;
			}

			@include mq(sp) {
				margin-bottom: 30px;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&__heading {
			margin-bottom: 20px;
			font-size: fs(28);
			font-weight: bold;
			font-feature-settings: "palt";
			letter-spacing: .1em;

			@include mq(sp) {
				margin-bottom: 15px;
				font-size: fs(24);
			}
		}

		&__text {
			@include mq(sp, true) {
				width: 58%;
			}

			.headline {
				&__secondary {
					@include mq(sp, true) {
						margin-bottom: 20px;
					}
				}
			}

			.heading_lv2 {
				font-weight: 700;
			}

			.access {
				margin-top: 20px;
				@include mq(sp, true) {
					@include display-flex;
					@include justify-content(flex-start);
					@include align-items(flex-start);
					@include flex-wrap(wrap);
					line-height: 2;
				}

				@include mq(sp) {
					line-height: 1.8;
				}

				// &:last-of-type {
				// 	margin-top: 0;
				// }

				> *{
					@include mq(sp, true) {
						margin-bottom: 7px;
					}
				}

				dt {
					font-weight: 600;
					@include mq(sp, true) {
						padding-right: 10px;
						//width: 10%;
						@include box-sizing(border-box);
						float: left;
						width: 5.5em;
					}

				}

				dd {
					@include mq(sp, true) {
						//width: 90%;
						overflow: hidden;
					}
					@include mq(sp) {
						margin-bottom: 5px;
					}
				}
			}

			.list_note {
				line-height: 2;

				&:not(:first-child) {
					margin-top: 20px;
				}

				&:not(:last-child) {
					margin-bottom: 20px;
				}

				li {
					margin-bottom: 0;
				}
			}
		}

		&__image {
			@include mq(sp, true) {
				width: 36%;
			}

			@include mq(sp) {
				margin-top: 20px;
			}
		}

		&__item_group {
			&:not(:last-child) {
				margin-bottom: 70px;
			}

			.page_marina__item {
				&:not(:last-child) {
					@include mq(sp, true) {
						margin-bottom: 20px;
					}
				}
			}
		}

		.facilities {
			@include mq(sp, true) {
				margin-bottom: 70px;
			}
			@include mq(sp) {
				margin-bottom: 30px;
			}
			&:last-of-type {
				margin-bottom: 0;
			}

			&__item {
				@include mq(sp, true) {
					@include display-flex;
					@include justify-content(space-between);
					@include align-items(stretch);
				}
			}
			&__text {
				@include mq(sp, true) {
					margin-bottom: 30px;
				}
				@include mq(sp) {
					margin-bottom: 20px;
				}

				.link {
					margin-top: 10px;
					a {
						text-decoration: underline;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
			&__col {
				@include mq(sp, true) {
					margin-right: 30px;
				}
				&:nth-child(2n){
					margin-right: 0;
				}
			}
		}
	}

	.page_price {
		.attention {
			margin-top: 25px;
			line-height: 2;
		}
	}

	.page_access {
		padding-bottom: 10px;

		@include mq(sp) {
			padding-bottom: 0;
		}

		&__body {
			font-size: fs(16);
			line-height: 1.8;
			letter-spacing: 1px;

			dl {
				margin-bottom: 20px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			dt {
				font-weight: 600;
			}

			p {
				margin-bottom: 20px;
			}
		}
	}

	.page_gmap {
		iframe {
			@include mq(sp) {
				height: 260px;
			}
		}
	}

	.page_contact {
		&__body {
			@include mq(sp,true) {
				@include display-flex;
				@include justify-content(space-between);
				@include align-items(center);
				@include flex-direction(row-reverse);
			}
		}

		&__text {
			line-height: 1.7;
			@include mq(sp,true) {
				margin-right: 9%;
				width: 36%;
			}

			@include mq(sp) {
				width: 92%;
				margin: 0 auto 40px;
			}

			.notes {
				font-size: fs(14);
			}
			.zestQR,.tokyoQR{
				width: 285px;
				margin-bottom: 20px;
				@include mq(sp){
					display: none;
				}
				img{
					width: 100%;
				}
			}
			.small_title{
				font-size: fs(15);
				font-weight: bold;
				border: 1px solid #060606;
				display: inline-block;
				padding: 0 5px;
				margin-bottom: 15px;
				@include mq(sp){
					display: block;
					text-align: center;
				}
			}
		}

		&__image {
			@include mq(sp,true) {
				width: 48%;
			}
		}

		&__message {
			font-size: fs(16);
			margin-bottom: 15px;
		}

		&__tel {
			&__label {
				font-size: fs(20);
				font-weight: 600;
				letter-spacing: 1px;
				margin-bottom: 5px;

				@include mq(sp) {
					font-size: fs(18);
				}
			}
			&__value {
				font-size: fs(51);
				font-family: $family_en_heebo;
				font-weight: 500;
				line-height: 1;
				letter-spacing: 1px;
				margin-top: 10px;
				margin-bottom: 5px;

				@include mq(sp) {
					font-size: fs(34);
				}

				i {
					margin-right: 15px;
					vertical-align: middle;
					position: relative;
					top: -11px;

					@include mq(sp) {
						top: -7px;
						margin-right: 10px;
					}

					img {
						@include mq(sp) {
							width: 18px;
						}
					}
				}

				a {
					@include mq(sp) {
						color: $color_text;
					}
				}

				span {
					display: inline-block;
					line-height: 1;
				}
			}
			&__sub {
				p {
					font-size: fs(18);
					margin-bottom: 15px;
				}
				li {
					line-height: 2;
				}
			}
		}

		&__mail {
			a {
				font-weight: 700;
				font-family: $family_en_heebo;
				line-height: 1;
				color: $color_text;
				letter-spacing: 1px;

				@include mq(sp, true) {
					font-size: fs(33);
				}

				@include mq(sp) {
					font-size: fs(24);
				}

				i {
					position: relative;
					top: -4px;
					margin-right: 20px;

					@include mq(sp) {
						margin-right: 15px;
					}

					img {
						@include mq(sp) {
							width: 28px;
						}
					}
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
		&__body__zest,
		&__body__tokyo{
			@include mq(sp){
				display: flex;
				flex-direction: column-reverse;
			}
			
			.page_contact__image__text{
				padding-left: 120px;
				margin-top: 50px;
				@include mq(sp){
					display: none;
				}
				.page_contact__tel{
					&__label {
						font-size: fs(20);
						font-weight: 600;
						letter-spacing: 1px;
						margin-bottom: 5px;
		
						@include mq(sp) {
							font-size: fs(18);
						}
					}
					&__value {
						font-size: fs(51);
						font-family: $family_en_heebo;
						font-weight: 500;
						line-height: 1;
						letter-spacing: 1px;
						margin-top: 10px;
						margin-bottom: 5px;
		
						@include mq(sp) {
							font-size: fs(34);
						}
		
						i {
							margin-right: 15px;
							vertical-align: middle;
							position: relative;
							top: -11px;
		
							@include mq(sp) {
								top: -7px;
								margin-right: 10px;
							}
		
							img {
								@include mq(sp) {
									width: 18px;
								}
							}
						}
		
						a {
							@include mq(sp) {
								color: $color_text;
							}
						}
		
						span {
							display: inline-block;
							line-height: 1;
						}
					}
					&__sub {
						p {
							font-size: fs(18);
							margin-bottom: 15px;
						}
						li {
							line-height: 2;
						}
					}
				}
			}
		}
		

	}

	.page_option {
		.column {
			.content {
				@include display-flex;
				@include flex-direction(column);

				> * {
					width: 100%;
				}

				.price {
					margin-top: auto;

					@include mq(sp) {
						margin-top: 30px;
					}

					dl {
						@include display-flex;
						@include align-items(flex-end);
						letter-spacing: 1px;

						@include mq(sp) {
							letter-spacing: 0;
						}

						dt {
							font-weight: 600;
							margin-right: 20px;
							font-size: fs(16);
						}

						dd {
							.en {
								font-size: fs(45);
								font-weight: 600;
								font-family: $family_en_heebo;
								line-height: 1;
								margin-right: 5px;

								@include mq(sp) {
									font-size: fs(26);
								}
							}
							.jp {
								font-size: fs(27);
								font-weight: 600;

								@include mq(sp) {
									font-size: fs(14);
								}
							}
						}
					}
				}

				.mrb10 {
					margin-bottom: 10px;
					display: block;
				}
			}
		}
	}

	.page_main_image {
		@include mq(sp) {
			margin-bottom: 0;
		}

		&__copy {
			.elem_inner {
				.elem_lead {
					@include mq(sp, true) {
						margin-bottom: 0;
					}
				}

				.elem_title {
					.jp {
						b {
							@include mq(sp, true) {
								margin-top: -.2em;
							}
						}
					}
				}
			}
		}
	}

	.module_instagram {
		&__list {
			dt {
				@include mq(sp, true) {
					font-size: fs(18);
				}

				@include mq(sp) {
					display: inline-block;
					margin-bottom: 30px;
				}
			}

			dd {
				ul {
					@include mq(sp, true) {
						margin-right: -10px;
						margin-bottom: -25px;
					}

					@include mq(sp) {
						flex-direction: column;
					}

					li {
						@include mq(sp, true) {
							margin-right: 10px;
							margin-bottom: 30px;
						}

						@include mq(sp) {
							width: 100%;
							margin-bottom: 10px;
						}

						&:last-child {
							@include mq(sp) {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.feature_gallery {
		display: flex;
		flex-wrap: wrap;
		margin: 65px -20px -20px 0;

		@include mq(sp) {
			margin: 20px 0 0 0;
		}

		&__item {
			width: calc((100% / 3) - 20px);
			margin-right: 20px;
			margin-bottom: 20px;

			@include mq(sp) {
				width: 100%;
				margin-right: 0;
			}

			&--md {
				@include mq(sp, true) {
					width: calc((100% / 2) - 20px);
				}
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}

	.heading_lv4 {
		@include mq(sp) {
			margin-bottom: 25px;
		}

		.en {
			@include mq(sp) {
				line-height: 1;
			}
		}

		.jp {
			font-size: fs(14);

			@include mq(sp) {
				font-size: fs(11);
			}
		}
	}

	.headline {
		&__primary {
			.en {
				@include mq(sp) {
					font-size: fs(28);
				}
			}
		}

		&__secondary {
			.en {
				@include mq(sp) {
					font-size: fs(15);
				}
			}
		}

		&__lead {
			line-height: 2.25;

			@include mq(sp) {
				line-height: 2;
			}
		}
	}

	.table.calendar {
		th {
			box-sizing: border-box;

			&:first-child {
				@include mq(sp) {
					width: 175px;
				}
			}

			&:not(:first-child) {
				width: 70px;

				@include mq(sp) {
					width: 50px;
				}
			}
		}

		tbody {
			td {
				font-size: fs(20);

				@include mq(sp) {
					font-size: fs(16);
				}

				img {
					width: 85%;
				}
			}
		}
	}


}

.info_table {
	line-height: 2;

	&:not(:first-child) {
		margin-top: 20px;
	}

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	&--block {
		display: block;

		> tbody > .info_table__row {
			&,
			> .info_table__head,
			> .info_table__content {
				display: block;
			}

			> .info_table__head {
				padding-right: 0;
			}
		}
	}

	&__row {
		&--sp_preserved {
			@include mq(sp) {
				display: flex;
			}

			> .info_table {
				&__head {
					@include mq(sp) {
						padding-right: .5em;
					}
				}
			}
		}
	}

	&__head {
		text-align: left;

		@include mq(sp, true) {
			padding-right: .5em;
			white-space: nowrap;
		}
	}

	&__content {
		@include mq(sp, true) {
		}
	}

	&:not(&--preserved) {
		@include mq(sp) {
			display: block;
		}

		> tbody > .info_table {
			&__row:not(.info_table__row--sp_preserved),
			&__row > .info_table__head,
			&__row > .info_table__content {
				@include mq(sp) {
					display: block;
				}
			}

			&__row {
				&:not(:last-child) {
					@include mq(sp) {
						margin-bottom: 15px;
					}
				}
			}

			&__head {
				@include mq(sp) {
					padding-right: 0;
				}
			}
		}
	}

	&--preserved {
		> tbody > .info_table {
			&__row {

			}

			&__row > .info_table__head {
				padding-right: .5em;
				white-space: nowrap;
			}
		}
	}
}

body.zest {
	.cta_btn {
		@include mq(sp) {
			display: block;
			position: fixed;
			z-index: 1001;
			right: 10px;
			bottom: 10px;
		}
	}

	.module_instagram {
		&__list {
			dt {
				@include mq(sp, true) {
					width: 21%;
				}
			}
		}
	}

	.zest_table {
		@include mq(sp) {
			display: block;
			overflow-x: auto;
			white-space: nowrap;
		}

		tr {
			&:last-child {
				td {
					border-bottom: 1px solid #d6d5d5;
				}
			}
		}

		th{
			background-color: $color_main;
			border-top: 1px solid #d6d5d5;
			border-right: 1px solid #d6d5d5;
			border-left: 1px solid #d6d5d5;

			&:last-child {
				//border-right: 2px solid #363535;
			}
			@include mq(sp) {
				display: table-cell;
			}
		}
		td{
			border-right: 1px solid #d6d5d5;
			border-bottom: 1px solid #d6d5d5;
			border-left: 1px solid #d6d5d5;

			&:first-child{
				border-left: 1px solid #d6d5d5;
				text-align: left;
				padding-left: 50px;
			}
			&:last-child {
				border-right: 1px solid #d6d5d5;
			}
			@include mq(sp) {
				display: table-cell;
			}
		}
	}

	.merge_row {
		text-align: center !important;
		background-color: #E0E9F4;
	}


	.shared_colum_4 {
		&:first-child {
			width: 29.5%;
		}
		width: 23.5%;
		text-align: center;
	}

	.shared_row_4 {
		&:first-child {
			width: 28%;
		}
		width: 24%;
	}

	.column_4 {
		th {
			&:first-child,
			&:last-child {
				width: 22%;
			}
			&:nth-child(2){
				width: 40%;
			}
			&:nth-child(3){
				width: 16%;
			}

			text-align: center;
		}
	}

	.flex_row {
		td {

			&:first-child,
			&:last-child {
				width: 22%;
			}
			&:nth-child(6){
				width: 16%;
			}
			width: 10%;
		}
	}

	.width-20 {
		width: 20%;
		vertical-align: top;
	}

	.margin-20 {
		margin-top: 20px !important;
	}

	.margin-40 {
		margin-top: 40px !important;
	}
	.pad-20 {
		padding-left: 20px;
	}

	.no-margin-top {
		margin-top: 0;
	}
	.no-margin-bottom {
		margin-bottom: 0;
	}
	.text_regular {
		font-weight: 400;
	}
}

body.yamatoda {
	.module_instagram {
		&__list {
			dt {
				@include mq(sp, true) {
					width: 20%;
				}
			}
		}
	}
	.no-margin-top {
		margin-top: 0;
	}
	.no-margin-bottom {
		margin-bottom: 0;
	}
}

html.over_main_image {
	body.zest,
	body.yamatoda {
		.site_header {
			a {
				color: #fff;
			}

			.drawer_btn i {
				background-color: #fff;
			}
			
		}
	}
}

body.kobe {
	.page_staff{
		&__title{
			.elem_main{
				@include mq(sp,true) {
					font-size: fs(54);
				}
			}
			.elem_bottom{
				@include mq(sp, true) {
					letter-spacing: .02em;
				}
			}
		}
	}
}
body.tokyo{
	.cta_btn {
		@include mq(sp) {
			display: block;
			position: fixed;
			z-index: 1001;
			right: 10px;
			bottom: 10px;
		}
	}
}