.default_btn{
	@mixin _colorize($color){
		&, a{
			color: $color;
			&:hover{
				@include mq(sp, true){
					color: $color;
				}
			}
		}
		&:before,
		&:after{
			border-color: $color;
			@include mq(sp, true){
				border-color: $color;
			}
		}

		.default_btn__top{
			&:before,
			&:after{
				background-color: $color;
			}
		}
	}
	@include _colorize(#333);
	&.attr_light{
		@include _colorize(#fff);
	}

	font-size: fs(14);
	display: inline-block;
	position: relative;
	padding: 11px 35px 10px;
	&:hover{
		text-decoration: none;
	}

	&__top,
	&:after{
		@include box-sizing(border-box);
		content: "";
		border: 2px solid;
		border-top: none;
		width: 100%;
		position: absolute;
		z-index: 0;
		left: 0;
	}
	&:after{
		bottom: 0;
		height: 50%;
	}
	&__top{
		@include transition(height 150ms linear 350ms);
		border-bottom: none;
		bottom: 50%;
		height: 0;
		overflow: hidden;
		&:before,
		&:after{
			@include box-sizing(border-box);
			@include transition(width 350ms linear);
			content: "";
			width: 0;
			height: 2px;
			position: absolute;
			z-index: 0;
			top: 0;
		}
		&:before{
			left: 0;
		}
		&:after{
			right: 0;
		}
	}

	@include mq(sp, true){
		&:hover &__top{
			@include transition(height 150ms linear);
			height: 50%;
			&:before,
			&:after{
				@include transition(width 350ms linear 150ms);
				width: 51%;
			}
		}
	}

}