.module_detail_table{
	width: 100%;
	text-align: left;
	border-top: 1px solid #ddd;

	@include mq(sp, true) {
		margin-bottom: 30px;
	}

	@include mq(sp) {
		margin-bottom: 20px;
	}

	table {
		width: 100%;
	}

	tr {
		border-bottom: 1px solid #ddd;
		&.border_none {
			border-bottom: none;
		}
	}
	th,
	td{
		line-height: 1.75;
		padding: 20px 20px;
		font-weight: normal;
		vertical-align: middle;
		text-align: left;

		@include mq(sp) {
			padding: 10px 20px;
		}
	}
	th{
		font-size: fs(17);
		@include mq(sp){
			font-size: fs(15);
			@include box-sizing(border-box);
			width: 45%;
			padding-right: 20px;
		}
	}
	td{
		font-size: fs(16);
		@include mq(sp) {
			font-size: fs(15);
		}
		.price{
			display: inline-block;
			font-size: fs(30);
			font-family: $family_en_heebo;
			line-height: 1.3;
			@include mq(sp){
				font-size: fs(26);
			}
		}
	}
}