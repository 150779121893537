/*

base font size

*/

/* $basic_font_size : 13, 81.25%; */

/* $basic_font_size : 14, 87.5%; */

$basic_font_size : 15, 93.75%;

/* $basic_font_size : 16, 100%; */

$family_jp_hira: arial,'ヒラギノ角ゴ ProN W3','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;

$family_jp_yuh: arial,"Yu Gothic", YuGothic, '游ゴシック', $family_jp_hira;

$family_jp_noto: YakuHanJP_Noto, "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;

@import url("https://fonts.googleapis.com/css?family=Pacifico");
$family_en_display: "Pacifico", cursive;

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap');
$family_en_heebo: 'Heebo', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;900&display=swap');
$family_en_lato: 'Lato', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');
$family_jp_noto_title: 'Noto Sans JP', sans-serif;