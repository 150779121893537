.module_option_table{
	width: 100%;
	text-align: left;

	@include mq(sp,true) {
		margin-top: 70px;
	}

	table {
		width: 100%;
	}

	tr {
		border-bottom: 1px solid #ddd;
		&.border_none {
			border-bottom: none;
		}
	}
	th,
	td{
		line-height: 1.75;
		font-weight: normal;
		vertical-align: middle;
		text-align: left;

		@include mq(sp, true) {
			padding: 20px 20px;
		}

		@include mq(sp) {
			padding: 10px 5px;
		}
	}
	th{
		font-size: fs(17);
		@include mq(sp){
			@include box-sizing(border-box);
			width: 55%;
			font-size: fs(13);
		}
	}
	td{
		font-size: fs(16);

		@include mq(sp){
			font-size: fs(13);
		}
		.price{
			display: inline-block;
			font-size: fs(30);
			font-family: $family_en_heebo;
			line-height: 1.3;
			@include mq(sp){
				font-size: fs(21);
			}
		}
	}
}